import React from 'react';
import { useSelector } from 'react-redux';

import { Colors } from '../../themes/colors';

// import closeLearnMore from '../../../themes/Images/closeLearnMore.png';

export default ({ closePopup }) => {
  const referralInfo = useSelector((state) => state.user.referralInfo);

  return (
    <div
      style={{
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        zIndex: 10,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <div
        style={{
          backgroundColor: Colors.white,
          alignContent: 'center',
          padding: 10,
          justifyContent: 'center',
          maxWidth: 700,
          minWidth: 500,
          width: '40%',
          borderRadius: 25,
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 50,
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3)',
          shadowColor: Colors.neutral_400,
          shadowOpacity: 0.3,
          shadowOffset: {
            height: 3,
            width: 0,
          },
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 20,
            padding: 10,
          }}
        >
          <p
            style={{
              marginRight: 16,
              fontSize: 20,
              fontWeight: 'bold',
              color: Colors.neutral_300,
              cursor: 'pointer',
              width: '100%',
              textAlign: 'right',
              marginTop: -10,
            }}
            onClick={closePopup}
          >
            X
          </p>
          <div
            style={{
              color: Colors.neutral_600,
              fontFamily: 'Nunito',
              fontWeight: 'bold',
              fontSize: 20,
              marginBottom: 20,
              textAlign: 'center',
            }}
          >
            Disclaimer/ Terms and Conditions
          </div>

          <div
            style={{
              color: Colors.neutral_600,
              fontFamily: 'Nunito',
              fontSize: 16,
              marginBottom: 20,
              lineHeight: 2,
            }}
          >
            Each person who joins GoTu with your unique link and passes the verification process
            will earn both of you the referral bonus of ${referralInfo.rewardReceiverHYG} via direct
            deposit to the payment method in the respective accounts. <br /> <br /> Dental
            hygienists must provide their hygienist license details such as year issued, expiration,
            state-issued by, and university name they received their education to become an RDH.
            Hygienists must also provide a valid government-issued photo I.D. to be used to verify
            their identity with the identity shown on the hygienist license. <br /> <br /> Dental
            offices must provide true and verifiable information and complete a shift to complete
            the verification process. By accepting an offer to earn a referral bonus, you are making
            a decision to allow GoTu to have complete control of the referral process, in-which GoTu
            reserves the right to revoke payment of the referral bonus under any circumstances.{' '}
            <br /> <br /> For further questions, feel free to contact our team via call or text at
            786-460-2170.
          </div>

          <span
            style={{
              color: Colors.neutral_400,
              fontFamily: 'Nunito',
              fontSize: 16,
            }}
          >
            Professionals & Offices in the following states are not eligible to participate in
            Gotu&apos;s referral program or receive referral payments: Massachusetts, Connecticut,
            California.
          </span>
        </div>
      </div>
    </div>
  );
};
