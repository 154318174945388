import React from 'react';
import { Colors } from '../../themes/colors';

const StarIcon = ({ color = Colors.primary_500 }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="9.5" fill={color} />
    <path
      d="M9.5 14.5L12 12M12 12L14.5 9.5M12 12L9.5 9.5M12 12L14.5 14.5"
      stroke="white"
      strokeWidth="2.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default StarIcon;
