import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_FAVORITE_PROFESSIONALS,
  FETCH_OPEN_JOBS_FOR_RECENT_PROFESSIONAL_ACTION,
  fetchFavoriteProfessionals,
  REMOVE_FAVORITE_PROFESSIONAL,
} from '../../../../../actions';
import useLoading from '../../../../../hooks/useLoading';

const actions = [
  FETCH_FAVORITE_PROFESSIONALS,
  REMOVE_FAVORITE_PROFESSIONAL,
  FETCH_OPEN_JOBS_FOR_RECENT_PROFESSIONAL_ACTION,
];

export const useFavoritesList = () => {
  const dispatch = useDispatch();
  const { isLoading } = useLoading(actions);
  const favoriteProfessionals = useSelector((state) => state.user.favoriteProfessionals);
  const filter = useSelector((state) => state.user.professionalHubFilter);
  const filteredProfessionals = favoriteProfessionals.filter((professional) => {
    if (filter === 'all') return true;
    return professional.professions?.includes(filter);
  });

  useEffect(() => {
    dispatch(fetchFavoriteProfessionals());
  }, [dispatch]);

  return {
    isLoading,
    isEmpty: !filteredProfessionals?.length,
    isFiltered: filter !== 'all',
    favoriteProfessionals: filteredProfessionals,
  };
};
