import { iconRegistry } from "./utils";

/**
 * Icon component that renders an SVG icon based on the provided name from the icon registry.
 * 
 * @param {Object} props - The props object.
 * @param {string} props.name - The name of the icon to be rendered. This is required and must match one of the keys in the `iconRegistry`.
 * @param {number} [props.width] - The width of the icon. Defaults to icon default if not provided.
 * @param {number} [props.height] - The height of the icon. Defaults to icon default if not provided.
 * @param {string} [props.color] - The color of the icon. Optional, and will use the default color if not provided.
 * 
 * @returns {JSX.Element} The rendered SVG icon component.
 * 
 */
export const Icon = ({ name, width, height, size, color }) => {
  const IconComponent = iconRegistry[name];
  return <IconComponent color={color} width={width} height={height} size={size} />;
}