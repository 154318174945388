import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { Colors } from "../../../../../themes/colors";
import { CloseCircleIcon } from "../../../../../images/icons";
import { Text } from "../../../../../commonComponents";
import { deleteAllAlerts, deleteOneAlert, hideMessage, showMessage } from "../../../../../actions";

export const useTaskDeletion = () => {
  const dispatch = useDispatch();

  const handleDeleteAll = useCallback(() => {
    dispatch(
      showMessage({
        title: 'Are you sure you want to clear all your tasks?',
        message: () => (
          <article style={{ textAlign: 'center' }}>
            <Text color={Colors.neutral_500}>
              Are you sure you would like to clear all your tasks?
            </Text>
            <br />
            <Text color={Colors.neutral_500}>
              This action can’t be undone.
            </Text>
          </article>
        ),
        isError: true,
        popupProps: {
          Icon: () => <CloseCircleIcon size={80} />,
          leftButtonText: 'Go Back',
          leftButtonAction: () => dispatch(hideMessage()),
          rightButtonText: 'Remove',
          rightButtonAction: () => {
            dispatch(deleteAllAlerts());
            dispatch(hideMessage());
          },
        },
      }),
    );
  }, [dispatch]);

  const handleDeleteOne = useCallback((alert) => {
    dispatch(
      showMessage({
        title: 'Are you sure you want to delete a task?',
        message: '',
        isError: true,
        popupProps: {
          Icon: () => <CloseCircleIcon size={80} />,
          leftButtonText: 'Go Back',
          leftButtonAction: () => dispatch(hideMessage()),
          rightButtonText: 'Remove',
          rightButtonAction: () => {
            dispatch(deleteOneAlert({alertId: alert.id}));
            dispatch(hideMessage());
          },
        },
      }),
    );
  }, [dispatch]);

  return {
    handleDeleteAll,
    handleDeleteOne,
  }
};