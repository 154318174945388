import { all, fork } from 'redux-saga/effects';
// eslint-disable-next-line import/no-unresolved, import/extensions
import sagaOfficeReview from './sagas/sagaOfficeReview';
import sagaPayments from './sagas/sagaPayments';
import sagaAuth from './sagas/sagaAuth';
import sagaJob from './sagas/sagaJob';
import sagaAlerts from './sagas/sagaAlerts';
import sagaMessages from './sagas/sagaMessages';
import sagaPP from './sagas/sagaPP';
import sagaAdjustments from './sagas/sagaAdjustments';
import sagaFavoritesProfessionals from './sagas/sagaFavoritesProfessionals';

export default function* rootSaga() {
  yield all([
    fork(sagaPayments),
    fork(sagaAuth),
    fork(sagaJob),
    fork(sagaAlerts),
    fork(sagaPP),
    fork(sagaMessages),
    fork(sagaAdjustments),
    fork(sagaFavoritesProfessionals),
    fork(sagaOfficeReview),
  ]);
}
