import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '../../../commonComponents';
import OfficeAvatar1 from '../../../images/avatars/Office-Avatar-1.svg';
import { Colors } from '../../../themes/colors';
import { ChangeAccountPhotoModal } from './ChangeAccountPhotoModal';

export const ChangeAccountPhoto = () => {
  const user = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);
  const [showChangeAccountPhotoModal, setShowChangeAccountPhotoModal] = useState(false);

  return (
    <div
      onClick={() => setShowChangeAccountPhotoModal(true)}
      style={{
        cursor: 'pointer',
        position: 'relative',
      }}
    >
      <img
        alt="profile"
        src={user?.profile_image ?? OfficeAvatar1}
        style={{
          width: 120,
          height: 120,
          borderRadius: '100%',
          resizeMode: 'contain',
        }}
      />
      <div
        style={{
          position: 'absolute',
          backgroundColor: Colors.secondary_500,
          borderRadius: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 6,
          borderWidth: 4,
          borderColor: Colors.white,
          borderStyle: 'solid',
          right: 0,
          top: 80,
        }}
      >
        <Icon name="camera" />
      </div>

      {showChangeAccountPhotoModal && (
        <ChangeAccountPhotoModal onClose={() => setShowChangeAccountPhotoModal(false)} />
      )}
    </div>
  );
};
