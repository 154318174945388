import history from '../../../../history';
import ArrowDownIcon from '../../../../images/icons/ArrowDown';
import BulbIcon from '../../../../images/icons/BulbIcon';
import EditPencilIcon from '../../../../images/icons/EditPencil';
import { Colors } from '../../../../themes/colors';

export default function BackTipsCard({ localDate, onReturnClick }) {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: Colors.secondary_25,
        borderRadius: 8,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingLeft: '16px',
          paddingRight: 16,
          paddingTop: 18,
          marginBottom: 12,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: `1px solid ${Colors.neutral_100}`,
            paddingBottom: 6,
            paddingRight: 12,
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={onReturnClick}
          >
            <div style={{ transform: 'rotate(90deg)' }}>
              <ArrowDownIcon />
            </div>
            <span className="card-title" style={styles.titleStyle}>
              Return
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 4,
            }}
          >
            <BulbIcon color={Colors.secondary_500} />
            <b
              style={{
                fontFamily: 'Nunito',
                color: Colors.primary_500,
                fontSize: 18,
                marginTop: 4,
              }}
            >
              Tips!
            </b>
          </div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingRight: 20,
          paddingLeft: 24,
          gap: 2,
        }}
      >
        <span style={styles.textStyle}>
          Boost your chances of filling this shift! We recommend increasing the hourly rate beyond
          the industry average on shifts posted within <b>36 hours</b> from its starting time.
          <br />
          Click “Edit” to bump the rate higher.
        </span>
      </div>

      <div
        style={{
          position: 'absolute',
          cursor: 'pointer',
          display: 'flex',
          gap: 4,
          right: 32,
          bottom: 24,
        }}
        onClick={() =>
          history.push('/dashboard/multi-day/edit', {
            editedDate: localDate,
            defaultOpenCardIndex: 'rate',
          })
        }
      >
        <EditPencilIcon color={Colors.secondary_500} />
        <b style={{ fontSize: 18, fontFamily: 'Nunito', color: Colors.secondary_500 }}>Edit</b>
      </div>
    </div>
  );
}

const styles = {
  titleStyle: {
    color: Colors.secondary_500,
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    fontSize: 18,
    marginLeft: 10,
    textTransform: 'capitalize',
  },
  textStyle: {
    color: Colors.neutral_600,
    fontFamily: 'Nunito',
    fontSize: 16,
    marginTop: 2,
  },
};
