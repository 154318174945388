import { Colors } from '../../themes/colors';

export const PhoneIcon = ({ color = Colors.secondary_500, size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Solid/phone">
      <path
        id="Icon"
        d="M2.40002 3.5999C2.40002 2.93716 2.93728 2.3999 3.60002 2.3999H6.18347C6.77008 2.3999 7.27071 2.824 7.36714 3.40262L8.25432 8.72567C8.34093 9.24531 8.07849 9.76067 7.6073 9.99626L5.74954 10.9251C7.08909 14.2539 9.74604 16.9108 13.0748 18.2504L14.0037 16.3926C14.2393 15.9214 14.7546 15.659 15.2743 15.7456L20.5973 16.6328C21.1759 16.7292 21.6 17.2298 21.6 17.8165V20.3999C21.6 21.0626 21.0628 21.5999 20.4 21.5999H18C9.38438 21.5999 2.40002 14.6155 2.40002 5.9999V3.5999Z"
        fill={color}
      />
    </g>
  </svg>
);
