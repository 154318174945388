import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createLoadingSelector } from '../../api/selectors';
import CustomSpinner from '../../commonComponents/CustomSpinner';
import {
  fetchPPapplications,
  postPPAction,
  fetchPPapplicationById,
  FETCH_APPLICATIONS_PP,
  FETCH_APPLICATION_BY_ID_PP,
} from '../../actions';
import BackButton from '../../commonComponents/BackButton';
import { Colors } from '../../themes/colors';
import CandidatePopup from './Popups/CandidatePopup';
import InterviewPopup from './Popups/InterviewPopup';
import DecisionPopup from './Popups/DecisionPopup';
import { ActionTypes } from './CandidateCards/Enums';
import history from '../../history';
import {
  renderCandidateStage,
  renderDeclinedStage,
  renderInterviewStage,
  renderRejectedStage,
  renderEmptyState,
  renderDecisionStage,
} from './RenderStages';
import RejectedPoup from './Popups/RejectedPoup';
import InterviewDetailsPopup from './Popups/InterviewDetailsPopup';
import ConfirmHirePopup from './Popups/ConfirmHirePopup';
import { HiredCandidateCard } from './CandidateCards/Cards';
import Confetti from './Confetti';

export default ({ match }) => {
  const user = useSelector((state) => state.user.user);

  const [showCandidatePopup, setShowCandidatePopup] = useState(false);
  const [showInterviewPopup, setShowInterviewPopup] = useState(false);
  const [showDecisionPopup, setShowDecisionPoup] = useState(false);
  const [showInreviewDetailsPopup, setShowInreviewDetailsPoup] = useState(false);
  const [showRejectedPopup, setShowRejectedPoup] = useState(false);
  const [showConfirmationHirePopup, setShowConfirmationHirePopup] = useState(false);
  const [notes, saveNotes] = useState('');

  const loadingSelector = createLoadingSelector([
    FETCH_APPLICATIONS_PP,
    FETCH_APPLICATION_BY_ID_PP,
  ]);

  const isLoading = useSelector((state) => loadingSelector(state));

  const hiredCandidte = useSelector((state) => state.pp.hiredCandidte);

  const currentApplication = useSelector((state) => state.pp.currentApplication);

  const candidateApplication = useSelector((state) => state.pp.candidateApplication);
  const declinedApplication = useSelector((state) => state.pp.declinedApplication);
  const rejectedApplication = useSelector((state) => state.pp.rejectedApplication);
  const interviewApplication = useSelector((state) => state.pp.interviewApplication);
  const decisiopnApplication = useSelector((state) => state.pp.decisiopnApplication);

  const [selectedCandidate, setSelectedCandidate] = useState(null);

  const jobId = history.location.state ? history.location.state.jobId : match.params.jobId;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPPapplications({ jobId }));
  }, []);

  useEffect(() => {
    if (hiredCandidte && hiredCandidte.length > 0) {
      dispatch(fetchPPapplicationById({ jobId, applicationId: hiredCandidte[0].applicationId }));
      setSelectedCandidate(hiredCandidte[0]);
    }
  }, [hiredCandidte]);

  const openCandidateClicked = (candidate) => {
    dispatch(fetchPPapplicationById({ jobId, applicationId: candidate.applicationId }));
    setSelectedCandidate(candidate);
    setShowCandidatePopup(true);
  };

  const openRejectedPopupClicked = (candidate) => {
    dispatch(fetchPPapplicationById({ jobId, applicationId: candidate.applicationId }));
    setSelectedCandidate(candidate);
    setShowRejectedPoup(true);
  };

  const showInterviewClicked = (candidate) => {
    dispatch(fetchPPapplicationById({ jobId, applicationId: candidate.applicationId }));
    setSelectedCandidate(candidate);
    setShowInreviewDetailsPoup(true);
  };

  const showDecisionPopupClicked = (candidate) => {
    dispatch(fetchPPapplicationById({ jobId, applicationId: candidate.applicationId }));
    setSelectedCandidate(candidate);
    setShowDecisionPoup(true);
  };

  if (hiredCandidte.length !== 0) {
    return (
      <div className="component_container">
        <div
          className="floating_container"
          style={{
            flexDirection: 'column',
            padding: 40,
            justifyContent: 'flex-start',
            alignItems: 'normal',
          }}
        >
          <p
            style={{
              fontFamily: 'Nunito',
              fontWeight: 'bold',
              fontSize: 35,
              color: Colors.primary_500,
            }}
          >
            Congratulations! You’ve Successfully Hired {selectedCandidate?.professional?.first_name}
          </p>

          <HiredCandidateCard
            currentApplication={currentApplication}
            selectedCandidate={selectedCandidate}
          />
        </div>
        <Confetti />
      </div>
    );
  }

  const renderStages = () => (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          width: '100%',
          flex: 0.5,
          display: 'flex',
          marginBottom: 30,
        }}
      >
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          {renderCandidateStage({ candidateApplication, openCandidateClicked })}
          {renderInterviewStage({ interviewApplication, showInterviewClicked })}
          {renderDecisionStage({ decisiopnApplication, showDecisionPopupClicked })}
        </div>
      </div>
      <div
        style={{
          backgroundColor: Colors.neutral_300,
          width: '100%',
          height: 1,
          marginTop: 30,
          marginBottom: 30,
        }}
      />

      <div
        style={{
          width: '100%',
          flex: 0.5,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {renderDeclinedStage({ declinedApplication, openCandidateClicked })}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '30%',
          }}
        />
        {renderRejectedStage({ rejectedApplication, openRejectedPopupClicked })}
      </div>
    </div>
  );
  return (
    <div className="component_container">
      <div className="floating_container" style={{ flexDirection: 'column', padding: 40 }}>
        <p
          style={{
            fontFamily: 'Nunito',
            fontWeight: 'bold',
            fontSize: 35,
            color: Colors.primary_500,
          }}
        >
          Candidates Home
        </p>
        {renderStages()}

        {showCandidatePopup && selectedCandidate ? (
          <CandidatePopup
            isDeclinedStage={selectedCandidate?.lastApplicationAction?.action === 'declined'}
            closePopupClicked={() => setShowCandidatePopup(false)}
            declinePressed={() => {
              setShowCandidatePopup(false);
              if (selectedCandidate?.lastApplicationAction?.action !== 'declined') {
                dispatch(
                  postPPAction({
                    actionType: ActionTypes.declined,
                    jobId,
                    applicationId: selectedCandidate.applicationId,
                  }),
                );
              }
            }}
            nextPressed={() => {
              setShowCandidatePopup(false);
              setShowInterviewPopup(true);
            }}
          />
        ) : (
          <div />
        )}

        {showInterviewPopup && selectedCandidate ? (
          <InterviewPopup
            selectedCandidate={selectedCandidate}
            closePopupClicked={() => setShowInterviewPopup(false)}
            selectInterviewClicked={(interviewType) => {
              setShowInterviewPopup(false);
              dispatch(
                postPPAction({
                  actionType: interviewType,
                  jobId,
                  applicationId: selectedCandidate.applicationId,
                }),
              );
            }}
          />
        ) : (
          <div />
        )}

        {showDecisionPopup && selectedCandidate ? (
          <DecisionPopup
            selectedCandidate={currentApplication}
            closePopupClicked={() => setShowDecisionPoup(false)}
            interviewPressed={() => {
              setShowDecisionPoup(false);
              setShowInterviewPopup(true);
            }}
            declinePressed={(notes) => {
              setShowDecisionPoup(false);
              dispatch(
                postPPAction({
                  actionType: ActionTypes.rejected,
                  jobId,
                  applicationId: selectedCandidate.applicationId,
                  notes,
                }),
              );
            }}
            hirePressed={(notes) => {
              setShowDecisionPoup(false);
              saveNotes(notes);
              setShowConfirmationHirePopup(true);
            }}
          />
        ) : (
          <div />
        )}

        {showRejectedPopup && selectedCandidate ? (
          <RejectedPoup
            selectedCandidate={selectedCandidate}
            closePopupClicked={() => setShowRejectedPoup(false)}
            hirePressed={() => {
              setShowRejectedPoup(false);
              setShowConfirmationHirePopup(true);
            }}
            interviewPressed={() => {
              setShowRejectedPoup(false);
              setShowInterviewPopup(true);
            }}
          />
        ) : (
          <div />
        )}

        {showInreviewDetailsPopup ? (
          <InterviewDetailsPopup
            selectedCandidate={selectedCandidate}
            closePopupClicked={() => setShowInreviewDetailsPoup(false)}
          />
        ) : (
          <div />
        )}

        {showConfirmationHirePopup ? (
          <ConfirmHirePopup
            selectedCandidate={selectedCandidate}
            closePopupClicked={() => setShowConfirmationHirePopup(false)}
            confirmHasBeenClicked={() => {
              setShowConfirmationHirePopup(false);
              dispatch(
                postPPAction({
                  actionType: ActionTypes.hired,
                  jobId,
                  applicationId: selectedCandidate.applicationId,
                  notes,
                }),
              );
            }}
          />
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};
