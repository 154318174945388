import { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { getAlerts, getUserMessages } from "../../../actions";
import { enableChatsUnread, enableLastJobPostedReminders, enablePaymentExpirationReminders, enableProfileCompletenessReminders } from "../../../growthbook";

const LIMIT_VALUE = 15;

export const useInbox = () => {
  const dispatch = useDispatch();

  const isEnableChatUnread = useRef(enableChatsUnread()).current;
  const isEnableLastJobPostedReminders = useRef(enableLastJobPostedReminders()).current;
  const isEnablePaymentExpirationReminders = useRef(enablePaymentExpirationReminders()).current;
  const isEnableProfileCompletenessReminders = useRef(enableProfileCompletenessReminders()).current;

  const isEnableNotificationReminder = 
    isEnableLastJobPostedReminders || 
    isEnablePaymentExpirationReminders || 
    isEnableProfileCompletenessReminders;

  const getMessages = useCallback(() => {
    dispatch(getUserMessages({
      page: 1,
      limit: LIMIT_VALUE,
      status: isEnableChatUnread ? "unread" : undefined,
    }));
  }, [dispatch, isEnableChatUnread]);

  const getNotifications = useCallback(() => {
    dispatch(getAlerts({
      page: 1,
      limit: LIMIT_VALUE,
    }));
  }, [dispatch]);

  useEffect(() => {
    if (isEnableNotificationReminder) {
      getNotifications();
    }
  }, [getNotifications, isEnableNotificationReminder]);
  
  useEffect(() => {
    if (isEnableChatUnread) {
      getMessages();
    }
  }, [getMessages, isEnableChatUnread]);
  
  return {
    getMessages,
    getNotifications,
  }
};