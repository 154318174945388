import React, { useCallback, useEffect, useRef } from 'react';

import { Colors } from '../themes/colors';
import logoLightFull from '../images/logos/logoLightFull.svg';
import DropdownMenu from '../commonComponents/DropdownMenu';
import history from '../history';
import { Links } from '../constants';

const Header = ({ onClick, isOpen, toggleDropdown, text }) => {
  const dropdownRef = useRef(null);

  const handleClickOutside = useCallback(
    (event) => {
      const visibleDropdown = dropdownRef.current?.querySelector(
        '#dropdown-content[style*="visibility: visible"]',
      );

      if (visibleDropdown && !dropdownRef.current?.contains(event.target)) {
        toggleDropdown();
      }
    },
    [toggleDropdown],
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => document.removeEventListener('click', handleClickOutside);
  }, [handleClickOutside]);

  return (
    <div className="header" ref={dropdownRef}>
      <img
        src={logoLightFull}
        alt="logo_light_full"
        className="logo"
        style={{ cursor: 'pointer', width: 95, marginLeft: 30 }}
        onClick={() => history.push('/')}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          color: isOpen ? Colors.white : Colors.success_500,
        }}
      >
        <p
          style={{
            cursor: 'pointer',
            marginRight: 30,
            fontSize: 18,
            fontFamily: 'Nunito',
          }}
          onClick={onClick}
        >
          {text}
        </p>
        <DropdownMenu isOpen={isOpen} toggleDropdown={toggleDropdown}>
          <a
            href={Links.temporaryStaffing}
            target="_blank"
            style={{
              textDecoration: 'none',
              textAlign: 'right',
              padding: '0 40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
            rel="noreferrer"
          >
            <p style={{ color: Colors.neutral_600, fontSize: '18px' }}>Dental Office</p>
          </a>
          <div
            style={{
              borderBottom: `1px solid ${Colors.neutral_100}`,
              fontFamily: 'Nunito',
            }}
          />
          <a
            href={Links.hygienist}
            target="_blank"
            style={{
              textDecoration: 'none',
              textAlign: 'right',
              padding: '0 40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
            rel="noreferrer"
          >
            <p
              style={{
                color: Colors.neutral_600,
                fontSize: '18px',
                fontFamily: 'Nunito',
              }}
            >
              Dental Professional
            </p>
          </a>
        </DropdownMenu>
      </div>
    </div>
  );
};

export default Header;
