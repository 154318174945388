import React from 'react';

const CloseIcon = ({ color = '#307F92' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.15152 1.15146C1.62015 0.68283 2.37995 0.68283 2.84858 1.15146L8.00005 6.30293L13.1515 1.15146C13.6202 0.68283 14.3799 0.68283 14.8486 1.15146C15.3172 1.62009 15.3172 2.37989 14.8486 2.84852L9.69711 7.99999L14.8486 13.1515C15.3172 13.6201 15.3172 14.3799 14.8486 14.8485C14.3799 15.3171 13.6202 15.3171 13.1515 14.8485L8.00005 9.69704L2.84858 14.8485C2.37995 15.3171 1.62015 15.3171 1.15152 14.8485C0.682892 14.3799 0.682892 13.6201 1.15152 13.1515L6.30299 7.99999L1.15152 2.84852C0.682892 2.37989 0.682892 1.62009 1.15152 1.15146Z"
    />
  </svg>
);

export default CloseIcon;
