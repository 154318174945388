import { useSelector } from 'react-redux';
import { EmptyScreen } from '../../../commonComponents/EmptyScreen/EmptyScreen';
import { Table } from '../../../commonComponents/Table/Table';
import { WCConfirmedWorkerTypeIcon } from '../../../images/emptyScreenIcons';
import { headerConfirmedWorkerTypesTitles } from '../utils';
import { renderRow } from './renderRow';

export const ConfirmedWorkerType = () => {
  const confirmedRequests = useSelector(
    (state) => state.user.workerClassificationManagement.confirmed,
  );

  if (!confirmedRequests?.length) {
    return (
      <div
        style={{
          marginTop: -20,
          height: 400,
        }}
      >
        <EmptyScreen
          width={420}
          Icon={WCConfirmedWorkerTypeIcon}
          title="No worker type has been confirmed yet!"
          description={(
            <>
              No offices have confirmed a worker type yet. Visit the “Unselected” or “Pending
              Requests” tabs on this page to verify worker types for your offices!
            </>
          )}
        />
      </div>
    );
  }

  return (
    <Table
      data={confirmedRequests}
      renderRow={renderRow}
      headerTitles={headerConfirmedWorkerTypesTitles}
    />
  );
};
