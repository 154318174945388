import CustomSpinner from '../../../../commonComponents/CustomSpinner';
import { Table } from '../../../../commonComponents/Table/Table';
import useLoading from '../../../../hooks/useLoading';
import { useTable } from '../hooks/useTable';
import { EmptyMessage } from './EmptyMessage';

export const CustomTable = ({
  type,
  emptyMessageType,
  data,
  renderRow,
  headerTitles,
  onRowClick,
  actions,
}) => {
  const { jobListScrollRef, isLoadingFirstPage } = useTable({ type });
  const { isLoading } = useLoading(actions);

  if (isLoadingFirstPage) {
    return <CustomSpinner />;
  }

  return (
    <div
      style={{
        height: 500,
        overflowY: 'auto',
        paddingBottom: 20,
      }}
    >
      {!data?.length && <EmptyMessage type={emptyMessageType ?? type} />}

      {data?.length > 0 && (
        <Table
          tableRef={jobListScrollRef}
          data={data}
          renderRow={renderRow}
          headerTitles={headerTitles}
          showActions
          onRowClick={onRowClick}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};
