export const WCPendingRequestsIcon = () => (
  <svg
    width="172"
    height="171"
    viewBox="0 0 172 171"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="85.8278" cy="85.44" rx="85.8278" ry="85.44" fill="#EBF4F5" />
    <path
      d="M70.05 34.8994C66.4878 34.8994 63.6 37.7872 63.6 41.3494C63.6 44.9116 66.4878 47.7994 70.05 47.7994H82.9501C86.5123 47.7994 89.4001 44.9116 89.4001 41.3494C89.4001 37.7872 86.5123 34.8994 82.9501 34.8994H70.05Z"
      fill="#A4E0E0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.8 54.2494C37.8 47.1249 43.5756 41.3494 50.7 41.3494C50.7 52.0361 59.3633 60.6994 70.05 60.6994H82.9501C93.6368 60.6994 102.3 52.0361 102.3 41.3494C109.425 41.3494 115.2 47.1249 115.2 54.2494V125.199C115.2 132.324 109.425 138.099 102.3 138.099H50.7001C43.5756 138.099 37.8 132.324 37.8 125.199V54.2494ZM58.3 80.0494C54.7377 80.0494 52.8709 82.6176 52.8709 85.414C52.8709 88.2103 55.2509 90.7785 58.3 90.7785C60.8477 90.7785 63.3916 88.5875 63.6646 85.414C63.3916 82.6176 61.8622 80.0494 58.3 80.0494ZM76.5 81.1348C72.9378 81.1348 70.05 82.6176 70.05 85.414C70.05 88.2103 72.9378 89.6931 76.5 89.6931H95.85C99.4123 89.6931 101.924 88.0448 102.3 85.414C101.924 82.6176 99.4123 81.1348 95.85 81.1348H76.5ZM58.2355 105.849C55.7936 105.954 52.8709 107.652 52.8709 111.214C52.8709 113.926 55.4544 116.579 58.2355 116.579H58.3C60.6781 116.579 63.6646 114.095 63.6646 111.214C63.6646 107.82 60.6781 105.954 58.3 105.849H58.2355ZM76.5 106.935C72.9378 106.935 70.2254 108.402 70.05 111.214C69.9042 113.552 72.9378 115.493 76.5 115.493H95.85C99.4123 115.493 102.3 113.76 102.3 111.214C102.3 108.668 99.4123 106.935 95.85 106.935H76.5Z"
      fill="#A4E0E0"
    />
    <path
      d="M69.9998 38.6667H59.3332C53.4421 38.6667 48.6665 43.4423 48.6665 49.3333V113.333C48.6665 119.224 53.4421 124 59.3332 124H112.667C118.558 124 123.333 119.224 123.333 113.333V49.3333C123.333 43.4423 118.558 38.6667 112.667 38.6667H102M69.9998 38.6667C69.9998 44.5577 74.7755 49.3333 80.6665 49.3333H91.3332C97.2242 49.3333 102 44.5577 102 38.6667M69.9998 38.6667C69.9998 32.7756 74.7755 28 80.6665 28H91.3332C97.2242 28 102 32.7756 102 38.6667M85.9998 76H102M85.9998 97.3333H102M69.9998 76H70.0532M69.9998 97.3333H70.0532"
      stroke="#0B8397"
      strokeWidth="8.7"
      strokeLinecap="round"
    />
    <path
      d="M140.057 117.472C140.057 130.466 129.523 141 116.528 141C103.534 141 93 130.466 93 117.472C93 104.477 103.534 93.9434 116.528 93.9434C129.523 93.9434 140.057 104.477 140.057 117.472Z"
      fill="#EBF4F5"
    />
    <path
      d="M116.528 104.587V118.779H125.678M140.057 117.472C140.057 130.466 129.523 141 116.528 141C103.534 141 93 130.466 93 117.472C93 104.477 103.534 93.9434 116.528 93.9434C129.523 93.9434 140.057 104.477 140.057 117.472Z"
      stroke="#EBF4F5"
      strokeWidth="6.72237"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M125.283 135.857C136.646 135.857 145.857 126.646 145.857 115.284C145.857 103.921 136.646 94.71 125.283 94.71C113.921 94.71 104.709 103.921 104.709 115.284C104.709 126.646 113.921 135.857 125.283 135.857ZM123.139 102.424C123.139 101.241 124.099 100.281 125.282 100.281C126.466 100.281 127.425 101.241 127.425 102.424V113.14L133.854 113.139C135.038 113.139 135.998 114.099 135.998 115.282C135.998 116.466 135.038 117.425 133.855 117.425L125.282 117.426C124.714 117.426 124.169 117.2 123.767 116.798C123.365 116.396 123.139 115.851 123.139 115.283V102.424Z"
      fill="#A4E0E0"
    />
    <path
      d="M121.453 104.34V118.201H130.39M144.434 116.924C144.434 129.617 134.145 139.906 121.453 139.906C108.761 139.906 98.4717 129.617 98.4717 116.924C98.4717 104.232 108.761 93.9434 121.453 93.9434C134.145 93.9434 144.434 104.232 144.434 116.924Z"
      stroke="#0B8397"
      strokeWidth="7.66038"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
