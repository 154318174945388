import { filterObjectKeys } from '../../../utils/Object';
import { removePrefixes } from '../utils';

export const useCandidate = (user, candidate, job) => {
  const officeInsurances = user?.office_info?.insurances;
  const candidateEducation = candidate?.professional_info?.education;
  const educationInfo = candidateEducation?.[job?.profession];
  const candidateInsurances = candidate?.professional_info?.insurances;
  const userSpecialties = Object.keys(
    filterObjectKeys(educationInfo?.specialtiesAndProcedures, 'specialty'),
  );

  const userProcedures = Object.keys(
    filterObjectKeys(educationInfo?.specialtiesAndProcedures, 'procedure'),
  );

  const specialtiesAndProcedures = educationInfo?.specialtiesAndProcedures ?? {};
  const professionProcedures = removePrefixes(educationInfo?.specialtiesAndProcedures ?? {});

  const isSpecialty =
    job?.skills?.[0]?.code?.includes('specialty') ||
    job?.specialty?.toLowerCase()?.includes('specialty');
  const specialtyMatch = userSpecialties.includes(job?.specialty) && isSpecialty;

  const insuranceMismatch =
    officeInsurances?.length &&
    candidateInsurances?.length &&
    !officeInsurances?.some((insurance) => candidateInsurances?.includes(insurance));

  return {
    userSpecialties,
    userProcedures,
    candidateInsurances,
    // professionProcedures is only processed specialtiesAndProcedures, use only for showing
    professionProcedures,
    specialtiesAndProcedures,
    specialtyMatch,
    insuranceMismatch,
  };
};
