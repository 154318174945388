import React from 'react';
import { Colors } from '../../../../../themes/colors';

import { QUESTIONS_REGISTRATION, QUESTIONS_SETTING } from './utils';
import { Question } from './Question';

export const FAQ = ({ isRegistration, showDescription = false }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginBottom: 14,
    }}
  >
    <span
      style={{
        fontFamily: 'Nunito',
        fontSize: 22,
        fontWeight: 700,
        textAlign: 'center',
        color: Colors.primary_500,
        marginTop: 50,
        marginBottom: 42,
      }}
    >
      Frequently Asked Questions
    </span>
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      {(isRegistration ? QUESTIONS_REGISTRATION : QUESTIONS_SETTING).map((question) => (
        <Question
          key={question.title}
          title={question.title}
          description={question.description}
          defaultValue={showDescription}
          isRegistration={isRegistration}
        />
      ))}
    </div>
  </div>
);
