import { motion } from 'framer-motion';
import { useCheckPaymentExpiration } from "../../Inbox/Notification/Reminders/hooks/useCheckPaymentExpiration";
import Checkcircle from '../../../commonComponents/Checkcircle';
import { Colors } from '../../../themes/colors';

const opacityVariants = {
  checked: {
    opacity: 1,
  },
  unchecked: {
    opacity: 0,
  },
};

export const PaymentLine = ({
  isSelected,
  item,
  handleRadioButtonClick,
  handleMakeDefault,
  handleOnDelete,
  showActions = true,
  showExpiration = false,
}) => {
  const { checkPaymentExpiration } = useCheckPaymentExpiration();

  const { isExpired, isExpiring } = checkPaymentExpiration(item);
   
  const getWarningColor = () => {
    if (isExpired) {
      return Colors.error_500;
    }

    if (isExpiring) {
      return Colors.warning_500;
    }

    return Colors.neutral_400;
  };

  return (
    <div className='global_font' data-cy="payment-line">
      <div
        style={{
          height: 28,
          display: 'flex',
        }}
      >
        <button
          data-cy="payment-line-radio-button"
          type="button"
          style={{
            all: 'unset',
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            width: 16,
          }}
          onClick={() => handleRadioButtonClick(item)}
        >
          <Checkcircle checked={isSelected} />
        </button>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '5px 20px',
            flexDirection: 'row',
            alignItems: 'center',
          }}
          key={item.id}
        >
          <button
            type="button"
            style={{
              all: 'unset',
              display: 'flex',
              cursor: 'pointer',
              marginRight: 24,
              flexDirection: 'column',
            }}
            onClick={() => handleRadioButtonClick(item)}
          >
            <span
              style={{
                fontSize: 16,
                color: Colors.neutral_600,
              }}
            >
              {item.brand || item.bank_name} - {item.last4}
            </span>
          </button>
  
          {item.is_selected && (
            <div
              style={{
                height: 20,
                display: 'flex',
                alignItems: 'center',
                backgroundColor: Colors.secondary_500,
                padding: '0px 8px',
                borderRadius: 4,
                marginRight: 8,
              }}
            >
              <span
                style={{
                  fontSize: 12,
                  fontWeight: 700,
                  color: Colors.white,
                }}
              >
                DEFAULT
              </span>
            </div>
          )}
  
          {showActions && (
            <>
              {isSelected && (
                <motion.div
                  animate={isSelected ? 'checked' : 'unchecked'}
                  initial="unchecked"
                  variants={opacityVariants}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    alignSelf: 'center',
                    gap: 8,
                    marginLeft: !item.is_selected && item.isExpired ? -8 : 0,
                  }}
                >
                  {!item.is_selected && !item.isExpired && (
                    <button
                      type="button"
                      style={{
                        all: 'unset',
                        fontSize: 18,
                        fontWeight: 700,
                        color: Colors.secondary_500,
                        cursor: 'pointer',
                      }}
                      onClick={() => handleMakeDefault(item.id)}
                    >
                      Make Default
                    </button>
                  )}
                  <span
                    style={{
                      fontSize: 18,
                      color: Colors.neutral_600,
                    }}
                  >
                    |
                  </span>
                  <button
                    type="button"
                    style={{
                      all: 'unset',
                      fontSize: 18,
                      fontWeight: 700,
                      color: Colors.secondary_500,
                      cursor: 'pointer',
                    }}
                    onClick={() => handleOnDelete(item)}
                  >
                    Delete
                  </button>
                </motion.div>
              )}
            </>
          )}
        </div>
      </div>
      {showExpiration && (
        <span
          style={{
            display: 'flex',
            fontSize: 15,
            color: getWarningColor(),
            marginLeft: 36,
            marginTop: -4,
          }}
        >
          Expires: {item.expirationDate.format('MM/YY')}
        </span>
      )}
    </div>
  );
};
