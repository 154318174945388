import { apiMethodsConst } from '../services/methods';

const initialState = {
  rating: {
    collaboration: 0,
    environment: 0,
    overall: 0,
    workload: 0,
  },
  totalReviews: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case apiMethodsConst.GET_OFFICE_REVIEW_INFO_REQUEST: {
    const {
      averageWorkEnvironment,
      averageWorkload,
      averageCollaborationAndRespect,
      overallAverageRating,
      totalReviews,
    } = action.payload;

    return {
      ...state,
      rating: {
        collaboration: averageCollaborationAndRespect,
        environment: averageWorkEnvironment,
        overall: overallAverageRating,
        workload: averageWorkload,
      },
      totalReviews,
    };
  }
  default:
    return state;
  }
};
