import { fetchFavoriteProfessionals } from '../../../actions';
import { trackEvent } from '../../../api/analytics';
import { EmptyFavorites } from '../../../components/ProfessionalsHub/components/FavoritesList/EmptyFavorites';
import { Colors } from '../../../themes/colors';
import { SendInviteToModal } from '../SendInviteToModal';
import { filterFavorites, mapFavoriteToRowProps } from '../utils';

export const SendToFavoritesModal = ({ onClose, refetchJob = true }) => (
  <SendInviteToModal
    fetchProfessionalsAction={fetchFavoriteProfessionals}
    professionalsSelector={(state) => state.user.favoriteProfessionals}
    filterFunction={filterFavorites}
    mapProfessionalToRowProps={mapFavoriteToRowProps}
    EmptyComponent={() => (
      <>
        <div
          style={{
            color: Colors.neutral_600,
            fontSize: 18,
            fontFamily: 'Nunito',
            textAlign: 'center',
            fontWeight: 'bold',
            marginBottom: 30,
          }}
        >
          No Favorites Available
        </div>
        <EmptyFavorites />
      </>
    )}
    headerTitle="Favorite Professionals"
    headerSubtitle="Favorites will be confirmed if they accept the offer at the listed rate and time."
    onClose={onClose}
    refetchJob={refetchJob}
    listener={(job, professionalsIds) => {
      trackEvent('Send to - Favorite invite sent/submitted', {
        job: {
          professionalsIds,
          jobId: job.id,
          profession: job.profession,
          rate: job.rate,
          shiftId: job.shiftId,
          totalHours: job.totalHours,
          local_date: job.local_date,
          local_end_time: job.local_end_time,
          local_start_time: job.local_start_time,
          local_timezone: job.local_timezone,
          lunch_break_time: job.lunch_break_time,
          lunch_final_break_time: job.lunch_final_break_time,
          specialty: job.specialty,
        },
      });
    }}
  />
);
