import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { createRequestTypes, GET_OFFICE_REVIEW_INFO } from '../actions';
import { apiMethods } from '../services/api';
import { apiMethodsConst } from '../services/methods';

function* callAndDispatch(method, payload) {
  const response = yield call(apiMethods[method], payload);
  yield put({ type: method, payload: response });
  return response;
}

function* watchGetOfficeRating() {
  const events = createRequestTypes(GET_OFFICE_REVIEW_INFO);
  
  function* handler({payload}) {
    yield put({ type: events.REQUEST });

    try {
      yield* callAndDispatch(apiMethodsConst.GET_OFFICE_REVIEW_INFO_REQUEST, {
        userId: payload.userId,
      });

      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }

  yield takeEvery(GET_OFFICE_REVIEW_INFO, handler);
}

export default function* sagaOfficeReview() {
  yield all([fork(watchGetOfficeRating)]);
}
