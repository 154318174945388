import React from 'react';
import { Colors } from '../../themes/colors';

const styles = {
  messageText: {
    fontFamily: 'Nunito',
    color: '#1E2022',
    fontSize: 16,
    fontWeight: '500',
  },
  timeText: {
    fontFamily: 'Nunito',
    color: '#77838F',
    fontSize: 9,
    textAlign: 'right',
  },
};

export const renderText = (text) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      margin: 2,
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      width: '100%',
    }}
  >
    <div
      style={{
        backgroundColor: '#1E2022',
        borderRadius: 50,
        width: 5,
        height: 5,
        marginRight: 5,
        marginTop: 10,
      }}
    />
    <p
      style={{
        fontFamily: 'Nunito',
        color: '#1E2022',
        fontSize: 16,
        margin: 2,
      }}
    >
      {text}
    </p>
  </div>
);

export const renderOpponentMessage = (text, time, isLast, key) => (
  <div key={key} style={{ justifyContent: 'flex-start', display: 'flex' }}>
    <div
      style={{
        borderRadius: 20,
        padding: 15,
        marginTop: 10,
        backgroundColor: Colors.primary_100,
        borderTopLeftRadius: 0,
        width: '80%',
        overflowWrap: 'break-word',
        wordWrap: 'break-word' /* For older browsers */,
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      <p style={styles.messageText}>{text}</p>
      <p style={styles.timeText}>{time}</p>
    </div>

    {isLast ? (
      <p
        style={{
          fontFamily: 'Nunito',
          color: '#77838F',
          fontSize: 12,
          margin: 2,
          alignSelf: 'flex-start',
        }}
      >
        Read
      </p>
    ) : (
      <div />
    )}
  </div>
);

export const renderAdminMessage = (text, time, isLast, key) => (
  <div key={key} style={{ justifyContent: 'flex-start', display: 'flex' }}>
    <div
      style={{
        borderRadius: 20,
        padding: 15,
        marginTop: 10,
        backgroundColor: Colors.primary_500,
        borderTopLeftRadius: 0,
        alignSelf: 'flex-start',
        width: '80%',
        overflowWrap: 'break-word',
        wordWrap: 'break-word' /* For older browsers */,
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      <p
        style={{
          fontFamily: 'Nunito',
          color: Colors.white,
          fontSize: 14,
          marginBottom: 5,
        }}
      >
        TempMee Support
      </p>

      <p
        style={{
          fontFamily: 'Nunito',
          fontWeight: 'bold',
          color: Colors.white,
          fontSize: 16,
          margin: 2,
        }}
      >
        {text}
      </p>
      <p
        style={{
          fontFamily: 'Nunito',
          margin: 2,
          fontSize: 9,
          textAlign: 'right',
          color: Colors.white,
        }}
      >
        {time}
      </p>
    </div>
    {isLast ? (
      <p
        style={{
          fontFamily: 'Nunito',
          color: '#77838F',
          fontSize: 12,
          lignSelf: 'flex-start',
          margin: 2,
        }}
      >
        Read
      </p>
    ) : (
      <div />
    )}
  </div>
);

export const renderUserMessage = (text, time, isLast, key) => (
  <div key={key} style={{ display: 'flex', justifyContent: 'flex-end' }}>
    <div
      style={{
        borderBottomRightRadius: 0,
        backgroundColor: Colors.success_100,
        borderRadius: 20,
        padding: 15,
        marginTop: 10,
        width: '80%',
        overflowWrap: 'break-word',
        wordWrap: 'break-word' /* For older browsers */,
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      <p style={styles.messageText}>{text}</p>
      <p style={styles.timeText}>{time}</p>
    </div>
    {isLast ? (
      <p
        style={{
          alignSelf: 'flex-end',
          fontFamily: 'Nunito',
          color: '#77838F',
          fontSize: 12,
          margin: 2,
        }}
      >
        Read
      </p>
    ) : (
      <div />
    )}
  </div>
);

export const renderSupportCard = (text, key) => (
  <div
    key={key}
    style={{
      borderRadius: 20,
      alignSelf: 'center',
      padding: 15,
      marginTop: 10,
      backgroundColor: Colors.primary_500,
      width: '80%',
    }}
  >
    <p
      style={{
        fontFamily: 'Nunito',
        fontSize: 16,
        fontWeight: 'bold',
        color: Colors.white,
        margin: 2,
      }}
    >
      {text ||
        'We are contacting support. Please remain on the chat. One of our representatives will join soon.'}
    </p>
  </div>
);
