import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Colors } from '../../themes/colors';
import logoDarkIcon from '../../images/logos/logoDarkIcon.svg';

export default ({ closePopupClicked }) => {
  const wrapperRef = useRef(null);
  const referralLinkHygienists = useSelector((state) => state.user.referralLink);
  const referralId = useSelector((state) => state.user.referralInfo.id);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closePopupClicked();
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  const websiteUrl =
    process.env.REACT_APP_TEMP_MEE_ENV === 'development'
      ? 'https://dental.dev.tempmeebe.com'
      : 'https://dental.tempmee.com';
  const referralLink = `${websiteUrl}/signup?referral=${referralId}`;

  const copyLink = (link) => {
    const el = document.createElement('textarea');
    el.value = link;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  return (
    <div
      style={{
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        zIndex: 10,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <div
        ref={wrapperRef}
        style={{
          backgroundColor: Colors.white,
          alignContent: 'center',
          padding: 10,
          justifyContent: 'center',
          maxWidth: 900,
          minWidth: 600,
          width: '40%',
          borderRadius: 25,
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 50,
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3)',
          shadowColor: Colors.neutral_400,
          shadowOpacity: 0.3,
          shadowOffset: {
            height: 3,
            width: 0,
          },
        }}
      >
        <div
          style={{
            marginTop: 8,
            fontSize: 20,
            fontWeight: 'bold',
            color: Colors.neutral_300,
            cursor: 'pointer',
            textAlign: 'right',
            marginRight: 20,
          }}
          onClick={() => closePopupClicked()}
        >
          X
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center',
            alignItems: 'center',
            marginBottom: 30,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              marginBottom: 30,
            }}
          >
            <img src={logoDarkIcon} style={{ width: 40 }} alt="logo_dark_icon" />
          </div>
          <div
            style={{
              fontSize: 24,
              textAlign: 'center',
              marginBottom: 30,
              color: Colors.primary_500,
              fontFamily: 'Nunito',
              fontWeight: 'bold',
              margin: 10,
            }}
          >
            Send this link to a friend and they’ll be able to join the GoTu Community!
          </div>
          <div className="gray-text-18" style={{ marginTop: 20 }}>
            Who are you inviting in the GoTu Community?
          </div>
          <div className="gray-text-18" style={{ marginTop: 20 }}>
            Dental Office & DSO
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <div className="global_font f-dark link-text" style={{ width: '60%' }}>
              {referralLink}
            </div>
            <button
              className="border-blue-button"
              style={{
                width: 200,
                height: 30,
                margin: 0,
                width: '20%',
                marginLeft: 20,
              }}
              onClick={() => copyLink(referralLink)}
            >
              <p className="blue-text-18">Copy Link</p>
            </button>
          </div>
          <div className="gray-text-18">Dental Professionals</div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <div className="global_font f-dark link-text" style={{ width: '60%' }}>
              {referralLinkHygienists}
            </div>
            <button
              className="border-blue-button"
              style={{
                width: 200,
                height: 30,
                margin: 0,
                width: '20%',
                marginLeft: 20,
              }}
              onClick={() => copyLink(referralLinkHygienists)}
            >
              <p className="blue-text-18">Copy Link</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
