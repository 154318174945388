import { Colors } from '../../../themes/colors';

export const HighlightedLines = ({ highlight, children }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: highlight ? Colors.neutral_150 : 'transparent',
      gap: highlight ? 10 : 12,
      ...(highlight && {
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 10,
        paddingRight: 10,
        marginLeft: -10,
        marginRight: -10,
        borderRadius: 4,
      }),
    }}
  >
    {children}
  </div>
);
