import React from 'react';
import { Colors } from '../../themes/colors';

const WarningIcon = ({ color = Colors.primary_500, size = 24, width, height}) => (
  <svg
    width={width || size}
    height={height || size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.7498 16.9143C22.5737 16.4542 22.0739 16.23 21.6331 16.4138C21.1922 16.5977 20.9774 17.1193 21.1536 17.5794C21.3774 18.1642 21.3039 18.832 20.9572 19.3661C20.6104 19.9003 20.0553 20.2066 19.4339 20.2066H4.56933C3.89172 20.2066 3.28972 19.8375 2.95929 19.219C2.62843 18.5997 2.6439 17.87 3.00097 17.2668L10.4333 4.70697C10.7719 4.13517 11.358 3.79388 12.0016 3.79388C12.6453 3.79388 13.2314 4.13517 13.57 4.70697L18.6566 13.1705C18.9059 13.5921 19.4357 13.7226 19.8391 13.4625C20.243 13.2023 20.3681 12.6494 20.1189 12.2283L15.0322 3.76473C14.3782 2.6597 13.2452 2 12.0016 2C10.7581 2 9.62504 2.6597 8.97148 3.76428L1.53875 16.3242C0.849529 17.4888 0.81988 18.8975 1.4584 20.0927C2.09648 21.2869 3.25965 22 4.5689 22H19.4335C20.6341 22 21.7078 21.4067 22.3794 20.3721C23.0463 19.3451 23.1851 18.0526 22.7498 16.9143Z"
      fill={color}
    />
    <path
      d="M12.0021 18.4575C12.4769 18.4575 12.8615 18.0561 12.8615 17.5605C12.8615 17.065 12.4769 16.6636 12.0021 16.6636C11.5278 16.6636 11.1428 17.065 11.1428 17.5605C11.1428 18.0561 11.5278 18.4575 12.0021 18.4575Z"
      fill={color}
    />
    <path
      d="M12.8611 14.4213V7.82922C12.8611 7.33411 12.4765 6.93228 12.0017 6.93228C11.5269 6.93228 11.1423 7.33366 11.1423 7.82922V14.4213C11.1423 14.9169 11.5269 15.3182 12.0017 15.3182C12.4761 15.3182 12.8611 14.9169 12.8611 14.4213Z"
      fill={color}
    />
    <path
      d="M22.7498 16.9143C22.5737 16.4542 22.0739 16.23 21.6331 16.4138C21.1922 16.5977 20.9774 17.1193 21.1536 17.5794C21.3774 18.1642 21.3039 18.832 20.9572 19.3661C20.6104 19.9003 20.0553 20.2066 19.4339 20.2066H4.56933C3.89172 20.2066 3.28972 19.8375 2.95929 19.219C2.62843 18.5997 2.6439 17.87 3.00097 17.2668L10.4333 4.70697C10.7719 4.13517 11.358 3.79388 12.0016 3.79388C12.6453 3.79388 13.2314 4.13517 13.57 4.70697L18.6566 13.1705C18.9059 13.5921 19.4357 13.7226 19.8391 13.4625C20.243 13.2023 20.3681 12.6494 20.1189 12.2283L15.0322 3.76473C14.3782 2.6597 13.2452 2 12.0016 2C10.7581 2 9.62504 2.6597 8.97148 3.76428L1.53875 16.3242C0.849529 17.4888 0.81988 18.8975 1.4584 20.0927C2.09648 21.2869 3.25965 22 4.5689 22H19.4335C20.6341 22 21.7078 21.4067 22.3794 20.3721C23.0463 19.3451 23.1851 18.0526 22.7498 16.9143Z"
      stroke={color}
      strokeWidth="0.3"
    />
    <path
      d="M12.0021 18.4575C12.4769 18.4575 12.8615 18.0561 12.8615 17.5605C12.8615 17.065 12.4769 16.6636 12.0021 16.6636C11.5278 16.6636 11.1428 17.065 11.1428 17.5605C11.1428 18.0561 11.5278 18.4575 12.0021 18.4575Z"
      stroke={color}
      strokeWidth="0.3"
    />
    <path
      d="M12.8611 14.4213V7.82922C12.8611 7.33411 12.4765 6.93228 12.0017 6.93228C11.5269 6.93228 11.1423 7.33366 11.1423 7.82922V14.4213C11.1423 14.9169 11.5269 15.3182 12.0017 15.3182C12.4761 15.3182 12.8611 14.9169 12.8611 14.4213Z"
      stroke={color}
      strokeWidth="0.3"
    />
  </svg>
);

export default WarningIcon;
