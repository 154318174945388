import { Colors } from '../../../../themes/colors';
import ConfirmPopup from '../../../shared/ConfirmPopup';
import { onCardClick } from '../../utils';
import { JobCardSmall } from '../JobCard/JobCardSmall';

export const JobListPopup = ({ jobList, onClose }) => (
  <ConfirmPopup
    showClosePopupIcon
    closePopup={onClose}
    modalStyle={{
      width: 600,
      height: 600,
      backgroundColor: Colors.neutral_50,
    }}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        alignItems: 'center',
        height: 600,
        marginTop: 20,
        width: '100%',
      }}
    >
      {jobList?.map((job, index) => (
        <div
          key={job.id}
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <JobCardSmall key={job.id} onCardClick={onCardClick} job={job} />
          <div
            style={{
              width: 520,
              backgroundColor: index < jobList.length - 1 ? Colors.neutral_200 : 'transparent',
              height: 2,
              margin: '12px 0',
            }}
          />
        </div>
      ))}
    </div>
  </ConfirmPopup>
);
