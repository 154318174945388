/**
 * Get top 3 prioritized badges
 * @param {*} badges - array of badges
 * @returns
 */
export const getPrioritizedBadges = ({ badges = [] }) => {
  if (!badges.length) return [];

  const prioritizedBadges = [];

  // Find attendance badge and completed shifts badge
  const attendanceBadge = badges.find((badge) => badge.type === 'attendance-score');
  const completedShiftsBadge = badges.find((badge) => badge.type === 'completed-shifts');

  // Add these badges to the prioritized list if they exist
  if (attendanceBadge) prioritizedBadges.push(attendanceBadge);
  if (completedShiftsBadge) prioritizedBadges.push(completedShiftsBadge);

  // Get remaining badges sorted by badgeCount in descending order
  const otherBadges = badges
    .filter((badge) => badge.type !== 'attendance-score' && badge.type !== 'completed-shifts')
    .sort((a, b) => (b.badgeCount || 0) - (a.badgeCount || 0));

  // Add up to 3 other badges to the prioritized list
  prioritizedBadges.push(...otherBadges.slice(0, 3 - prioritizedBadges.length));

  return prioritizedBadges.reverse();
};

/**
 * Reorder the sequence of badges
 * @param {*} badges - array of badges
 * @returns
 */
export const reorderBadges = (badges = [], { inverted = false }) => {
  // 1. attendance-score
  // 2. completed-shifts
  // 3. the rest of the badges sorted by badgeCount in descending order
  const sortedBadges = badges.sort((a, b) => {
    if (a.type === 'attendance-score') return -1;
    if (b.type === 'attendance-score') return 1;

    if (a.type === 'completed-shifts') return -1;
    if (b.type === 'completed-shifts') return 1;

    const badgeCountA = a.badgeCount || 0;
    const badgeCountB = b.badgeCount || 0;

    return badgeCountB - badgeCountA;
  });

  return inverted ? sortedBadges.reverse() : sortedBadges;
};
