export const getRedirectTarget = (adjustments) => {
  const appliedAdjustment = adjustments?.find?.((item) => item.status === 'applied');
  if (appliedAdjustment) {
    // if we have appliedAdjustment then redirect to rating page
    return 'feedback-rating';
  }

  // to get unresolved adjustment, so i only find first 'none' adjustment
  const unresolvedAdjustment = adjustments?.find?.((item) => item.status === 'none');

  if (!unresolvedAdjustment) {
    // if we don't have unresolvedAdjustment, then just redirect to feedback page
    return 'feedback';
  }

  const timeChange = unresolvedAdjustment?.time_change;

  if (typeof timeChange !== 'number') {
    return null;
  }

  const hasUnresolvedAdjustmentFromHYG = timeChange > 0;
  const hasUnresolvedAdjustmentFromDNT = timeChange < 0;

  if (hasUnresolvedAdjustmentFromHYG) {
    // if we have hasUnresolvedAdjustmentFromHYG then redirect to adjustment page
    return 'adjustment';
  }

  if (hasUnresolvedAdjustmentFromDNT) {
    // if we have hasUnresolvedAdjustmentFromDNT then redirect to rating page
    return 'feedback-rating';
  }

  return null;
};
