import moment from "moment";
import { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_LAST_POSTED_JOBS, getLastPostedJobs } from "../../../../../actions";
import { enableLastJobPostedReminders, getHideNotificationsOnParentOffice } from "../../../../../growthbook";
import useLoading from "../../../../../hooks/useLoading";

export const useLastShiftPosted = () => {
  const dispatch = useDispatch();
  
  const isEnableLastJobPosted = useRef(enableLastJobPostedReminders()).current;
  const isHideNotificationsOnParentOffice = useRef(getHideNotificationsOnParentOffice()).current;

  const {isLoading} = useLoading([GET_LAST_POSTED_JOBS]);
  const jobs = useSelector((state) => state.job?.lastJobsPosted || []);
  const isParentOffice = useSelector((state) => 
    (state.user?.selectedChildOffice?.childOffices || state.user?.childOffices)?.length > 1
  );
  
  const isParentOfficeAndHideNotificationsOnParentOffice = 
    isParentOffice && isHideNotificationsOnParentOffice;

  useEffect(() => {
    if (isEnableLastJobPosted && !isParentOfficeAndHideNotificationsOnParentOffice) {
      dispatch(getLastPostedJobs())
    }
  }, [dispatch, isEnableLastJobPosted, isParentOfficeAndHideNotificationsOnParentOffice]);

  const isLastPostMoreThan60Days = useMemo(() => {
    if (jobs.length === 0) return false;

    const lastJob = jobs[0];
    return moment(lastJob?.createdAt).isBefore(moment().subtract(60, 'days'));
  }, [jobs])
  const hasNotPosted = jobs.length === 0;

  if (!isEnableLastJobPosted || isParentOfficeAndHideNotificationsOnParentOffice) {
    return {
      isLoading: false,
      isLastPostMoreThan60Days: false,
      hasNotPosted: false,
    }
  }

  return {
    isLoading,
    isLastPostMoreThan60Days: !isLoading && !hasNotPosted && isLastPostMoreThan60Days,
    hasNotPosted: !isLoading && hasNotPosted,
  }
};