import React from 'react';
import { Colors } from '../../../../themes/colors';

export const Overview = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginBottom: 14,
    }}
  >
    <span
      style={{
        fontFamily: 'Nunito',
        fontSize: 16,
        fontStyle: 'italic',
        fontWeight: 400,
        color: Colors.neutral_600,
        marginTop: 58,
      }}
    >
      Please read this information carefully, selecting your office’s worker classification is
      required to finalize your sign up. Please select one of the options at the bottom, then
      continue.
    </span>

    <span
      style={{
        fontFamily: 'Nunito',
        fontSize: 22,
        fontWeight: 700,
        textAlign: 'center',
        color: Colors.primary_500,
        marginTop: 50,
      }}
    >
      What is worker type?
    </span>
    <p
      style={{
        fontFamily: 'Nunito',
        fontSize: 16,
        fontWeight: 400,
        color: Colors.neutral_600,
      }}
    >
      Worker Type refers to the type of worker, specifically, whether individual professionals will
      be engaged as independent contractors that have chosen to offer professional services through
      the GoTu platform (“Contractor Option”) or as employees of our PEO (GoTu’s Professional
      Employer Organization) that are sent to your office to provide services (“Employee Option”).
      <br />
      <br />
      To be able to post shifts on GoTu, your office must select a worker classification. It is
      important to remember that groups of offices that share the same Tax Identification Number
      (TIN), must select the same worker type, if available.
    </p>
  </div>
);
