/* eslint-disable react/no-array-index-key */
import history from '../../../../history';
import { Popover } from '../../../../commonComponents/Popover/Popover';
import StarIcon from '../../../../images/icons/StarIcon';
import { Colors } from '../../../../themes/colors';
import ChevronRight from '../../../../images/arrow_right.svg';

export const ProfessionalRating = ({ rating, jobId, popoverPosition = 'bottom' }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 2,
    }}
  >
    {!rating ? (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 5,
          marginLeft: 2,
          cursor: 'pointer',
        }}
        onClick={() => {
          history.push(`/dashboard/job/${jobId}/feedback-rating`, {
            isFromProfessionalHub: true,
          });
        }}
      >
        <div style={{ height: 20, width: 2, backgroundColor: Colors.neutral_600 }} />
        <b
          style={{
            fontSize: 20,
            color: Colors.secondary_500,
          }}
        >
          Rate
        </b>
        <img src={ChevronRight} alt="arrow" style={{ width: 16, height: 16 }} />
      </div>
    ) : (
      <>
        <div style={{ marginTop: 2 }}>
          <StarIcon width={20} height={20} />
        </div>
        <Popover
          position={popoverPosition}
          Target={() => (
            <span
              style={{
                textDecoration: 'underline',
                color: Colors.neutral_400,
              }}
            >
              <b
                style={{
                  fontSize: 20,
                  color: Colors.neutral_600,
                  fontFamily: 'Nunito',
                }}
              >
                {rating.averageRating.toFixed(1)}
              </b>
            </span>
          )}
          Content={() => <PopoverContent rating={rating} />}
        />
      </>
    )}
  </div>
);

const PopoverContent = ({ rating }) => {
  const { timeManagement, professionalSkills, interpersonalSkills } = rating || {};

  const renderStars = (value) => {
    const fullStars = Math.floor(value);
    const totalStars = 5;

    return (
      <>
        {Array.from({ length: fullStars }).map((_, index) => (
          <StarIcon key={`full-${index}`} width={18} height={18} />
        ))}

        {Array.from({
          length: totalStars - fullStars,
        }).map((_, index) => (
          <StarIcon key={`empty-${index}`} width={18} height={18} color={Colors.neutral_200} />
        ))}
      </>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 16,
        color: Colors.neutral_600,
        fontSize: 16,
      }}
    >
      <b
        style={{
          fontSize: 18,
        }}
      >
        Your Rating
      </b>
      {[
        { label: 'Professional Skills', value: professionalSkills },
        { label: 'Interpersonal Skills', value: interpersonalSkills },
        { label: 'Time Management', value: timeManagement },
      ].map(({ label, value }) => (
        <div
          key={label}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 4,
          }}
        >
          <b>{label}</b>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 4,
            }}
          >
            {renderStars(value)}
            <span>{value.toFixed(1)}</span>
          </div>
        </div>
      ))}
    </div>
  );
};
