import React from 'react';
import CustomSpinner from '../../../commonComponents/CustomSpinner';

export const ContentLayout = ({ isLoading, children }) => {
  if (isLoading) {
    return (
      <div style={{ height: '100%' }}>
        <CustomSpinner isLoading />
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: 120,
        height: '100%',
      }}
    >
      {children}
    </div>
  );
};
