import { Icon, Text } from '../../../commonComponents';
import { Colors } from '../../../themes/colors';

export const NotificationLayout = ({
  icon,
  title,
  children,
  style = {body: {}},
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
    }}
  >
    <div
      style={{
        backgroundColor: Colors.neutral_100,
        borderRadius: 20,
        boxShadow: "0px 2px 6px 2px #00000026",
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          height: 48,
          display: 'flex',
          alignItems: 'center',
          padding: '0 20px',
          gap: 6,
        }}
      >
        <Icon name={icon} size={24} color={Colors.primary_500} />
        <Text bold color={Colors.primary_500} fontSize={20}>
          {title}
        </Text>
      </div>
      <div style={{
        backgroundColor: Colors.white,
        boxShadow: "0px 2px 6px 2px #00000026",
        padding: "8px 32px",
        borderTopLeftRadius: 25,
        borderTopRightRadius: 25,
        ...style.body,
      }}
      >
        {children}
      </div>
    </div>
  </div>
);
