import '../../themes/global.scss';
import '../../themes/mapactiveoffer.scss';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RemoteConfigUtils } from '../../utils/RemoteConfigUtils';
import { SendToFavoritesModal } from '../../commonComponents/SendToModal';
import { Colors } from '../../themes/colors';

import {
  CANCEL_JOB_ACTION,
  cancelJob,
  DECLINE_COUNTER_OFFER,
  FETCH_JOB_ACTION,
  fetchJob,
  UPDATE_JOB_ACTION,
} from '../../actions';
import BackButton from '../../commonComponents/BackButton';
import CardSpinner from '../../commonComponents/CardSpinner';
import browserHistory from '../../history';
import CloseIcon from '../../images/icons/CloseIcon';
import EditPencilIcon from '../../images/icons/EditPencil';
import TrashIcon from '../../images/icons/Trash';
import shareIcon from '../../images/share-icon.svg';
import repostWhiteIcon from '../../images/repost-white.svg';
import AnimatedCloseCircle from '../shared/AnimatedCloseCircle';
import ConfirmPopup from '../shared/ConfirmPopup';
import { UserProfession, UserSubProfession } from '../../enums/UserProfession';
import { formatFromMinuteToFormattedHour } from '../../utils/DateUtils';
import useLoading from '../../hooks/useLoading';
import { getShouldAllowOfficeToPostProcedure } from '../../growthbook';
import { EducationUtils } from '../../utils/EducationUtils';
import Tooltip from '../Tooltip';

export default function OpenJobScreen({ history, match }) {
  const dispatch = useDispatch();

  const jobId = history.location.state ? history.location.state?.jobId : match.params?.jobId;
  const job = useSelector((state) => (jobId === state.job.job?.id ? state.job.job : null));

  const tooltipDescription = (
    <>
      Professional’s eligibility criteria:
      <br />
      <br />
      {'\u2022  '}
      Has at least a {RemoteConfigUtils.getAutofillMinAttendanceScore()}% attendance score for
      previous shifts worked.
      <br />
      {'\u2022  '}
      Has a minimum of 1 year working experience.
      <br />
      {'\u2022  '}
      Has received exceptional performance ratings on over 95% of completed shifts.
      <br />
      {'\u2022  '}
      Has completed at least 3 shifts.
      <br />
      <br />
      Note: You may change your auto-confirm preference at any time by visiting the Shift Details
      page.
    </>
  );

  const { subProfession, specialty, procedures, profession, professionName } = job || {};

  // show "repost as DA" button only for EFDA
  const shouldShowRepostButton = subProfession === UserSubProfession.EFDA;

  const { isLoading } = useLoading([
    FETCH_JOB_ACTION,
    UPDATE_JOB_ACTION,
    DECLINE_COUNTER_OFFER,
    CANCEL_JOB_ACTION,
  ]);

  const [showInviteFavoritesPopup, setShowInviteFavoritesPopup] = useState(false);
  const [cancelJobPopup, setCancelJobPopup] = useState(false);
  const professionTypeCode = UserProfession.getNormalizedCode(profession);

  useEffect(() => {
    dispatch(fetchJob({ jobId }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (job && (job.status === 'pending' || job.status === 'counter')) {
      const candidateId = job?.multipleCounters?.[0]?.candidate?.id;
      history.replace(`/dashboard/job/${job.id}/confirm/${candidateId}`);
    } else if (
      job &&
      (job.status === 'filled' || job.status === 'pay-out' || job.status === 'complete')
    ) {
      history.replace(`/dashboard/job/${job.id}/details`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job]);

  const repostEfdaAsDa = () => {
    history.push('/dashboard/temp', {
      job: {
        ...job,
        subProfession: null,
      },
    });
  };

  if (isLoading || !job) {
    return <CardSpinner />;
  }

  return (
    <div className="component_container global_font">
      <div className="floating_container">
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              width: '95%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingTop: 40,
            }}
          >
            {history.location.state?.isJobPost ? (
              <div
                onClick={() => browserHistory.push('/dashboard/home')}
                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
              >
                <CloseIcon color={Colors.secondary_500} />

                <p
                  style={{
                    fontFamily: 'Nunito',
                    fontWeight: 'bold',
                    fontSize: 20,
                    color: Colors.secondary_500,
                    marginLeft: 8,
                  }}
                >
                  Close
                </p>
              </div>
            ) : (
              <BackButton />
            )}

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div
                onClick={() => history.push(`/dashboard/job/${jobId}/edit`)}
                className="buttons-container"
                style={{
                  marginRight: 40,
                }}
              >
                <div className="edit-button">
                  <EditPencilIcon className="icon" color="#FFFFFF" />
                </div>
                <p>Edit</p>
              </div>
              <div className="buttons-container">
                <div className="trash-button" onClick={() => setCancelJobPopup(true)}>
                  <TrashIcon className="icon" color="#FFFFFF" />
                </div>
                <p>Cancel</p>
              </div>
            </div>
          </div>
          <div style={{ width: '70%', paddingTop: 20 }}>
            <h1
              className="f-dark"
              style={{
                fontSize: 32,
                fontWeight: 'bold',
                marginTop: 20,
                textAlign: 'center',
                width: '100%',
              }}
            >
              Shift Details
            </h1>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ width: 500, display: 'flex', flexDirection: 'column' }}>
                <div className="row_style">
                  <p
                    style={{
                      margin: 0,
                      color: Colors.neutral_600,
                      fontSize: 18,
                      width: '40%',
                    }}
                  >
                    <b>Office:</b>
                  </p>
                  <p style={{ margin: 0, color: Colors.neutral_500, fontSize: 16 }}>
                    {job.offer_owner.office_name}
                  </p>
                </div>

                <div className="row_style">
                  <p
                    style={{
                      margin: 0,
                      color: Colors.neutral_600,
                      fontSize: 18,
                      width: '40%',
                    }}
                  >
                    <b>Date:</b>
                  </p>
                  <p style={{ margin: 0, color: Colors.neutral_500, fontSize: 16 }}>
                    {job.local_date}
                  </p>
                </div>
                <div className="row_style">
                  <p
                    style={{
                      margin: 0,
                      color: Colors.neutral_600,
                      fontSize: 18,
                      width: '40%',
                    }}
                  >
                    <b>Professional:</b>
                  </p>
                  <p style={{ margin: 0, color: Colors.neutral_500, fontSize: 16 }}>
                    {UserSubProfession.getName(subProfession) ?? professionName}
                  </p>
                </div>

                <div className="row_style">
                  <p
                    style={{
                      margin: 0,
                      color: Colors.neutral_600,
                      fontSize: 18,
                      width: '40%',
                    }}
                  >
                    <b>Shift Specialty:</b>
                  </p>
                  <p
                    style={{
                      margin: 0,
                      color: Colors.neutral_500,
                      fontSize: 16,
                      textTransform: 'capitalize',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {specialty?.split('_')?.[1] || 'General'}
                  </p>
                </div>

                {professionTypeCode === UserProfession.RDH && (
                  <div className="row_style">
                    <p
                      style={{
                        margin: 0,
                        color: Colors.neutral_600,
                        fontSize: 18,
                        width: '50%',
                      }}
                    >
                      <b>Assisted Hygiene Available:</b>
                    </p>
                    <p
                      style={{
                        margin: 0,
                        color: Colors.neutral_500,
                        fontSize: 16,
                        textTransform: 'capitalize',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {job.isAssistedHygiene ? 'Yes' : 'No'}
                    </p>
                  </div>
                )}

                {getShouldAllowOfficeToPostProcedure() && procedures && procedures.length > 0 && (
                  <div
                    style={{
                      borderBottom: '1px solid #ededed',
                    }}
                  >
                    <div
                      className="row_style"
                      style={{
                        border: 0,
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          color: Colors.neutral_600,
                          fontSize: 18,
                        }}
                      >
                        <b>Shift Procedure(s):</b>
                      </p>
                    </div>

                    <ul
                      style={{
                        color: Colors.neutral_600,
                        fontSize: 18,
                        marginTop: 0,
                      }}
                    >
                      {EducationUtils.mapToRemoveProcedurePrefix(procedures).map((procedure) => (
                        <li key={procedure}>{procedure}</li>
                      ))}
                    </ul>
                  </div>
                )}

                <div className="row_style">
                  <p
                    style={{
                      margin: 0,
                      color: Colors.neutral_600,
                      fontSize: 18,
                      width: '40%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <b>
                      Hours:
                      <span
                        style={{
                          fontSize: 12,
                          color: Colors.white,
                          background: Colors.neutral_300,
                          borderRadius: 10,
                          padding: '0 4px',
                          marginLeft: 4,
                          alignSelf: 'center',
                          height: 15,
                        }}
                      >
                        {job.local_timezone}
                      </span>
                    </b>
                  </p>
                  <p style={{ margin: 0, color: Colors.neutral_500, fontSize: 16 }}>
                    {`${job.local_start_time} - ${job.local_end_time}`}
                  </p>
                </div>
                <div className="row_style">
                  <p
                    style={{
                      margin: 0,
                      color: Colors.neutral_600,
                      fontSize: 18,
                      width: '40%',
                    }}
                  >
                    <b>Hourly Rate:</b>
                  </p>
                  <p style={{ margin: 0, color: Colors.neutral_500, fontSize: 16 }}>
                    ${job.rate.toFixed(2)}
                  </p>
                </div>
                <div className="row_style">
                  <p
                    style={{
                      margin: 0,
                      color: Colors.neutral_600,
                      fontSize: 18,
                      width: '40%',
                    }}
                  >
                    <b>Meal Break:</b>
                  </p>
                  <p style={{ margin: 0, color: Colors.neutral_500, fontSize: 16 }}>
                    {formatFromMinuteToFormattedHour(job.lunch_break_time)}
                    {job.is_lunch_break_paid ? ' (Paid)' : ' (Unpaid)'}
                  </p>
                </div>
                <div className="row_style" style={{ alignItems: 'flex-start', paddingBottom: 20 }}>
                  <div
                    style={{
                      display: 'flex',
                      width: '40%',
                      alignItems: 'center',
                    }}
                  >
                    <b style={{ color: Colors.neutral_600, fontSize: 18 }}>Auto-Confirm:</b>
                    <div
                      style={{
                        marginLeft: 8,
                        position: 'relative',
                        top: '2px',
                      }}
                    >
                      <Tooltip content={tooltipDescription} />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 16,
                      color: Colors.neutral_500,
                      fontSize: 16,
                      textAlign: 'right',
                    }}
                  >
                    <span>Favorites / {job.autofillOptions?.favorite ? 'On' : 'Off'}</span>
                    <span>
                      Your Highly-Rated / {job.autofillOptions?.highlyRated ? 'On' : 'Off'}
                    </span>
                    <span>Eligible Professionals / {job.autoFill ? 'On' : 'Off'}</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: 100,
                marginTop: 160,
              }}
            >
              <button
                type="button"
                className="button-reset"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: Colors.primary_500,
                  borderRadius: '50px',
                  width: 296,
                  height: 48,
                  cursor: 'pointer',
                  margin: '16px 0 8px 0',
                }}
                onClick={() => setShowInviteFavoritesPopup(true)}
              >
                <img src={shareIcon} alt="icon" style={{ height: 24, width: 24, marginRight: 8 }} />
                <p
                  className="f-dark f-18"
                  style={{
                    textAlign: 'center',
                    color: Colors.white,
                  }}
                >
                  <b>Send to Favorites</b>
                </p>
              </button>

              {shouldShowRepostButton && (
                <button
                  className="button-reset"
                  type="button"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: Colors.secondary_500,
                    borderRadius: '50px',
                    width: 296,
                    height: 48,
                    cursor: 'pointer',
                    margin: '16px 0 8px',
                  }}
                  onClick={repostEfdaAsDa}
                >
                  <img
                    src={repostWhiteIcon}
                    alt="icon"
                    style={{ height: 24, width: 24, marginRight: 8 }}
                  />
                  <p
                    className="f-dark f-18"
                    style={{
                      textAlign: 'center',
                      color: Colors.white,
                    }}
                  >
                    <b>Repost Shift as DA</b>
                  </p>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {showInviteFavoritesPopup && (
        <SendToFavoritesModal
          onClose={() => setShowInviteFavoritesPopup(false)}
          refetchJob={false}
        />
      )}

      {cancelJobPopup && (
        <ConfirmPopup
          Icon={() => <AnimatedCloseCircle />}
          title="Are you sure you want to cancel?"
          titleStyle={{ marginTop: 20 }}
          description="Canceling an active shift means that it will no longer be available to professionals around your office to accept and potentially work."
          descriptionStyle={{ marginBottom: 50 }}
          leftButtonText="Go Back"
          leftButtonAction={() => setCancelJobPopup(false)}
          rightButtonText="Cancel Shift"
          rightButtonAction={() => {
            dispatch(cancelJob({ jobId: job.id, redirect: true }));

            setCancelJobPopup(false);
          }}
        />
      )}
    </div>
  );
}
