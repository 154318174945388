import { UserProfession } from '../../../../enums/UserProfession';
import { Colors } from '../../../../themes/colors';

export const DnDaProcedures = ({ candidate, job }) => {
  const candidateEducation = candidate?.professional_info?.education;

  return (
    [UserProfession.DN, UserProfession.DA].includes(job?.profession) && (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 16,
          marginTop: 12,
        }}
      >
        {Object.keys(candidateEducation?.[job?.profession]?.specialtiesAndProcedures || {})?.map(
          (name) => (
            <div key={name}>
              <b
                id={name}
                style={{
                  fontSize: 18,
                  color: Colors.primary_500,
                }}
              >
                {name?.split('_')?.[1]}
              </b>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                  marginTop: 8,
                }}
              >
                {candidateEducation?.[job?.profession]?.specialtiesAndProcedures?.[name]?.map(
                  (item) => (
                    <span key={item} style={{ color: Colors.neutral_600 }}>
                      {`\u2022  ${item?.split('_')?.[1]}`}
                    </span>
                  ),
                )}
              </div>
            </div>
          ),
        )}
      </div>
    )
  );
};
