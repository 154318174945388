import { useState } from 'react';
import { Column } from '../../../../commonComponents';
import { STEPS } from '../../utils';

export const CollapsibleCardsGroup = () => {
  const [openCardIndex, setOpenCardIndex] = useState(null);

  const handleCardToggle = (index) => {
    setOpenCardIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <Column gap={16} marginTop={24}>
      {STEPS.map(({ name, Component }, index) => (
        <Component
          key={name}
          isOpen={openCardIndex === index}
          onToggle={() => handleCardToggle(index)}
        >
          <Component />
        </Component>
      ))}
    </Column>
  );
};
