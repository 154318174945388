import '../../../themes/global.scss';

import { motion } from 'framer-motion';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';

import { FETCH_INVOICE_LIST, fetchInvoiceList } from '../../../actions';
import { createLoadingSelector } from '../../../api/selectors';
import BackButton from '../../../commonComponents/BackButton';
import CardSpinner from '../../../commonComponents/CardSpinner';
import history from '../../../history';
import { currencyFormat } from '../../../hooks/currencyFormat';
import { Colors } from '../../../themes/colors';

const LIMIT_VALUE = 25;

const normalText = {
  fontFamily: 'Nunito',
  fontSize: 16,
  color: Colors.neutral_600,
  textTransform: 'capitalize',
  width: '20%',
  margin: 10,
  textAlign: 'center',
};

const titleText = {
  fontFamily: 'Nunito',
  fontWeight: 'bold',
  fontSize: '18px',
  color: Colors.primary_500,
  textTransform: 'capitalize',
  width: '20%',
  textAlign: 'center',
};

export default () => {
  const dispatch = useDispatch();
  const myRef = useRef(null);

  const loadingSelector = createLoadingSelector([FETCH_INVOICE_LIST]);
  const isLoading = useSelector((state) => loadingSelector(state));

  const {
    invoices,
    isFinalPageInvoices,
    isActiveRequestInvoices,
    receiptReport,
    invoicesPagination,
    totalUnpaid,
  } = useSelector((state) => state.job);

  const cvsDownload = useRef(null);

  useEffect(() => {
    dispatch(fetchInvoiceList({ page: 1, limit: LIMIT_VALUE }));
  }, []);

  const handleScrollReceipts = () => {
    const userScrollHeight = window.innerHeight + window.scrollY + 1;
    const windowBottomHeight = document.documentElement.offsetHeight;

    if (userScrollHeight > windowBottomHeight && !isActiveRequestInvoices && !isFinalPageInvoices) {
      dispatch(fetchInvoiceList({ page: invoicesPagination?.page + 1, limit: LIMIT_VALUE }));
    }
  };

  useEffect(() => {
    if (cvsDownload.current?.link && receiptReport !== '') {
      cvsDownload.current.link.click();
    }
  }, [receiptReport]);

  if (isLoading && invoices.length === 0) {
    return <CardSpinner />;
  }

  return (
    <div className="component_container">
      <div
        className="floating_container"
        style={{
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: 40,
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 20,
            overflowY: 'auto',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <BackButton />
          </div>

          <h1 className="global_font f-dark" style={{ marginLeft: 30, textAlign: 'center' }}>
            Invoices
          </h1>

          {invoices.length === 0 ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: 30,
                marginTop: 20,
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  color: '#546e7a',
                  textAlign: 'center',
                }}
              >
                There are no invoices.
              </span>
            </div>
          ) : (
            <div
              ref={myRef}
              onScroll={handleScrollReceipts}
              style={{ overflowY: 'scroll', maxHeight: 600 }}
            >
              <div
                style={{
                  flex: 1,
                  margin: 10,
                  display: 'flex',
                }}
              >
                <span style={titleText}>Shift</span>
                <span style={titleText}>Office</span>

                <span style={titleText}>Amount</span>
                <span style={titleText}>Shift Date</span>

                <span style={titleText}>Invoice date</span>
              </div>
              <div style={{ height: 1, backgroundColor: '#F6F6F6' }} />
              {invoices.map((invoice, index) => (
                <motion.div
                  whileHover={{
                    boxShadow:
                      '0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
                    transition: { duration: 0.3 },
                  }}
                  key={invoice.id}
                  style={{
                    flex: 1,
                    margin: 10,
                    display: 'flex',
                    backgroundColor: index % 2 === 0 ? '#F6F6F6' : '#FAFAFA',
                    cursor: 'pointer',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '30px',
                  }}
                >
                  <span
                    style={{
                      fontFamily: 'Nunito',
                      fontSize: 16,
                      color: Colors.neutral_600,
                      textTransform: 'capitalize',
                      width: '20%',
                      margin: 10,
                      textAlign: 'center',
                      textDecoration: 'underline',
                    }}
                    onClick={() =>
                      history.push(`/dashboard/job/${invoice?.job_id}`, {
                        job_id: invoice?.job_id,
                        jobId: invoice?.job_id,
                      })
                    }
                  >
                    View Shift
                  </span>

                  <span style={normalText}>{invoice.office_name}</span>
                  <span
                    style={{
                      fontFamily: 'Nunito',
                      fontSize: 16,
                      color: Colors.neutral_600,
                      textTransform: 'capitalize',
                      width: '20%',
                      margin: 10,
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    {invoice.type === 'credit'
                      ? `- $${currencyFormat(invoice.amount.toFixed(2))}`
                      : `$${currencyFormat(invoice.amount.toFixed(2))}`}
                  </span>
                  <span style={normalText}>
                    {moment(invoice.jobDate).format('ddd, MMM DD, YYYY')}
                  </span>

                  <span style={normalText}>
                    {`${moment(invoice.date).format('MM-DD-YYYY HH:mm')}`}
                  </span>
                </motion.div>
              ))}
              {!isFinalPageInvoices && isActiveRequestInvoices ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <ClipLoader sizeUnit="px" size={50} color={Colors.primary_500} loading />
                </div>
              ) : (
                <div />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
