import { useSelector } from 'react-redux';

export const useIsParentOfficeSelector = () => {
  const isParentOffice = useSelector(
    (state) =>
      (state.user?.selectedChildOffice?.childOffices || state.user?.childOffices)?.length > 1,
  );

  return isParentOffice;
};
