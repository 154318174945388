import { motion } from 'framer-motion';
import { EmptyScreen } from '../../../../commonComponents/EmptyScreen/EmptyScreen';
import { CalendarTodayFilterIcon } from '../../../../images/emptyScreenIcons';

export const EmptyRecentProfessionals = () => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5 }}
    style={{
      maxWidth: '827px',
      height: 600,
      display: 'flex',
      flexDirection: 'column',
      gap: 32,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: -20,
    }}
  >
    <EmptyScreen
      Icon={CalendarTodayFilterIcon}
      title="No recent professionals yet!"
      width={540}
      description={(
        <>
          It looks like you haven&apos;t completed any shifts yet! Once a professional completes a
          shift, they will appear on this screen where you will be able to:
          <ul
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 24,
              marginTop: 20,
              marginLeft: -20,
            }}
          >
            <li>Add them to your favorites.</li>
            <li>Block the professional.</li>
            <li>Send direct invites to upcoming shifts.</li>
          </ul>
        </>
      )}
      descriptionStyle={{
        textAlign: 'left',
      }}
    />
  </motion.div>
);
