import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Colors } from '../themes/colors';
import { createLocumTenensRequest } from '../actions';
import BackButton from '../commonComponents/BackButton';

export default () => {
  const dispatch = useDispatch();
  const [text, setText] = useState('');

  const onSubmit = (event) => {
    event.preventDefault();
    dispatch(createLocumTenensRequest(text));
    setText('');
  };

  return (
    <div className="component_container">
      <div className="floating_container" style={{ flexDirection: 'column' }}>
        <div style={{ marginTop: 20, marginLeft: 30 }}>
          <BackButton />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <h1
            className="card-title global_font f-dark"
            style={{
              textAlign: 'center',
            }}
          >
            Request A Locum Tenens Dentist
          </h1>
          <div style={{ width: '60%' }}>
            <p
              className="global_font f-dark"
              style={{
                fontSize: '16px',
                fontWeight: 700,
                marginTop: '50px',
              }}
            >
              GoTu now offers Locum Tenens Dentists to help you fill your open shifts fast.
            </p>
            <p
              className="global_font"
              style={{
                fontSize: '18px',
                color: Colors.primary_500,
                fontWeight: 700,
                marginTop: '42px',
              }}
            >
              How it works:
            </p>
            <p className="global_font f-dark" style={{ marginTop: '-10px', marginBottom: '50px' }}>
              If your office needs a Locum Tenens Dentist for days, weeks, or months, we’ll help you
              find the right match.
            </p>

            <p
              className="global_font f-dark"
              style={{
                fontSize: '16px',
                fontWeight: 700,
                marginBottom: '-10px',
              }}
            >
              For more information tell us what you are looking for below or contact us now.
            </p>
            <form id="locum-tenens-dentist-form" onSubmit={onSubmit} />
            <textarea
              className="textarea"
              form="locum-tenens-dentist-form"
              placeholder="Ex: We are looking to hire a Locum Tenens Dentist, for 3 months starting in July. Please text/call me at 555-555-5555 and ask for Sarah."
              style={{
                width: '100%',
                height: '192px',
                border: '1px solid transparent',
                borderRadius: '10px',
                padding: '20px 30px',
                backgroundColor: Colors.neutral_50,
                boxShadow: 'inset 0px 2px 4px rgba(0, 0, 0, 0.25)',
              }}
              value={text}
              onChange={(event) => setText(event.target.value)}
            />
            <button
              style={{
                backgroundColor: Colors.primary_500,
                borderRadius: '25px',
                height: '50px',
                width: '320px',
                alignSelf: 'center',
                margin: '100px auto 30px auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              type="submit"
              onClick={(event) => onSubmit(event)}
            >
              <p className="global_font">Submit</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
