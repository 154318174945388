import { trackEvent } from '../../../../../api/analytics';
import { RedirectButton } from '../../../../../commonComponents/Table/RedirectButton';
import { TableData } from '../../../../../commonComponents/Table/TableData';
import { UserSubProfession } from '../../../../../enums/UserProfession';
import { SendTo } from '../SendTo';

export const renderRow = (item, handleOnClick) => {
  const {
    offer_owner: offerOwner,
    local_date: localDate,
    local_start_time: localStartTime,
    local_end_time: localEndTime,
    subProfession,
    professionName,
    specialty,
    rate,
  } = item || {};

  return (
    <>
      <TableData text={offerOwner.office_name} />
      <TableData
        text={
          <span>
            <strong>{localDate}</strong>
            <br />
            {`${localStartTime} - ${localEndTime}`}
          </span>
        }
      />
      <TableData
        text={
          <span>
            <strong>{UserSubProfession.getName(subProfession) ?? professionName}</strong>
            <br />
            {specialty?.split('_')?.[1] || 'General'}
          </span>
        }
      />
      <TableData text={`$${rate.toFixed(2)}`} bold />
      <SendTo 
        item={item} 
        listener={() => {
          trackEvent(`Shifts - Open tab - Invite Icon Clicked`, {
            job: {
              jobId: item.id,
              profession: item.profession,
              rate: item.rate,
              shiftId: item.shiftId,
              totalHours: item.totalHours,
              local_date: item.local_date,
              local_end_time: item.local_end_time,
              local_start_time: item.local_start_time,
              local_timezone: item.local_timezone,
              lunch_break_time: item.lunch_break_time,
              lunch_final_break_time: item.lunch_final_break_time,
              specialty: item.specialty,
            }}
          )}}
      />
      <RedirectButton onClick={() => handleOnClick(item)} />
    </>
  );
};
