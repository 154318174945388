import { useEffect, useState } from 'react';
import greenCircleCheck from '../../../../images/green-circle-check.svg';
import warningIconRed from '../../../../images/warning_alert.svg';
import warningIconOrange from '../../../../images/warning_alert_orange.svg';
import { Colors } from '../../../../themes/colors';

export const useRate = ({ averagePrice, defaultValue }) => {
  const averageRate = averagePrice?.average.toFixed(2).toString() || '40.00';
  const [selectedRate, setSelectedRate] = useState(averageRate);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [industrialAverage, setIndustrialAverage] = useState(100.0);
  const [industrialAverageColor, setIndustrialAverageColor] = useState(Colors.success_500);
  const rates = [];
  const minPrice = averagePrice?.min || 28;
  const maxPrice = averagePrice?.max || 72;

  for (let i = minPrice; i <= maxPrice; i += 1) {
    rates.push(i);
  }

  const industrialAverageIcon = {
    [Colors.success_500]: greenCircleCheck,
    [Colors.warning_700]: warningIconOrange,
    [Colors.error_500]: warningIconRed,
  };

  const getIndustrialAverageColor = (percentage) => {
    if (percentage >= 1) {
      return Colors.success_500;
    }

    if (percentage >= 0.85 && percentage < 1) {
      return Colors.warning_700;
    }

    return Colors.error_500;
  };

  const getIndustrialAverageText = () => {
    let message;
    let status;

    if (industrialAverage >= 106) {
      status = 'More likely to fill.';
      message = 'Rate is competitive with the industry average';
    } else if (industrialAverage >= 100 && industrialAverage < 106) {
      status = 'Likely to fill.';
      message = 'Rate is around the industry average';
    } else if (industrialAverage >= 85 && industrialAverage < 100) {
      status = 'Less likely to fill.';
      message = 'Rate is below industry average';
    } else {
      status = 'Unlikely to fill.';
      message = 'Rate is well below industry average';
    }

    return (
      <b
        style={{
          color: Colors.neutral_600,
          fontSize: 16,
        }}
      >
        <span style={{ color: industrialAverageColor }}>{status}</span> {message}
      </b>
    );
  };

  useEffect(() => {
    setSelectedRate(averageRate);
    setIndustrialAverage(100.0);
    setIndustrialAverageColor(Colors.success_500);
    setDisabledBtn(false);
  }, [averageRate]);

  const getWarningMessage = () => {
    if (selectedRate < rates[0]) {
      return (
        <span
          className="bold global_font f-dark"
          style={{ color: Colors.error_500, fontSize: 16, textAlign: 'center' }}
        >
          Invalid value, too low
        </span>
      );
    }

    if (selectedRate > rates[rates.length - 1]) {
      return (
        <span
          className="bold global_font f-dark"
          style={{ color: Colors.error_600, fontSize: 16, textAlign: 'center' }}
        >
          Invalid value, above maximum
        </span>
      );
    }

    return getIndustrialAverageText();
  };

  const setOfferRate = (newRate) => {
    setSelectedRate(newRate);

    if (newRate < rates[0] || newRate > rates[rates.length - 1]) {
      setDisabledBtn(true);
    } else {
      setDisabledBtn(false);
    }

    const percentage = newRate / averageRate;

    setIndustrialAverage(parseFloat(percentage * 100).toFixed(1));
    setIndustrialAverageColor(getIndustrialAverageColor(percentage));
  };

  const currentIndex = rates.reduce((closestIndex, value, index) => {
    const currentValue = Math.abs(Math.ceil(value - selectedRate));
    const closestValue = Math.abs(Math.ceil(rates[closestIndex] - selectedRate));

    return currentValue < closestValue ? index : closestIndex;
  }, 0);

  useEffect(() => {
    if (defaultValue) {
      setOfferRate(defaultValue);
    }
  }, []);

  return {
    selectedRate,
    industrialAverageIcon,
    industrialAverageColor,
    rates,
    getWarningMessage,
    setOfferRate,
    currentIndex,
    disabledBtn,
    setSelectedRate,
    industrialAverage,
  };
};
