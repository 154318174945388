import '../themes/selectable_chips.scss';

/**
 * Showing chip collection
 *
 * @param values
 * @param selectedIndices
 * @param onItemPressed
 * @param style
 * @constructor
 */
export function SelectableChips({ values, selectedIndices, onItemPressed, style }) {
  return (
    <div className="selectable-chip" style={style}>
      {values.map((value, index) => {
        const selected = [...selectedIndices].includes(index);

        return (
          <div
            className="selectable-chip-item"
            onClick={() => onItemPressed(index)}
            key={`procedure-${value}`}
          >
            <input id={`procedure-${value}`} type="checkbox" defaultChecked={selected} />

            <label htmlFor={`procedure-${index}`}>{value}</label>
          </div>
        );
      })}
    </div>
  );
}
