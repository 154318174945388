import moment from 'moment';
import { UserProfession } from '../../../../enums/UserProfession';
import { Colors } from '../../../../themes/colors';
import { ProfessionalRating } from './ProfessionalRating';

export const ProfessionalCardDetails = ({ user, popoverPosition = 'bottom' }) => {
  const { fullname, datetime, profession, rating } = user;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '24px',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 8,
        }}
      >
        <span
          style={{
            fontFamily: 'Nunito',
            fontSize: 22,
            fontWeight: 700,
            color: Colors.neutral_600,
            maxWidth: 310,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {fullname}
        </span>

        <ProfessionalRating rating={rating} popoverPosition={popoverPosition} />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <span
          style={{
            fontSize: 16,
            color: Colors.neutral_500,
          }}
        >
          {UserProfession.getName(profession)}
        </span>
        <b
          style={{
            fontFamily: 'Nunito',
            fontSize: 16,
            color: Colors.neutral_500,
          }}
        >
          Worked on:{' '}
          <span
            style={{
              fontWeight: 'normal',
              textTransform: 'capitalize',
            }}
          >
            {moment(datetime).format('ddd, MMM DD, YYYY')}
          </span>
        </b>
      </div>
    </div>
  );
};
