import InputSelect from '../../../../commonComponents/InputSelect';
import Tooltip from '../../../Tooltip';
import UserIcon from '../../../../images/user-icon.svg';
import { useJobCandidates } from '../../hooks/useJobCandidates';
import { Row, Text } from '../../../../commonComponents';
import { Colors } from '../../../../themes/colors';

export const Filters = () => {
  const { onFilterChange, filters } = useJobCandidates();

  return (
    <Row alignItems="center">
      <img alt="icon" src={UserIcon} style={{ width: 24, height: 24, marginRight: 4 }} />

      <Text fontSize={20} bold>
        Sort By:
      </Text>

      <div style={{ width: 210, marginLeft: 12, marginRight: 4 }}>
        <InputSelect
          value={filters?.sort}
          setValue={(option) => {
            onFilterChange({ sort: option });
          }}
          isSelectVisible={false}
          options={[
            { title: 'Local Favorites', value: 'local-favorites' },
            { title: 'Skill Match', value: 'skills-match' },
            { title: 'Previously Rated', value: 'previously-rated' },
          ]}
        />
      </div>

      <Tooltip
        content={
          <Text color={Colors.white}>
            <Text color={Colors.white} bold>
              Local Favorites
            </Text>
            : Professionals who are often liked by other offices.
            <br />
            <br />
            <Text color={Colors.white} bold>
              Skill Match
            </Text>
            : Professionals whose skills and procedures most closely match your needs.
            <br />
            <br />
            <Text color={Colors.white} bold>
              Previously Rated
            </Text>
            : Professionals that have most likely worked with your office before.
          </Text>
        }
      />
    </Row>
  );
};
