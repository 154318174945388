import React from 'react';

import { Colors } from '../themes/colors';
import arrowBack from '../images/Arrow_left.png';
import arrowBackWhite from '../images/arrow_left.svg';
import history from '../history';

export default ({ whiteArrow, containerStyle, backRoute }) => (
  <div
    style={{
      display: 'flex',
      cursor: 'pointer',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      marginLeft: 10,
      ...containerStyle,
    }}
    onClick={() => (backRoute ? history.push(backRoute) : history.goBack())}
  >
    <img
      src={whiteArrow ? arrowBackWhite : arrowBack}
      alt="back"
      style={{ height: 20, marginRight: 10 }}
    />
    <p
      className="global_font f-dark"
      style={{
        color: Colors.white,
        fontWeight: 'bold',
        fontSize: 22,
        textAlign: 'left',
        width: '100%',
        cursor: 'pointer',
      }}
    >
      Back
    </p>
  </div>
);
