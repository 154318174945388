import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getWorkerClassificationManagement } from '../../actions';
import { createLoadingSelector } from '../../api/selectors';
import BackButton from '../../commonComponents/BackButton';
import CustomSpinner from '../../commonComponents/CustomSpinner';
import { Tab, TabList, TabPanel, Tabs } from '../../commonComponents/Tabs';
import { Colors } from '../../themes/colors';
import { ConfirmedWorkerType } from './ConfirmedWorkerType/ConfirmedWorkerType';
import { PendingRequests } from './PendingRequests/PendingRequests';
import { Unselected } from './Unselected/Unselected';
import { getInitialTab } from './utils';

const loadingSelector = createLoadingSelector(['GET_WORKER_CLASSIFICATION_MANAGEMENT']);

export function WorkerClassificationManagement() {
  const dispatch = useDispatch();
  const requests = useSelector((state) => state.user.workerClassificationManagement);
  const isLoading = useSelector((state) => loadingSelector(state));
  const [initialTab, setInitialTab] = useState(0);

  useEffect(() => {
    setInitialTab(getInitialTab(requests));
  }, [requests]);

  useEffect(() => {
    dispatch(getWorkerClassificationManagement());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="component_container">
      <div
        className="floating_container"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          paddingBottom: 0,
        }}
      >
        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <div style={{ position: 'absolute', left: 60, top: 40 }}>
            <BackButton />
          </div>

          <div>
            <span
              style={{
                fontFamily: 'Nunito',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: 120,
                marginBottom: 50,
                color: Colors.neutral_600,
                fontSize: 32,
                fontWeight: 700,
              }}
            >
              Worker Type Management
            </span>

            <span
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontFamily: 'Nunito',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: 50,
                fontSize: 16,
                color: Colors.neutral_500,
                fontStyle: 'italic',
                gap: 2,
              }}
            >
              For more information about what worker type is,
              <a
                href="https://lp.joingotu.com/workerclassification"
                target="_blank"
                rel="noreferrer"
                style={{
                  fontFamily: 'Nunito',
                  color: Colors.secondary_500,
                  fontWeight: 700,
                  textDecoration: 'none',
                  fontStyle: 'normal',
                }}
              >
                Click here
              </a>
              .
            </span>
          </div>

          {isLoading ? (
            <CustomSpinner style={{ marginBottom: 0 }} isLoading />
          ) : (
            <div style={{ width: '80%', alignSelf: 'center', marginBottom: 120 }}>
              <Tabs initialTab={initialTab}>
                <TabList>
                  <Tab
                    title="Unselected"
                    badge={requests?.unselected?.length > 10 ? '10+' : requests?.unselected?.length}
                  />
                  <Tab
                    title="Pending Requests"
                    badge={requests?.pending?.length > 10 ? '10+' : requests?.pending?.length}
                  />
                  <Tab title="Confirmed Worker Type" />
                </TabList>
                <TabPanel>
                  <Unselected />
                  <PendingRequests />
                  <ConfirmedWorkerType />
                </TabPanel>
              </Tabs>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
