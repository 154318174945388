import BackButton from '../../../commonComponents/BackButton';
import { Tab, TabList, TabPanel, Tabs } from '../../../commonComponents/Tabs';
import { Colors } from '../../../themes/colors';
import { OpenTab } from './components/OpenTab/OpenTab';
import { PendingTab } from './components/PendingTab/PendingTab';
import { ScheduledTab } from './components/ScheduledTab/ScheduledTab';
import { useShiftList } from './hooks/useShiftList';

export const ShiftList = ({ history, match }) => {
  const type = history.location.state ? history.location.state.type : match.params.type;
  const { activeTab, dashboardInfo, setActiveTab } = useShiftList({ type });

  return (
    <>
      <div className="component_container">
        <div
          className="floating_container"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            paddingBottom: 0,
          }}
        >
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <div style={{ position: 'absolute', left: 20, top: 40 }}>
              <BackButton />
            </div>

            <div>
              <span
                style={{
                  fontFamily: 'Nunito',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  marginTop: 120,
                  marginBottom: 50,
                  color: Colors.neutral_600,
                  fontSize: 32,
                  fontWeight: 700,
                }}
              >
                Shifts Overview
              </span>
            </div>

            <div style={{ width: '85%', alignSelf: 'center', marginBottom: 80 }}>
              <Tabs initialTab={activeTab} onChangeTab={setActiveTab}>
                <TabList>
                  <Tab title="Open Shifts" />
                  <Tab
                    title="Action Needed"
                    badge={
                      dashboardInfo?.actionRequired > 10 ? '10+' : dashboardInfo?.actionRequired
                    }
                  />
                  <Tab title="Scheduled Shifts" />
                </TabList>
                <TabPanel>
                  <OpenTab />
                  <PendingTab />
                  <ScheduledTab />
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
