import { motion } from 'framer-motion';
import { EmptyScreen } from '../../../../commonComponents/EmptyScreen/EmptyScreen';
import { EmptyHighlyRatedIcon } from '../../../../images/emptyScreenIcons';

export const EmptyHighlyRated = () => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5 }}
    style={{
      maxWidth: '827px',
      height: 600,
      display: 'flex',
      flexDirection: 'column',
      gap: 32,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: -20,
    }}
  >
    <EmptyScreen
      Icon={EmptyHighlyRatedIcon}
      title="No highly-rated professionals yet!"
      width={540}
      description={(
        <>
          Highly-rated professionals are professionals that you have worked with and received an
          average rating greater than 4.2 on your last shift worked together. There are two ways to
          add professionals to this list:
          <ul
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 24,
              marginTop: 20,
              marginLeft: -20,
            }}
          >
            <li>
              <b>End-of-day review:</b>
              {' '}
              Leave a rating for a professional during the end of the day
              review.
            </li>
            <li>
              <b>Professional Hub:</b>
              {' '}
              Add a rating for a professional you recently worked with on
              the “Recent Professionals” tab.
            </li>
          </ul>
        </>
      )}
      descriptionStyle={{
        textAlign: 'left',
      }}
    />
  </motion.div>
);
