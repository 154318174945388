import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DELETE_JOB_FROM_MDB, deleteJobFromMDB, MULTIDAY_BOOKING_INVITE_POST, selectDaysMDB } from '../../../../actions';
import history from '../../../../history';
import useLoading from '../../../../hooks/useLoading';
import { handleConfirmRemoveDate } from '../utils';

const actions = [DELETE_JOB_FROM_MDB, MULTIDAY_BOOKING_INVITE_POST];

export const useReviewStep = () => {
  const dispatch = useDispatch();
  const [showDeleted, setShowDeleted] = useState(false);
  const [deletedJob, setDeletedJob] = useState(null);
  const jobDates = useSelector((state) => state.multiday.jobDates);
  const jobInfo = useSelector((state) => state.multiday.jobInfo);
  const { isLoading } = useLoading(actions);

  const editPressed = (editedDate) => {
    history.push('/dashboard/multi-day/edit', { editedDate });
  };

  const confirmRemoveDate = () => {
    const jobData = handleConfirmRemoveDate(jobDates, deletedJob);
    dispatch(selectDaysMDB({ jobData }));
    dispatch(deleteJobFromMDB({ deletedJob }));
  };

  const deletePressed = (jobDate) => {
    setDeletedJob(jobDate);
    setShowDeleted(true);
  };

  return {
    jobDates,
    isLoading,
    jobInfo,
    showDeleted,
    deletedJob,
    editPressed,
    confirmRemoveDate,
    deletePressed,
    setShowDeleted,
  };
};
