import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactSwitch from 'react-switch';
import { RemoteConfigUtils } from '../../utils/RemoteConfigUtils';
import { growthbook } from '../../growthbook';

import Button from '../../commonComponents/Button';
import arrowRight from '../../images/arrow_right.svg';

import { acceptPSATerms } from '../../actions';
import { ProgressBar } from '../../commonComponents/ProgressBar';
import { Colors } from '../../themes/colors';
import { STEPS } from './utils';

export function ConfirmDualModelTermsPopup() {
  const [stepIndex, setStepIndex] = useState(0);
  const dispatch = useDispatch();
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const { user } = useSelector((state) => state.user);
  const stateToShowDualModelPopup = RemoteConfigUtils.getShowDualModelLaunchStates();
  const shouldDisplayDualModelPopupStates = stateToShowDualModelPopup.includes(
    user.address?.[0]?.state,
  );
  const [showPopup, setShowPopup] = useState(false);
  const shouldDisplayDualModelPopup =
    user?.is_profile_complete &&
    shouldDisplayDualModelPopupStates &&
    !user?.office_info?.psaTerms?.accepted &&
    growthbook.isOn('should-display-terms-popup') &&
    showPopup;

  const getStorage = () => {
    const showDualModelTermsPopUp = localStorage.getItem(`showDualModelTermsPopUp-${user?.id}`);

    if (showDualModelTermsPopUp === null || showDualModelTermsPopUp === 'true') {
      setShowPopup(true);
    }
  };

  const handleOnClick = () => {
    if (stepIndex < STEPS.length - 1) {
      setStepIndex(stepIndex + 1);
      return;
    }
    dispatch(acceptPSATerms());
  };

  useEffect(() => {
    getStorage();
  }, [user]);

  if (shouldDisplayDualModelPopup) {
    return (
      <div
        className="modal"
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          position: 'fixed',
        }}
      >
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4 }}
          style={{
            width: 580,
            height: 600,
            backgroundColor: Colors.white,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 14,
            padding: 35,
            paddingBottom: 48,
            overflowY: 'auto',
          }}
        >
          {stepIndex > 0 && (
            <button
              type="button"
              style={{ all: 'unset', cursor: 'pointer' }}
              onClick={() => setStepIndex(stepIndex - 1)}
            >
              <div style={{ position: 'absolute', transform: 'rotate(180deg)' }}>
                <img src={arrowRight} alt="back button" />
              </div>
            </button>
          )}
          <div
            style={{
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img
              src={STEPS[stepIndex].image}
              style={{ width: 170, height: 170, marginBottom: 20 }}
              alt="welcome_illustration"
            />

            <ProgressBar step={stepIndex} size={3} />

            <div
              style={{
                display: 'flex',
                height: '100%',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <h1
                  style={{
                    fontFamily: 'Nunito',
                    fontWeight: 'bold',
                    fontSize: 18,
                    color: Colors.neutral_600,
                    marginTop: 32,
                    marginBottom: 24,
                  }}
                >
                  {STEPS[stepIndex].title}
                </h1>
                <p
                  style={{
                    fontFamily: 'Nunito',
                    fontSize: 16,
                    color: Colors.neutral_500,
                    margin: 0,
                    textAlign: 'center',
                  }}
                >
                  {STEPS[stepIndex].description}
                </p>

                {STEPS[stepIndex].switchText && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 16,
                      width: 480,
                      marginTop: 20,
                    }}
                  >
                    <ReactSwitch
                      checked={acceptedTerms}
                      onChange={() => setAcceptedTerms(!acceptedTerms)}
                      onColor={Colors.secondary_500}
                      onHandleColor="#fff"
                      handleDiameter={20}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      offColor={Colors.neutral_100}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.35)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={25}
                      width={50}
                      className="react-switch"
                      id="material-switch"
                    />
                    <p className="global_font" style={{ margin: 0, color: Colors.neutral_500 }}>
                      {STEPS[stepIndex].switchText}
                    </p>
                  </div>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Button
                  type="button"
                  style={{
                    width: 150,
                  }}
                  disabled={STEPS[stepIndex].switchText && !acceptedTerms}
                  onClick={handleOnClick}
                  text={stepIndex === STEPS.length - 1 ? 'Done' : 'Continue'}
                />
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    );
  }
  return <></>;
}
