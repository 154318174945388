import moment from 'moment';

/**
 * Populate the time difference value
 * @param {*} originalStartTime - The original start time
 * @param {*} originalEndTime - The original end time
 * @param {*} adjustedStartTime - The adjusted start time
 * @param {*} adjustedEndTime - The adjusted end time
 * @param {*} originalLunchBreakTime - The original lunch break time
 * @param {*} adjustedLunchBreakTime - The adjusted lunch break time
 * @returns The time difference value
 */
export const populateTimeDifferenceValue = ({
  originalStartTime,
  originalEndTime,
  adjustedStartTime,
  adjustedEndTime,
  originalLunchBreakTime,
  adjustedLunchBreakTime,
}) => {
  const formattedNewStartTime = moment(adjustedStartTime, 'ddd, MMM DD, YYYY HH:mm:ss a');
  const formattedNewEndTime = moment(adjustedEndTime, 'ddd, MMM DD, YYYY HH:mm:ss a');

  const originalTimeDifference =
    moment(originalEndTime).diff(moment(originalStartTime), 'minutes') -
    parseInt(originalLunchBreakTime, 10);
  const adjustedTimeDifference =
    formattedNewEndTime.diff(formattedNewStartTime, 'minutes') -
    parseInt(adjustedLunchBreakTime, 10);

  const totalTimeDifference = adjustedTimeDifference - originalTimeDifference;
  const duration = moment.duration(totalTimeDifference, 'minutes');

  return {
    originalTimeDifference,
    adjustedTimeDifference,
    totalTimeDifference,
    duration,
  };
};

/**
 * Calculate the time difference
 * @param {*} originalStartTime - The original start time
 * @param {*} originalEndTime - The original end time
 * @param {*} adjustedStartTime - The adjusted start time
 * @param {*} adjustedEndTime - The adjusted end time
 * @param {*} originalLunchBreakTime - The original lunch break time
 * @param {*} adjustedLunchBreakTime - The adjusted lunch break time
 * @returns {string} Time difference - The time difference
 */
export const timeDifferenceCalculator = (
  originalStartTime,
  originalEndTime,
  adjustedStartTime,
  adjustedEndTime,
  originalLunchBreakTime,
  adjustedLunchBreakTime,
) => {
  const { duration } = populateTimeDifferenceValue({
    originalStartTime,
    originalEndTime,
    adjustedStartTime,
    adjustedEndTime,
    originalLunchBreakTime,
    adjustedLunchBreakTime,
  });

  const displayHour = (num) => `${Math.abs(num) === 1 ? `hour` : `hours`}`;

  if (duration.hours() === 0 && duration.minutes() === 0) return 'No changes in time.';
  if (duration.hours() !== 0 && duration.minutes() !== 0)
    return `${duration.hours()} ${displayHour(duration.hours())} ${`and`} ${
      duration.minutes() < 0 ? duration.minutes() * -1 : duration.minutes()
    } minutes`;
  if (duration.hours() !== 0 && duration.minutes() === 0)
    return `${duration.hours()} ${displayHour(duration.hours())}`;
  return `${duration.minutes()} minutes`;
};

/**
 * Take the job and form values and return the time difference form value mapping
 * @param {*} job - job
 * @param {*} form - form values
 * @returns Time difference form value mapping
 */
export const timeDifferenceFormValueMapping = (job, form) => ({
  originalStartTime: job?.job_datetime,
  originalEndTime: job?.job_datetime_end,
  adjustedStartTime: `${job?.local_date} ${moment(form.start).format('hh:mm a')}`,
  adjustedEndTime: `${job?.local_date} ${moment(form.end).format('hh:mm a')}`,
  originalLunchBreakTime: job?.is_lunch_break_paid ? '0.0' : job?.lunch_final_break_time,
  adjustedLunchBreakTime: form.breakPaid ? '0.0' : form.break,
});
