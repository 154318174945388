import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SIGN_IN_ACTION, signIn } from '../../../actions';
import { createLoadingSelector } from '../../../api/selectors';
import Button from '../../../commonComponents/Button';
import { validateEmail } from '../../../commonComponents/CommonFunctions';
import { InputPassword } from '../../../commonComponents/InputPassword';
import history from '../../../history';
import { Colors } from '../../../themes/colors';
import InputError from '../components/InputError';

export default function SignIn() {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitError, setSubmitError] = useState(false);

  const loadingSelector = createLoadingSelector([SIGN_IN_ACTION]);
  const isLoading = useSelector((state) => loadingSelector(state));

  const showEmailError = !validateEmail(email) && email.length > 0;
  const disabledSubmit =
    email.indexOf(' ') >= 0 ||
    email.length <= 0 ||
    password.indexOf(' ') >= 0 ||
    password.length <= 0;

  const submitSignIn = (e) => {
    e.preventDefault();

    if (showEmailError) {
      setSubmitError(true);

      return;
    }

    dispatch(signIn({ email, password }));
  };

  return (
    <motion.form
      id="signinForm"
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      style={{
        width: '50%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
        marginTop: 20,
      }}
    >
      <h1 className="global_font bold" style={{ color: Colors.primary_500, marginBottom: 0 }}>
        Hello Again!
      </h1>

      <p className="global_font f-dark" style={{ margin: 0 }}>
        Welcome Back! Please enter your details.
      </p>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          maxWidth: 430,
          marginBottom: 15,
        }}
      >
        <div style={{ position: 'relative', width: '100%', marginBottom: 20 }}>
          <p className="input_names" style={{ width: '100%', marginBottom: 8 }}>
            Email
          </p>
          <input
            id="email"
            type="text"
            className="input-field"
            placeholder="Enter your email"
            style={{ width: 'calc(100% - 40px)', margin: 0 }}
            onChange={(e) => setEmail(e.target.value)}
          />
          <InputError
            text="The email address entered is invalid."
            showError={submitError && showEmailError}
          />
        </div>

        <p className="input_names" style={{ width: '100%', marginBottom: 8 }}>
          Password
        </p>
        <InputPassword
          id="password"
          placeholder="Enter your password"
          style={{ width: 'calc(100% - 40px)' }}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      <h5
        id="forgotPasswordButton"
        className="sm-links"
        onClick={() => history.push('/forgotpassword')}
      >
        Forgot Password?
      </h5>

      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          marginBottom: 120,
        }}
      >
        <Button
          isLoading={isLoading}
          disabled={disabledSubmit}
          type="submit"
          text="Log In"
          onClick={submitSignIn}
        />
      </div>
    </motion.form>
  );
}
