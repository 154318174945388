import { motion } from 'framer-motion';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { VERIFY_PARTNERSHIP_CODE, verifyPartnershipCode } from '../../../../../actions';
import { Box, Icon, Label, Row, Text } from '../../../../../commonComponents';
import Button from '../../../../../commonComponents/Button';
import InputSelect from '../../../../../commonComponents/InputSelect';
import useLoading from '../../../../../hooks/useLoading';
import { Colors } from '../../../../../themes/colors';
import Tooltip from '../../../../Tooltip';
import { useOfficeInformation } from '../hooks/useOfficeInformation';

export const PartnershipField = () => {
  const dispatch = useDispatch();
  const { setValue, control } = useFormContext();
  const { formValues } = useOfficeInformation(control);
  const selectedChildOffice = useSelector((state) => state.user.selectedChildOffice);
  const partnershipOrganizations = useSelector((state) => state.user.partnershipOrganizations);
  const { isLoading } = useLoading([VERIFY_PARTNERSHIP_CODE]);

  return (
    <Box>
      <Box marginBottom={12}>
        <Text fontSize={18} bold style={{ flex: '1 1 100%' }}>
          Select which Dental Association or state this office is affiliated with, if applicable.
        </Text>

        <Box display="inline-block" marginLeft={5} position="relative" top={5}>
          <Tooltip content="GoTu is strategically partnered with various Dental Associations. If you are affiliated with any of the following associations, you may be eligible to have your booking fee refunded on your first completed shift." />
        </Box>
      </Box>

      {selectedChildOffice?.partnerReferral ? (
        <Text style={{ marginLeft: 20 }}>{formValues.partnershipOrganization}</Text>
      ) : (
        <InputSelect
          value={formValues.partnershipOrganization}
          setValue={(value) => setValue('partnershipOrganization', value)}
          options={partnershipOrganizations?.map((item) => ({ title: item, value: item }))}
        />
      )}

      {formValues.partnershipOrganization && (
        <motion.div
          initial={selectedChildOffice?.partnerReferral ? 'show' : 'hide'}
          animate={formValues.partnershipOrganization ? 'show' : 'hide'}
          variants={formValues.partnershipCodeVariants}
          transition={{ duration: 0.3 }}
        >
          <Box marginTop={24}>
            <Label text="Partner Discount Code:" required={false} />
          </Box>

          <Row position="relative" alignItems="center">
            {selectedChildOffice?.partnerReferral ? (
              <Row alignItems="center">
                <Text marginLeft={20}>{formValues.partnershipCode}</Text>
                <Icon name="circle-check-solid" />
              </Row>
            ) : (
              <input
                className="input-field"
                type="text"
                value={formValues.partnershipCode}
                placeholder="Enter Code"
                onChange={(e) => setValue('partnershipCode', e.target.value)}
                disabled={selectedChildOffice?.partnerReferral}
                style={{
                  width: '100%',
                  maxWidth: '-webkit-fill-available',
                  marginBottom: 0,
                }}
              />
            )}
          </Row>

          {!selectedChildOffice?.partnerReferral && (
            <Row width="100%" marginTop={46} justifyContent="center">
              <Button
                type="button"
                disabled={!formValues.partnershipCode}
                style={{
                  alignSelf: 'center',
                  width: 150,
                  margin: 0,
                  backgroundColor: !formValues.partnershipCode
                    ? Colors.secondary_200
                    : Colors.secondary_500,
                }}
                onClick={() =>
                  dispatch(
                    verifyPartnershipCode({
                      code: formValues.partnershipCode,
                      organization: formValues.partnershipOrganization,
                    }),
                  )
                }
                text="Apply Code"
                isLoading={isLoading}
              />
            </Row>
          )}
        </motion.div>
      )}
    </Box>
  );
};
