import React, { useState } from 'react';
import { Colors } from '../../../../themes/colors';

export const MenuItem = ({ title, isActive, onClick, isRegistration }) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const getTextColor = () => {
    if (isActive) {
      if (isRegistration) {
        return Colors.white;
      }
      return Colors.neutral_300;
    }

    if (isRegistration) {
      return Colors.neutral_300;
    }

    return Colors.neutral_400;
  };

  const getListColor = () => {
    if (isActive) {
      if (isRegistration) {
        return Colors.white;
      }
      return Colors.primary_500;
    }

    if (isRegistration) {
      return Colors.neutral_300;
    }

    return Colors.neutral_200;
  };

  return (
    <li>
      <button
        type="button"
        style={{
          all: 'unset',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          paddingLeft: 10,
          height: 36,
          borderRadius: 6,
          cursor: 'pointer',
          ...(isHovering && {
            backgroundColor: isRegistration ? Colors.primary_500 : Colors.neutral_100,
          }),
        }}
        onFocus={handleMouseOver}
        onBlur={handleMouseOut}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={onClick}
      >
        <div
          style={{
            height: 16,
            width: 16,
            backgroundColor: getListColor(),
            borderRadius: '50%',
            marginRight: 18,
          }}
        />
        <span
          style={{
            fontFamily: 'Nunito',
            fontSize: 18,
            fontWeight: isActive ? 700 : 400,
            color: getTextColor(),
          }}
        >
          {title}
        </span>
      </button>
    </li>
  );
};
