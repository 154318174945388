import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  fetchJob,
  changeStatusOfAdjustments,
  CHANGE_STATUS_ADJUSTMENT,
  FETCH_JOB_ACTION,
} from '../../actions';
import { Colors } from '../../themes/colors';
import { HygienistHeaderInfo, renderLine, DisputeReasonModal } from './AdjustmentsComponents';
import { createLoadingSelector } from '../../api/selectors';
import CustomSpinner from '../../commonComponents/CustomSpinner';
import history from '../../history';
import BackButton from '../../commonComponents/BackButton';

export default ({ match }) => {
  const [showDisputeReasonScreenModal, setShowDisputeReasonScreenModal] = useState(false);
  const dispatch = useDispatch();
  const actions = [CHANGE_STATUS_ADJUSTMENT, FETCH_JOB_ACTION];

  const { jobId } = match.params;

  const job = useSelector((state) =>
    state.job.adjustments.filter((currJob) => currJob.id === jobId),
  )[0];

  if (!job) {
    history.push('/');
  }
  const childOffices = useSelector((state) => state.user.childOffices);

  const currentAdjustment = job?.adjustments[0];

  const loadingSelector = createLoadingSelector(actions);

  const isLoading = useSelector((state) => loadingSelector(state));

  useEffect(() => {
    dispatch(fetchJob({ jobId }));
  }, [dispatch, jobId]);

  const renderContent = () => {
    if (isLoading) {
      return <CustomSpinner isLoading />;
    }
    if (!job) {
      return <div />;
    }
    return (
      <>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            width: '50%',
            maxWidth: 700,
            minWidth: 500,
          }}
        >
          {childOffices.length !== 0 ? (
            renderLine({
              title: 'Office:',
              value: job.offer_owner?.office_name,
            })
          ) : (
            <div />
          )}
          {renderLine({ title: 'Date:', value: job.local_date })}
          {renderLine({
            title: 'Hours:',
            value: `${job.local_start_time} - ${job.local_end_time}`,
            isOldFied: true,
            timezone: true,
          })}
          {renderLine({
            title: 'Adjusted Hours:',
            value: `${moment(currentAdjustment?.start).format('hh:mm a')} - ${moment(
              currentAdjustment?.finish,
            ).format('hh:mm a')}`,
            isAdjustedField: true,
          })}
          {renderLine({
            title: 'Hourly Rate:',
            value: `$${job.rate.toFixed(2)}`,
          })}
          {renderLine({
            title: `Meal Break ${job.is_lunch_break_paid ? '(Paid)' : '(Unpaid)'}`,
            value: `${job.lunch_break_time} min`,
            isOldFied: true,
          })}
          {renderLine({
            title: 'Adjusted Meal Break:',
            value: `${currentAdjustment?.break} Min`,
            isAdjustedField: true,
          })}
          {renderLine({
            title: 'Auto-Confirm:',
            value: job.autoFill ? 'On' : 'Off',
          })}
        </div>
        <div
          className="global_font bold_font"
          style={{
            marginTop: 64,
            marginBottom: 64,
            alignItems: 'center',
            justifyContent: 'center',
            color: Colors.neutral_600,
            fontSize: 16,
            textAlign: 'center',
          }}
        >
          <span>The professional has submitted an adjustment to this shift.</span>
          <div>
            <span style={{ color: Colors.primary_700 }}>Adjustment Difference: </span>
            {currentAdjustment.total_change >= 0
              ? `+$${currentAdjustment.total_change.toFixed(2)}`
              : `-$${Math.abs(currentAdjustment.total_change).toFixed(2)}`}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 30,
            marginBottom: 70,
          }}
        >
          <button
            type="button"
            className="green-button"
            style={{ backgroundColor: Colors.neutral_500, maxWidth: 200 }}
            onClick={() => setShowDisputeReasonScreenModal(true)}
          >
            <p className="white-text-18">Dispute</p>
          </button>
          <button
            type="button"
            className="green-button"
            style={{ backgroundColor: Colors.primary_500, maxWidth: 200 }}
            onClick={() =>
              dispatch(
                changeStatusOfAdjustments({
                  jobId,
                  adjustmentId: currentAdjustment.id,
                  status: 'applied',
                }),
              )
            }
          >
            <p className="white-text-18">Confirm</p>
          </button>
        </div>
      </>
    );
  };
  return (
    <div className="component_container">
      <div
        className="floating_container"
        style={{
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: 0,
          position: 'relative',
        }}
      >
        <div
          style={{
            backgroundColor: Colors.white,
            width: '100%',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            paddingTop: '76px',
            paddingBottom: 35,
            boxShadow: '0px 3px 10px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
            zIndex: 2,
            position: 'relative',
            marginBottom: 64,
          }}
        >
          <div style={{ gridArea: '1 / 1', paddingLeft: '73px' }}>
            <BackButton />
          </div>
          <div
            style={{
              gridArea: '1 / 2',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h1
              className="title"
              style={{
                textAlign: 'center',
                color: Colors.neutral_600,
                fontSize: '32px',
                width: '100%',
              }}
            >
              New Adjustments
            </h1>
            <HygienistHeaderInfo job={job} />
          </div>
        </div>
        {renderContent()}
        {showDisputeReasonScreenModal ? (
          <DisputeReasonModal
            submitDisputeReasonMessage={(data) =>
              dispatch(
                changeStatusOfAdjustments({
                  jobId,
                  adjustmentId: currentAdjustment.id,
                  disputeReason: data,
                  status: 'declined',
                }),
              )
            }
            closeModal={() => setShowDisputeReasonScreenModal(false)}
          />
        ) : null}
      </div>
    </div>
  );
};
