import { useWatch } from 'react-hook-form';

export const useNotes = (control) => {
  const watchedValues = useWatch({
    control,
    name: ['notes'],
  });

  const formValues = {
    notes: watchedValues[0],
  };

  return {
    formValues,
  };
};
