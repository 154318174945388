import React, { useEffect } from 'react';
import { Route, useLocation } from 'react-router-dom';

export const AppRoute = ({ title, render, component: Component, ...rest }) => {
  const location = useLocation();

  useEffect(() => {
    if (title) {
      // Send analytics page view
      window.analytics.page(title);

      if (process.env.REACT_APP_TEMP_MEE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.debug(`Viewed ${title}`);
      }
    }
  }, [title, location.pathname]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (render) {
          return render(props);
        }

        return <Component {...props} />;
      }}
    />
  );
};
