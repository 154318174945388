import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EDIT_JOB_ACTION, editJob, getUserEducation } from '../../../../../actions';
import { getAutoFillInfo } from '../../../../../constants';
import { UserProfession, UserSubProfession } from '../../../../../enums/UserProfession';
import useLoading from '../../../../../hooks/useLoading';
import { convertMinutesToFormattedValue, convertTimeToMinutes } from '../../../multiday/utils';

export const useEditJobForm = (job) => {
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState(null);
  const { isLoading } = useLoading([EDIT_JOB_ACTION]);

  const autoFillInfo = useMemo(
    () => getAutoFillInfo(formValues?.professionType, formValues?.skills),
    [formValues?.professionType, formValues?.skills],
  );

  const handleSubmit = () => {
    const professionTypeCode = UserProfession.getNormalizedCode(job?.profession);

    const jobData = {
      localDate: formValues?.localDate,
      localStart: formValues?.offerStartTime,
      localEnd: formValues?.offerEndTime,
      rate: formValues?.rate,
      lunch_break_time: convertTimeToMinutes(formValues?.lunch),
      is_lunch_break_paid: formValues?.isLunchPaid,
      autoFill: formValues?.enableAutoFill,
      autoFillFavorites: formValues?.enableAutoFillFavorites,
      autoFillHighlyRated: formValues?.enableAutoFillHighlyRated,
      procedures: formValues?.procedures,
      ...(professionTypeCode === UserProfession.RDH && {
        isAssistedHygiene: formValues?.isAssistedHygiene,
      }),
    };

    dispatch(editJob({ jobId: job.id, jobData }));
  };

  useEffect(() => {
    if (job) {
      const newFormValues = {
        localDate: moment(job?.local_date, 'ddd, MMM DD, YYYY').format('YYYY-MM-DD'),
        offerStartTime: moment(
          `${job?.local_date} ${job?.local_start_time}`,
          'ddd, MMM DD, YYYY hh:mm a',
        ).format('hh:mm a'),
        offerEndTime: moment(
          `${job?.local_date} ${job?.local_end_time}`,
          'ddd, MMM DD, YYYY hh:mm a',
        ).format('hh:mm a'),
        rate: job?.rate.toFixed(2) || 0,
        lunch: convertMinutesToFormattedValue(job?.lunch_break_time),
        isLunchPaid: job?.is_lunch_break_paid || false,
        professionType:
          UserSubProfession.getName(job?.subProfession) ?? UserProfession.getName(job?.profession),
        skills: job?.specialty || 'specialty_General Dentistry',
        procedures: job?.procedures ?? [],
        isAssistedHygiene: job?.isAssistedHygiene,
        enableAutoFill: autoFillInfo?.show ? job?.autoFill || false : false,
        enableAutoFillFavorites: autoFillInfo?.show
          ? job?.autofillOptions?.favorite || false
          : false,
        enableAutoFillHighlyRated: autoFillInfo?.show
          ? job?.autofillOptions?.highlyRated || false
          : false,
      };

      // Check if formValues have actually changed before calling setFormValues
      setFormValues((prevValues) => {
        if (JSON.stringify(prevValues) !== JSON.stringify(newFormValues)) {
          return newFormValues;
        }
        return prevValues;
      });
    }
  }, [job, autoFillInfo]);

  useEffect(() => {
    if (formValues?.professionType) {
      const professionCode = UserProfession.getNormalizedCode(
        UserProfession.getCode(formValues?.professionType),
      );

      dispatch(getUserEducation({ profession: professionCode }));
    }
  }, [formValues?.professionType, dispatch]);

  return { formValues, setFormValues, handleSubmit, isLoading };
};
