const HeartBorderedIcon = ({ width = 31, height = 26 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 31 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.8293 4.72337L15.5522 5.43826L16.275 4.72337L17.1647 3.8435C17.1648 3.84346 17.1648 3.84342 17.1649 3.84338C19.8766 1.166 24.2968 1.16426 27.0155 3.84546C29.2971 6.13825 29.8059 9.827 27.9869 14.1972L27.9865 14.1983C26.9082 16.7973 24.9884 19.5271 22.7031 21.597C20.3996 23.6833 17.8602 24.9806 15.5522 24.9806C13.2438 24.9806 10.703 23.6842 8.39845 21.5986C6.112 19.5293 4.19121 16.7997 3.11306 14.1986L3.11284 14.1981C1.29833 9.82741 1.80707 6.13837 4.08885 3.84545C6.80739 1.16441 11.2272 1.16601 13.939 3.84292C13.939 3.84298 13.9391 3.84304 13.9392 3.8431C13.9393 3.84323 13.9394 3.84336 13.9396 3.8435L14.8293 4.72337Z"
      fill="#9D70D8"
      stroke="white"
      strokeWidth="2.05607"
    />
  </svg>
);

export default HeartBorderedIcon;
