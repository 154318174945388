import { apiMethodsConst } from '../services/methods';
import * as actions from '../actions';

const initialState = {
  allJobsWithMessages: [],
  selectedContent: 'alerts',
  messagedPerJob: [],
  isFinalPage: false,
  isActiveRequest: false,
  totalMessages: 0,
  totalUnread: 0,
  searchParams: {
    page: 1,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
  case apiMethodsConst.GET_MESSAGES_REQUEST:
    const {
      conversations,
      page,
      total,
      unread,
    } = action.payload;

    const mergedOffers = [...state.allJobsWithMessages, ...conversations];
    
    return {
      ...state,
      allJobsWithMessages: page === 1 ? conversations : mergedOffers,
      isFinalPage:
          conversations.length === 0 ||
          conversations.length < state.searchParams.limit ||
          conversations.length === state.totalMessages,
      totalMessages: total,
      isActiveRequest: false,
      totalUnread: unread,
    };
  case 'GET_USER_MESSAGES_REQUEST':
    return {
      ...state,
      searchParams: action.payload,
      isActiveRequest: true,
    };
  case actions.SELECT_CONTENT_TYPE_INBOX:
    return {
      ...state,
      selectedContent: action.payload,
    };

  case apiMethodsConst.GET_MESSAGES_BY_JOB_ID_REQUEST:
    return {
      ...state,
      messagedPerJob: action.payload,
    };
  case apiMethodsConst.POST_MESSAGE_REQUEST:
  case apiMethodsConst.INVITE_ADMIN_REQUEST:
    const msgCount = state.messagedPerJob.length;
    if (msgCount !== 0 && state.messagedPerJob[msgCount - 1].id === action.payload.id)
      return state;
    return {
      ...state,
      messagedPerJob: [...state.messagedPerJob, action.payload],
    };

  default:
    return state;
  }
};
