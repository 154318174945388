export const EmptyFilteredRecent = () => (
  <svg
    width="181"
    height="181"
    viewBox="0 0 181 181"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="85.2632" cy="94.9866" rx="85.2632" ry="86.0134" fill="#EBF4F5" />
    <path
      d="M90.2151 123.988L90.2155 123.999C90.4115 128.954 90.6214 134.262 91.9623 138.332C92.6339 140.37 93.5923 142.108 94.9836 143.337C96.3774 144.568 98.1968 145.279 100.573 145.279C103.045 145.279 105.32 144.071 107.336 142.007C109.352 139.945 111.117 137.021 112.575 133.571C115.49 126.671 117.186 117.639 117.186 109.096C117.186 104.019 119.548 100.802 122.573 96.6883C126.226 91.7242 130.381 86.0746 130.381 76.3105C130.381 66.0915 127.058 58.6823 122.076 53.8279C117.096 48.9749 110.467 46.6847 103.872 46.6847C97.8898 46.6847 94.6602 48.8229 92.0802 50.5338C90.9285 51.2965 89.9092 51.9714 88.6869 52.4577C87.4671 52.943 86.0406 53.2419 84.0787 53.2419C82.1169 53.2419 80.6904 52.943 79.4706 52.4577C78.2483 51.9714 77.229 51.2965 76.0773 50.5338C73.4973 48.8229 70.2677 46.6847 64.2855 46.6847C57.6906 46.6847 51.0619 48.9749 46.0813 53.8279C41.0994 58.6823 37.7761 66.0915 37.7761 76.3105C37.7761 86.0746 41.9319 91.7242 45.5845 96.6883C48.6095 100.802 50.9716 104.019 50.9716 109.096C50.9716 117.639 52.6674 126.671 55.5829 133.571C57.0405 137.021 58.8057 139.945 60.8215 142.007C62.8379 144.071 65.1125 145.279 67.5844 145.279C69.9606 145.279 71.7801 144.568 73.1739 143.337C74.5652 142.108 75.5235 140.37 76.1952 138.332C77.5361 134.262 77.746 128.954 77.942 123.999L77.9424 123.988C78.1603 118.499 78.4366 113.972 79.2979 110.817C79.7283 109.241 80.3018 108.02 81.0766 107.194C81.8472 106.373 82.8231 105.936 84.0787 105.936C85.3344 105.936 86.3102 106.373 87.0809 107.194C87.8557 108.02 88.4292 109.241 88.8596 110.817C89.7209 113.972 89.9972 118.499 90.2151 123.988Z"
      fill="#A4E0E0"
      stroke="#A4E0E0"
      strokeWidth="0.236842"
    />
    <path
      d="M106.347 159.198C93.9916 159.198 93.4064 145.191 92.8905 132.833C92.3183 119.173 91.4383 110.218 84.6713 110.218C77.9042 110.218 77.0242 119.173 76.452 132.833C75.9361 145.191 75.3509 159.198 62.996 159.198C50.222 159.198 41.3207 135.535 41.3207 114.3C41.3207 107.924 38.1836 103.882 34.2112 98.765C29.4137 92.5881 23.9805 85.588 23.9805 73.4832C23.9805 48.1062 41.3987 36.7483 58.6609 36.7483C66.4756 36.7483 70.6878 39.3905 74.0706 41.5157L74.0751 41.5185C77.096 43.4138 79.4833 44.9116 84.6713 44.9116C89.8592 44.9116 92.2466 43.4138 95.2674 41.5185L95.2719 41.5157C98.6547 39.3905 102.867 36.7483 110.682 36.7483C127.944 36.7483 145.362 48.1062 145.362 73.4832C145.362 85.588 139.929 92.5881 135.131 98.765C131.159 103.882 128.022 107.924 128.022 114.3C128.022 135.535 119.121 159.198 106.347 159.198ZM84.6713 102.055C100.28 102.055 100.987 118.942 101.555 132.512V132.512C101.916 141.194 102.326 151.035 106.347 151.035C110.226 151.035 119.352 135.523 119.352 114.3C119.352 105.259 123.815 99.5078 128.135 93.9472L128.139 93.9421C132.537 88.2746 136.692 82.9212 136.692 73.4832C136.692 52.3946 122.681 44.9116 110.682 44.9116C105.494 44.9116 103.106 46.4094 100.085 48.3047L100.081 48.3075C96.6982 50.4327 92.4859 53.0749 84.6713 53.0749C76.8566 53.0749 72.6444 50.4327 69.2616 48.3075L69.2571 48.3047C66.2362 46.4094 63.8488 44.9116 58.6609 44.9116C46.6615 44.9116 32.6506 52.3946 32.6506 73.4832C32.6506 82.9212 36.8054 88.2746 41.204 93.9421L41.208 93.9472C45.5271 99.5078 49.9908 105.259 49.9908 114.3C49.9908 135.523 59.1161 151.035 62.996 151.035C67.016 151.035 67.4264 141.194 67.7877 132.512L67.7877 132.512C68.3556 118.942 69.0623 102.055 84.6713 102.055Z"
      fill="#0B8397"
    />
    <path
      d="M106.347 159.198C93.9916 159.198 93.4064 145.191 92.8905 132.833C92.3183 119.173 91.4383 110.218 84.6713 110.218C77.9042 110.218 77.0242 119.173 76.452 132.833C75.9361 145.191 75.3509 159.198 62.996 159.198C50.222 159.198 41.3207 135.535 41.3207 114.3C41.3207 107.924 38.1836 103.882 34.2112 98.765C29.4137 92.5881 23.9805 85.588 23.9805 73.4832C23.9805 48.1062 41.3987 36.7483 58.6609 36.7483C66.4756 36.7483 70.6878 39.3905 74.0706 41.5157L74.0751 41.5185C77.096 43.4138 79.4833 44.9116 84.6713 44.9116C89.8592 44.9116 92.2466 43.4138 95.2674 41.5185L95.2719 41.5157C98.6547 39.3905 102.867 36.7483 110.682 36.7483C127.944 36.7483 145.362 48.1062 145.362 73.4832C145.362 85.588 139.929 92.5881 135.131 98.765C131.159 103.882 128.022 107.924 128.022 114.3C128.022 135.535 119.121 159.198 106.347 159.198ZM84.6713 102.055C100.28 102.055 100.987 118.942 101.555 132.512V132.512C101.916 141.194 102.326 151.035 106.347 151.035C110.226 151.035 119.352 135.523 119.352 114.3C119.352 105.259 123.815 99.5078 128.135 93.9472L128.139 93.9421C132.537 88.2746 136.692 82.9212 136.692 73.4832C136.692 52.3946 122.681 44.9116 110.682 44.9116C105.494 44.9116 103.106 46.4094 100.085 48.3047L100.081 48.3075C96.6982 50.4327 92.4859 53.0749 84.6713 53.0749C76.8566 53.0749 72.6444 50.4327 69.2616 48.3075L69.2571 48.3047C66.2362 46.4094 63.8488 44.9116 58.6609 44.9116C46.6615 44.9116 32.6506 52.3946 32.6506 73.4832C32.6506 82.9212 36.8054 88.2746 41.204 93.9421L41.208 93.9472C45.5271 99.5078 49.9908 105.259 49.9908 114.3C49.9908 135.523 59.1161 151.035 62.996 151.035C67.016 151.035 67.4264 141.194 67.7877 132.512L67.7877 132.512C68.3556 118.942 69.0623 102.055 84.6713 102.055Z"
      stroke="#0B8397"
    />
    <ellipse cx="156" cy="25.5" rx="25" ry="25.5" fill="#DFEBEE" />
    <g opacity="0.8">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M148.083 14.8255C147.198 14.8255 146.481 15.5396 146.481 16.4204V18.0153H144.879C143.109 18.0153 141.674 19.4434 141.674 21.205V37.1538C141.674 38.9155 143.109 40.3436 144.879 40.3436H164.104C165.874 40.3436 167.308 38.9155 167.308 37.1538V21.205C167.308 19.4434 165.874 18.0153 164.104 18.0153H162.502V16.4204C162.502 15.5396 161.785 14.8255 160.9 14.8255C160.015 14.8255 159.298 15.5396 159.298 16.4204V18.0153H149.685V16.4204C149.685 15.5396 148.968 14.8255 148.083 14.8255ZM148.083 23.8102C147.198 23.8102 146.481 24.5243 146.481 25.4051C146.481 26.2859 147.198 27 148.083 27H160.9C161.785 27 162.502 26.2859 162.502 25.4051C162.502 24.5243 161.785 23.8102 160.9 23.8102H148.083Z"
        fill="#A4E0E0"
      />
    </g>
    <path
      d="M150.487 16.6166V11.3003M161.167 16.6166V11.3003M149.5 23.5H162M145.481 36.2235H166.173C167.647 36.2235 168.843 35.0334 168.843 33.5654V16.6166C168.843 15.1485 167.647 13.9584 166.173 13.9584H145.481C144.007 13.9584 142.811 15.1485 142.811 16.6166V33.5654C142.811 35.0334 144.007 36.2235 145.481 36.2235Z"
      stroke="#0B8397"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
