import { apiMethodsConst } from '../services/methods';

/**
 * @type {{credentials: {education: {softwares: string[], specialtiesAndProcedures: null}, insurances: string[], softwares: string[], educationUpdatedAt: string | null}}}
 */
const initialState = {
  credentials: {
    education: {
      specialtiesAndProcedures: null,
      softwares: [],
    },
    insurances: [],
    softwares: [],
    educationUpdatedAt: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
  case apiMethodsConst.USER_CREDENTIALS_REQUEST:
    return {
      ...state,
      credentials: action.payload,
    };
  default:
    return state;
  }
};
