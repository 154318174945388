import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import '../themes/alertmessage.scss';

import React from 'react';

import history from '../history';
import { Colors } from '../themes/colors';
import ConfirmPopup from '../components/shared/ConfirmPopup';
import AnimatedCheckCircle from '../components/shared/AnimatedCheckCircle';
import WarningIcon from '../images/icons/Warning';

export default ({
  title,
  text,
  route,
  params,
  submitPressed,
  isSuccess,
  isError,
  popupProps,
  buttonText = 'Ok',
  redirect = true,
}) => (
  <ConfirmPopup
    Icon={() => {
      if (isSuccess) {
        return <AnimatedCheckCircle />;
      }
      if (isError) {
        return <WarningIcon color={Colors.warning_500} width={70} height={70} />;
      }
      return <></>;
    }}
    title={title}
    titleStyle={{ marginTop: isSuccess || isError ? 30 : 0 }}
    description={text}
    descriptionStyle={{ width: '90%', marginBottom: 50 }}
    rightButtonText={buttonText}
    rightButtonAction={() => {
      submitPressed();
    
      if (!redirect) {
        return;
      }
    
      if (route === 'back') {
        history.goBack();
      } else {
        history.push(route, params);
      }
    }}
    {...popupProps}
  />
);
