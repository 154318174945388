import React, { useState } from 'react';
import ConfirmPopup from '../../shared/ConfirmPopup';
import { RadioOption } from '../../registration/components/RadioOption';
import { Colors } from '../../../themes/colors';

import WarningIcon from '../../../images/warning-icon.svg';

const ReportIncidentPopup = ({ onCancelClick = () => {}, onConfirmClick = () => {} }) => {
  const [incidentType, setIncidentType] = useState(null);

  const cancelButtonHandler = () => {
    onCancelClick();
  };

  const confirmButtonHandler = () => {
    onConfirmClick(incidentType);
  };

  return (
    <ConfirmPopup
      Icon={() => <img src={WarningIcon} style={{ marginBottom: 20 }} alt="warning_icon" />}
      title="Report An Incident"
      leftButtonText="Go Back"
      leftButtonAction={cancelButtonHandler}
      rightButtonText="Continue"
      rightButtonAction={confirmButtonHandler}
      rightButtonDisabled={!incidentType}
      description={
        <div>
          <p style={normalText}>What type of incident would you like to report?</p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              marginVertical: 20,
            }}
          >
            <RadioOption
              textStyle={{ color: Colors.neutral_500 }}
              onClick={() => setIncidentType('no-show')}
              text="No-Show"
              checked={incidentType === 'no-show'}
            />
            <RadioOption
              textStyle={{ color: Colors.neutral_500 }}
              onClick={() => setIncidentType('walked-out')}
              text="Walked-Out"
              checked={incidentType === 'walked-out'}
            />
          </div>
          <p style={normalText}>
            If the incident doesn&apos;t fall into these two categories, please call or text our
            Customer Support at 786-460-2170, and they will be able to assist you.
          </p>
        </div>
      }
    />
  );
};

const normalText = {
  fontFamily: 'Nunito',
  fontSize: 16,
  color: Colors.neutral_500,
  textAlign: 'center',
};

export default ReportIncidentPopup;
