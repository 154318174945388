import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import '../../../themes/global.scss';
import {
  FETCH_RECEIPTS_REPORT,
  clearAllReceipts,
  clearReceiptsReport,
  fetchJobsWithTransactions,
  fetchReceiptList,
  fetchReceiptsCVS,
  updateReceiptDisplayMode,
} from '../../../actions';
import { createLoadingSelector } from '../../../api/selectors';
import BackButton from '../../../commonComponents/BackButton';
import InputSelect from '../../../commonComponents/InputSelect';
import receiptsAllIcon from '../../../images/receipts-all-icon.svg';
import receiptsShiftIcon from '../../../images/receipts-shift-icon.svg';
import { getFilterDateRage } from './utils';
import { paymentHistoryFilterOptions, paymentHistoryFilterOptionsLabels } from '../../../constants';
import AllReceiptTable from './AllReceiptTable';
import ShiftReceiptTable from './ShiftReceiptTable';
import Button from '../../../commonComponents/Button';

const LIMIT_VALUE = 25;

export default () => {
  const dispatch = useDispatch();
  const receiptsScrollRef = useRef(null);

  const isFinalPage = useSelector((state) => state.job.isFinalPage);
  const isActiveRequest = useSelector((state) => state.job.isActiveRequest);

  const receiptReport = useSelector((state) => state.job.receiptReport);
  const loadingCSVSelector = createLoadingSelector([FETCH_RECEIPTS_REPORT]);
  const isCSVReportLoading = useSelector((state) => loadingCSVSelector(state));

  const jobsWithTransactionPagination = useSelector(
    (state) => state.job.jobsWithTransactionPagination,
  );
  const receiptsPagination = useSelector((state) => state.job.receiptsPagination);
  const receiptDisplayMode = useSelector((state) => state.job.receiptDisplayMode);
  const cvsDownload = useRef(null);
  const [sort, setSort] = useState({
    field: 'date',
    order: 'desc',
  });
  const [filter, setFilter] = useState(paymentHistoryFilterOptions.ALL);

  useEffect(() => {
    // Reset filter when changing display mode
    setFilter(paymentHistoryFilterOptions.ALL);
  }, [receiptDisplayMode]);

  useEffect(() => {
    if (cvsDownload.current?.link && receiptReport !== '') {
      cvsDownload.current.link.click();
      dispatch(clearReceiptsReport());
    }
  }, [receiptReport]);

  useEffect(() => {
    dispatch(clearAllReceipts());
    if (receiptDisplayMode === 'shift') {
      dispatch(
        fetchJobsWithTransactions({
          page: 1,
          limit: LIMIT_VALUE,
          sort: sort.field,
          order: sort.order,
        }),
      );
    } else {
      dispatch(
        fetchReceiptList({
          page: 1,
          limit: LIMIT_VALUE,
          sort: sort.field,
          order: sort.order,
          ...getFilterDateRage(filter),
        }),
      );
    }
  }, [receiptDisplayMode, sort, filter]);

  const handleScroll = () => {
    const receiptsScroll = receiptsScrollRef.current;

    const userScrollHeight = receiptsScroll.scrollHeight - receiptsScroll?.scrollTop - 20;
    const clientHeight = receiptsScroll?.clientHeight;

    if (receiptDisplayMode === 'all') {
      if (userScrollHeight <= clientHeight && !isActiveRequest && !isFinalPage) {
        dispatch(
          fetchReceiptList({
            page: receiptsPagination?.page + 1,
            limit: LIMIT_VALUE,
            sort: sort.field,
            order: sort.order,
            ...getFilterDateRage(filter),
          }),
        );
      }
    } else if (
      userScrollHeight <= clientHeight &&
      !isActiveRequest &&
      !jobsWithTransactionPagination?.isFinal
    ) {
      dispatch(
        fetchJobsWithTransactions({
          page: jobsWithTransactionPagination?.page + 1,
          limit: LIMIT_VALUE,
        }),
      );
    }
  };

  const handleDownloadCSVPressed = () => {
    dispatch(fetchReceiptsCVS({ ...getFilterDateRage(filter) }));
  };

  const changeSort = (sortName) => {
    setSort({
      field: sortName,
      order: sort.order === 'asc' ? 'desc' : 'asc',
    });

    if (receiptsScrollRef.current) {
      receiptsScrollRef.current.scrollTop = 0;
    }
  };

  const renderDownloadCSVButton = () => (
    <motion.div
      initial={receiptDisplayMode === 'all' ? 'show' : 'hide'}
      animate={receiptDisplayMode === 'all' ? 'show' : 'hide'}
      transition={{ duration: 0.3 }}
      variants={{
        hide: { opacity: 0 },
        show: { opacity: 1 },
      }}
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 20,
      }}
    >
      <div style={{ width: 240 }}>
        <InputSelect
          value={filter}
          setValue={(val) => setFilter(val)}
          isSelectVisible={false}
          options={Object.entries(paymentHistoryFilterOptionsLabels).map(([key, name]) => ({
            value: key,
            title: name,
          }))}
        />
      </div>
      <CSVLink
        ref={cvsDownload}
        className="btn btn-success"
        data={receiptReport}
        enclosingCharacter=""
        filename="receiptReport.csv"
      />
      <Button
        isLoading={isCSVReportLoading}
        text="Download"
        textStyle={{
          fontFamily: 'Nunito',
          fontWeight: 'bold',
          fontSize: 16,
        }}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 25,
          width: 120,
          height: 35,
          marginLeft: 20,
          marginTop: 0,
          cursor: receiptDisplayMode === 'all' ? 'pointer' : 'default',
        }}
        onClick={() => handleDownloadCSVPressed()}
        disabled={receiptDisplayMode === 'shift'}
      />
    </motion.div>
  );

  return (
    <div className="component_container">
      <div
        className="floating_container"
        style={{
          position: 'relative',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: 40,
          maxHeight: '90vh',
        }}
      >
        <div style={{ position: 'absolute', left: 50 }}>
          <BackButton />
        </div>

        <h1 className="global_font f-dark" style={{ textAlign: 'center', marginBottom: 10 }}>
          Payment History
        </h1>

        <div
          style={{
            position: 'relative',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <motion.h3
            key={receiptDisplayMode}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="global_font f-dark"
            style={{ fontSize: 24, marginTop: 0 }}
          >
            {receiptDisplayMode === 'shift' ? 'By Shift' : 'All Transactions'}
          </motion.h3>

          <div
            style={{
              position: 'absolute',
              left: '90%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div className="button_group_container" style={{ display: 'flex' }}>
              <div
                onClick={() => dispatch(updateReceiptDisplayMode('shift'))}
                className={`button_group_container_first_option ${
                  receiptDisplayMode === 'shift' ? 'selected_button_group_option' : ''
                }`}
              >
                <img src={receiptsShiftIcon} alt="receipts_shift_icon" />
              </div>

              <div
                onClick={() => dispatch(updateReceiptDisplayMode('all'))}
                className={`button_group_container_last_option ${
                  receiptDisplayMode === 'all' ? 'selected_button_group_option' : ''
                }`}
              >
                <img src={receiptsAllIcon} alt="receipts_all_icon" />
              </div>
            </div>

            <div style={{ display: 'flex', marginTop: 5, columnGap: 20 }}>
              <p className="global_font" style={{ margin: 0 }}>
                Shift
              </p>
              <p className="global_font" style={{ margin: 0 }}>
                All
              </p>
            </div>
          </div>
        </div>

        {renderDownloadCSVButton()}

        {receiptDisplayMode === 'all' ? (
          <AllReceiptTable
            ref={receiptsScrollRef}
            onScroll={handleScroll}
            sort={sort}
            onSort={changeSort}
          />
        ) : (
          <ShiftReceiptTable
            ref={receiptsScrollRef}
            onScroll={handleScroll}
            sort={sort}
            onSort={changeSort}
          />
        )}
      </div>
    </div>
  );
};
