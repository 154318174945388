import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../themes/confirm_hyg.scss';
import '../../themes/global.scss';

import { getRedirectTarget } from '../../utils/AdjustmentUtil';
import { RemoteConfigUtils } from '../../utils/RemoteConfigUtils';
import {
  ADD_FAVORITE_PROFESSIONAL,
  BLOCK_PROFESSIONAL,
  FETCH_JOBS_WITH_ADJUSTMENTS,
  FETCH_JOB_ACTION,
  addFavoriteProfessional,
  blockProfessional,
  fetchJob,
  fetchJobsWithAdjustmts,
  hideMessage,
} from '../../actions';
import { createErrorObjectSelector, createLoadingSelector } from '../../api/selectors';
import BackButton from '../../commonComponents/BackButton';
import Button from '../../commonComponents/Button';
import CardSpinner from '../../commonComponents/CardSpinner';
import { Links } from '../../constants';
import { UserType } from '../../enums/UserType';
import { getShouldAllowOfficeToPostProcedure, showCandidateOvertime } from '../../growthbook';
import history from '../../history';
import blockCross from '../../images/block-cross.svg';
import blockUserIcon from '../../images/block-user.svg';
import defaultPersonImage from '../../images/defaultPerson.svg';
import defaultProfileImage from '../../images/defaultProfileImage.png';
import favoriteHeartSummary from '../../images/favorite-heart-summary.svg';
import heartOutline from '../../images/heart-outline.svg';
import ChatOutline from '../../images/chat-outline.svg';
import PencilIcon from '../../images/icons/Pencil';
import receiptsShiftIcon from '../../images/receipts-shift-icon.svg';
import { Colors } from '../../themes/colors';
import CancelledJob from '../CancelledJob';
import Tooltip from '../Tooltip';
import { isEmployeeOptionShift } from '../WorkerClassification/utils';
import ConfirmPopup from '../shared/ConfirmPopup';
import { UserProfession, UserSubProfession } from '../../enums/UserProfession';
import { InviteButton } from '../ProfessionalsHub/components/InviteButton';
import { IconButton } from '../../commonComponents/IconButton';
import { EducationUtils } from '../../utils/EducationUtils';
import { trackEvent } from '../../api/analytics';
import { jobCompletedByToday } from './utils';
import { JobStatusInfo } from './JobStatusInfo';

const convenienceFeeTooltip =
  'GoTu charges a 3.5% convenience fee on Credit Card transactions. If you do not wish to be charged the convenience fee, please update your billing to E-check/ACH.';

const overtimeTooltip =
  'Overtime pay (1.5x rate) is required for hours that exceed the state’s daily and/or weekly regular working hour limits.';

class JobDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFee: false,
      showCancellationPopup: false,
      showUnableToAddBlockedModal: false,
      showBlockConfirmPopup: false,
      isRedirected: false,
    };
  }

  componentDidMount() {
    const { jobId } = this.props.match.params;
    const { fetchJob } = this.props;

    fetchJob({ jobId });
  }

  componentDidUpdate() {
    const isPrevScreenFeedback = this.props.location.state?.isPrevScreenFeedback;

    const { job, history, isLoading } = this.props;
    const { isRedirected } = this.state;
    if (history.action !== 'POP') {
      if (job && !job.isFeedbackSubmited && !isLoading && !isRedirected && !isPrevScreenFeedback) {
        this.handleRedirectFeedback();
      }
    }
  }

  handleRedirectFeedback = () => {
    const { jobId } = this.props.match.params;
    const { adjustments } = this.props.job;

    if (!jobId || !adjustments) {
      return;
    }

    this.setState({
      isRedirected: true,
    });

    const redirectTarget = getRedirectTarget(adjustments);
    if (redirectTarget === 'feedback-rating') {
      history.push(`/dashboard/job/${jobId}/feedback-rating`);
    } else if (redirectTarget === 'feedback') {
      history.push(`/dashboard/job/${jobId}/feedback`);
    } else if (redirectTarget === 'adjustment') {
      this.props.fetchJobsWithAdjustmts();
    }
  };

  showEodButton = () => {
    const { job } = this.props;
    if (job.status === 'pay-out' && !job.isFeedbackSubmited) {
      return (
        <div
          onClick={() => history.push(`/dashboard/job/${job?.id}/feedback`)}
          style={{
            position: 'absolute',
            top: '50%',
            right: '-25px',
            transform: 'translateY(-50%)',
            cursor: 'pointer',
          }}
        >
          <PencilIcon color={Colors.secondary_500} />
        </div>
      );
    }
    return null;
  };

  addToFavorite = () => {
    const { addFavoriteProfessional, job, user } = this.props;
    const isBlocked = user?.blocked_hygienist?.includes(job?.offer_final_reciever?.id);

    if (isBlocked) {
      this.setState({ showUnableToAddBlockedModal: true });
    } else {
      addFavoriteProfessional(job?.offer_final_reciever?.id);
    }
  };

  blockProfessional = () => {
    const { job, blockProfessional } = this.props;

    blockProfessional({ user: job?.offer_final_reciever });
  };

  hygInfo = () => {
    const { user, job, history } = this.props;
    const isToday = moment(job?.local_date, 'ddd, MMM DD, YYYY').isSame(moment(), 'day');

    const hasProfessional = !!job?.offer_final_reciever;
    const isFavorite =
      job?.offer_final_reciever?.isFavorite ||
      user?.favoriteProfessionals?.includes(job?.offer_final_reciever?.id);
    const blockedProfessional = user?.blocked_hygienist?.includes(job?.offer_final_reciever?.id);
    const isJobCompletedByToday = jobCompletedByToday(job);

    return (
      <div
        style={{
          position: 'relative',
          backgroundColor: Colors.white,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: 50,
          paddingBottom: 30,
          boxShadow: '0px 3px 10px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
          zIndex: 1,
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              position: 'relative',
              width: '100%',
              alignItems: 'center',
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
            }}
          >
            <div style={{ paddingLeft: 32 }}>
              <BackButton />
            </div>

            <h1
              className="title global_font"
              style={{
                fontSize: 28,
                fontWeight: 700,
                textAlign: 'center',
                width: '100%',
                color: Colors.neutral_600,
                flex: 1,
              }}
            >
              Job Summary
            </h1>

            <div
              style={{
                width: '100%',
                justifyContent: 'center',
                display: 'flex',
                columnGap: 24,
              }}
            >
              {isToday && !blockedProfessional && job?.job_status === 'filled' && (
                <div
                  style={{
                    width: 75,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    rowGap: 8,
                  }}
                >
                  <InviteButton
                    user={{
                      id: job.offer_final_reciever?.id,
                      image: job.offer_final_reciever?.profile_image,
                      name: job.offer_final_reciever?.full_name,
                      profession: UserProfession.getCode(job.professionName),
                    }}
                    showTooltip={false}
                    position="bottom"
                  />
                  <p
                    style={{
                      fontFamily: 'Nunito',
                      fontSize: 14,
                      color: Colors.neutral_600,
                      textAlign: 'center',
                      margin: 0,
                    }}
                  >
                    Invite Professional
                  </p>
                </div>
              )}

              {this.chatBtnDisplay()}

              {hasProfessional && job?.status !== 'filled' && job?.status !== 'cancelled' && !isJobCompletedByToday && (
                <div
                  style={{
                    display: 'flex',
                    columnGap: 32,
                  }}
                >
                  <div
                    style={{
                      width: 75,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      rowGap: 8,
                    }}
                  >
                    <IconButton
                      buttonStyle={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 50,
                        height: 50,
                        borderRadius: '50%',
                        backgroundColor: isFavorite ? Colors.primary_200 : Colors.primary_500,
                      }}
                      src={heartOutline}
                      imgStyle={{
                        width: 20,
                        height: 18,
                      }}
                      disabled={isFavorite}
                      onClick={this.addToFavorite}
                    />

                    <p
                      style={{
                        fontFamily: 'Nunito',
                        fontSize: 14,
                        color: isFavorite ? Colors.neutral_400 : Colors.neutral_600,
                        textAlign: 'center',
                        margin: 0,
                      }}
                    >
                      {isFavorite ? 'Added to Favorites' : 'Add to Favorites'}
                    </p>
                  </div>

                  <div
                    style={{
                      width: 50,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      rowGap: 8,
                    }}
                  >
                    <IconButton
                      buttonStyle={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 50,
                        height: 50,
                        borderRadius: '50%',
                        backgroundColor: blockedProfessional
                          ? Colors.secondary_100
                          : Colors.secondary_500,
                      }}
                      src={blockUserIcon}
                      imgStyle={{
                        width: 24,
                        height: 24,
                      }}
                      onClick={() => this.setState({ showBlockConfirmPopup: true })}
                      disabled={blockedProfessional}
                    />

                    <p
                      style={{
                        fontFamily: 'Nunito',
                        fontSize: 14,
                        color: blockedProfessional ? Colors.neutral_400 : Colors.neutral_600,
                        textAlign: 'center',
                        margin: 0,
                      }}
                    >
                      {blockedProfessional ? 'Blocked Professional' : 'Block Professional'}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              columnGap: '20px',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {job?.offer_final_reciever?.profile_image ? (
              <div
                style={{
                  position: 'relative',
                  display: 'flex',
                  border: job.offer_final_reciever?.isFavorite ? '3px solid #B764EB' : 'none',
                  borderRadius: '50%',
                  padding: 3,
                }}
              >
                <img
                  src={job.offer_final_reciever?.profile_image}
                  style={{
                    width: 100,
                    height: 100,
                    borderRadius: '50%',
                    objectFit: 'cover',
                  }}
                  alt="profile_image"
                />

                {job.offer_final_reciever?.isFavorite && (
                  <img
                    src={favoriteHeartSummary}
                    style={{ position: 'absolute', top: '65%', left: '70%' }}
                    alt="favorite_heart_summary"
                  />
                )}
              </div>
            ) : (
              <img
                src={hasProfessional ? defaultProfileImage : defaultPersonImage}
                alt="profile_image"
                style={{
                  width: 100,
                  height: 100,
                  borderRadius: 100,
                  objectFit: 'cover',
                  marginRight: 28,
                }}
              />
            )}
            {hasProfessional ? (
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span
                    className="global_font f-20"
                    style={{ color: Colors.neutral_600, fontWeight: 'bold', marginRight: 10 }}
                  >
                    {`${job.offer_final_reciever?.first_name || ''} ${
                      job.offer_final_reciever?.last_name || ''
                    }`}{' '}
                    |
                  </span>

                  <div
                    className="global_font f-20"
                    style={{
                      marginLeft: 20,
                      color: Colors.secondary_500,
                      fontWeight: 'bold',
                      margin: 0,
                      cursor: 'pointer',
                    }}
                    onClick={() => history.push(`/dashboard/candidate/${job?.id}`)}
                  >
                    Profile
                  </div>
                </div>
                <p className="global_font std_font" style={{ color: Colors.neutral_600 }}>
                  {UserSubProfession.getName(job?.subProfession) || job.professionName || ''}
                </p>
                {job.offer_final_reciever.license?.license ? (
                  <p className="global_font std_font" style={{ color: Colors.neutral_600 }}>
                    License: {job.offer_final_reciever.license?.license || ''}
                  </p>
                ) : (
                  <div />
                )}
                <p className="global_font std_font" style={{ color: Colors.neutral_600 }}>
                  {job.offer_final_reciever.address[0].formatted_address}
                </p>
              </div>
            ) : (
              <div>
                <span
                  className="global_font f-20"
                  style={{ color: Colors.neutral_600, fontWeight: 'bold', marginLeft: -24 }}
                >
                  Shift Unfilled
                </span>
              </div>
            )}
          </div>

          {this.state.showUnableToAddBlockedModal && (
            <ConfirmPopup
              title="Unable to Add to Favorites"
              description="To add this professional to your Favorites, please contact Customer Support to remove this professional from your blocked list."
              rightButtonText="Close"
              rightButtonAction={() => this.setState({ showUnableToAddBlockedModal: false })}
            />
          )}
        </div>
      </div>
    );
  };

  handleLearnMore = () => {
    window.open(Links.bankPayments);
    this.setState({ showFee: false });
  };

  handleOKClicked = () => {
    this.setState({ showFee: false });
  };

  cancelBtnDisplay = () => {
    const { job } = this.props;
    const currentTime = new Date();

    if (
      job.job_status === 'filled' &&
      !job.is_cancelled &&
      moment(job.job_datetime).toDate() > currentTime
    ) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 8,
          }}
        >
          <Button
            style={{
              backgroundColor: Colors.secondary_500,
              width: 320,
              height: 50,
            }}
            text="Cancel Job"
            onClick={() => this.setState({ showCancellationPopup: true })}
          />
        </div>
      );
    }

    return <></>;
  };

  transactionHistoryBtn = () => {
    const { job } = this.props;

    if (
      job?.transactions?.length &&
      (job.status === 'filled' ||
        job.status === 'complete' ||
        job.status === 'pay-out' ||
        job.status === 'cancelled')
    ) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 8,
          }}
        >
          <Button
            style={{
              backgroundColor: Colors.primary_500,
              width: 320,
              height: 50,
            }}
            Icon={receiptsShiftIcon}
            text="Transaction History"
            onClick={() => history.push(`/dashboard/receipts/transactions/${job?.id}`)}
          />
        </div>
      );
    }

    return <></>;
  };

  renderRow = ({ title, description, timezone, rightComponent }) => (
    <div className="row_style">
      <div style={{ display: 'flex', alignItems: 'center', columnGap: 10 }}>
        <p className="global_font bold_font" style={{ margin: 0, color: Colors.neutral_600 }}>
          {title}
        </p>

        {timezone && (
          <div
            style={{ backgroundColor: Colors.neutral_300, padding: '1px 4px', borderRadius: 100 }}
          >
            <p
              style={{
                margin: 0,
                fontSize: 12,
                fontWeight: 'bold',
                color: Colors.white,
              }}
            >
              EDT
            </p>
          </div>
        )}
      </div>

      <p
        className="global_font"
        style={{
          margin: 0,
          color: Colors.neutral_600,
          fontSize: 18,
          textAlign: 'right',
          maxWidth: '66%',
        }}
      >
        {description}
      </p>
      {rightComponent}
    </div>
  );

  renderFilledJobInfo = () => {
    const { job } = this.props;
    const chargeDate = moment(job?.job_datetime).subtract(28, 'days').format('MM/DD/YYYY');
    const startDiff = moment(job?.job_datetime).diff(moment(), 'days');
    return (
      <>
        {!showCandidateOvertime() ? (
          this.renderRow({
            title: `Professional Pay ${job?.totalHours?.toFixed(2)} hours:`,
            description: `$${job.amount?.toFixed(2) || ''}`,
          })
        ) : (
          <>
            {job?.regularAndOvertimeHours?.regular > 0 &&
              job?.totalRegular > 0 &&
              this.renderRow({
                title: `Regular Rate Pay (${job?.regularAndOvertimeHours?.regular?.toFixed(
                  2,
                )} hrs):`,
                description: `$${job?.totalRegular?.toFixed(2) || ''}`,
              })}
            {job?.regularAndOvertimeHours?.overtime > 0 &&
              job?.totalOvertime > 0 &&
              this.renderRow({
                title: (
                  <p
                    className="global_font bold_font"
                    style={{ margin: 0, color: Colors.DarkGray }}
                  >
                    {`Overtime Pay (${job?.regularAndOvertimeHours?.overtime?.toFixed(2)} hrs):`}
                    <span
                      style={{
                        marginLeft: '5px',
                        position: 'relative',
                        top: '5px',
                      }}
                    >
                      <Tooltip content={overtimeTooltip} />
                    </span>
                  </p>
                ),
                description: `$${job?.totalOvertime?.toFixed(2) || ''}`,
              })}
          </>
        )}
        {this.renderRow({
          title:
            job.tm_fee_percent !== 0
              ? `Service Fee 
        (${job.tm_fee_percent}%):`
              : 'Service Fee:',

          description: `$${job.tm_fee?.toFixed(2)}`,
        })}
        {isEmployeeOptionShift(job) &&
          this.renderRow({
            title: 'Employee Fee:',
            description: job.employeeOptionFee ? `$${job.employeeOptionFee?.toFixed(2)}` : '$--.--',
          })}
        {job.convenience_fee_percent !== 0 ? (
          <div className="row_style">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <p className="global_font bold_font" style={{ margin: 0, color: Colors.DarkGray }}>
                Convenience Fee {`(${job.convenience_fee_percent.toFixed(1) || '0'}%)`}:
                <span
                  style={{
                    marginLeft: '5px',
                    position: 'relative',
                    top: '5px',
                  }}
                >
                  <Tooltip content={convenienceFeeTooltip} />
                </span>
              </p>
            </div>
            <p
              className="global_font"
              style={{ margin: 0, color: Colors.neutral_600, fontSize: 18 }}
            >
              {`$${job.convenience_fee.toFixed(2) || '0'}`}
            </p>
          </div>
        ) : null}
        {job?.multi_day_booking && startDiff > 28 ? (
          this.renderRow({ title: 'Professional pay charge date', description: chargeDate })
        ) : (
          <div />
        )}

        {job.counterCover
          ? this.renderRow({
            title: 'GoTu Counter Cover',
            description: `-$${job.counterCover.amount.toFixed(2)}`,
          })
          : null}

        <div
          className="row_style"
          style={{
            marginTop: 10,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 10,
            gap: 10,
          }}
        >
          <p className="global_font bold_font" style={{ margin: 0, color: Colors.success_500 }}>
            Total Pay:
          </p>
          <p className="global_font" style={{ margin: 0, color: Colors.neutral_600, fontSize: 18 }}>
            $ {job.total.toFixed(2)}
          </p>
        </div>
        {job?.cancelPerformedBy &&
          this.renderRow({
            title: 'Cancelled by:',
            description: `${job?.cancelPerformedBy?.first_name || ''} ${
              job?.cancelPerformedBy?.last_name || ''
            }`,
          })}
        {job?.confirmPerformedBy &&
          this.renderRow({
            title: 'Scheduled By:',
            description: `${job?.confirmPerformedBy?.first_name || ''} ${
              job?.confirmPerformedBy?.last_name || ''
            }`,
          })}
      </>
    );
  };

  renderFilledCancelledJobInfo = () => {
    const { job } = this.props;
    return (
      <div>
        {this.renderRow({
          title: 'Cancellation Fee:',
          description: `$ ${job.cancellation_fee?.toFixed(2) || ''}`,
        })}
        {this.renderRow({
          title:
            job.tm_fee_percent !== 0
              ? `Service Fee 
        (${job.tm_fee_percent}%):`
              : 'Service Fee:',
          description: `$${job.tm_fee?.toFixed(2)}`,
        })}
        {isEmployeeOptionShift(job) &&
          this.renderRow({
            title: 'Employee Fee:',
            description: job.employeeOptionFee ? `$${job.employeeOptionFee?.toFixed(2)}` : '$--.--',
          })}
        {job.convenience_fee_percent !== 0 ? (
          <div className="row_style">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <p className="global_font bold_font" style={{ margin: 0, color: Colors.DarkGray }}>
                Convenience Fee {`(${job.convenience_fee_percent.toFixed(1) || '0'}%)`}:
                <span
                  style={{
                    marginLeft: '5px',
                    position: 'relative',
                    top: '5px',
                  }}
                >
                  <Tooltip content={convenienceFeeTooltip} />
                </span>
              </p>
            </div>
            <p
              className="global_font"
              style={{ margin: 0, color: Colors.neutral_600, fontSize: 18 }}
            >
              {`$${job.convenience_fee.toFixed(2) || '0'}`}
            </p>
          </div>
        ) : null}
        <div className="row_style_bold">
          <p className="global_font bold_font" style={{ margin: 0, color: Colors.neutral_600 }}>
            Refund Total {job.card_name} *{job.card_number}
          </p>
          <p className="global_font" style={{ margin: 0, color: Colors.neutral_600, fontSize: 18 }}>
            $ {job.total_refund?.toFixed(2)}
          </p>
        </div>
      </div>
    );
  };

  chatBtnDisplay = () => {
    const { job, history, user } = this.props;
    const currentTime = new Date();
    if (
      job.job_status === 'filled' &&
      !job.is_cancelled &&
      moment(job.job_datetime_end).toDate() > currentTime &&
      job.offer_final_reciever
    ) {
      return (
        <div
          style={{
            width: 75,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            rowGap: 8,
          }}
        >
          <IconButton
            buttonStyle={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 50,
              height: 50,
              borderRadius: '50%',
              backgroundColor: Colors.secondary_500,
            }}
            src={ChatOutline}
            imgStyle={{
              width: 24,
              height: 24,
            }}
            onClick={() => {
              const timeUntilShift = moment(job?.job_datetime).fromNow(true);
              trackEvent('Job Summary - Contact Professional Button Clicked', {
                hours: job?.totalHours,
                shiftId: job?.id,
                shiftSpecialty: job?.specialty,
                procedures: job?.procedures,
                breakTime: job?.lunch_break_time,
                autoConfirm: job?.autoFill,
                softwares: user?.office_info?.softwares,
                assistedHygiene: job?.isAssistedHygiene,
                tpp: job?.amount,
                candidate: {
                  id: job?.offer_final_reciever?.id,
                  appliedDateTime: job?.job_filled_datetime,
                  yearOfExperience: job?.offer_final_reciever?.years_of_experience,
                  schoolName: job?.offer_final_reciever?.school,
                  graduationYear: job?.offer_final_reciever?.graduationDate,
                  skills: job?.offer_final_reciever?.skills,
                  badges: job?.offer_final_reciever?.badges,
                  rating: job?.offer_final_reciever?.average_rating,
                },
                timeUntilShift,
              });

              history.push(`/dashboard/chat/${job?.id}`, { jobId: job?.id });
            }}
          />

          <p
            style={{
              fontFamily: 'Nunito',
              fontSize: 14,
              color: Colors.neutral_600,
              textAlign: 'center',
              margin: 0,
            }}
          >
            Contact Professional
          </p>
        </div>
      );
    }

    return <></>;
  };

  renderSummaryContent = () => {
    const { job } = this.props;

    if (job.status === 'filled' || job.status === 'complete' || job.status === 'pay-out') {
      return this.renderFilledJobInfo();
    }
    if (job.status === 'cancelled' && job.offer_final_reciever) {
      return this.renderFilledCancelledJobInfo();
    }

    return <></>;
  };

  render() {
    const { job, isLoading, childOffices } = this.props;

    const { procedures: _procedures } = job || {};

    const procedures = _procedures ?? [];

    const { showCancellationPopup, showBlockConfirmPopup } = this.state;

    if (isLoading || !job) {
      return <CardSpinner />;
    }
    const professionalName = `${job?.offer_final_reciever?.first_name} ${job?.offer_final_reciever?.last_name}`;
    const isFavorite = job?.offer_final_reciever?.isFavorite;
    const isCanceledByProfessional = job?.is_cancelled_by_user_type === UserType.HYG;
    const autoFillTooltipDescription = (
      <>
        Professional’s eligibility criteria:
        <br />
        <br />
        {'\u2022  '}
        Has at least a {RemoteConfigUtils.getAutofillMinAttendanceScore()}% attendance score for
        previous shifts worked.
        <br />
        {'\u2022  '}
        Has a minimum of 1 year working experience.
        <br />
        {'\u2022  '}
        Has received exceptional performance ratings on over 95% of completed shifts.
        <br />
        {'\u2022  '}
        Has completed at least 3 shifts.
        <br />
        <br />
        Note: You may change your auto-confirm preference at any time by visiting the Shift Details
        page.
      </>
    );

    return (
      <div className="component_container" ref={(element) => (this.divRef = element)}>
        <div
          style={{
            width: '100%',
            minWidth: 1200,
            boxShadow: '0px 3px 10px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
          }}
        >
          {this.hygInfo()}

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '0 16px 16px 16px',
              background: Colors.white,
              boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
            }}
          >
            <div
              style={{
                width: 500,
                display: 'flex',
                flexDirection: 'column',
                marginTop: 40,
                paddingBottom: 60,
              }}
            >
              <JobStatusInfo job={job} />

              {childOffices.length !== 0 ? (
                <div className="row_style">
                  <p
                    className="global_font bold_font"
                    style={{ margin: 0, color: Colors.neutral_600 }}
                  >
                    Office:
                  </p>
                  <p
                    className="global_font"
                    style={{ margin: 0, color: Colors.neutral_600, fontSize: 18 }}
                  >
                    {job.offer_owner?.office_name}
                  </p>
                </div>
              ) : (
                <div />
              )}

              {this.renderRow({ title: 'Date:', description: job.local_date })}

              <div className="row_style">
                <p
                  className="global_font bold_font"
                  style={{ margin: 0, color: Colors.neutral_600 }}
                >
                  Profession:
                </p>
                <p
                  className="global_font"
                  style={{
                    margin: 0,
                    color: Colors.neutral_600,
                    fontSize: 18,
                    textTransform: 'capitalize',
                  }}
                >
                  {UserSubProfession.getName(job?.subProfession) || job.professionName || ''}
                </p>
              </div>

              {this.renderRow({
                title: 'Preferred Specialty:',
                description: job?.specialty?.split('_')?.[1] || 'General',
              })}

              {getShouldAllowOfficeToPostProcedure() && procedures.length > 0 && (
                <div
                  style={{
                    borderBottom: '1px solid #ededed',
                  }}
                >
                  <div
                    className="row_style"
                    style={{
                      border: 0,
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        color: Colors.neutral_600,
                        fontSize: 18,
                      }}
                    >
                      <b>Shift Procedure(s):</b>
                    </p>
                  </div>

                  <ul
                    style={{
                      color: Colors.neutral_600,
                      fontSize: 18,
                      marginTop: 0,
                    }}
                  >
                    {EducationUtils.mapToRemoveProcedurePrefix(procedures).map((procedure) => (
                      <li key={procedure}>{procedure}</li>
                    ))}
                  </ul>
                </div>
              )}

              {this.renderRow({
                title: 'Hours:',
                description: `${job.local_start_time} - ${job.local_end_time}`,
                timezone: true,
                rightComponent: this.showEodButton(),
              })}

              <div className="row_style">
                <p
                  className="global_font bold_font"
                  style={{ margin: 0, color: Colors.neutral_600 }}
                >
                  Hourly Rate:
                </p>
                <p
                  className="global_font"
                  style={{ margin: 0, color: Colors.neutral_600, fontSize: 18 }}
                >
                  ${job.rate.toFixed(2)}
                </p>
              </div>
              {this.renderRow({
                title: `Meal Break:`,
                description: `${job.lunch_break_time} min ${
                  job.is_lunch_break_paid ? '(Paid)' : '(Unpaid)'
                }`,
              })}

              <div
                className="row_style"
                style={{
                  alignItems: 'flex-start',
                }}
              >
                <span
                  className="global_font bold_font"
                  style={{ margin: 0, color: Colors.DarkGray }}
                >
                  Auto-Confirm:
                  <span
                    style={{
                      marginLeft: '5px',
                      position: 'relative',
                      top: '5px',
                    }}
                  >
                    <Tooltip content={autoFillTooltipDescription} />
                  </span>
                </span>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 12,
                    color: Colors.neutral_600,
                    fontSize: 18,
                    textAlign: 'end',
                  }}
                >
                  <span>Favorites / {job.autofillOptions?.favorite ? 'On' : 'Off'}</span>
                  <span>Your Highly-Rated / {job.autofillOptions?.highlyRated ? 'On' : 'Off'}</span>
                  <span>Eligible Professionals / {job.autoFill ? 'On' : 'Off'}</span>
                </div>
              </div>

              {job?.cancelPerformedBy &&
                this.renderRow({
                  title: 'Cancelled by:',
                  description: `${job?.cancelPerformedBy?.first_name || ''} ${
                    job?.cancelPerformedBy?.last_name || ''
                  }`,
                })}

              {isCanceledByProfessional ? (
                <span
                  style={{
                    fontFamily: 'Nunito',
                    color: Colors.neutral_600,
                    fontSize: 16,
                    fontWeight: 700,
                    textAlign: 'center',
                    marginTop: 40,
                  }}
                >
                  *This shift has been re-posted*
                </span>
              ) : (
                <>
                  {this.renderSummaryContent()}

                  <div
                    style={{
                      marginTop: 40,
                    }}
                  >
                    {this.transactionHistoryBtn()}

                    {this.cancelBtnDisplay()}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {showCancellationPopup ? (
          <CancelledJob closePopup={() => this.setState({ showCancellationPopup: false })} />
        ) : null}
        {showBlockConfirmPopup && (
          <ConfirmPopup
            title="Block Professional"
            description={
              isFavorite
                ? `To block this professional, they must be removed from your Favorites list. Would you like to continue with removing ${professionalName} from your Favorites and blocking them?`
                : 'Are you sure you want to block this professional from working with you again?'
            }
            leftButtonText="Go Back"
            leftButtonAction={() => this.setState({ showBlockConfirmPopup: false })}
            rightButtonText="Continue"
            rightButtonAction={this.blockProfessional}
            Icon={() => <img src={blockCross} style={{ marginBottom: 20 }} alt="block_cross" />}
            closePopup={() => this.setState({ showBlockConfirmPopup: false })}
          />
        )}
      </div>
    );
  }
}
const actions = [
  FETCH_JOB_ACTION,
  ADD_FAVORITE_PROFESSIONAL,
  BLOCK_PROFESSIONAL,
  FETCH_JOBS_WITH_ADJUSTMENTS,
];
const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorObjectSelector(actions);

const mapStateToProps = (state, props) => ({
  error: errorSelector(state),
  job: props.match.params.jobId === state.job.job?.id ? state.job.job : null,
  isLoading: loadingSelector(state),
  user: state.user.user,
  favoriteProfessionals: state.user.favoriteProfessionals,
  childOffices: state.user.childOffices,
});

export default connect(mapStateToProps, {
  fetchJob,
  hideMessage,
  addFavoriteProfessional,
  blockProfessional,
  fetchJobsWithAdjustmts,
})(JobDetails);
