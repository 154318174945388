import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeOverlappingModal } from '../../../../actions';
import { Row } from '../../../../commonComponents';
import Button from '../../../../commonComponents/Button';
import WarningIcon from '../../../../images/icons/Warning';
import { Colors } from '../../../../themes/colors';
import ConfirmPopup from '../../../shared/ConfirmPopup';
import { useConfirmStep } from '../hooks/useConfirmStep';
import { GuideLinesSwitch } from './Guidelines/GuidelinesSwitch';

export const ConfirmRequest = () => {
  const dispatch = useDispatch();
  const [acceptedGuidelines, setAcceptedGuidelines] = useState(false);
  const {
    handleConfirmButton,
    job,
    showLeavingPopup,
    showJobCreatePaymentError,
    setShowLeavingPopup,
    confirmOverlapping,
    cancelRequest,
    hidePaymentError,
    updatePayment,
    setShowOvertimeLimitPopup,
    showOvertimeLimitPopup,
  } = useConfirmStep();

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 120,
        marginTop: 60,
      }}
    >
      <GuideLinesSwitch onSwitch={setAcceptedGuidelines} />

      <Row gap={16}>
        <Button
          text="Cancel Request"
          style={{
            backgroundColor: Colors.secondary_500,
          }}
          onClick={() => setShowLeavingPopup(true)}
        />
        <Button
          text="Send Request"
          onClick={handleConfirmButton}
          style={{
            backgroundColor: !acceptedGuidelines ? Colors.primary_200 : Colors.primary_500,
          }}
          disabled={!acceptedGuidelines}
        />
      </Row>

      {showJobCreatePaymentError && (
        <ConfirmPopup
          Icon={() => (
            <div style={{ marginBottom: 20 }}>
              <WarningIcon />
            </div>
          )}
          title="Auto-Confirm Disabled"
          description={
            <>
              The auto-confirm feature is only available for offices that have input their payment
              information.
              <br />
              <br />
              You can update your payment information now or continue without payment, and with
              auto-confirm disabled.
            </>
          }
          descriptionStyle={{ marginBottom: 45 }}
          containerStyle={{ paddingTop: 45, paddingBottom: 50 }}
          leftButtonText="Go Back"
          leftButtonStyle={{
            backgroundColor: Colors.secondary_500,
          }}
          leftButtonAction={hidePaymentError}
          rightButtonText="Update"
          rightButtonAction={updatePayment}
        />
      )}

      {showLeavingPopup && (
        <ConfirmPopup
          Icon={() => (
            <div style={{ marginBottom: 20 }}>
              <WarningIcon width={64} height={64} />
            </div>
          )}
          title="Please Confirm"
          description="Are you sure you would like to cancel this request?"
          descriptionStyle={{ marginBottom: 100 }}
          containerStyle={{ paddingTop: 45, paddingBottom: 50 }}
          leftButtonText="Go Back"
          closePopup={() => setShowLeavingPopup(false)}
          leftButtonStyle={{
            backgroundColor: Colors.secondary_500,
          }}
          leftButtonAction={() => setShowLeavingPopup(false)}
          rightButtonText="Confirm"
          rightButtonAction={cancelRequest}
        />
      )}

      {job?.isOverlappingJob && (
        <ConfirmPopup
          Icon={() => (
            <div style={{ marginBottom: 20 }}>
              <WarningIcon width={64} height={64} />
            </div>
          )}
          title="Please Confirm"
          description={
            'At least 1 shift overlaps with an existing shift posted and/or confirmed. If you would like to review the dates, click "Go Back". If you wish to continue, click on "Confirm".'
          }
          descriptionStyle={{ width: '76%', marginBottom: 50 }}
          leftButtonText="Go Back"
          leftButtonStyle={{
            backgroundColor: Colors.secondary_500,
          }}
          leftButtonAction={() => dispatch(closeOverlappingModal())}
          rightButtonText="Confirm"
          rightButtonAction={confirmOverlapping}
        />
      )}

      {showOvertimeLimitPopup && (
        <ConfirmPopup
          Icon={() => (
            <div style={{ marginBottom: 20 }}>
              <WarningIcon width={64} height={64} color={Colors.warning_500} />
            </div>
          )}
          title="Overtime Hours"
          description={`
            The payable hours of this shift exceed your state’s daily 
            overtime limit. Your office will be subject to additional
            charges for any overtime hours worked by a professional.
          `}
          descriptionStyle={{ width: '76%', marginBottom: 50 }}
          leftButtonText="Go Back"
          leftButtonAction={() => setShowOvertimeLimitPopup(false)}
          rightButtonText="Continue"
          rightButtonAction={() => {
            handleConfirmButton({ alertOvertime: false });
            setShowOvertimeLimitPopup(false);
          }}
        />
      )}
    </div>
  );
};
