import React from 'react';

export const QUESTIONS_REGISTRATION = [
  {
    title: 'Do I have to choose my worker type right now?',
    description:
      '​​No! If you aren’t ready to select a worker type during sign up, you can bypass the selection by choosing “I’m not sure. I’ll decide later”. However, please note that you won’t be able to post shifts until the Type of Worker is selected. You can submit your selection at a later time by navigating to Account > Worker Type.',
  },
  {
    title: 'Can I change my worker type after I set it?',
    description:
      'At this time, it is not possible to change your worker type once it is set. However, we are working to make this possible in the future.',
  },
  {
    title: 'Is there a pricing difference between the two worker types?',
    description: (
      <span>
        Yes. Due to the different structures comprising classification, there are pricing
        differences.
        <br />
        <br />
        • Contractor Option: Service Fee (22.5% of professional pay)
        <br />• Employee Option: Service Fee (22.5% of professional pay) + Employee Fee (20% of
        professional pay)
      </span>
    ),
  },
  {
    title: 'Will my experience on GoTu differ depending upon my worker type selection?',
    description:
      'We’re working to ensure that the product experience will be very similar, if not the exact same. This applies to the in-platform user experience, support, and account resources.',
  },
  {
    title: 'What happens to the Employee Fee if I cancel a confirmed shift?',
    description: (
      <>
        We’ve kept the same timeframes for cancellation fees under both classifications. Therefore,
        any cancellation fees for the Employee Fee will depend upon the time of cancellation
        relative to the shift start time. If a confirmed shift is canceled by an office less than{' '}
        <b>4 days</b> before the shift, the office will be responsible for 100% of the Employee Fee.
      </>
    ),
  },
  {
    title:
      'What if my office is under the legal entity of a larger business group? (Ex: Multi-Office private practice)',
    description:
      'If your practice operates under the same legal entity as other practices within a given state, you must all set the same worker type. Please consult with your business group to decide which worker type option applies to your office.',
  },
];

export const QUESTIONS_SETTING = [
  {
    title: 'Can I change my worker type after I set it?',
    description:
      'At this time, it is not possible to change your worker type once it is set. However, we are working to make this possible in the future.',
  },
  {
    title: 'Is there a pricing difference between the two worker types?',
    description: (
      <span>
        Yes. Due to the different structures comprising classification, there are pricing
        differences.
        <br />
        <br />
        • Contractor Option: Service Fee (22.5% of professional pay)
        <br />• Employee Option: Service Fee (22.5% of professional pay) + Employee Fee (20% of
        professional pay)
      </span>
    ),
  },
  {
    title: 'Will my experience on GoTu differ depending upon my worker type selection?',
    description:
      'We’re working to ensure that the product experience will be very similar, if not the exact same. This applies to the in-platform user experience, support, and account resources.',
  },
  {
    title: 'What happens to the Employee Fee if I cancel a confirmed shift?',
    description: (
      <>
        We’ve kept the same timeframes for cancellation fees under both classifications. Therefore,
        any cancellation fees for the Employee Fee will depend upon the time of cancellation
        relative to the shift start time. If a confirmed shift is canceled by an office less than{' '}
        <b>4 days</b> before the shift, the office will be responsible for 100% of the Employee Fee.
      </>
    ),
  },
  {
    title:
      'What if my office is under the legal entity of a larger business group? (Ex: Multi-Office private practice)',
    description:
      'If your practice operates under the same legal entity as other practices within a given state, you must all set the same worker type. Please consult with your business group to decide which worker type option applies to your office.',
  },
];
