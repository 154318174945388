/* eslint-disable indent */
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { clearMultidayForm } from '../../actions';
import { trackEvent } from '../../api/analytics';
import { Colors } from '../../themes/colors';

const Card = ({ children }) => (
  <div className="dashboard-top-button">
    {children}
  </div>
);

const Button = ({ disabled, text, clickUrl, isBeta, style, listener }) => (
  <div
    className="dashboard-buttons-shadow" style={{ flex: 0, height: 48 }} 
    onClick={() => {
      listener?.();
    }}
  >
    <Link
      to={clickUrl}
      style={{
        display: 'flex',
        textDecoration: 'none',
        height: '100%',
        width: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
        pointerEvents: disabled ? 'none' : 'auto',
        backgroundColor: disabled ? Colors.primary_200 : Colors.primary_500,
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '25px',
        color: Colors.white,
        borderRadius: '32px',
        whiteSpace: 'nowrap',
        padding: '0 20px',
        ...style,
      }}
    >
      {text}
      {isBeta && (
        <p
          style={{
            fontSize: '10px',
            color: Colors.primary_500,
            backgroundColor: Colors.white,
            height: '16px',
            borderRadius: '36px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0 10px',
            marginLeft: '8px',
          }}
        >
          Beta
        </p>
      )}
    </Link>
  </div>
);

const TopButtons = ({ showLocumTenens = false }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.selectedChildOffice);
  const allowedRequest = user?.allowedRequest || {};
  const missingWorkerClassification =
    user?.isWorkerClassificationEnabled && !user?.workerClassification?.classificationType;
  const dashboardInfo = useSelector((state) => state.job.dashboardInfo);

  useEffect(() => {
    dispatch(clearMultidayForm());
  }, [dispatch]);

  const renderEmptyState = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      style={{
        flex: 1,
        padding: '0px 40px',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <p
        style={{
          fontFamily: 'Nunito',
          fontWeight: 'bold',
          fontSize: 18,
          color: Colors.neutral_400,
          textAlign: 'center',
        }}
      >
        Temporary and Permanent Services are currently disabled. Please contact your account manager
        for support.
      </p>
    </motion.div>
  );

  return (
    <Card>
      {allowedRequest?.tempOrMdbShifts ||
      allowedRequest?.ppJob ||
      (allowedRequest?.locumTenensDentist && showLocumTenens) ? (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              height: 65,
              alignItems: 'center',
              columnGap: '14px',
            }}
          >
            {allowedRequest?.tempOrMdbShifts && (
              <Button
                disabled={missingWorkerClassification}
                text="Request A Temp"
                clickUrl="/dashboard/multi-day/info"
                style={{ width: 206 }}
                listener={() => trackEvent('Request A Temp Button Clicked', {
                    scheduledShiftsCount: dashboardInfo?.scheduled, 
                    openShiftsCount: dashboardInfo?.activeRequest,
                    actionNeededShiftsCount: dashboardInfo?.actionRequired
                })}
              />
            )}

            {allowedRequest?.ppJob && (
              <Button
                text="Permanent Hire"
                clickUrl="/dashboard/pprs"
                style={{ width: 206 }}
              />
            )}
            {showLocumTenens && allowedRequest?.locumTenensDentist && (
              <Button
                text="Locum Tenens Dentist"
                clickUrl="/dashboard/locum-tenens-dentist"
                isBeta
              />
            )}
          </div>
        </>
      ) : (
        renderEmptyState()
      )}
    </Card>
  );
};

export default TopButtons;
