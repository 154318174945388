import { Colors } from '../../../themes/colors';

export const PartialShift = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 3,
      padding: '2px 7px',
      backgroundColor: Colors.neutral_150,
      width: 'fit-content',
    }}
  >
    <span
      style={{
        fontFamily: 'Nunito',
        fontWeight: 700,
        fontSize: 12,
        textAlign: 'center',
        color: Colors.neutral_500,
      }}
    >
      AVAILABLE FOR PARTIAL SHIFT
    </span>
  </div>
);
