import { isEqualWith } from 'lodash';

export const filterObjectKeys = (obj, string) => {
  const filteredEntries = Object.entries(obj || {}).filter(([key]) => key.startsWith(string));

  return Object.fromEntries(filteredEntries);
};

/**
 * Compares two objects to check for deep equality, treating `null` and empty strings `""`
 * as equivalent values with a custom comparator function.
 *
 * @param {Object} obj1 - The first object to compare.
 * @param {Object} obj2 - The second object to compare.
 * @returns {boolean} - Returns `true` if the objects are considered equal, otherwise `false`.
 */
export const isObjectEqual = (obj1, obj2) =>
  isEqualWith(obj1, obj2, (objValue, othValue) => {
    // Treat null and empty strings as equal
    if ((objValue === null || objValue === '') && (othValue === null || othValue === '')) {
      return true;
    }

    return undefined;
  });
