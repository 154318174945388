import { HIDE_MESSAGE } from '../actions';

const successReducer = (state = {}, action) => {
  if (action.type === HIDE_MESSAGE) {
    if (Array.isArray(action.payload)) {
      // If the payload is an array, iterate over it and set each key to false
      const newState = { ...state };
      action.payload.forEach((key) => {
        newState[key] = false;
      });
      return newState;
    }
    // If the payload is a string, just set that key to false
    return {
      ...state,
      [action.payload]: false,
    };
  }

  const { type } = action;
  // Match the action type against the pattern to capture the base request name and its state
  const matches = /(.*)_(REQUEST|SUCCESS)/.exec(type);

  // If the action type does not match the pattern, return the current state without changes.
  if (!matches) return state;

  const [, requestName, requestState] = matches;

  // Update the state to reflect whether the specific operation was successful.
  return {
    ...state,
    [requestName]: requestState === 'SUCCESS',
  };
};

export default successReducer;
