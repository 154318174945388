export const Column = ({
  children,
  className,
  backgroundColor,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingBottom,
  paddingHorizontal,
  marginLeft,
  marginRight,
  marginBottom,
  marginHorizontal,
  width,
  height,
  gap,
  marginTop,
  flex,
  style,
  ...props
}) => (
  <div
    {...props}
    className={className}
    style={{
      display: 'flex',
      flexDirection: 'column',
      width,
      height,
      flex,
      gap,
      paddingBottom,
      paddingTop,
      paddingRight: paddingRight || paddingHorizontal,
      paddingLeft: paddingLeft || paddingHorizontal,
      marginTop,
      marginLeft: marginLeft || marginHorizontal,
      marginRight: marginRight || marginHorizontal,
      marginBottom,
      backgroundColor,
      ...style,
    }}
  >
    {children}
  </div>
);
