import { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserMessages } from "../../../../actions";

const LIMIT_VALUE = 15;

export const useChatsInfiniteScroll = () => {
  const dispatch = useDispatch();

  const isActiveRequest = useSelector((state) => state.messages.isActiveRequest);
  const { page } = useSelector((store) => store.messages.searchParams);

  const elRef = useRef(null);

  const onScrollListener = useCallback(() => {
    const el = elRef.current;

    const userScrollHeight = el?.scrollHeight - el?.scrollTop;
    const clientHeight = el?.clientHeight;

    if (userScrollHeight <= clientHeight && !isActiveRequest) {
      dispatch(getUserMessages({ page: page + 1, limit: LIMIT_VALUE, status: "unread" }));
    }
  }, [dispatch, isActiveRequest, page]);

  return {
    elRef,
    onScrollListener,
  }
}