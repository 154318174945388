import moment from 'moment';
import { useState } from 'react';
import CalendarIcon from '../../../../../../images/calendar-icon.svg';
import { Colors } from '../../../../../../themes/colors';
import { CollapsibleRow } from '../../CollapsibleRow/CollapsibleRow';
import { Title } from '../../CollapsibleRow/Title';
import { DateContent } from './DateContent';

export const SingleDateRow = ({ defaultValue, onToggle, isOpen, onConfirm }) => {
  const [selectedDate, setSelectedDate] = useState(defaultValue);

  const handleConfirmDates = (dates) => {
    setSelectedDate(dates[0]);
    onConfirm(dates[0]);
    onToggle();
  };

  return (
    <>
      <CollapsibleRow
        isOpen={isOpen}
        onToggle={onToggle}
        showArrow
        TitleComponent={() => <Title text="Date:" />}
        titlePosition="flex-start"
        ValueComponent={() => (
          <span
            style={{
              fontFamily: 'Nunito',
              color: Colors.neutral_600,
              textTransform: 'capitalize',
            }}
          >
            {selectedDate && moment(selectedDate).format('ddd, MMM DD, YYYY')}
          </span>
        )}
        icon={CalendarIcon}
      >
        <DateContent
          maxDaysToSelect={1}
          onConfirm={handleConfirmDates}
          defaultValues={[defaultValue]}
        />
      </CollapsibleRow>
    </>
  );
};
