import React from 'react';
import { Colors } from '../../themes/colors';

const BulbIcon = ({ color = Colors.white }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Outline/light-bulb">
      <path
        id="Icon"
        d="M9.40386 17.5556H14.596M11.9999 2V3.11111M19.071 4.92893L18.2853 5.71461M22 11.9999H20.8889M3.11111 11.9999H2M5.71455 5.71461L4.92887 4.92893M8.07157 15.9284C5.90199 13.7588 5.90199 10.2412 8.07157 8.07165C10.2411 5.90208 13.7587 5.90208 15.9283 8.07165C18.0979 10.2412 18.0979 13.7588 15.9283 15.9284L15.3204 16.5363C14.6172 17.2395 14.2222 18.1932 14.2222 19.1877V19.7778C14.2222 21.0051 13.2272 22 11.9999 22C10.7726 22 9.77772 21.0051 9.77772 19.7778V19.1877C9.77772 18.1932 9.38266 17.2395 8.67946 16.5363L8.07157 15.9284Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default BulbIcon;
