import BackButton from '../BackButton';
import { Box } from '../Box';
import { Center } from '../Center';
import { Text } from '../Text';
import { ZStack } from '../ZStack';

export const ScreenTitle = ({ title, onBackClick }) => (
  <Box data-testid="screen-title" position="relative" marginBottom={32} marginTop={60} width="100%">
    <Center>
      <ZStack left={20}>
        <BackButton onClick={onBackClick} />
      </ZStack>

      <Text fontSize={32} bold textAlign="center">
        {title}
      </Text>
    </Center>
  </Box>
);
