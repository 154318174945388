import moment from 'moment';
import browserHistory from '../../history';
import {
  CalendarActionNeededFilterIcon,
  CalendarOpenFilterIcon,
  CalendarTodayFilterIcon,
} from '../../images/emptyScreenIcons';
import { Colors } from '../../themes/colors';
import { FilterStatusEnum, StatusEnum } from '../../utils/Enum';
import { getJobStatus } from '../Dashboard/ReceiptList/utils';

export function createDateArrayWithNumberOfDays(start, numberOfDays) {
  const dateArray = [];
  const startDate = moment(start);

  dateArray.push(startDate.format('YYYY-MM-DD'));

  while (dateArray.length < numberOfDays) {
    startDate.add(1, 'day');
    dateArray.push(startDate.format('YYYY-MM-DD'));
  }

  return dateArray;
}

export function createDaysOfWeekArray(start) {
  const daysArray = [];
  const startDate = moment(start);
  const startDay = startDate.day();

  const arrayStartDate = moment(startDate).subtract(startDay, 'days');

  const lastDayOfMonth = moment(startDate).endOf('month');

  const daysToFillArray = lastDayOfMonth.day() === 0 ? 42 : 35;

  for (let i = 0; i < daysToFillArray; i += 1) {
    const currentDate = moment(arrayStartDate).add(i, 'days');

    const formattedDate = currentDate.format('YYYY-MM-DD');

    daysArray.push(formattedDate);
  }

  return daysArray;
}

export const getCalendarJobStatus = (job) => {
  if (
    job.status === 'filled'
    && moment(job.local_date, 'ddd, MMM D, YYYY').isSame(moment(), 'day')
  ) {
    return {
      label: StatusEnum.today,
      color: Colors.primary_500,
    };
  }
  return getJobStatus(job.is_canceled ? 'canceled' : job.status);
};

export const onCardClick = (job) => {
  let url = `/dashboard/job/${job.id}/details`;

  if (job.status === 'open') {
    url = `/dashboard/job/${job.id}`;
  } else if (job.status === 'pending' || job.status === 'counter') {
    url = `/dashboard/job/${job.id}/confirm`;
  }

  browserHistory.push(url);
  window.scrollTo(0, 0);
};

export const getEmptyFilteredScreen = (filter) => {
  switch (filter) {
  case FilterStatusEnum.OPEN:
    return {
      title: 'Post shifts, let us help you!',
      description: (
        <>
          You have not posted any shifts.
          <br />
          Request a temp, to find the right professionals
          <br />
          and secure the coverage you need!
        </>
      ),
      Icon: CalendarOpenFilterIcon,
    };
  case FilterStatusEnum.SCHEDULED:
    return {
      title: 'Ready to schedule your next candidate!',
      description: (
        <>
          You have no candidates scheduled.
          <br />
          Check to see if any candidates have applied to
          <br />
          your posted shifts!
        </>
      ),
      Icon: CalendarTodayFilterIcon,
    };
  case FilterStatusEnum.COMPLETED:
    return {
      title: 'Bring in you first professionals!',
      description: (
        <>
          No professionals have completed their first shift
          <br />
          at your office yet. Start posting shifts to
          <br />
          welcome them in!
        </>
      ),
      Icon: CalendarTodayFilterIcon,
    };
  case FilterStatusEnum.PENDING_COUNTER:
    return {
      title: 'Waiting for candidates to apply!',
      description: (
        <>
          No professionals have applied to your shifts yet.
          <br />
          Check back soon, someone might apply at any moment!
        </>
      ),
      Icon: CalendarActionNeededFilterIcon,
    };
  default:
    return {
      title: 'No professionals stopping by today!',
      description: (
        <>
          There are no professionals booked for today.
          <br />
          Post a shift to get things rolling!
        </>
      ),
      Icon: CalendarTodayFilterIcon,
    };
  }
};
