import { Colors } from '../../themes/colors';
import { Box } from '../Box';
import { Center } from '../Center';
import { Column } from '../Column';
import { Text } from '../Text';

export const EmptyScreen = ({
  Icon, title, description, width = 405, descriptionStyle = {},
}) => (
  <Center>
    <Box
      style={{
        marginTop: 60,
        marginBottom: 28,
      }}
    >
      <Icon />
    </Box>
    <Column width={width} gap={8}>
      <Text fontSize={22} bold color={Colors.neutral_500} textAlign="center">
        {title}
      </Text>
      <Text fontSize={16} color={Colors.neutral_500} textAlign="center" {...descriptionStyle}>
        {description}
      </Text>
    </Column>
  </Center>
);
