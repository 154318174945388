import { InsuranceProviderCard } from "./components/InsuranceProviderCard/InsuranceProviderCard";
import { NotesCard } from "./components/NotesCard/NotesCard";
import { OfficeInformationCard } from "./components/OfficeInformationCard/OfficeInformationCard";
import { OfficeProfileCard } from "./components/OfficeProfileCard/OfficeProfileCard";
import { ParkingCard } from "./components/ParkingCard/ParkingCard";
import { SoftwareCard } from "./components/SoftwareCard/SoftwareCard";

export const STEPS = [
  { name: 'Office Profile', Component: OfficeProfileCard },
  { name: 'Office Information', Component: OfficeInformationCard },
  { name: 'Insurance Provider', Component: InsuranceProviderCard },
  { name: 'Software', Component: SoftwareCard },
  { name: 'Parking', Component: ParkingCard },
  { name: 'Notes', Component: NotesCard },
];