import { isObjectEqual } from '../../../../utils/Object';
import { useInsuranceProvider } from '../../components/InsuranceProviderCard/hooks/useInsuranceProvider';
import { useOfficeInformation } from '../../components/OfficeInformationCard/hooks/useOfficeInformation';
import { useOfficeProfile } from '../../components/OfficeProfileCard/hooks/useOfficeProfile';
import { useParking } from '../../components/ParkingCard/hooks/useParking/useParking';
import { useSoftware } from '../../components/SoftwareCard/hooks/useSoftware/useSoftware';

export const useFormStatus = ({ control, formValues, defaultValues }) => {
  const isDirty = !isObjectEqual(defaultValues, formValues);

  const { isValid: isOfficeValid } = useOfficeProfile(control);
  const { isValid: isOfficeInformationValid } = useOfficeInformation(control);
  const { isValid: isInsuranceProviderValid } = useInsuranceProvider(control);
  const { isValid: isSoftwareValid } = useSoftware(control);
  const { isValid: isParkingValid } = useParking(control);
  const isValid =
    isOfficeValid &&
    isInsuranceProviderValid &&
    isSoftwareValid &&
    isOfficeInformationValid &&
    isParkingValid;

  const isButtonDisabled = !isDirty || !isValid;

  return {
    isButtonDisabled,
    isDirty,
    isValid,
  };
};
