import React from 'react';
import { Colors } from '../../themes/colors';

const StarIcon = ({ color = Colors.success_400, width = 12, height = 11 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.33427 0.749069C5.54382 0.104151 6.45621 0.104152 6.66575 0.749069L7.41445 3.05333C7.50816 3.34174 7.77693 3.53701 8.08019 3.53701H10.503C11.1811 3.53701 11.4631 4.40475 10.9145 4.80333L8.95436 6.22744C8.70902 6.40569 8.60636 6.72164 8.70007 7.01006L9.44877 9.31432C9.65832 9.95924 8.92018 10.4955 8.37158 10.0969L6.41146 8.67283C6.16612 8.49458 5.8339 8.49458 5.58856 8.67283L3.62844 10.0969C3.07984 10.4955 2.34171 9.95924 2.55126 9.31432L3.29995 7.01006C3.39367 6.72164 3.29101 6.40569 3.04566 6.22744L1.08555 4.80333C0.536947 4.40475 0.81889 3.53701 1.497 3.53701H3.91984C4.22309 3.53701 4.49186 3.34174 4.58558 3.05333L5.33427 0.749069Z"
      fill={color}
    />
  </svg>
);

export default StarIcon;
