import { useDispatch, useSelector } from 'react-redux';
import { changeHubFilter } from '../../../../actions';
import { Box, Center, Row, Text } from '../../../../commonComponents';
import InputSelect from '../../../../commonComponents/InputSelect';
import { UserProfession } from '../../../../enums/UserProfession';
import UserIcon from '../../../../images/user-icon.svg';

export const FilterSelect = () => {
  const dispatch = useDispatch();
  const filter = useSelector((state) => state.user.professionalHubFilter);

  return (
    <Row gap={4}>
      <Center width={120}>
        <Row gap={4} width={120}>
          <img src={UserIcon} alt="User Icon" />
          <Text bold fontSize={18}>
            Filter by:
          </Text>
        </Row>
      </Center>

      <Box width={220}>
        <InputSelect
          value={filter}
          setValue={(option) => dispatch(changeHubFilter(option))}
          options={options}
          isSelectVisible={false}
        />
      </Box>
    </Row>
  );
};

const options = [
  { title: 'All Professionals', value: 'all' },
  { title: 'Dental Hygienist', value: UserProfession.RDH },
  { title: 'Dental Assistant', value: UserProfession.DA },
  { title: 'Associate Dentist', value: UserProfession.DN },
  { title: 'Front Desk', value: UserProfession.FD },
];
