export const ZStack = ({ children, left, style, ...props }) => (
  <div
    {...props}
    style={{
      position: 'absolute',
      left,
      ...style,
    }}
  >
    {children}
  </div>
);
