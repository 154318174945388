import '../../../themes/global.scss';
import '../../../themes/registration.scss';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EDIT_GENERAL_INFO_ACTION, editUserGeneral } from '../../../actions';
import { trackEvent } from '../../../api/analytics';
import { createLoadingSelector } from '../../../api/selectors';
import Button from '../../../commonComponents/Button';
import { Colors } from '../../../themes/colors';
import { ContainerLayout, ContentLayout, Title } from '../components';
import { FieldValue, OptionField } from './components';

const actions = [EDIT_GENERAL_INFO_ACTION];
const loadingSelector = createLoadingSelector(actions);

export const ContactInformation = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => loadingSelector(state));
  const user = useSelector((state) => state.user.user);
  const [titleName, setNameTitle] = useState(user?.title ?? 'Dr.');
  const [firstName, setFirstName] = useState(user?.first_name ?? '');
  const [lastName, setLastName] = useState(user?.last_name ?? '');
  const [titleNameAccountAdmin, setNameTitleAccountAdmin] = useState(
    user?.account_admin[0]?.title ?? 'Dr.',
  );
  const [firstNameAccountAdmin, setFirstNameAccountAdmin] = useState(
    user?.account_admin[0]?.first_name ?? '',
  );
  const [lastNameAccountAdmin, setLastNameAccountAdmin] = useState(
    user?.account_admin[0]?.last_name ?? '',
  );
  const isDisabled =
    !firstName ||
    !lastName ||
    (!!lastNameAccountAdmin && !firstNameAccountAdmin) ||
    (!!firstNameAccountAdmin && !lastNameAccountAdmin);

  const handleSubmit = () => {
    const data = {
      user_type: 'DNT',
      title: titleName,
      first_name: firstName,
      last_name: lastName,
      account_admin_title: titleNameAccountAdmin,
      account_admin_first_name: firstNameAccountAdmin,
      account_admin_last_name: lastNameAccountAdmin,
    };

    trackEvent('Office Sign up - Contact information submitted', data);
    dispatch(editUserGeneral(data));
  };

  return (
    <ContainerLayout>
      <Title text="Contact Information" />
      <ContentLayout>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 50,
          }}
        >
          <span
            style={{
              fontFamily: 'Nunito',
              fontSize: 16,
              fontStyle: 'italic',
              fontWeight: 400,
              margin: 0,
              color: Colors.neutral_500,
              textAlign: 'center',
            }}
          >
            This information is used to create your account. Once your account is created, you can
            edit
            <br />
            the contact names at anytime.
          </span>
        </div>
        <div>
          <span className="title_font" style={{ color: Colors.primary_500 }}>
            Dentist Name
          </span>
          <div
            style={{
              marginTop: 15,
              display: 'flex',
              gap: 22,
            }}
          >
            <OptionField value={titleName} setValue={setNameTitle} />
            <FieldValue label="First Name" value={firstName} setValue={setFirstName} required />
            <FieldValue label="Last Name" value={lastName} setValue={setLastName} required />
          </div>

          <div style={{ marginTop: 40 }}>
            <span className="title_font" style={{ color: Colors.primary_500 }}>
              Additional Contact Name
            </span>
            <div
              style={{
                marginTop: 15,
                display: 'flex',
                gap: 22,
              }}
            >
              <OptionField value={titleNameAccountAdmin} setValue={setNameTitleAccountAdmin} />
              <FieldValue
                label="First Name"
                value={firstNameAccountAdmin}
                setValue={setFirstNameAccountAdmin}
              />
              <FieldValue
                label="Last Name"
                value={lastNameAccountAdmin}
                setValue={setLastNameAccountAdmin}
              />
            </div>
          </div>

          <div
            style={{
              marginTop: 90,
              marginBottom: 80,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              isLoading={isLoading}
              text="Continue"
              disabled={isDisabled}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </ContentLayout>
    </ContainerLayout>
  );
};
