import React from 'react';
import { Links } from '../../constants';
import CheckCardPhoneSVG from '../../images/dualModelPopup/check-card-phone.svg';
import EmployeeLabelSearchSVG from '../../images/dualModelPopup/employee-label-search.svg';
import PersonCalendarSVG from '../../images/dualModelPopup/person-calendar.svg';
import { Link } from '../../commonComponents/Link';

export const STEPS = [
  {
    title: 'Announcing Employee Work!',
    description: (
      <>
        At GoTu, we’ve created a platform that allows offices and professionals to work together as
        safely as possible. In your state, the next step in this ever evolving journey is to
        transition from an independent contractor to a direct employment model.
        <br />
        <br />
        While this sounds like a massive shift, your day-to-day experience with the platform will
        remain largely the same. There will be 2 main differences with this direct employment model
        - Click “Continue”.
      </>
    ),
    image: EmployeeLabelSearchSVG,
  },
  {
    title: 'What will Change?',
    description: (
      <>
        1. The dental professionals that you select for temporary shifts will be employed with our
        PEO, our selected Professional Employer Organization, rather than contracted by GoTu. Our
        PEO will handle all of the employee onboarding and payments, so your office does not have to
        worry about any paperwork, including tax forms!
        <br />
        <br />
        2. To cover additional costs associated with an employment model, there will be an “Employee
        Option Fee” that amounts to an additional 20% of the professional pay. This fee does not
        replace our booking fee. To learn more about what will happen with your existing shifts -
        Click “Continue”.
      </>
    ),
    image: CheckCardPhoneSVG,
  },
  {
    title: 'What will happen to your existing shifts?',
    description: (
      <>
        Unfilled shifts: Any new candidates will be applying as an Employee of our PEO.
        <br />
        <br />
        Filled shifts: The professional will complete the shift as an Independent Contractor of
        GoTu.
        <br />
        <br />
        New shifts: All new shifts will be posted and worked with the professionals as employees of
        our PEO.
      </>
    ),
    image: PersonCalendarSVG,
    switchText: (
      <>
        I agree to GoTu’s updated <Link text="Terms and Conditions" href={Links.termsOfUse} />
        {' and '}
        <Link text="Personnel Service Agreement" href={Links.termsOfUseEmployee} />
      </>
    ),
  },
];
