import { Icon, Text } from '../../../../commonComponents';
import { Colors } from '../../../../themes/colors';
import { NotificationLayout } from '../Layout';
import { useReminder } from './hooks/useReminder';
import history from '../../../../history';

export const ReminderList = () => {
  const { reminders, totalReminders } = useReminder();

  if (totalReminders === 0) return null;

  return (
    <NotificationLayout icon="bell" title={`Reminders (${totalReminders})`}>
      {reminders.map(({ title, description, isVisible, redirectTo }, index) => {
        if (!isVisible) return null;

        const isLast = index === totalReminders - 1;

        const onRedirect = () => history.push(redirectTo);

        return (
          <div key={title} style={{
            borderBottom: !isLast ? `1px solid ${Colors.neutral_100}` : 'none',
            display: 'flex',
            justifyContent: 'space-between',
            gap: 8,
            padding: 16,
          }}>
            <button
              type="button"
              style={{
                background: 'none',
                textAlign: 'left',
                width: '80%',
              }}
              onClick={onRedirect}
            >
              <Text
                bold
                color={Colors.neutral_600}
                style={{
                  lineHeight: '22px',
                }}
              >
                {title}: {description}
              </Text>
            </button>

            <button
              type="button"
              style={{
                background: 'none',
              }}
              onClick={onRedirect}
            >
              <Icon name="arrow-right" color={Colors.secondary_500} />
            </button>
          </div>
        );
      })}
    </NotificationLayout>
  );
};
