import { UserProfession, UserSubProfession } from '../enums/UserProfession';
import { getShouldOfficeShowFrontDeskProfession } from '../growthbook';
import { EnumHelper } from './EnumHelper';

/**
 * Provides the profession type options for the user
 * including sub-professions like {@link UserSubProfession.EFDA}
 *
 * @param {Array.<{code: string, name: string}>} allProfessions - all professions available,
 *    gotten from {@link getAllProfessionals}
 * @param {string[]} licenseTypes - license types user has, usually gotten from `user.licenseTypes`
 * @param {string[]} - sub-professions available, usually gotten from `user.licenseTypes`
 * @returns {Array.<{code: string, name: string}>}
 */
export function getProfessionTypeOptions({ allProfessions, licenseTypes, subProfessions }) {
  const licenses = allProfessions.filter((profession) => licenseTypes.includes(profession.code));

  // find DA index
  const daIndex = licenses.findIndex((license) => license.code === UserProfession.DA);

  if (daIndex > -1) {
    // add DA's sub-profession after DA
    return licenses.toSpliced(
      ...[daIndex + 1, 0].concat(
        subProfessions.map((subProfession) => ({
          code: subProfession,
          name: UserSubProfession.getName(subProfession),
        })),
      ),
    );
  }

  return licenses;
}

/**
 * Getting all profession type options available for job posting
 * including sub-professions like {@link UserSubProfession.EFDA}
 *
 * @param {Array.<{code: string, name: string}>} allProfessions - all professions available,
 *  gotten from {@link getAllProfessionals}
 * @param {boolean} showEfda - whether to show EFDA sub-profession or not
 */
export function getAllProfessionTypeOptions(allProfessions, { showEfda = false }) {
  const availableProfessions = [...(allProfessions ?? [])]
    // filter out FrontDesk profession if growthbook key is set to false
    .filter(
      (profession) =>
        !(!getShouldOfficeShowFrontDeskProfession() && profession.code === UserProfession.FD),
    );

  // find DA index
  const daIndex = availableProfessions.findIndex((license) => license.code === UserProfession.DA);

  if (daIndex > -1) {
    // add DA's sub-profession after DA
    const subProfessions = EnumHelper.getObjectKeys(UserSubProfession)
      .filter((subProfession) => !(!showEfda && subProfession === UserSubProfession.EFDA))
      .map((subProfession) => ({
        code: subProfession,
        name: UserSubProfession.getName(subProfession),
      }));

    availableProfessions.splice(daIndex + 1, 0, ...subProfessions);
  }

  // Remove duplicates
  return Array.from(new Set(availableProfessions.map((p) => p.code))).map((code) =>
    availableProfessions.find((p) => p.code === code),
  );
}
