export const EnumHelper = {
  /**
   * Get the keys of an object that are not functions
   *
   * @param {object} object
   * @returns {string[]}
   */
  getObjectKeys(object) {
    return Object.keys(object).filter((key) => typeof object[key] !== 'function');
  },
};
