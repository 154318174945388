import { Icon, Row, Text } from '../../../../commonComponents';
import { Colors } from '../../../../themes/colors';

export const CardTitle = ({ name, showWarningIcon }) => (
  <Row data-testid="card-title" gap={8} alignItems="center">
    <Text bold color={Colors.primary_500} fontSize={18}>
      {name}
    </Text>
    {showWarningIcon && <Icon name="warning-solid" color={Colors.warning_500} />}
  </Row>
);
