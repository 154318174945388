import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DentistScrubsImage from '../images/dentist-scrubs.svg';
import { Colors } from '../themes/colors';
import ConfirmPopup from './shared/ConfirmPopup';
import { getShouldOfficeShowFrontDeskProfession } from '../growthbook';

export function OfficeFrontDeskOnboardingPopup() {
  const user = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);
  const [showPopup, setShowPopup] = useState(false);
  const isSameOrBefore = moment().isBefore('2024-08-25');

  const shouldOfficeShowFrontDesk = getShouldOfficeShowFrontDeskProfession();

  const getStorage = () => {
    const showOfficeFrontDeskOnboardingPopUp = localStorage.getItem(
      `showOfficeFrontDeskOnboardingPopUp-${user?.id}`,
    );

    if (showOfficeFrontDeskOnboardingPopUp === null) {
      setShowPopup(true);
    }
  };

  const setStorage = () => {
    localStorage.setItem(`showOfficeFrontDeskOnboardingPopUp-${user?.id}`, 'false');
    setShowPopup(false);
  };

  const handleConfirm = () => {
    setStorage();
  };

  useEffect(() => {
    getStorage();
  }, [user]);

  return (
    shouldOfficeShowFrontDesk &&
    isSameOrBefore &&
    showPopup && (
      <ConfirmPopup
        Icon={() => (
          <img
            alt="Dentist Scrubs Onboarding Header"
            src={DentistScrubsImage}
            style={{ width: 224, height: 158, marginBottom: 24 }}
          />
        )}
        title="We Have A New Profession!"
        description="GoTu now provides Front Desk as an option for shift posting. We are excited to be able to support your office needs in a new way!"
        descriptionStyle={{ color: Colors.neutral_500, marginBottom: 32 }}
        rightButtonText="Got it!"
        rightButtonAction={handleConfirm}
        closePopup={handleConfirm}
      />
    )
  );
}
