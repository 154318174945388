import OfficeAvatar1 from '../../images/avatars/Office-Avatar-1.svg';
import OfficeAvatar2 from '../../images/avatars/Office-Avatar-2.svg';
import OfficeAvatar3 from '../../images/avatars/Office-Avatar-3.svg';
import OfficeAvatar4 from '../../images/avatars/Office-Avatar-4.svg';
import OfficeAvatar5 from '../../images/avatars/Office-Avatar-5.svg';
import OfficeAvatar6 from '../../images/avatars/Office-Avatar-6.svg';
import OfficeAvatar7 from '../../images/avatars/Office-Avatar-7.svg';
import OfficeAvatar8 from '../../images/avatars/Office-Avatar-8.svg';
import OfficeAvatar9 from '../../images/avatars/Office-Avatar-9.svg';
import { Colors } from '../../themes/colors';
import { convertSvgToJpeg } from './utils';

const imgArr = [
  OfficeAvatar1,
  OfficeAvatar2,
  OfficeAvatar3,
  OfficeAvatar4,
  OfficeAvatar5,
  OfficeAvatar6,
  OfficeAvatar7,
  OfficeAvatar8,
  OfficeAvatar9,
];

export const AvatarList = ({ selected, onSelect, horizontal = false }) => {
  const handleOnSelect = async (imgUrl, index) => {
    const { url } = await convertSvgToJpeg(imgUrl);
    onSelect(url, index);
  };

  return (
    <div
      style={{
        display: horizontal ? 'flex' : 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridTemplateRows: 'auto auto auto',
        gap: horizontal ? 8 : 26,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {imgArr.map((img, index) => (
        <img
          src={img}
          alt={`OfficeAvatar${index + 1}`}
          onClick={() => handleOnSelect(img, index + 1)}
          key={img}
          width={horizontal ? 44 : 70}
          style={{
            cursor: 'pointer',
            border: `3px solid ${selected === index + 1 ? Colors.secondary_500 : 'transparent'}`,
            padding: 2,
            borderRadius: '100%',
          }}
        />
      ))}
    </div>
  );
};
