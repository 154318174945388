import React from 'react';
import { motion } from 'framer-motion';

import { Colors } from '../themes/colors';

export default function Checkcircle({ checked }) {
  const circleVariants = {
    checked: {
      opacity: 1,
      r: 4.5,
    },
    unchecked: {
      opacity: 0,
      r: 0,
    },
  };

  return (
    <motion.svg
      animate={checked ? 'checked' : 'unchecked'}
      initial="unchecked"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.rect
        x="1"
        y="1"
        width="15"
        height="15"
        rx="7.5"
        stroke={Colors.secondary_500}
        strokeWidth="2"
      />
      <motion.circle cx="8.5" cy="8.5" fill={Colors.secondary_500} variants={circleVariants} />
    </motion.svg>
  );
}
