import { getShouldEnableUnlimitedCandidatesList } from '../../growthbook';
import FavoriteProfessionalIcon from '../../images/icons/FavoriteProfessional';
import UserIcon from '../../images/icons/User';
import { Colors } from '../../themes/colors';

export const getNumberOfCandidates = (candidates) => {
  const hasFavoriteCandidate = candidates?.some((candidate) => candidate.isFavorite);
  let candidateCount = candidates?.length;
  if (typeof candidateCount === 'number') {
    // Max is 5 candidates if unlimited candidates list is disabled
    candidateCount = getShouldEnableUnlimitedCandidatesList()
      ? candidateCount
      : Math.min(candidateCount, 5);
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {hasFavoriteCandidate ? (
        <FavoriteProfessionalIcon />
      ) : (
        <UserIcon color={Colors.secondary_500} />
      )}
      <span
        style={{
          fontFamily: 'Nunito',
          fontWeight: 700,
          fontSize: 22,
          color: Colors.secondary_500,
          marginTop: 6,
        }}
      >
        {candidateCount}
      </span>
    </div>
  );
};
