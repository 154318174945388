import React from 'react';

export default class TimerCountdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      millisecondsRemaining: 0,
    };
  }

  componentDidMount() {
    this.tick();
  }

  componentWillUnmount() {
    const { timeoutId } = this.state;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  }

  tick = () => {
    const { expireTime } = this.props;
    const delta =
      process.env.NODE_ENV !== 'test'
        ? new Date(expireTime).getTime() - Date.now()
        : 60 * 60 * 1000;
    if (delta < 0) {
      this.setState({
        millisecondsRemaining: 0,
        timeoutId: null,
      });
    } else {
      this.setState({
        millisecondsRemaining: Math.trunc(delta / 1000),
        timeoutId: process.env.NODE_ENV !== 'test' ? setTimeout(this.tick, 1000) : null,
      });
    }
  };

  pad = (val) => (val < 10 ? `0${val}` : `${val}`);

  render() {
    const { millisecondsRemaining } = this.state;
    const { allowFontScaling, expireTime } = this.props;
    const { style } = this.props;

    const hours = Math.trunc(millisecondsRemaining / 3600);
    const minutes = Math.trunc((millisecondsRemaining % 3600) / 60);
    const seconds = millisecondsRemaining % 60;
    return (
      <div allowFontScaling={allowFontScaling} style={style}>
        {this.pad(hours)}:{this.pad(minutes)}:{this.pad(seconds)}
      </div>
    );
  }
}

TimerCountdown.defaultProps = {
  formatMilliseconds: undefined,
  onTick: undefined,
  onExpire: undefined,
};
