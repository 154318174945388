import { motion } from 'framer-motion';

import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Colors } from '../themes/colors';
import { Carousel } from '../commonComponents/Carousel';
import Portal from '../commonComponents/Portal';
import Button from '../commonComponents/Button';

import AttendanceBadgeSlideIcon from '../images/badges/attendance-badge-announcement.svg';
import RatingBadgeSlideIcon from '../images/badges/rating-badge-announcement.svg';
import CompletedShiftBadgeSlideIcon from '../images/badges/completed-shifts-badge-announcement.svg';
import CloseIcon from '../images/icons/CloseIcon';

const slides = [
  {
    Icon: AttendanceBadgeSlideIcon,
    title: 'New Order for the Attendance Badge!',
    description: (
      <>
        Our attendance badges just got an update! Gold is now for perfect attendance -{' '}
        <strong>confirm these professionals now before they&apos;re gone!</strong>
      </>
    ),
  },
  {
    Icon: RatingBadgeSlideIcon,
    title: 'Introducing Ratings Badges!',
    description: (
      <>
        You can now award <strong>badges</strong> for <strong>Timeliness,</strong>{' '}
        <strong>Knowledge,</strong> and <strong>Teamwork</strong> after a shift! These will help
        highlight each professional&apos;s strengths, making it easier for offices to find and hire
        the <strong>best</strong> in the field.
      </>
    ),
  },
  {
    Icon: CompletedShiftBadgeSlideIcon,
    title: 'Introducing the Shifts Worked Badge!',
    description: (
      <>
        Professionals on GoTu can now earn a <strong>badge</strong> that showcases how many{' '}
        <strong>shifts</strong> they&apos;ve completed! This badge highlights their{' '}
        <strong>experience</strong> and dedication, helping them <strong>stand out</strong> to
        offices seeking reliable and seasoned pros.
      </>
    ),
  },
];

export function NewBadgeAnnouncementPopup() {
  const carouselRef = useRef(null);
  const user = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);
  const [activeItem, setActiveItem] = useState(0);
  const [showPopup, setShowPopup] = useState(false);

  const isLastSlide = activeItem === slides.length - 1;

  const getStorage = () => {
    const showNewBadgeAnnouncementPopUp = localStorage.getItem(
      `showNewBadgeAnnouncementPopUp-${user?.id}`,
    );

    if (showNewBadgeAnnouncementPopUp === null) {
      setShowPopup(true);
    }
  };

  const closePopup = () => {
    localStorage.setItem(`showNewBadgeAnnouncementPopUp-${user?.id}`, 'false');
    setShowPopup(false);
  };

  useEffect(() => {
    getStorage();
  }, [user]);

  const renderItem = useCallback(({ item }) => {
    const { Icon, title, description } = item || {};

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '0 39px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 250,
            width: 250,
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <img
            src={Icon}
            alt="icon"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        </div>

        <div
          className="bold f-18"
          style={{
            color: Colors.primary_500,
            textAlign: 'center',
            marginTop: 20,
          }}
        >
          {title}
        </div>
        <div
          className="f-16"
          style={{
            color: Colors.neutral_500,
            textAlign: 'center',
            marginTop: 23,
          }}
        >
          {description}
        </div>
      </div>
    );
  }, []);

  return (
    showPopup && (
      <Portal>
        <div className="modal" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
          <motion.div
            onClick={(event) => event.stopPropagation()}
            className="modal_content global_font"
            style={{
              position: 'relative',
              padding: '35px 10px',
              width: '40vw',
              maxWidth: '647px',
              maxHeight: '624px',
            }}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div
              style={{
                top: 30,
                right: 30,
                position: 'absolute',
                cursor: 'pointer',
              }}
              onClick={closePopup}
            >
              <CloseIcon color={Colors.secondary_500} />
            </div>

            <div
              className="bold"
              style={{
                fontSize: '22px',
                color: Colors.primary_500,
              }}
            >
              New Announcements
            </div>

            <Carousel
              ref={carouselRef}
              data={slides}
              renderItem={renderItem}
              onSnapToItem={(index) => setActiveItem(index)}
              containerCustomStyle={{ height: '55vh', maxHeight: '624px', marginTop: 10 }}
            />

            <Button
              style={{ marginTop: 20 }}
              size="small"
              text={isLastSlide ? 'Close' : 'Next'}
              onClick={() => {
                if (isLastSlide) {
                  closePopup();

                  return;
                }

                carouselRef?.current?.goToNext?.();
              }}
            />
          </motion.div>
        </div>
      </Portal>
    )
  );
}
