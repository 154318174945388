import lottie from "lottie-web";
import { useEffect, useRef } from "react";
import thumbUpPath from "./json/thumb-up.json";

export const LottieThumbUp = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: containerRef.current,
      loop: false, 
      autoplay: true, 
      animationData: thumbUpPath,
    });

    // Replay the animation after it completes with a delay
    anim.addEventListener("complete", () => {
      setTimeout(() => {
        anim.goToAndPlay(0); // Restart animation
      }, 1000)
    });

    return () => {
      anim.destroy();
    };
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        width: "152px", 
        height: "152px", 
      }}
    />
  );
};