import ArrowRightIcon from '../images/icons/ArrowRight';
import { Colors } from '../themes/colors';
import { Center } from './Center';

export default function WarningBanner({
  description,
  containerStyle,
  iconStyle,
  descriptionStyle,
  iconColor,
  primaryColor = Colors.error_500,
  secondaryColor = '#FEF9F2',
  onClick,
  noIcon = false,
  showRightArrow = false,
}) {
  return (
    <div
      className="warning-banner-container"
      style={{
        display: 'flex',
        marginBottom: 20,
        backgroundColor: secondaryColor,
        border: `1px solid ${primaryColor}`,
        animation: 'fadeIn 1s ease-in-out',
        cursor: onClick ? 'pointer' : 'default',
        ...containerStyle,
      }}
      onClick={() => onClick && onClick()}
    >
      {!noIcon && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            alignSelf: 'center',
            marginLeft: 12,
            ...iconStyle,
          }}
        >
          <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.90822 1.71858C8.82575 0.0874181 11.1743 0.0874173 12.0918 1.71858L18.7882 13.6232C19.6881 15.2231 18.532 17.1999 16.6964 17.1999H3.30364C1.46806 17.1999 0.311944 15.2231 1.21186 13.6232L7.90822 1.71858ZM11.1999 13.6C11.1999 14.2627 10.6626 14.8 9.9999 14.8C9.33716 14.8 8.7999 14.2627 8.7999 13.6C8.7999 12.9373 9.33716 12.4 9.9999 12.4C10.6626 12.4 11.1999 12.9373 11.1999 13.6ZM9.9999 3.99999C9.33716 3.99999 8.7999 4.53725 8.7999 5.19999V8.79999C8.7999 9.46274 9.33716 9.99999 9.9999 9.99999C10.6626 9.99999 11.1999 9.46274 11.1999 8.79999V5.19999C11.1999 4.53725 10.6626 3.99999 9.9999 3.99999Z"
              fill={iconColor || primaryColor}
            />
          </svg>
        </div>
      )}
      <p
        className="global_font"
        style={{
          color: primaryColor,
          margin: '8px 12px 8px 10px',
          ...descriptionStyle,
        }}
      >
        {description}
      </p>

      {showRightArrow && (
        <Center>
          <ArrowRightIcon color={primaryColor} />
        </Center>
      )}
    </div>
  );
}
