import { Children } from 'react';
import { Tab } from './Tab';

export const TabList = ({ children, setActiveTab, activeTab }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 20,
    }}
  >
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginBottom: 20,
        gap: 40,
      }}
    >
      {Children.map(children, (child, index) => {
        if (child.type.displayName === 'Tab') {
          return (
            <Tab
              isActive={activeTab === index}
              setActiveTab={() => setActiveTab(index)}
              {...child.props}
            />
          );
        }
        return null;
      })}
    </div>
  </div>
);

TabList.displayName = 'TabList';
