import { Colors } from '../../themes/colors';

export const AtIcon = ({ color, size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Solid/at-symbol">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0912 6.90873C14.2794 4.09696 9.7206 4.09696 6.90882 6.90873C4.09705 9.72051 4.09705 14.2793 6.90882 17.0911C9.35273 19.535 13.119 19.8556 15.9083 18.0489C16.4646 17.6886 17.2076 17.8474 17.5679 18.4036C17.9282 18.9599 17.7694 19.7029 17.2131 20.0632C13.4933 22.4727 8.47271 22.0491 5.21177 18.7881C1.46274 15.0391 1.46274 8.96071 5.21177 5.21168C8.9608 1.46264 15.0392 1.46264 18.7882 5.21168C20.6625 7.08594 21.6 9.54494 21.6 11.9999C21.6 13.9881 19.9882 15.5999 18 15.5999C17.1896 15.5999 16.4418 15.3321 15.8401 14.8802C14.9644 16.0459 13.5702 16.7999 12 16.7999C9.34903 16.7999 7.19999 14.6509 7.19999 11.9999C7.19999 9.34894 9.34903 7.1999 12 7.1999C14.651 7.1999 16.8 9.34894 16.8 11.9999C16.8 12.6626 17.3373 13.1999 18 13.1999C18.6627 13.1999 19.2 12.6626 19.2 11.9999C19.2 10.1554 18.4973 8.31483 17.0912 6.90873ZM14.4 11.9999C14.4 10.6744 13.3255 9.5999 12 9.5999C10.6745 9.5999 9.59999 10.6744 9.59999 11.9999C9.59999 13.3254 10.6745 14.3999 12 14.3999C13.3255 14.3999 14.4 13.3254 14.4 11.9999Z"
        fill={color || Colors.secondary_500}
      />
    </g>
  </svg>
);
