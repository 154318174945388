import CustomSpinner from './CustomSpinner';

export default function CardSpinner() {
  return (
    <div className="component_container">
      <div className="floating_container">
        <CustomSpinner />
      </div>
    </div>
  );
}
