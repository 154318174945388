import React, { useEffect } from 'react';

import BackButton from '../../commonComponents/BackButton';
import { Content } from './components/Content';
import { Title } from './components/Title';

export function WorkerClassification({ isRegistration }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="component_container">
      <div
        className="floating_container"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          paddingBottom: 0,
        }}
      >
        <div
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <div style={{ position: 'absolute', left: 60, top: 40 }}>
            <BackButton />
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              marginTop: 100,
              marginBottom: 20,
            }}
          >
            <Title isRegistration={isRegistration} />
          </div>
        </div>

        <div style={{ marginTop: 28 }}>
          <Content isRegistration={isRegistration} />
        </div>
      </div>
    </div>
  );
}
