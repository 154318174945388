import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Text } from '../../commonComponents';
import { Colors } from '../../themes/colors';
import { RemoteConfigUtils } from '../../utils/RemoteConfigUtils';

import { feedbackRequire, fetchDashboardInfo, fetchJobsWithAdjustmts } from '../../actions';
import WarningBanner from '../../commonComponents/WarningBanner';
import history from '../../history';
import '../../themes/inbox.scss';
import Inbox from '../Inbox';
import BillingCard from './BillingCard';
import ShiftsWidget from './ShiftsWidget';
import { TodayShiftCard } from './TodayShiftCard';
import TopButtons from './TopButtons';
import { SelectWorkerTypePopup } from '../../commonComponents/popups/SelectWorkerTypePopup/SelectWorkerTypePopup';

const Dashboard = () => {
  const dashboardInfo = useSelector((state) => state.job.dashboardInfo);
  const dispatch = useDispatch();
  const selectedOfficeId = useSelector((state) => state.user.selectedOfficeId);
  const user = useSelector((state) => state.user.user);
  const showBillingCard = dashboardInfo?.lastReceipt;

  useEffect(() => {
    dispatch(fetchDashboardInfo());
  }, [dispatch, selectedOfficeId]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 12,
          justifyContent: 'space-between',
          alignContent: 'start',
        }}
      >
        <TopButtons showLocumTenens={user?.address?.[0]?.state === 'FL'} />
        <div
          style={{
            display: 'flex',
            width: '100%',
            ...(showBillingCard && {
              display: 'grid',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 12,
              gridTemplateColumns: '2fr 1fr',
            }),
          }}
        >
          <ShiftsWidget
            activeCount={dashboardInfo?.activeRequest.toString()}
            scheduledCount={dashboardInfo?.scheduled.toString()}
            actionRequiredCount={dashboardInfo?.actionRequired.toString()}
          />
          {showBillingCard && <BillingCard lastReceipt={dashboardInfo?.lastReceipt} />}
        </div>

        {dashboardInfo?.todayShifts?.length > 0 && (
          <div>
            <TodayShiftCard />
          </div>
        )}

        <Inbox history={history} isRootComponent={false} />
      </div>
    </div>
  );
};

const WelcomeBanner = () => (
  <div
    style={{
      background: `linear-gradient(90deg, ${Colors.dashboard.welcomeBanner.gradient.start} 14%, ${Colors.dashboard.welcomeBanner.gradient.end} 100%)`,
      fontFamily: 'Nunito',
      height: '85px',
      overflow: 'hidden',
      position:'relative',
      width: '100%',
    }}
  >
    <Text
      color={Colors.dashboard.welcomeBanner.primary}
      fontSize={144}
      style={{
        bottom: 0,
        left: 0,
        letterSpacing: '-4px',
        opacity: 0.25,
        position: 'absolute',
        top: '-60px',
      }}
    >
      Welcome!
    </Text>
    <Text
      bold
      color={Colors.white}
      fontSize={70}
      style={{
        marginLeft: 24,
        position: 'relative',
      }}>
      Hey there!
    </Text>
  </div>
)

const dashboardRequestInterval = 1 * 60 * 60 * 1000; // 1 hour in milliseconds

export default () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.selectedChildOffice);
  const allowedRequest = user?.allowedRequest || {};

  const statesToDisableTempRequests = RemoteConfigUtils.getDisabledTempPostStates();
  const showBlockedStateWarning =
    !allowedRequest?.tempOrMdbShifts ||
    statesToDisableTempRequests?.includes(user?.address?.[0]?.state);

  const showWorkerClassificationWarning =
    user?.isWorkerClassificationEnabled && !user?.workerClassification?.classificationType;

  useEffect(() => {
    const prevDashboardRequestDate = localStorage.getItem(`prevDashboardRequestDate-${user.id}`);

    if (
      !prevDashboardRequestDate ||
      Date.now() - new Date(prevDashboardRequestDate).getTime() > dashboardRequestInterval
    ) {
      dispatch(feedbackRequire());
      dispatch(fetchJobsWithAdjustmts());

      localStorage.setItem(`prevDashboardRequestDate-${user.id}`, new Date().toISOString());
    }
  }, [dispatch, user.id]);

  return (
    <div
      className="component_container"
      style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
    >
      <WelcomeBanner />

      {showBlockedStateWarning && (
        <WarningBanner
          description="GoTu is not offering temporary services in your state as we work on building the best user experience for offices like you. However, “Permanent Hires” are still available!"
          containerStyle={{ width: '100%', alignItems: 'flex-start' }}
          indicatorStyle={{ display: 'none' }}
          iconStyle={{ marginTop: 10, marginLeft: 20, alignSelf: 'start' }}
          descriptionStyle={{ marginRight: 80 }}
        />
      )}

      {showWorkerClassificationWarning && (
        <WarningBanner
          description="Temp requests are unavailable until a worker type has been chosen for this office. Please click here to set a preference."
          containerStyle={{ alignItems: 'flex-start' }}
          indicatorStyle={{ display: 'none' }}
          onClick={() => history.push('/dashboard/worker-classification')}
          showRightArrow
        />
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: 12,
          background: '#FAFAFA',
          minWidth: 800,
        }}
      >
        <Dashboard />
      </div>

      <SelectWorkerTypePopup />
    </div>
  );
};
