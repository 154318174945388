import { call, put, take, all, fork } from 'redux-saga/effects';
import { getRedirectTarget } from 'utils/AdjustmentUtil';
import * as actions from '../actions';
import history from '../history';
import { apiMethods } from '../services/api';
import { apiMethodsConst } from '../services/methods';
import { store } from '../store/configureStore';

function* callAndDispatch(method, payload) {
  const response = yield call(apiMethods[method], payload);
  yield put({ type: method, payload: response });
}

function* fetchJobsWithAdjustmts() {
  const events = actions.createRequestTypes(actions.FETCH_JOBS_WITH_ADJUSTMENTS);
  while (true) {
    yield take(actions.FETCH_JOBS_WITH_ADJUSTMENTS);
    try {
      yield put({ type: events.REQUEST });

      yield* callAndDispatch(apiMethodsConst.FETCH_JOBS_WITH_ADJUSTMENTS_REQUEST);

      yield put({
        type: events.SUCCESS,
      });

      const job = store.getState().job.adjustments?.[0];
      const { id: jobId, adjustments } = job;
      if (!jobId || !adjustments) {
        return;
      }

      const redirectTarget = getRedirectTarget(adjustments);
      if (redirectTarget === 'adjustment') {
        history.push(`/dashboard/adjustments/${job?.id}`, {
          job_id: jobId,
        });
      }
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* changeStatusAdjustment() {
  const events = actions.createRequestTypes(actions.CHANGE_STATUS_ADJUSTMENT);
  while (true) {
    const { adjustmentId, status, disputeReason, jobId } = yield take(
      actions.CHANGE_STATUS_ADJUSTMENT,
    );
    try {
      yield put({ type: events.REQUEST });
      yield* callAndDispatch(apiMethodsConst.CHANGE_STATUS_ADJUSTMENTS_REQUEST, {
        adjustmentId,
        status,
        disputeReason,
        jobId,
      });

      const jobsWithAdjustments = store
        .getState()
        .job.adjustments.filter((job) => job.id !== jobId);

      if (jobsWithAdjustments.length > 0) {
        history.push(`/dashboard/adjustments/${jobsWithAdjustments[0].id}`, {
          job_id: jobsWithAdjustments[0].id,
        });
      } else {
        history.push('/');
      }
      yield put({
        type: events.SUCCESS,
        payload: jobId,
      });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

export default function* sagaAdjustments() {
  yield all([fork(fetchJobsWithAdjustmts), fork(changeStatusAdjustment)]);
}
