import { useEffect, useMemo } from "react"
import { useDispatch } from "react-redux"
import { setTotalReminders } from "../../../../../actions"
import { useLastShiftPosted } from "./useLastShiftPosted"
import { usePaymentExpiration } from "./usePaymentExpiration"
import { useProfileCompleteness } from "./useProfileCompleteness"

export const useReminder = () => {
  const dispatch = useDispatch()
  
  const {hasNotPosted, isLastPostMoreThan60Days} = useLastShiftPosted()

  const {isPaymentExpired, isPaymentExpiring} = usePaymentExpiration()

  const {isProfileFulfilled} = useProfileCompleteness()

  const reminders = useMemo(() => [
    {
      title: "Complete Profile",
      description: "Complete your account details to boost your chances of finding the best professionals!",
      redirectTo: '/account/information',
      isVisible: !isProfileFulfilled,
    },
    {
      title: "Post a Shift",
      description: "It’s been a while. Post a shift to find the right candidate fast!",
      redirectTo: '/dashboard/multi-day/info',
      isVisible: isLastPostMoreThan60Days,
    },
    {
      title: "Let's Get Started",
      description: "Welcome to GoTu! Post your first shift and connect with top candidates today.",
      redirectTo: '/dashboard/multi-day/info',
      isVisible: hasNotPosted,
    },
    {
      title: "Expired Payment Method",
      description: "Please update your default payment method so you can continue accepting candidates for your shifts.",
      redirectTo: '/account/payment',
      isVisible: isPaymentExpired,
    },
    {
      title: "Expiring Payment Method",
      description: "Payment method is about to expire. Please update your default payment information to confirm future professionals.",
      redirectTo: '/account/payment',
      isVisible: isPaymentExpiring,
    },
  ].
    filter(({isVisible}) => isVisible)
  , [
    hasNotPosted,
    isLastPostMoreThan60Days,
    isPaymentExpired,
    isPaymentExpiring,
    isProfileFulfilled,
  ])

  const totalReminders = reminders.length

  useEffect(() => {
    dispatch(setTotalReminders(totalReminders))
  }, [dispatch, totalReminders])

  return {
    reminders,
    totalReminders
  }
}