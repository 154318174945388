export const EmojiSoHappy = ({ selected = false }) => (
  <svg
    width="58"
    height="58"
    viewBox="0 0 58 58"
    fill="none"
    data-testid={`emoji-soHappy-${selected ? 'selected' : 'unselected'}`}
  >
    <path
      d="M29 50.75C41.0122 50.75 50.75 41.0122 50.75 29C50.75 16.9878 41.0122 7.25 29 7.25C16.9878 7.25 7.25 16.9878 7.25 29C7.25 41.0122 16.9878 50.75 29 50.75Z"
      stroke="#4FC0CF"
      strokeWidth="3.5"
      fill={selected ? '#4FC0CF' : '#FFFFFF'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38 35.5C36.5 45.5 21.5 45.5 20 35.5"
      stroke={selected ? '#FFFFFF' : '#4FC0CF'}
      strokeWidth="3.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.144 17.5343C19.4134 16.7052 20.5864 16.7052 20.8559 17.5343L21.8185 20.497C21.939 20.8678 22.2845 21.1188 22.6744 21.1188H25.7895C26.6614 21.1188 27.0239 22.2345 26.3185 22.747L23.7984 24.578C23.4829 24.8071 23.3509 25.2134 23.4714 25.5842L24.434 28.5468C24.7034 29.376 23.7544 30.0655 23.0491 29.553L20.5289 27.722C20.2135 27.4929 19.7863 27.4929 19.4709 27.722L16.9508 29.553C16.2454 30.0655 15.2964 29.376 15.5658 28.5468L16.5284 25.5842C16.6489 25.2134 16.5169 24.8071 16.2015 24.578L13.6813 22.747C12.976 22.2345 13.3385 21.1188 14.2103 21.1188H17.3254C17.7153 21.1188 18.0609 20.8678 18.1813 20.497L19.144 17.5343Z"
      fill={selected ? '#FFFFFF' : '#4FC0CF'}
    />
    <path
      d="M37.144 17.5343C37.4134 16.7052 38.5864 16.7052 38.8559 17.5343L39.8185 20.497C39.939 20.8678 40.2845 21.1188 40.6744 21.1188H43.7895C44.6614 21.1188 45.0239 22.2345 44.3185 22.747L41.7984 24.578C41.4829 24.8071 41.3509 25.2134 41.4714 25.5842L42.434 28.5468C42.7034 29.376 41.7544 30.0655 41.0491 29.553L38.5289 27.722C38.2135 27.4929 37.7863 27.4929 37.4709 27.722L34.9508 29.553C34.2454 30.0655 33.2964 29.376 33.5658 28.5468L34.5284 25.5842C34.6489 25.2134 34.5169 24.8071 34.2015 24.578L31.6813 22.747C30.976 22.2345 31.3385 21.1188 32.2103 21.1188H35.3254C35.7153 21.1188 36.0609 20.8678 36.1813 20.497L37.144 17.5343Z"
      fill={selected ? '#FFFFFF' : '#4FC0CF'}
    />
  </svg>
);
