import React from 'react';

import { Colors } from '../themes/colors';
import icon from '../images/unavalibleMobileImage.png';
import Button from './Button';

export default () => {
  const submitButtonPressed = () => {
    window.open('https://apps.apple.com/us/app/tempmee/id1437086894', '_blank');
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img src={icon} alt="image" style={{ marginTop: 50, marginBottom: 30, maxWidth: 300 }} />
      <text
        style={{
          fontFamily: 'Nunito',
          fontWeight: 'bold',
          fontSize: 18,
          textAlign: 'center',
          width: '80%',
          marginBottom: 40,
          color: Colors.neutral_600,
        }}
      >
        To continue with GoTu’s Dental Portal, come back on desktop or download the app.
      </text>
      <Button text="Download GoTu" onClick={() => submitButtonPressed()} />
    </div>
  );
};
