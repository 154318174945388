import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { Box, Column, Label, Row, Text } from '../../../../commonComponents';
import Checkcircle from '../../../../commonComponents/Checkcircle';
import { CollapsibleCard } from '../../../../commonComponents/CollapsibleCard';
import InputSelect from '../../../../commonComponents/InputSelect';
import { Colors } from '../../../../themes/colors';
import { useFormContext } from '../../hooks/useFormContext';
import { CardTitle } from '../CardTitle';
import { useParking } from './hooks/useParking/useParking';

export const ParkingCard = ({ isOpen, onToggle }) => {
  const {
    setValue,
    control,
  } = useFormContext();

  const { formValues, isValid, parkingCostInformation } = useParking(control);
  const allMetadata = useSelector((state) => state.user.allMetadata);

  return (
    <CollapsibleCard
      TitleComponent={() => <CardTitle name="Parking" showWarningIcon={!isOpen && !isValid} />}
      isOpen={isOpen}
      onToggle={onToggle}
    >
      <Column gap={20} paddingHorizontal={80} marginBottom={40}>
        <Column>
          <Label text="Parking:" error={!parkingCostInformation} />

          <Text color={Colors.neutral_500} marginBottom={16}>
            Free parking is available on site.
          </Text>

          <Box
            style={{ cursor: 'pointer' }}
            marginBottom={8}
            onClick={() => setValue('isParkingFree', true)}
          >
            <Row width={100} alignItems="center">
              <Checkcircle checked={formValues.isParkingFree} />
              <Text marginLeft={12}>Yes</Text>
            </Row>
          </Box>

          <Row
            marginBottom={22}
            style={{ cursor: 'pointer' }}
            onClick={() => setValue('isParkingFree', false)}
          >
            <Row width={100} alignItems="center">
              <Checkcircle checked={formValues.isParkingFree === false} />
              <Text marginLeft={12}>No</Text>
            </Row>

            <motion.div
              animate={formValues.isParkingFree === false ? 'show' : 'hide'}
              variants={opacityVariants}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Box
                alignItems="center"
                display="grid"
                gridTemplateColumns="1.05fr 2fr 2fr"
                style={{
                  maxWidth: '100%',
                  gridColumnGap: 12,
                  boxSizing: 'border-box',
                }}
              >
                <Text bold fontSize={18}>
                  Parking Cost:
                </Text>

                <Box>
                  <input
                    type="text"
                    className="input-field"
                    value={formValues.parkingCost ? `US$ ${formValues.parkingCost}` : ''}
                    placeholder="US$ --,--"
                    onChange={(e) => {
                      const value = e.target.value?.replace('US$ ', '');

                      if (/^\d+(\.\d{0,2})?$/.test(value) || value === '') {
                        setValue('parkingCost', value);
                      }
                    }}
                    onClick={(e) => {
                      e.currentTarget.value = '';
                      e.currentTarget.value = formValues.parkingCost
                        ? `US$ ${formValues.parkingCost}`
                        : '';
                    }}
                  />
                </Box>

                <Box width="100%">
                  <InputSelect
                    value={formValues.parkingCostType}
                    setValue={(value) => setValue('parkingCostType', value)}
                    options={[
                      { title: 'per hour', value: 'hr' },
                      { title: 'per day', value: 'day' },
                    ]}
                  />
                </Box>
              </Box>
            </motion.div>
          </Row>

          <Box width="100%">
            <Label text="Parking Type:" error={!formValues.parkingType} />

            <InputSelect
              value={formValues.parkingType}
              setValue={(value) => setValue('parkingType', value)}
              options={allMetadata?.parkingTypes?.map((item) => ({ title: item, value: item }))}
            />
          </Box>
        </Column>
      </Column>
    </CollapsibleCard>
  );
};

const opacityVariants = {
  show: { opacity: 1 },
  hide: { opacity: 0 },
};
