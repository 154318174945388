import moment from "moment";
import { getJobStatus } from "../../Dashboard/ReceiptList/utils";
import { StatusEnum } from "../../../utils/Enum";

export const jobCompletedByToday = (job) => {
  const todayStart = moment().utc().hours(0).minutes(0).seconds(0);
  const todayEnd = moment(todayStart).add(1, 'day');

  const { label } = getJobStatus(job?.status)
  
  const isCompletedStatus = label === StatusEnum.completed;
  const isNotCancelled = !job?.is_cancelled;
  const isCompletedToday = moment.utc(job?.job_datetime).isBetween(todayStart, todayEnd);

  return isCompletedStatus && isNotCancelled && isCompletedToday
}