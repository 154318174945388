import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ADD_FAVORITE_PROFESSIONAL,
  FETCH_HIGHLY_RATED_PROFESSIONALS_ACTION,
  FETCH_OPEN_JOBS_FOR_RECENT_PROFESSIONAL_ACTION,
  fetchHighlyRatedProfessionals,
} from '../../../../../actions';
import { createSuccessSelector } from '../../../../../api/selectors';
import useLoading from '../../../../../hooks/useLoading';

const actions = [
  FETCH_HIGHLY_RATED_PROFESSIONALS_ACTION,
  ADD_FAVORITE_PROFESSIONAL,
  FETCH_OPEN_JOBS_FOR_RECENT_PROFESSIONAL_ACTION,
];
const successAddToFavoritesSelector = createSuccessSelector([ADD_FAVORITE_PROFESSIONAL]);

export const useHighlyRatedList = () => {
  const { page, data, isActiveRequest, isFinal } = useSelector(
    (state) => state.user.highlyRatedProfessionals,
  );
  const dispatch = useDispatch();
  const { isLoading } = useLoading(actions);
  const isAddedToFavoritesSuccess = useSelector((state) => successAddToFavoritesSelector(state));
  const isScrollLoading = page > 1 && isActiveRequest;
  const filter = useSelector((state) => state.user.professionalHubFilter);

  useEffect(() => {
    dispatch(fetchHighlyRatedProfessionals({ page: 1, filter }));
  }, [dispatch, isAddedToFavoritesSuccess, filter]);

  const handleScrollList = useCallback(
    (event) => {
      const tableScrollHeight = event?.target?.scrollHeight - event?.target?.scrollTop - 20;
      const clientHeight = event?.target?.clientHeight;
      if (tableScrollHeight <= clientHeight && !isActiveRequest && !isFinal) {
        dispatch(
          fetchHighlyRatedProfessionals({
            page: page + 1,
            filter,
          }),
        );
      }
    },
    [dispatch, page, isActiveRequest, isFinal, filter],
  );

  return {
    data,
    isLoading: isLoading && (!data.length || page === 1),
    isScrollLoading,
    handleScrollList,
    isEmpty: !data?.length,
    isFiltered: filter !== 'all',
  };
};
