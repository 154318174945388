import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactSwitch from 'react-switch';

import { SIGN_UP_ACTION, signUp } from '../../../actions';
import { createLoadingSelector } from '../../../api/selectors';
import Button from '../../../commonComponents/Button';
import { validateEmail } from '../../../commonComponents/CommonFunctions';
import { InputPassword } from '../../../commonComponents/InputPassword';
import { Links } from '../../../constants';
import { Colors } from '../../../themes/colors';
import InputError from '../components/InputError';

export default function SignUp() {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const loadingSelector = createLoadingSelector([SIGN_UP_ACTION]);
  const isLoading = useSelector((state) => loadingSelector(state));

  const showEmailError = !validateEmail(email) && email.length > 0;
  const showPasswordError = password.length > 0 && password.length < 8;
  const showConfirmPasswordError = confirmPassword !== password && confirmPassword.length > 0;

  const disabledSubmit =
    !acceptedTerms ||
    email.indexOf(' ') >= 0 ||
    email.length <= 0 ||
    password.indexOf(' ') >= 0 ||
    password.length <= 0 ||
    confirmPassword.indexOf(' ') >= 0 ||
    confirmPassword.length <= 0;

  const createAccount = (e) => {
    e.preventDefault();

    if (showEmailError || showPasswordError || showConfirmPasswordError) {
      setSubmitError(true);

      return;
    }

    const data = {
      device_token: '',
      devicetype: 'web',
      password,
      email,
      referral_id: localStorage.getItem('referralId') || '',
    };

    dispatch(signUp(data));
  };

  return (
    <motion.form
      id="signupForm"
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      style={{
        width: '50%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
        marginTop: 20,
      }}
    >
      <h1 className="global_font bold" style={{ color: Colors.primary_500, marginBottom: 0 }}>
        Welcome!
      </h1>

      <p className="global_font f-dark" style={{ margin: 0 }}>
        Let’s get started! Create your account.
      </p>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          maxWidth: 430,
          marginBottom: 15,
        }}
      >
        <div style={{ position: 'relative', width: '100%', marginBottom: 20 }}>
          <p className="input_names" style={{ width: '100%', marginBottom: 8 }}>
            Email
          </p>
          <input
            id="email"
            type="text"
            className="input-field"
            placeholder="Enter your email"
            style={{ width: 'calc(100% - 40px)', margin: 0 }}
            onChange={(e) => setEmail(e.target.value)}
          />
          <InputError
            text="The email address entered is invalid."
            showError={submitError && showEmailError}
          />
        </div>

        <div style={{ position: 'relative', width: '100%', marginBottom: 20 }}>
          <p className="input_names" style={{ width: '100%', marginBottom: 8 }}>
            Password
          </p>
          <InputPassword
            id="password"
            placeholder="Enter your password"
            style={{ width: 'calc(100% - 40px)' }}
            onChange={(e) => setPassword(e.target.value)}
          />
          <InputError
            text="Password must contain 8 characters."
            showError={submitError && showPasswordError}
          />
        </div>

        <div style={{ position: 'relative', width: '100%' }}>
          <p className="input_names" style={{ width: '100%', marginBottom: 8 }}>
            Confirm Password
          </p>
          <InputPassword
            id="confirmPassword"
            placeholder="Confirm your password"
            style={{ width: 'calc(100% - 40px)' }}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <InputError
            text="Passwords do not match. Please try again."
            showError={submitError && showConfirmPasswordError}
          />
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          columnGap: 15,
          marginTop: 35,
        }}
      >
        <ReactSwitch
          checked={acceptedTerms}
          onChange={() => setAcceptedTerms(!acceptedTerms)}
          onColor={Colors.secondary_500}
          onHandleColor="#fff"
          handleDiameter={20}
          uncheckedIcon={false}
          checkedIcon={false}
          offColor={Colors.neutral_100}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.35)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          height={25}
          width={50}
          className="react-switch"
          id="material-switch"
        />

        <p className="global_font" style={{ margin: 0, color: Colors.neutral_600 }}>
          Agree to{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={Links.termsOfUse}
            className="title_font"
            style={{
              color: Colors.secondary_500,
              textDecoration: 'none',
              fontSize: 16,
            }}
          >
            Terms and Conditions
          </a>
        </p>
      </div>

      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          marginBottom: 100,
        }}
      >
        <Button
          isLoading={isLoading}
          disabled={disabledSubmit}
          type="submit"
          text="Sign Up"
          onClick={createAccount}
        />
      </div>
    </motion.form>
  );
}
