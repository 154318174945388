import { useEffect, useMemo, useState } from 'react';
import ToothIcon from '../../../../../images/tooth-icon.svg';
import { Colors } from '../../../../../themes/colors';
import { filterObjectKeys } from '../../../../../utils/Object';
import { RadioOption } from '../../../../registration/components/RadioOption';
import { CollapsibleRow } from '../CollapsibleRow/CollapsibleRow';
import { Title } from '../CollapsibleRow/Title';
import { Value } from '../CollapsibleRow/Value';
import { getShouldShowFrontDeskSpecialty } from '../../../../../growthbook';

export const SkillsRow = ({
  defaultSkill,
  onToggle,
  isOpen,
  onConfirm,
  specialtiesAndProcedures,
}) => {
  const [selectedSkill, setSelectedSkill] = useState(defaultSkill);

  const specialties = useMemo(() => {
    const filteredSpecialties = filterObjectKeys(specialtiesAndProcedures, 'specialty');
    const shouldShowFrontDeskSpecialty = getShouldShowFrontDeskSpecialty();

    if (!shouldShowFrontDeskSpecialty) {
      return Object.keys(filteredSpecialties).filter(
        (specialty) => specialty !== 'specialty_Front Desk',
      );
    }

    return Object.keys(filteredSpecialties);
  }, [specialtiesAndProcedures]);

  const getSkillName = (skill) => skill?.split('_')?.[1] || 'General';

  useEffect(() => {
    onConfirm({
      selectedSkill,
    });

    if (isOpen) {
      onToggle();
    }
  }, [selectedSkill]);

  useEffect(() => {
    setSelectedSkill(defaultSkill);
  }, [defaultSkill]);

  return (
    <CollapsibleRow
      isOpen={isOpen}
      onToggle={onToggle}
      TitleComponent={() => <Title text="Shift Specialty:" />}
      ValueComponent={() => (
        <Value
          customStyles={{
            color: Colors.neutral_600,
          }}
          text={getSkillName(selectedSkill)}
        />
      )}
      icon={ToothIcon}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          width: '100%',
        }}
      >
        <div
          style={{
            marginRight: 68,
          }}
        >
          {specialties.map((code) => (
            <div style={{ marginBottom: 10 }}>
              <RadioOption
                onClick={() => setSelectedSkill(code)}
                text={code.split('_')?.[1]}
                checked={code === selectedSkill}
              />
            </div>
          ))}
        </div>
      </div>
    </CollapsibleRow>
  );
};
