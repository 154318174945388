import { Colors } from '../../../../../themes/colors';

export const Value = ({ text, customStyles }) => (
  <span
    style={{
      fontFamily: 'Nunito',
      color: Colors.neutral_600,
      ...customStyles,
      maxWidth: 240,
      whiteSpace: 'nowrap',
      overflow: 'hidden', 
      textOverflow: 'ellipsis',
    }}
  >
    {text}
  </span>
);
