import { Colors } from '../../../themes/colors';

export const ContentLayout = ({ isRegistration, children }) => (
  <div
    style={{
      backgroundColor: Colors.white,
      width: 720,
      paddingTop: 60,
      paddingRight: 111,
      paddingLeft: 111,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      boxShadow: '0px 3px 10px 1px rgba(0, 0, 0, 0.15)',
      marginBottom: 33,
      ...(isRegistration && { padding: 0, width: 1200 }),
    }}
  >
    {children}
  </div>
);
