import React from 'react';
import { motion } from 'framer-motion';

import KnowledgeBadgeIcon from '../../images/eod/knowledge-badge-icon.svg';
import TimelinessBadgeIcon from '../../images/eod/timeliness-badge-icon.svg';
import TeamworkBadgeIcon from '../../images/eod/teamwork-badge-icon.svg';
import { Colors } from '../../themes/colors';

const BADGE_SIZE = 91;

export const RatingBadgesFeedback = ({ selectedBadges = [], onSelectBadge = () => {} }) => {
  const renderRatingBadge = ({ Icon, value, label }) => {
    const isSelected = selectedBadges.includes(value);

    return (
      <div
        style={{
          marginInline: 12,
          cursor: 'pointer',
        }}
        onClick={() => onSelectBadge(value)}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <motion.div
            style={{
              width: BADGE_SIZE,
              height: BADGE_SIZE,
              borderRadius: BADGE_SIZE / 2,
              backgroundColor: isSelected ? '#E9F7F8' : undefined,
              opacity: isSelected ? 1 : 0.3,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'baseline',
            }}
            whileHover={{ scale: 1.05 }}
          >
            <img src={Icon} alt={label} />
          </motion.div>
          <span className="f-18 f-dark" style={{ marginTop: 7, textAlign: 'center' }}>
            {label}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div>
        <h3
          className="f-20 f-dark"
          style={{
            marginTop: 62,
            textAlign: 'center',
          }}
        >
          Did anything stand out?{' '}
          <span style={{ fontWeight: 400, color: Colors.neutral_500 }}>(Optional)</span>
        </h3>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          marginTop: 10,
        }}
      >
        {renderRatingBadge({ Icon: KnowledgeBadgeIcon, value: 'knowledge', label: 'Knowledge' })}
        {renderRatingBadge({ Icon: TimelinessBadgeIcon, value: 'timeliness', label: 'Timeliness' })}
        {renderRatingBadge({ Icon: TeamworkBadgeIcon, value: 'teamwork', label: 'Teamwork' })}
      </div>
    </div>
  );
};
