import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SEND_FEEDBACK_TEMPMEE, sendTempmeeFeedback } from '../../actions';
import {
  Box,
  Button,
  CardSpinner,
  Center,
  Column,
  EmojiSelection,
  ScreenTitle,
  Text,
} from '../../commonComponents';
import useLoading from '../../hooks/useLoading';
import { Colors } from '../../themes/colors';

export const SendFeedback = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoading } = useLoading([SEND_FEEDBACK_TEMPMEE]);
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const [feedbackNote, setFeedbackNote] = useState('');

  const handleSubmit = () => {
    dispatch(sendTempmeeFeedback({ message: `${selectedEmoji} ${feedbackNote}` }));
  };

  if (isLoading) {
    return <CardSpinner />;
  }

  return (
    <Box className="component_container">
      <Column className="floating_container">
        <ScreenTitle title="Send Feedback" onBackClick={() => history.goBack()} />

        <Column
          marginHorizontal={48}
          marginBottom={40}
          paddingBottom={40}
          backgroundColor={Colors.neutral_50}
          paddingHorizontal={32}
          paddingTop={32}
        >
          <Column
            paddingTop={32}
            paddingBottom={32}
            backgroundColor={Colors.white}
            marginTop={20}
            style={{
              borderRadius: 8,
              boxShadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
            }}
          >
            <Center>
              <Text fontSize={24} bold color={Colors.primary_500}>
                How do you rate GoTu?
              </Text>
              <Text textAlign="center" marginTop={20}>
                Let us know how we are doing! Your feedback helps make GoTu better for everyone.
                <br />
                Got thoughts? Share them with us below!
              </Text>

              <Box marginTop={40} marginBottom={32}>
                <EmojiSelection onSelect={({ title }) => setSelectedEmoji(title)} />
              </Box>

              <Column width="100%" marginTop={20}>
                <Text marginBottom={8} fontSize={18} bold>
                  Anything we could do better?
                </Text>
                <textarea
                  className="textarea-style"
                  style={{ maxWidth: 900, height: 80 }}
                  placeholder="Tell us what you like, or what you didn’t"
                  onChange={(e) => setFeedbackNote(e.target.value)}
                  value={feedbackNote}
                />
              </Column>
              <Text marginBottom={12} color={Colors.neutral_500} textAlign="center" marginTop={32}>
                For help with your account or posted jobs, contact your
                <br />
                regional account manager, or call (786) 460-2170.
              </Text>
            </Center>
          </Column>

          <Center marginTop={56}>
            <Button text="Send Feedback" disabled={!selectedEmoji} onClick={handleSubmit} />
          </Center>
        </Column>
      </Column>
    </Box>
  );
};
