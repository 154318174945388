import { useChatsUnreadCount } from "../Chat/hooks/useChatsUnreadCount";
import { useNotificationCount } from "../Notification/hooks/useNotificationCount";

export const useInboxCount = () => {
  const totalUnread = useChatsUnreadCount();
  const totalNotifications = useNotificationCount();

  const totalInbox = totalUnread + totalNotifications;

  return {
    formattedTotalInbox: totalInbox > 99 ? '99+' : totalInbox,
    totalInbox,
  }
};