import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Center, Row, Text } from '../../../../commonComponents';
import browserHistory from '../../../../history';
import activeStep from '../../../../images/progressBarMDB/activeStep.svg';
import firstStep from '../../../../images/progressBarMDB/greenSolidCircle.svg';
import inactiveStep from '../../../../images/progressBarMDB/inactiveStep.svg';
import { Colors } from '../../../../themes/colors';

const Step = ({ title, active, isFirstStep }) => (
  <Row
    style={{
      alignItems: 'center',
    }}
  >
    {isFirstStep && <img src={firstStep} alt={title} />}
    {!isFirstStep && active && <img src={activeStep} alt={title} />}
    {!isFirstStep && !active && <img src={inactiveStep} alt={title} />}
    <Text
      color={active ? Colors.neutral_600 : Colors.neutral_300}
      marginLeft={4}
    >
      {title}
    </Text>
  </Row>
);

export default ({ step, title = 'Temp Request / Re-Book', isHidden = false }) => {
  const jobDates = useSelector((state) => state.multiday.jobDates);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (jobDates === null && step > 1) {
      browserHistory.replace('/dashboard/multi-day/info');
    }
  }, [step, jobDates]);

  return (
    <Center marginBottom={32}>
      <Text bold fontSize={32} textAlign="center">
        {title}
      </Text>

      {!isHidden && (
        <Row
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            height: 30,
            gap: '4px',
            marginTop: 24,
          }}
        >
          <Step title="Shift Details" active isFirstStep />
          <Step title="Review and Confirm" active={step >= 2} />
        </Row>
      )}
    </Center>
  );
};
