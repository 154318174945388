import React from 'react';
import activeStep from '../images/progressBarMDB/activeStep.svg';
import firstStep from '../images/progressBarMDB/greenSolidCircle.svg';
import inactiveStep from '../images/progressBarMDB/inactiveStep.svg';

export const ProgressBar = ({ step, size }) => {
  const stepImages = Array.from({ length: size }, (_, index) => {
    if (index === 0) {
      return firstStep;
    }
    if (index <= step) {
      return activeStep;
    }
    return inactiveStep;
  });

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {stepImages.map((image, index) => (
        <img key={`step-${index + 1}`} src={image} alt={`step-${index + 1}`} />
      ))}
    </div>
  );
};
