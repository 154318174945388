import React from 'react';
import { Colors } from '../../themes/colors';

const TrashIcon = ({ color = Colors.primary_500, className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M19.875 6.44444L18.8993 19.9361C18.8152 21.099 17.8354 22 16.655 22H7.34502C6.16458 22 5.18484 21.099 5.10074 19.9361L4.125 6.44444M9.75 10.8889V17.5556M14.25 10.8889V17.5556M15.375 6.44444V3.11111C15.375 2.49746 14.8713 2 14.25 2H9.75C9.12868 2 8.625 2.49746 8.625 3.11111V6.44444M3 6.44444H21"
      stroke={color}
      strokeWidth="2.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TrashIcon;
