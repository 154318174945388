import React, { useEffect, useRef, useState } from 'react';
import HY_tooth from '../../images/HY_tooth.png';
import CloseIcon from '../../images/icons/CloseIcon';
import { Colors } from '../../themes/colors';
import '../../themes/disputeReasonPopup.scss';
import Button from '../../commonComponents/Button';
import { UserSubProfession } from '../../enums/UserProfession';

export const HygienistHeaderInfo = ({ job }) => (
  <div
    style={{
      backgroundColor: Colors.white,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: 10,
      marginBottom: 20,
    }}
  >
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {job?.offer_final_reciever?.profile_image ? (
        <img
          src={job?.offer_final_reciever?.profile_image}
          style={{
            width: 100,
            height: 100,
            borderRadius: 100,
            objectFit: 'cover',
          }}
          alt="profile_image"
        />
      ) : (
        <div className="default_circle_container">
          <img src={HY_tooth} alt="profile_image" width="50px" />
        </div>
      )}
      <div
        style={{
          alignItems: 'center',
          marginLeft: 25,
        }}
      >
        <p className="global_font bold_font" style={{ color: Colors.neutral_600 }}>
          {`${job?.offer_final_reciever?.first_name || ''} ${
            job?.offer_final_reciever?.last_name || ''
          }`}
        </p>
        <p className="global_font std_font" style={{ color: Colors.neutral_600 }}>
          {UserSubProfession.getName(job?.subProfession) || job?.professionName || ''}
        </p>
      </div>
    </div>
  </div>
);

export const renderLine = ({ title, value, isAdjustedField, isOldFied, timezone }) => (
  <div style={{ width: '100%' }}>
    <div
      style={{
        padding: '10px 30px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            fontSize: 18,
            fontFamily: 'Nunito',
            fontWeight: 'bold',
            marginBottom: 7,
            marginTop: 7,
            display: 'flex',
            color: !isAdjustedField ? Colors.neutral_600 : Colors.primary_700,
            alignItems: 'center',
          }}
        >
          {title}
          {timezone && (
            <div
              style={{
                width: 33,
                height: 16,
                backgroundColor: Colors.neutral_300,
                borderRadius: 100,
                marginLeft: 10,
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: 12,
                  fontWeight: 'bold',
                  color: 'white',
                  textAlign: 'center',
                }}
              >
                EDT
              </p>
            </div>
          )}
        </div>
        <div
          style={{
            fontSize: 18,
            color: Colors.neutral_600,
            fontFamily: 'Nunito',
            textDecoration: isOldFied ? 'line-through' : 'none',
          }}
        >
          {value}
        </div>
      </div>
    </div>

    <div
      style={{
        height: 1,
        backgroundColor: Colors.neutral_100,
      }}
    />
  </div>
);

export const DisputeReasonModal = ({ closeModal, submitDisputeReasonMessage }) => {
  const [disputeReasonMessage, setDisputeReasonMessage] = useState('');
  const wrapperRef = useRef(null);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closeModal();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  return (
    <>
      <div className="overlay" />
      <div id="diputeReasonModal">
        <div className="modal_content" ref={wrapperRef}>
          <span className="close_button" onClick={closeModal}>
            <CloseIcon />
          </span>
          <h1>Reason For Dispute</h1>
          <div
            style={{
              width: 425,
              marginLeft: 35,
              marginRight: 35,
              fontFamily: 'Nunito',
              fontSize: '16px',
              fontStyle: 'italic',
              fontHeight: 400,
              lineHeight: '22px',
              letterSpacing: 0,
              textAlign: 'center',
              color: Colors.neutral_500,
              marginBottom: 70,
            }}
          >
            Please explain in detail the reason for your dispute and the time you think is fair so
            we can best assist you. Upon submission, our Adjustment team will resolve this dispute
            within 24 hours and release payment accordingly.
          </div>
          <textarea
            type="text"
            value={disputeReasonMessage}
            placeholder="Reason for adjustment"
            onChange={(e) => setDisputeReasonMessage(e.target.value)}
          />
          <div className="button_container">
            <Button
              text="Submit"
              size="small"
              disabled={!disputeReasonMessage}
              onClick={() => submitDisputeReasonMessage(disputeReasonMessage)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
