import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectJob } from '../../../../actions';
import { trackEvent } from '../../../../api/analytics';
import { DotsMenu, MenuItem } from '../../../../commonComponents/DotsMenu';
import {
  SendToFavoritesModal,
  SendToRecentProfessionalsModal,
} from '../../../../commonComponents/SendToModal';
import HeartIcon from '../../../../images/icons/Heart';
import OutlineCalendarIcon from '../../../../images/icons/OutlineCalendarIcon';
import ShareIcon from '../../../../images/share-icon-blue.svg';
import { Colors } from '../../../../themes/colors';

export const SendTo = ({ item, listener }) => {
  const dispatch = useDispatch();
  const [showInviteFavoritesPopup, setShowInviteFavoritesPopup] = useState(false);
  const [showInviteRecentProfessionalsPopup, setShowInviteRecentProfessionalsPopup] =
    useState(false);

  const handleSelectJob = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(selectJob(item));
  };

  const sendToFavorites = (event) => {
    handleSelectJob(event);
    setShowInviteFavoritesPopup(true);
  };

  const sendToRecentProfessionals = (event) => {
    handleSelectJob(event);
    setShowInviteRecentProfessionalsPopup(true);
  };

  return (
    <td>
      <div
        style={{
          display: 'flex',
          height: '100%',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <DotsMenu position="bottom" iconSrc={ShareIcon} listener={listener}>
          <MenuItem
            Icon={() => <HeartIcon color={Colors.neutral_600} outline />}
            text="Favorite Professionals"
            onClick={sendToFavorites}
            listener={() => trackEvent(`Shifts- Send to favorite Button Clicked`, { 
              job: {
                jobId: item.id,
                profession: item.profession,
                rate: item.rate,
                shiftId: item.shiftId,
                totalHours: item.totalHours,
                local_date: item.local_date,
                local_end_time: item.local_end_time,
                local_start_time: item.local_start_time,
                local_timezone: item.local_timezone,
                lunch_break_time: item.lunch_break_time,
                lunch_final_break_time: item.lunch_final_break_time,
                specialty: item.specialty,
              }
            })}
          />
          <MenuItem
            Icon={() => <OutlineCalendarIcon color={Colors.neutral_600} />}
            text="Recent Professionals"
            onClick={sendToRecentProfessionals}
            listener={() => trackEvent(`Shifts - Send to recent Button Clicked`, { 
              job: {
                jobId: item.id,
                profession: item.profession,
                rate: item.rate,
                shiftId: item.shiftId,
                totalHours: item.totalHours,
                local_date: item.local_date,
                local_end_time: item.local_end_time,
                local_start_time: item.local_start_time,
                local_timezone: item.local_timezone,
                lunch_break_time: item.lunch_break_time,
                lunch_final_break_time: item.lunch_final_break_time,
                specialty: item.specialty,
              }
            })}
          />
        </DotsMenu>
      </div>

      {(showInviteFavoritesPopup || showInviteRecentProfessionalsPopup) && (
        <div onClick={(event) => event.stopPropagation()}>
          {showInviteFavoritesPopup && (
            <SendToFavoritesModal onClose={() => setShowInviteFavoritesPopup(false)} />
          )}
          {showInviteRecentProfessionalsPopup && (
            <SendToRecentProfessionalsModal
              onClose={() => setShowInviteRecentProfessionalsPopup(false)}
            />
          )}
        </div>
      )}
    </td>
  );
};
