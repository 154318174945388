import { EmptyScreen } from '../../../../commonComponents/EmptyScreen/EmptyScreen';
import { getEmptyFilteredScreen } from '../utils';

export const EmptyMessage = ({ type }) => {
  const { title, description, Icon } = getEmptyFilteredScreen(type);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 300,
      }}
    >
      <EmptyScreen
        Icon={Icon}
        title={title}
        description={description}
      />
    </div>
  );
};
