import { useSelector } from 'react-redux';
import { showProfessionalAttendanceBadges } from '../../../growthbook';
import WarningAlertIcon from '../../../images/warning_alert_orange.svg';
import { Colors } from '../../../themes/colors';
import { EducationUtils } from '../../../utils/EducationUtils';
import { OfficeTypeEnum } from '../../../utils/Enum';
import { useCandidate } from '../../CandidateList/hooks/useCandidate';
import { getCompanyMatchLabel } from '../../CandidateList/utils';
import { CandidateBadges } from '../../candidates/CandidateBadges';
import { DnDaProcedures } from './Procedures/DnDaProcedures';
import { RdhFdProcedures } from './Procedures/RdhFdProcedures';
import { WarningBanner } from './WarningBanner';

export const CandidateInfo = ({ candidate }) => {
  const parentOffice = useSelector((state) => state.user.user);
  const childOffice = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);
  const job = useSelector((state) => state.job.job);
  const { specialtyMatch, insuranceMismatch, userSpecialties } = useCandidate(
    childOffice,
    candidate,
    job,
  );

  const isDSO = childOffice?.office_info?.officeType === OfficeTypeEnum.DSO;
  const companyMatchLabel = getCompanyMatchLabel({
    averageRating: candidate?.average_rating,
    hasBadges: !!candidate?.badges?.length || candidate?.currentRank?.rank,
    hasWorkedWithDSO: candidate?.hasWorkedWithDSO,
    hasWorkedWithOffice: candidate?.hasWorkedWithOffice,
    isDSO,
    officeName: isDSO ? parentOffice?.dsoFullName : childOffice?.office_name,
  });

  return (
    <div style={{ width: '100%' }}>
      {showProfessionalAttendanceBadges() && <CandidateBadges candidate={candidate} />}
      <WarningBanner candidate={candidate} job={job} />

      <div
        style={{
          width: '100%',
          display: 'flex',
          columnGap: 5,
          justifyContent: 'space-between',
          marginTop: 4,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            rowGap: 20,
            width: '100%',
            padding: '16px 18px',
          }}
        >
          {companyMatchLabel && (
            <ProfessionalInfoSection
              title={companyMatchLabel.label}
              value={companyMatchLabel.text}
            />
          )}

          <ProfessionalInfoSection
            title="Years of Experience"
            value={candidate?.years_of_experience}
          />

          <ProfessionalInfoSection
            title="Specialty(ies)"
            value={EducationUtils.mapToRemoveSpecialtyPrefix(userSpecialties).join(', ')}
            showWarningIcon={!specialtyMatch || insuranceMismatch}
          />

          <div style={{ width: '100%' }}>
            <b style={{ color: Colors.neutral_600, fontSize: 20 }}>Experienced Procedures:</b>

            <RdhFdProcedures candidate={candidate} job={job} />

            <DnDaProcedures candidate={candidate} job={job} />
          </div>
        </div>
      </div>
    </div>
  );
};

const ProfessionalInfoSection = ({ title, value, showWarningIcon = false }) => (
  <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
      }}
    >
      <b style={{ color: Colors.neutral_600, fontSize: 20 }}>{title}:</b>
      {showWarningIcon ? <img src={WarningAlertIcon} alt="Warning Alert Icon" width={18} /> : null}
    </div>

    <span
      style={{
        fontSize: 16,
        color: Colors.neutral_600,
      }}
    >
      {value}
    </span>
  </div>
);
