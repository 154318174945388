import React from 'react';
import ArrowUpIcon from '../images/icons/ArrowUp';
import ArrowDownIcon from '../images/icons/ArrowDown';
import { Colors } from '../themes/colors';

const DropdownMenu = ({ children, isOpen, toggleDropdown }) => {
  const dropdownContentStyle = {
    position: 'absolute',
    width: '240px',
    top: '62px',
    backgroundColor: Colors.white,
    right: '-30px',
    boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
    opacity: isOpen ? '1' : '0',
    visibility: isOpen ? 'visible' : 'hidden',
    transition: 'opacity 0.5s ease-in-out, visibility 0.5s ease-in-out',
  };

  const toggleContentStyle = {
    display: 'flex',
    alignItems: 'center',
    color: isOpen ? Colors.success_500 : Colors.white,
  };

  return (
    <div style={{ position: 'relative' }}>
      <div
        onClick={toggleDropdown}
        type="button"
        style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}
      >
        <div style={toggleContentStyle}>
          <p
            style={{
              marginRight: '10px',
              fontSize: 18,
              fontWeight: 'bold',
              fontFamily: 'Nunito',
            }}
          >
            Explore GoTu
          </p>
          {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </div>
      </div>
      <div id="dropdown-content" style={dropdownContentStyle}>
        {children}
      </div>
    </div>
  );
};

export default DropdownMenu;
