import { trackEvent } from '../../../api/analytics';
import { fetchRecentProfessionals } from '../../../actions';
import { EmptyRecentProfessionals } from '../../../components/ProfessionalsHub/components/RecentProfessionalsList/EmptyRecentProfessionals';
import { MAX_RECENT_PROFESSIONALS } from '../../../components/ProfessionalsHub/components/RecentProfessionalsList/RecentProfessionalsList';
import { Colors } from '../../../themes/colors';
import { SendInviteToModal } from '../SendInviteToModal';
import { filterRecentProfessionals, mapRecentToRowProps } from '../utils';

export const SendToRecentProfessionalsModal = ({ onClose }) => (
  <SendInviteToModal
    fetchProfessionalsAction={fetchRecentProfessionals}
    professionalsSelector={(state) => state.user.recentProfessionals}
    filterFunction={filterRecentProfessionals}
    mapProfessionalToRowProps={mapRecentToRowProps}
    EmptyComponent={() => (
      <>
        <div
          style={{
            color: Colors.neutral_600,
            fontSize: 18,
            fontFamily: 'Nunito',
            textAlign: 'center',
            fontWeight: 'bold',
            marginBottom: 30,
          }}
        >
          No Recent Professionals Available
        </div>
        <EmptyRecentProfessionals />
      </>
    )}
    headerTitle="Recent Professionals"
    headerSubtitle={`
      Below is a list of the ${MAX_RECENT_PROFESSIONALS} 
      most recent professionals who have worked at your office.
    `}
    onClose={onClose}
    listener={(job, professionalsIds) => {
      trackEvent('Send to - Recent invite sent/submitted', {
        job: {
          professionalsIds,
          jobId: job.id,
          profession: job.profession,
          rate: job.rate,
          shiftId: job.shiftId,
          totalHours: job.totalHours,
          local_date: job.local_date,
          local_end_time: job.local_end_time,
          local_start_time: job.local_start_time,
          local_timezone: job.local_timezone,
          lunch_break_time: job.lunch_break_time,
          lunch_final_break_time: job.lunch_final_break_time,
          specialty: job.specialty,
        },
      });
    }}
  />
);
