import React, { useState, useRef, useEffect } from 'react';
import './PopoverWrapper.scss';
import { motion } from 'framer-motion';

export const Popover = ({ Target, Content, position = 'top' }) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const parentRef = useRef(null);
  const popoverRef = useRef(null);

  const togglePopover = () => {
    setIsPopoverVisible(!isPopoverVisible);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        !parentRef.current.contains(event.target)
      ) {
        setIsPopoverVisible(false);
      }
    };

    if (isPopoverVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isPopoverVisible]);

  const getPopoverStyles = () => {
    if (position === 'bottom') {
      return {
        top: 'calc(100% + 10px)',
        bottom: 'auto',
        transform: 'translateX(-50%)',
      };
    }
    if (position === 'top') {
      return {
        bottom: 'calc(100% + 10px)',
        top: 'auto',
        transform: 'translateX(-50%)',
      };
    }

    return {};
  };

  return (
    <div className="popover-container" ref={parentRef}>
      <button type="button" className="popover-target" onClick={togglePopover}>
        <Target />
      </button>

      <motion.div
        initial="hide"
        animate={isPopoverVisible ? 'show' : 'hide'}
        transition={{ duration: 0.25 }}
        variants={variants}
        className={`popover-box ${position}`}
        style={getPopoverStyles()}
        ref={popoverRef}
      >
        <div className="popover-content">
          <Content />
        </div>
      </motion.div>
    </div>
  );
};

const variants = {
  show: { opacity: 1, display: 'flex' },
  hide: { opacity: 0, transitionEnd: { display: 'none' } },
};
