import { motion } from 'framer-motion';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectJob } from '../../../../actions';
import { trackEvent } from '../../../../api/analytics';
import { SendToFavoritesModal } from '../../../../commonComponents/SendToModal';
import { UserSubProfession } from '../../../../enums/UserProfession';
import { Colors } from '../../../../themes/colors';
import { StatusEnum } from '../../../../utils/Enum';
import { getCalendarJobStatus } from '../../utils';
import { Date } from './components/Date';
import { Image } from './components/Image';

export const JobCard = ({ job, onCardClick }) => {
  const dispatch = useDispatch();
  const jobStatus = getCalendarJobStatus(job);
  const [showInviteFavoritesPopup, setShowInviteFavoritesPopup] = useState(false);

  const Title = () => (
    <>
      {[StatusEnum.today, StatusEnum.scheduled, StatusEnum.completed].includes(jobStatus.label) && (
        <span style={styles.personName}>
          {job.offer_final_reciever?.first_name} {job.offer_final_reciever?.last_name}
        </span>
      )}
      <span style={styles.professionName}>
        {UserSubProfession.getName(job?.subProfession) ?? job?.professionName}
      </span>
      {jobStatus.label === StatusEnum.open && job.autoFill && (
        <span style={styles.professionName}>Auto-Confirm: On</span>
      )}
    </>
  );

  return (
    <>
      <button
        style={{
          all: 'unset',
          cursor: 'pointer',
        }}
        type="button"
        onClick={() => onCardClick(job)}
      >
        <motion.div
          key={job.id}
          initial={{ opacity: 0, x: -10 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: Colors.neutral_50,
            borderRadius: 8,
            minHeight: 80,
            paddingLeft: 12,
            width: 822,
          }}
          whileHover={{
            boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
            transition: { duration: 0.3 },
          }}
        >
          <div
            style={{
              width: 60,
            }}
          >
            <Date
              backgroundColor={jobStatus.color}
              localDate={moment(job.local_date, 'ddd, MMM D, YYYY').format('YYYY-MM-DD')}
            />
          </div>
          <div
            style={{
              width: 200,
              marginLeft: 32,
              marginRight: 32,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Title />
          </div>

          <div
            style={{
              width: 160,
              display: 'flex',
              justifyContent: 'center',
              marginRight: 48,
            }}
          >
            <span style={{ ...styles.datetimeText }}>
              {`${job.local_start_time} - ${job.local_end_time}`}
            </span>
          </div>
          <div style={{ width: 48, display: 'flex', justifyContent: 'center' }}>
            <Image
              label={jobStatus.label}
              multipleCandidates={job?.multipleCounters}
              profilePicture={job?.offer_final_reciever?.profile_image}
              onShareIconClick={() => {
                dispatch(selectJob(job));
                trackEvent('Schedule - List View - Share icon Clicked', { 
                  job: {
                    jobId: job.id,
                    profession: job.profession,
                    rate: job.rate,
                    shiftId: job.shiftId,
                    totalHours: job.totalHours,
                    local_date: job.local_date,
                    local_end_time: job.local_end_time,
                    local_start_time: job.local_start_time,
                    local_timezone: job.local_timezone,
                    lunch_break_time: job.lunch_break_time,
                    lunch_final_break_time: job.lunch_final_break_time,
                    specialty: job.specialty,
                  }
                });
                setShowInviteFavoritesPopup(true);
              }}
            />
          </div>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <span style={{ ...styles.statusText, color: jobStatus.color }}>{jobStatus.label}</span>
          </div>
        </motion.div>
      </button>

      {showInviteFavoritesPopup && (
        <SendToFavoritesModal onClose={() => setShowInviteFavoritesPopup(false)} />
      )}
    </>
  );
};

const styles = {
  statusText: {
    fontFamily: 'Nunito',
    fontSize: 18,
    textTransform: 'uppercase',
  },
  datetimeText: {
    fontFamily: 'Nunito',
    fontSize: 16,
    textTransform: 'uppercase',
    color: Colors.neutral_600,
  },
  personName: {
    color: Colors.neutral_600,
    fontFamily: 'Nunito',
    fontSize: 18,
    fontWeight: '700',
  },
  professionName: {
    color: Colors.neutral_600,
    fontFamily: 'Nunito',
    fontSize: 16,
  },
};
