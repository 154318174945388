import { motion } from 'framer-motion';
import React from 'react';
import { Colors } from '../themes/colors';

import arrow from '../images/arrow_right.svg';

export const CollapsibleCard = ({
  title,
  TitleComponent,
  description,
  children,
  isOpen,
  onToggle,
}) => {
  const collapsableVariants = {
    open: {
      maxHeight: 2000,
      opacity: 1,
      marginTop: description ? -48 : 0,
      paddingBottom: 16,
    },
    closed: {
      maxHeight: 0,
      opacity: 0,
    },
  };

  const arrowVariants = {
    open: {
      rotate: -90,
    },
    closed: {
      rotate: 90,
    },
  };

  const opacityVariants = {
    show: {
      opacity: 1,
    },
    hide: {
      opacity: 0,
    },
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: Colors.white,
        borderRadius: 8,
        boxShadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
      }}
    >
      <button
        type="button"
        style={{
          height: '100%',
          all: 'unset',
          display: 'flex',
          justifyContent: 'space-between',
          cursor: 'pointer',
          padding: '32px 40px',
        }}
        onClick={(event) => {
          event.stopPropagation();
          onToggle();
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {TitleComponent ? (
              <TitleComponent />
            ) : (
              <span
                style={{
                  fontFamily: 'Nunito',
                  color: Colors.primary_500,
                  fontSize: 18,
                  fontWeight: 700,
                }}
              >
                {title}
              </span>
            )}
            {description && (
              <motion.span
                animate={isOpen ? 'hide' : 'show'}
                transition={{ duration: 0.4 }}
                variants={opacityVariants}
                style={{
                  fontFamily: 'Nunito',
                  marginTop: isOpen ? 4 : 0,
                  color: Colors.neutral_500,
                  fontSize: 16,
                  fontWeight: 400,
                  fontStyle: 'italic',
                }}
              >
                {description}
              </motion.span>
            )}
          </div>
        </div>
        <div>
          <motion.img
            initial="closed"
            animate={isOpen ? 'open' : 'closed'}
            transition={{ duration: 0.4 }}
            variants={arrowVariants}
            alt="arrow"
            src={arrow}
          />
        </div>
      </button>
      <motion.div
        initial="closed"
        animate={isOpen ? 'open' : 'closed'}
        transition={{ duration: 0.5 }}
        style={{
          overflow: isOpen ? 'visible' : 'hidden',
          height: 'auto',
          backgroundColor: Colors.white,
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
        }}
        variants={collapsableVariants}
      >
        {children}
      </motion.div>
    </div>
  );
};
