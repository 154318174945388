import { useState } from 'react';
import BackButton from '../../../../commonComponents/BackButton';
import CustomSpinner from '../../../../commonComponents/CustomSpinner';
import { EmptyScreen } from '../../../../commonComponents/EmptyScreen/EmptyScreen';
import { GridTable } from '../../../../commonComponents/GridTable/GridTable';
import { EmptyInvitesIcon } from '../../../../images/emptyScreenIcons';
import { Colors } from '../../../../themes/colors';
import { ExpandableInviteCard } from './ExpandableInviteCard';
import { useOfficeJobInvites } from './useOfficeJobInvites';

export const OfficeJobInvites = () => {
  const {
    jobs, handleScrollList, isLoading, isLoadingFirstPage,
  } = useOfficeJobInvites();
  const [openCardIndex, setOpenCardIndex] = useState(null);

  const handleCardToggle = (index) => {
    setOpenCardIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="component_container">
      <div
        className="floating_container"
        style={{
          flexDirection: 'column',
          justifyContent: 'flex-start',
          padding: 40,
        }}
      >
        <div style={{ position: 'absolute', zIndex: 1 }}>
          <BackButton />
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            alignItems: 'center',
          }}
        >
          <h1 className="global_font f-dark" style={{ alignSelf: 'center' }}>
            Invites
          </h1>
        </div>

        <div
          style={{
            backgroundColor: Colors.neutral_50,
            height: 600,
            marginTop: 20,
            padding: '40px 60px',
            overflowY: 'auto',
          }}
          onScroll={handleScrollList}
        >
          {isLoadingFirstPage ? (
            <CustomSpinner isLoading size={32} style={{ marginTop: 20 }} />
          ) : (
            <>
              {!jobs?.length && (
                <EmptyScreen
                  Icon={EmptyInvitesIcon}
                  title="No invitations have been sent!"
                  description={(
                    <>
                      Invite a favorite, highly-rated and/or those
                      <br />
                      professionals who have recently worked at your
                      <br />
                      office! Invite them back!
                    </>
                  )}
                />
              )}

              <div
                style={{
                  maxWidth: 860,
                  margin: 'auto',
                }}
              >
                {jobs?.length > 0 && (
                  <GridTable
                    data={jobs}
                    renderRow={(item) => (
                      <ExpandableInviteCard
                        job={item}
                        isOpen={openCardIndex === item?.id}
                        onToggle={() => handleCardToggle(item?.id)}
                      />
                    )}
                    headerTitles={['Shifts Date', 'Profession', 'Shifts Time']}
                    headerStyle={{
                      marginLeft: 80,
                    }}
                    isLoading={isLoading}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
