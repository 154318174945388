import { useState } from 'react';
import ReactSwitch from 'react-switch';
import { Center, Pressable, Row, Text } from '../../../../../commonComponents';
import { Colors } from '../../../../../themes/colors';
import { GuidelinesPopup } from './GuidelinesPopup';

export const GuideLinesSwitch = ({ onSwitch }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const handleSwitch = () => {
    setAcceptedTerms(!acceptedTerms);
    onSwitch(!acceptedTerms);
  };

  return (
    <Center marginBottom={40}>
      <Row gap={16}>
        <ReactSwitch
          checked={acceptedTerms}
          onChange={handleSwitch}
          onColor={Colors.secondary_500}
          onHandleColor="#fff"
          handleDiameter={20}
          uncheckedIcon={false}
          checkedIcon={false}
          offColor={Colors.neutral_100}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.35)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          height={25}
          width={50}
          className="react-switch"
          id="material-switch"
        />
        <Row>
          <Text>
            I agree to{' '}
            <Pressable onPress={() => setShowPopup(true)}>
              <Text bold color={Colors.secondary_500}>
                GoTu’s Shift Guidelines
              </Text>
            </Pressable>
            .
          </Text>
        </Row>
      </Row>
      {showPopup && <GuidelinesPopup onClose={() => setShowPopup(false)} />}
    </Center>
  );
};
