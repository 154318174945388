import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteDefaultPayment, deletePayment } from '../../../actions';
import WarningIcon from '../../../images/icons/Warning';
import { Colors } from '../../../themes/colors';
import ConfirmPopup from '../../shared/ConfirmPopup';
import { addExpirationDate } from '../utils';
import { PaymentLine } from './PaymentLine';

export const DeletePaymentModal = ({ paymentToDelete, onClose }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);
  const allPayments = useSelector((state) => state.payments.allPayments);
  const isDefaultPaymentMethod = !user?.is_pre_approved && paymentToDelete?.is_selected;
  const cardList = allPayments
    ?.filter((item) => item.type === 'card')
    .map((item) => addExpirationDate(item))
    .filter((item) => item.id !== paymentToDelete?.id && !item.isExpired);
  const bankList = allPayments
    ?.filter((item) => item.type === 'bank_account')
    .filter((item) => item.id !== paymentToDelete?.id);
  const [newDefaultPaymentMethod, setNewDefaultPaymentMethod] = useState(null);

  const handleRadioButtonClick = (item) => {
    setNewDefaultPaymentMethod(item);
  };

  const handleDeleteButton = () => {
    if (isDefaultPaymentMethod) {
      dispatch(
        deleteDefaultPayment({
          oldPaymentId: paymentToDelete?.id,
          newPaymentId: newDefaultPaymentMethod?.id,
        }),
      );
    } else {
      dispatch(deletePayment({ paymentId: paymentToDelete?.id }));
    }
    onClose();
  };

  return (
    <ConfirmPopup
      Icon={() => (
        <div style={{ marginBottom: 20 }}>
          <WarningIcon width={80} height={80} color={Colors.warning_500} />
        </div>
      )}
      title={isDefaultPaymentMethod ? 'Delete Default Payment Method' : 'Delete Payment Method'}
      description={
        isDefaultPaymentMethod ? (
          <div
            style={{
              marginBottom: -40,
            }}
          >
            To prevent disruptions requesting shifts and services paid
            <br />
            with this card, update your default payment method.
            <div
              style={{
                width: '100%',
                marginTop: 16,
                overflowY: 'auto',
                maxHeight: 300,
              }}
            >
              <div
                style={{
                  paddingLeft: 100,
                }}
              >
                {cardList?.map((item) => (
                  <PaymentLine
                    key={item.id}
                    item={item}
                    handleRadioButtonClick={handleRadioButtonClick}
                    isSelected={newDefaultPaymentMethod?.id === item.id}
                    showActions={false}
                    showExpiration
                  />
                ))}
              </div>
              {cardList?.length > 0 && bankList?.length > 0 && (
                <div
                  style={{
                    height: 1,
                    backgroundColor: Colors.neutral_100,
                    margin: '20px 80px',
                  }}
                />
              )}
              <div
                style={{
                  paddingLeft: 100,
                }}
              >
                {bankList?.map((item) => (
                  <PaymentLine
                    key={item.id}
                    item={item}
                    handleRadioButtonClick={handleRadioButtonClick}
                    isSelected={newDefaultPaymentMethod?.id === item.id}
                    showActions={false}
                  />
                ))}
              </div>
            </div>
          </div>
        ) : (
          <>
            Are you sure you want to delete your save payment
            <br />
            method ending in <b>{paymentToDelete?.last4}</b>?
          </>
        )
      }
      descriptionStyle={{ width: '100%', marginBottom: 80 }}
      leftButtonText="Go Back"
      leftButtonAction={onClose}
      rightButtonText={isDefaultPaymentMethod ? 'Delete & Update' : 'Delete'}
      rightButtonAction={handleDeleteButton}
      rightButtonStyle={{
        width: 180,
        backgroundColor:
          isDefaultPaymentMethod && !newDefaultPaymentMethod
            ? Colors.primary_200
            : Colors.primary_500,
      }}
      rightButtonDisabled={isDefaultPaymentMethod && !newDefaultPaymentMethod}
      closePopup={onClose}
    />
  );
};
