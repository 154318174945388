import { Box, Column, Label, Row } from '../../../../commonComponents';
import { CollapsibleCard } from '../../../../commonComponents/CollapsibleCard';
import { useFormContext } from '../../hooks/useFormContext';
import { CardTitle } from '../CardTitle';
import { useNotes } from './hooks/useNotes';

export const NotesCard = ({ isOpen, onToggle }) => {
  const { setValue, control } = useFormContext();
  const { formValues } = useNotes(control);

  return (
    <CollapsibleCard
      TitleComponent={() => <CardTitle name="Notes" />}
      isOpen={isOpen}
      onToggle={onToggle}
    >
      <Column gap={20} paddingHorizontal={80} marginBottom={40}>
        <Box>
          <Row alignItems="baseline">
            <Label text="Notes for Temps:" marginBottom={8} required={false} />
          </Row>
          <textarea
            className="textarea-style"
            style={{
              width: '-webkit-fill-available',
              height: 80,
            }}
            placeholder="Ex: Check in at the front desk."
            value={formValues.notes}
            onChange={(e) => setValue('notes', e.target.value)}
          />
        </Box>
      </Column>
    </CollapsibleCard>
  );
};
