import InputSelect from '../InputSelect';

export const OptionField = ({ value, setValue, validation = {}, customStyle = {}, }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      width: 120,
      ...customStyle,
    }}
  >
    <span className="input_names" style={{ marginBottom: 8 }}>
      Title
    </span>

    <InputSelect
      value={value}
      setValue={(option) => setValue(option)}
      options={[
        { title: 'Dr.', value: 'Dr.' },
        { title: 'Mr.', value: 'Mr.' },
        { title: 'Mrs.', value: 'Mrs.' },
        { title: 'Ms.', value: 'Ms.' },
      ]}
      {...validation}
    />
  </div>
);
