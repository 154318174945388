import React from 'react';
import InputSelect from '../../../../commonComponents/InputSelect';

export const OptionField = ({ value, setValue }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      width: 120,
    }}
  >
    <span className="input_names" style={{ marginBottom: 8 }}>
      Title
    </span>

    <InputSelect
      value={value}
      setValue={(option) => setValue(option)}
      options={[
        { title: 'Dr.', value: 'Dr.' },
        { title: 'Mr.', value: 'Mr.' },
        { title: 'Mrs.', value: 'Mrs.' },
        { title: 'Ms.', value: 'Ms.' },
      ]}
    />
  </div>
);
