// api/selectors.js
import _ from 'lodash';

export const createLoadingSelector = (actions) => (state) =>
  // returns true only when all actions is not loading
  _(actions).some((action) => _.get(state, `loading.${action}`));

export const createErrorMessageSelector = (actions) => (state) =>
  // returns the first error messages for actions
  // * We assume when any request fails on a page that
  //   requires multiple API calls, we shows the first error
  _(actions)
    .map((action) => _.get(state, `error.${action}`))
    .compact()
    .first() || '';

export const createErrorObjectSelector = (actions) => (state) =>
  // returns the first error messages for actions
  // * We assume when any request fails on a page that
  //   requires multiple API calls, we shows the first error
  _(actions)
    .map((action) => ({
      error: _.get(state, `error.${action}`),
      action,
    }))
    .compact()
    .first() || null;

export const allErrorSelector = () => (state) =>
  _(Object.keys(state.error))
    .map((action) => ({
      message: _.get(state, `error.${action}`).message,
      action,
    }))
    .filter((obj) => obj.message && obj?.message !== 'No referesh token')
    .compact()
    .first() || null;

export const createSuccessSelector = (actions) => (state) =>
  // returns true only when all actions are successful
  _(actions).every((action) => _.get(state, `success.${action}`));
