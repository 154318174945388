export const CalendarOpenFilterIcon = () => (
  <svg
    width="172"
    height="171"
    viewBox="0 0 172 171"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="85.8278" cy="85.44" rx="85.8278" ry="85.44" fill="#EBF4F5" />
    <path
      d="M95.0231 132.002C93.0521 131.385 91.0877 130.782 89.4842 129.36C87.1282 127.271 85.7041 124.672 85.6626 121.55C85.5598 113.644 85.562 105.736 85.6451 97.8306C85.6954 93.2411 88.852 89.8592 93.3758 89.2204C93.6646 89.1789 93.9534 89.1417 94.3668 89.0848C94.3668 88.2273 94.3537 87.3632 94.369 86.4991C94.4127 83.8281 96.2722 81.8987 98.7703 81.9162C101.227 81.9337 103.06 83.8435 103.113 86.451C103.13 87.2866 103.115 88.1223 103.115 89.0432H118.867C118.867 88.2076 118.854 87.3479 118.869 86.4882C118.915 83.8238 120.786 81.8922 123.282 81.9162C125.738 81.9403 127.563 83.85 127.611 86.4619C127.626 87.3304 127.613 88.1988 127.613 89.1111C130.538 89.297 132.999 90.3317 134.591 92.7336C135.394 93.9433 135.788 95.4243 136.365 96.7828V122.596C136.298 122.755 136.199 122.911 136.164 123.079C135.554 125.892 134.211 128.305 131.78 129.863C130.317 130.799 128.576 131.304 126.959 132.004H95.0209L95.0231 132.002ZM94.3668 103.389V104.334C94.3668 109.689 94.3646 115.044 94.3668 120.399C94.3668 122.48 95.1324 123.252 97.1997 123.252C106.381 123.256 115.56 123.256 124.741 123.252C126.867 123.252 127.615 122.489 127.617 120.334C127.62 115.016 127.617 109.696 127.617 104.378C127.617 104.061 127.585 103.746 127.565 103.391H94.369L94.3668 103.389Z"
      fill="#EBF4F5"
    />
    <g opacity="0.8">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.0359 44.7236C54.842 44.7236 52.2528 47.3011 52.2528 50.4807V56.2377H46.4696C40.0817 56.2377 34.9033 61.3927 34.9033 67.7517V125.322C34.9033 131.681 40.0817 136.836 46.4696 136.836H115.867C122.255 136.836 127.434 131.681 127.434 125.322V67.7517C127.434 61.3927 122.255 56.2377 115.867 56.2377H110.084V50.4807C110.084 47.3011 107.495 44.7236 104.301 44.7236C101.107 44.7236 98.518 47.3011 98.518 50.4807V56.2377H63.8191V50.4807C63.8191 47.3011 61.2299 44.7236 58.0359 44.7236ZM58.0359 77.1557C54.842 77.1557 52.2528 79.7332 52.2528 82.9127C52.2528 86.0922 54.842 88.6697 58.0359 88.6697H104.301C107.495 88.6697 110.084 86.0922 110.084 82.9127C110.084 79.7332 107.495 77.1557 104.301 77.1557H58.0359Z"
        fill="#A4E0E0"
      />
    </g>
    <path
      d="M66.7118 51.1901V32M105.266 51.1901V32M63.1511 76.0373H108.272M48.6442 121.965H123.334C128.657 121.965 132.972 117.669 132.972 112.37V51.1901C132.972 45.8909 128.657 41.595 123.334 41.595H48.6442C43.321 41.595 39.0056 45.8909 39.0056 51.1901V112.37C39.0056 117.669 43.321 121.965 48.6442 121.965Z"
      stroke="#0B8397"
      strokeWidth="8.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <mask
      id="path-5-outside-1_9055_1680"
      maskUnits="userSpaceOnUse"
      x="96"
      y="90"
      width="55"
      height="55"
      fill="black"
    >
      <rect fill="white" x="96" y="90" width="55" height="55" />
      <path d="M132.363 142.599C127.359 142.599 127.122 136.926 126.913 131.92C126.681 126.387 126.324 122.76 123.583 122.76C120.842 122.76 120.486 126.387 120.254 131.92C120.045 136.926 119.808 142.599 114.804 142.599C109.629 142.599 106.024 133.015 106.024 124.413C106.024 121.83 104.753 120.193 103.144 118.12C101.201 115.618 99 112.783 99 107.88C99 97.6006 106.055 93 113.048 93C116.213 93 117.919 94.0702 119.289 94.9311C120.514 95.6993 121.481 96.3066 123.583 96.3066C125.686 96.3066 126.653 95.6993 127.877 94.9311C129.247 94.0702 130.954 93 134.119 93C141.111 93 148.167 97.6006 148.167 107.88C148.167 112.783 145.966 115.618 144.023 118.12C142.414 120.193 141.143 121.83 141.143 124.413C141.143 133.015 137.537 142.599 132.363 142.599ZM123.583 119.453C129.906 119.453 130.192 126.293 130.422 131.79C130.569 135.307 130.735 139.293 132.363 139.293C133.935 139.293 137.631 133.01 137.631 124.413C137.631 120.751 139.439 118.421 141.189 116.169C142.971 113.872 144.655 111.704 144.655 107.88C144.655 99.3377 138.98 96.3066 134.119 96.3066C132.017 96.3066 131.05 96.9139 129.825 97.6822C128.455 98.543 126.749 99.6132 123.583 99.6132C120.418 99.6132 118.712 98.543 117.342 97.6822C116.117 96.9139 115.15 96.3066 113.048 96.3066C108.187 96.3066 102.512 99.3377 102.512 107.88C102.512 111.704 104.196 113.872 105.978 116.169C107.728 118.421 109.536 120.751 109.536 124.413C109.536 133.01 113.232 139.293 114.804 139.293C116.432 139.293 116.598 135.307 116.744 131.79C116.975 126.293 117.261 119.453 123.583 119.453Z" />
    </mask>
    <path
      d="M132.363 142.599C127.359 142.599 127.122 136.926 126.913 131.92C126.681 126.387 126.324 122.76 123.583 122.76C120.842 122.76 120.486 126.387 120.254 131.92C120.045 136.926 119.808 142.599 114.804 142.599C109.629 142.599 106.024 133.015 106.024 124.413C106.024 121.83 104.753 120.193 103.144 118.12C101.201 115.618 99 112.783 99 107.88C99 97.6006 106.055 93 113.048 93C116.213 93 117.919 94.0702 119.289 94.9311C120.514 95.6993 121.481 96.3066 123.583 96.3066C125.686 96.3066 126.653 95.6993 127.877 94.9311C129.247 94.0702 130.954 93 134.119 93C141.111 93 148.167 97.6006 148.167 107.88C148.167 112.783 145.966 115.618 144.023 118.12C142.414 120.193 141.143 121.83 141.143 124.413C141.143 133.015 137.537 142.599 132.363 142.599ZM123.583 119.453C129.906 119.453 130.192 126.293 130.422 131.79C130.569 135.307 130.735 139.293 132.363 139.293C133.935 139.293 137.631 133.01 137.631 124.413C137.631 120.751 139.439 118.421 141.189 116.169C142.971 113.872 144.655 111.704 144.655 107.88C144.655 99.3377 138.98 96.3066 134.119 96.3066C132.017 96.3066 131.05 96.9139 129.825 97.6822C128.455 98.543 126.749 99.6132 123.583 99.6132C120.418 99.6132 118.712 98.543 117.342 97.6822C116.117 96.9139 115.15 96.3066 113.048 96.3066C108.187 96.3066 102.512 99.3377 102.512 107.88C102.512 111.704 104.196 113.872 105.978 116.169C107.728 118.421 109.536 120.751 109.536 124.413C109.536 133.01 113.232 139.293 114.804 139.293C116.432 139.293 116.598 135.307 116.744 131.79C116.975 126.293 117.261 119.453 123.583 119.453Z"
      fill="#EBF4F5"
    />
    <path
      d="M132.363 142.599C127.359 142.599 127.122 136.926 126.913 131.92C126.681 126.387 126.324 122.76 123.583 122.76C120.842 122.76 120.486 126.387 120.254 131.92C120.045 136.926 119.808 142.599 114.804 142.599C109.629 142.599 106.024 133.015 106.024 124.413C106.024 121.83 104.753 120.193 103.144 118.12C101.201 115.618 99 112.783 99 107.88C99 97.6006 106.055 93 113.048 93C116.213 93 117.919 94.0702 119.289 94.9311C120.514 95.6993 121.481 96.3066 123.583 96.3066C125.686 96.3066 126.653 95.6993 127.877 94.9311C129.247 94.0702 130.954 93 134.119 93C141.111 93 148.167 97.6006 148.167 107.88C148.167 112.783 145.966 115.618 144.023 118.12C142.414 120.193 141.143 121.83 141.143 124.413C141.143 133.015 137.537 142.599 132.363 142.599ZM123.583 119.453C129.906 119.453 130.192 126.293 130.422 131.79C130.569 135.307 130.735 139.293 132.363 139.293C133.935 139.293 137.631 133.01 137.631 124.413C137.631 120.751 139.439 118.421 141.189 116.169C142.971 113.872 144.655 111.704 144.655 107.88C144.655 99.3377 138.98 96.3066 134.119 96.3066C132.017 96.3066 131.05 96.9139 129.825 97.6822C128.455 98.543 126.749 99.6132 123.583 99.6132C120.418 99.6132 118.712 98.543 117.342 97.6822C116.117 96.9139 115.15 96.3066 113.048 96.3066C108.187 96.3066 102.512 99.3377 102.512 107.88C102.512 111.704 104.196 113.872 105.978 116.169C107.728 118.421 109.536 120.751 109.536 124.413C109.536 133.01 113.232 139.293 114.804 139.293C116.432 139.293 116.598 135.307 116.744 131.79C116.975 126.293 117.261 119.453 123.583 119.453Z"
      stroke="#EBF4F5"
      strokeWidth="4.4"
      mask="url(#path-5-outside-1_9055_1680)"
    />
    <mask
      id="path-6-outside-2_9055_1680"
      maskUnits="userSpaceOnUse"
      x="105.917"
      y="90.96"
      width="54"
      height="54"
      fill="black"
    >
      <rect fill="white" x="105.917" y="90.96" width="54" height="54" />
      <path d="M141.28 142.559C136.275 142.559 136.038 136.886 135.829 131.88C135.597 126.347 135.241 122.72 132.5 122.72C129.759 122.72 129.402 126.347 129.171 131.88C128.962 136.886 128.725 142.559 123.72 142.559C118.546 142.559 114.94 132.974 114.94 124.373C114.94 121.79 113.67 120.153 112.061 118.08C110.117 115.578 107.917 112.743 107.917 107.84C107.917 97.5606 114.972 92.96 121.964 92.96C125.13 92.96 126.836 94.0302 128.206 94.891C129.431 95.6593 130.397 96.2666 132.5 96.2666C134.602 96.2666 135.569 95.6593 136.794 94.891C138.164 94.0302 139.87 92.96 143.036 92.96C150.028 92.96 157.083 97.5606 157.083 107.84C157.083 112.743 154.882 115.578 152.939 118.08C151.33 120.153 150.059 121.79 150.059 124.373C150.059 132.974 146.454 142.559 141.28 142.559ZM132.5 119.413C138.823 119.413 139.109 126.253 139.339 131.75C139.485 135.267 139.651 139.253 141.28 139.253C142.851 139.253 146.548 132.97 146.548 124.373C146.548 120.711 148.356 118.381 150.105 116.129C151.887 113.832 153.571 111.664 153.571 107.84C153.571 99.2976 147.896 96.2666 143.036 96.2666C140.933 96.2666 139.966 96.8739 138.742 97.6421C137.372 98.503 135.665 99.5732 132.5 99.5732C129.335 99.5732 127.628 98.503 126.258 97.6421C125.034 96.8739 124.067 96.2666 121.964 96.2666C117.104 96.2666 111.429 99.2976 111.429 107.84C111.429 111.664 113.112 113.832 114.895 116.129C116.644 118.381 118.452 120.711 118.452 124.373C118.452 132.97 122.149 139.253 123.72 139.253C125.349 139.253 125.515 135.267 125.661 131.75C125.891 126.253 126.177 119.413 132.5 119.413Z" />
    </mask>
    <path
      d="M141.28 142.559C136.275 142.559 136.038 136.886 135.829 131.88C135.597 126.347 135.241 122.72 132.5 122.72C129.759 122.72 129.402 126.347 129.171 131.88C128.962 136.886 128.725 142.559 123.72 142.559C118.546 142.559 114.94 132.974 114.94 124.373C114.94 121.79 113.67 120.153 112.061 118.08C110.117 115.578 107.917 112.743 107.917 107.84C107.917 97.5606 114.972 92.96 121.964 92.96C125.13 92.96 126.836 94.0302 128.206 94.891C129.431 95.6593 130.397 96.2666 132.5 96.2666C134.602 96.2666 135.569 95.6593 136.794 94.891C138.164 94.0302 139.87 92.96 143.036 92.96C150.028 92.96 157.083 97.5606 157.083 107.84C157.083 112.743 154.882 115.578 152.939 118.08C151.33 120.153 150.059 121.79 150.059 124.373C150.059 132.974 146.454 142.559 141.28 142.559ZM132.5 119.413C138.823 119.413 139.109 126.253 139.339 131.75C139.485 135.267 139.651 139.253 141.28 139.253C142.851 139.253 146.548 132.97 146.548 124.373C146.548 120.711 148.356 118.381 150.105 116.129C151.887 113.832 153.571 111.664 153.571 107.84C153.571 99.2976 147.896 96.2666 143.036 96.2666C140.933 96.2666 139.966 96.8739 138.742 97.6421C137.372 98.503 135.665 99.5732 132.5 99.5732C129.335 99.5732 127.628 98.503 126.258 97.6421C125.034 96.8739 124.067 96.2666 121.964 96.2666C117.104 96.2666 111.429 99.2976 111.429 107.84C111.429 111.664 113.112 113.832 114.895 116.129C116.644 118.381 118.452 120.711 118.452 124.373C118.452 132.97 122.149 139.253 123.72 139.253C125.349 139.253 125.515 135.267 125.661 131.75C125.891 126.253 126.177 119.413 132.5 119.413Z"
      fill="#EBF4F5"
    />
    <path
      d="M141.28 142.559C136.275 142.559 136.038 136.886 135.829 131.88C135.597 126.347 135.241 122.72 132.5 122.72C129.759 122.72 129.402 126.347 129.171 131.88C128.962 136.886 128.725 142.559 123.72 142.559C118.546 142.559 114.94 132.974 114.94 124.373C114.94 121.79 113.67 120.153 112.061 118.08C110.117 115.578 107.917 112.743 107.917 107.84C107.917 97.5606 114.972 92.96 121.964 92.96C125.13 92.96 126.836 94.0302 128.206 94.891C129.431 95.6593 130.397 96.2666 132.5 96.2666C134.602 96.2666 135.569 95.6593 136.794 94.891C138.164 94.0302 139.87 92.96 143.036 92.96C150.028 92.96 157.083 97.5606 157.083 107.84C157.083 112.743 154.882 115.578 152.939 118.08C151.33 120.153 150.059 121.79 150.059 124.373C150.059 132.974 146.454 142.559 141.28 142.559ZM132.5 119.413C138.823 119.413 139.109 126.253 139.339 131.75C139.485 135.267 139.651 139.253 141.28 139.253C142.851 139.253 146.548 132.97 146.548 124.373C146.548 120.711 148.356 118.381 150.105 116.129C151.887 113.832 153.571 111.664 153.571 107.84C153.571 99.2976 147.896 96.2666 143.036 96.2666C140.933 96.2666 139.966 96.8739 138.742 97.6421C137.372 98.503 135.665 99.5732 132.5 99.5732C129.335 99.5732 127.628 98.503 126.258 97.6421C125.034 96.8739 124.067 96.2666 121.964 96.2666C117.104 96.2666 111.429 99.2976 111.429 107.84C111.429 111.664 113.112 113.832 114.895 116.129C116.644 118.381 118.452 120.711 118.452 124.373C118.452 132.97 122.149 139.253 123.72 139.253C125.349 139.253 125.515 135.267 125.661 131.75C125.891 126.253 126.177 119.413 132.5 119.413Z"
      stroke="#EBF4F5"
      strokeWidth="4"
      mask="url(#path-6-outside-2_9055_1680)"
    />
    <mask
      id="path-7-outside-3_9055_1680"
      maskUnits="userSpaceOnUse"
      x="110.625"
      y="94.75"
      width="35"
      height="37"
      fill="black"
    >
      <rect fill="white" x="110.625" y="94.75" width="35" height="37" />
      <path d="M134.527 131.375C131.092 131.375 130.929 127.3 130.785 123.705C130.626 119.73 130.382 117.125 128.5 117.125C126.618 117.125 126.374 119.73 126.215 123.705C126.071 127.3 125.908 131.375 122.473 131.375C118.921 131.375 116.446 124.491 116.446 118.313C116.446 116.458 115.574 115.282 114.47 113.793C113.136 111.996 111.625 109.959 111.625 106.438C111.625 99.0544 116.468 95.75 121.268 95.75C123.441 95.75 124.612 96.5187 125.553 97.137C126.393 97.6888 127.057 98.125 128.5 98.125C129.943 98.125 130.607 97.6888 131.448 97.137C132.388 96.5187 133.559 95.75 135.732 95.75C140.532 95.75 145.375 99.0544 145.375 106.438C145.375 109.959 143.864 111.996 142.53 113.793C141.426 115.282 140.554 116.458 140.554 118.313C140.554 124.491 138.079 131.375 134.527 131.375Z" />
    </mask>
    <path
      d="M134.527 131.375C131.092 131.375 130.929 127.3 130.785 123.705C130.626 119.73 130.382 117.125 128.5 117.125C126.618 117.125 126.374 119.73 126.215 123.705C126.071 127.3 125.908 131.375 122.473 131.375C118.921 131.375 116.446 124.491 116.446 118.313C116.446 116.458 115.574 115.282 114.47 113.793C113.136 111.996 111.625 109.959 111.625 106.438C111.625 99.0544 116.468 95.75 121.268 95.75C123.441 95.75 124.612 96.5187 125.553 97.137C126.393 97.6888 127.057 98.125 128.5 98.125C129.943 98.125 130.607 97.6888 131.448 97.137C132.388 96.5187 133.559 95.75 135.732 95.75C140.532 95.75 145.375 99.0544 145.375 106.438C145.375 109.959 143.864 111.996 142.53 113.793C141.426 115.282 140.554 116.458 140.554 118.313C140.554 124.491 138.079 131.375 134.527 131.375Z"
      fill="#A4E0E0"
    />
    <path
      d="M134.527 131.375C131.092 131.375 130.929 127.3 130.785 123.705C130.626 119.73 130.382 117.125 128.5 117.125C126.618 117.125 126.374 119.73 126.215 123.705C126.071 127.3 125.908 131.375 122.473 131.375C118.921 131.375 116.446 124.491 116.446 118.313C116.446 116.458 115.574 115.282 114.47 113.793C113.136 111.996 111.625 109.959 111.625 106.438C111.625 99.0544 116.468 95.75 121.268 95.75C123.441 95.75 124.612 96.5187 125.553 97.137C126.393 97.6888 127.057 98.125 128.5 98.125C129.943 98.125 130.607 97.6888 131.448 97.137C132.388 96.5187 133.559 95.75 135.732 95.75C140.532 95.75 145.375 99.0544 145.375 106.438C145.375 109.959 143.864 111.996 142.53 113.793C141.426 115.282 140.554 116.458 140.554 118.313C140.554 124.491 138.079 131.375 134.527 131.375Z"
      stroke="#A4E0E0"
      strokeWidth="0.19187"
      mask="url(#path-7-outside-3_9055_1680)"
    />
    <mask
      id="path-8-outside-4_9055_1680"
      maskUnits="userSpaceOnUse"
      x="100.917"
      y="86.96"
      width="55"
      height="55"
      fill="black"
    >
      <rect fill="white" x="100.917" y="86.96" width="55" height="55" />
      <path d="M137.28 139.559C132.275 139.559 132.038 133.886 131.829 128.88C131.597 123.347 131.241 119.72 128.5 119.72C125.759 119.72 125.402 123.347 125.171 128.88C124.962 133.886 124.725 139.559 119.72 139.559C114.546 139.559 110.94 129.974 110.94 121.373C110.94 118.79 109.67 117.153 108.061 115.08C106.117 112.578 103.917 109.743 103.917 104.84C103.917 94.5606 110.972 89.96 117.964 89.96C121.13 89.96 122.836 91.0302 124.206 91.891C125.431 92.6593 126.397 93.2666 128.5 93.2666C130.602 93.2666 131.569 92.6593 132.794 91.891C134.164 91.0302 135.87 89.96 139.036 89.96C146.028 89.96 153.083 94.5606 153.083 104.84C153.083 109.743 150.882 112.578 148.939 115.08C147.33 117.153 146.059 118.79 146.059 121.373C146.059 129.974 142.454 139.559 137.28 139.559ZM128.5 116.413C134.823 116.413 135.109 123.253 135.339 128.75C135.485 132.267 135.651 136.253 137.28 136.253C138.851 136.253 142.548 129.97 142.548 121.373C142.548 117.711 144.356 115.381 146.105 113.129C147.887 110.832 149.571 108.664 149.571 104.84C149.571 96.2976 143.896 93.2666 139.036 93.2666C136.933 93.2666 135.966 93.8739 134.742 94.6421C133.372 95.503 131.665 96.5732 128.5 96.5732C125.335 96.5732 123.628 95.503 122.258 94.6421C121.034 93.8739 120.067 93.2666 117.964 93.2666C113.104 93.2666 107.429 96.2976 107.429 104.84C107.429 108.664 109.112 110.832 110.895 113.129C112.644 115.381 114.452 117.711 114.452 121.373C114.452 129.97 118.149 136.253 119.72 136.253C121.349 136.253 121.515 132.267 121.661 128.75C121.891 123.253 122.177 116.413 128.5 116.413Z" />
    </mask>
    <path
      d="M137.28 139.559C132.275 139.559 132.038 133.886 131.829 128.88C131.597 123.347 131.241 119.72 128.5 119.72C125.759 119.72 125.402 123.347 125.171 128.88C124.962 133.886 124.725 139.559 119.72 139.559C114.546 139.559 110.94 129.974 110.94 121.373C110.94 118.79 109.67 117.153 108.061 115.08C106.117 112.578 103.917 109.743 103.917 104.84C103.917 94.5606 110.972 89.96 117.964 89.96C121.13 89.96 122.836 91.0302 124.206 91.891C125.431 92.6593 126.397 93.2666 128.5 93.2666C130.602 93.2666 131.569 92.6593 132.794 91.891C134.164 91.0302 135.87 89.96 139.036 89.96C146.028 89.96 153.083 94.5606 153.083 104.84C153.083 109.743 150.882 112.578 148.939 115.08C147.33 117.153 146.059 118.79 146.059 121.373C146.059 129.974 142.454 139.559 137.28 139.559ZM128.5 116.413C134.823 116.413 135.109 123.253 135.339 128.75C135.485 132.267 135.651 136.253 137.28 136.253C138.851 136.253 142.548 129.97 142.548 121.373C142.548 117.711 144.356 115.381 146.105 113.129C147.887 110.832 149.571 108.664 149.571 104.84C149.571 96.2976 143.896 93.2666 139.036 93.2666C136.933 93.2666 135.966 93.8739 134.742 94.6421C133.372 95.503 131.665 96.5732 128.5 96.5732C125.335 96.5732 123.628 95.503 122.258 94.6421C121.034 93.8739 120.067 93.2666 117.964 93.2666C113.104 93.2666 107.429 96.2976 107.429 104.84C107.429 108.664 109.112 110.832 110.895 113.129C112.644 115.381 114.452 117.711 114.452 121.373C114.452 129.97 118.149 136.253 119.72 136.253C121.349 136.253 121.515 132.267 121.661 128.75C121.891 123.253 122.177 116.413 128.5 116.413Z"
      fill="#0B8397"
    />
    <path
      d="M137.28 139.559C132.275 139.559 132.038 133.886 131.829 128.88C131.597 123.347 131.241 119.72 128.5 119.72C125.759 119.72 125.402 123.347 125.171 128.88C124.962 133.886 124.725 139.559 119.72 139.559C114.546 139.559 110.94 129.974 110.94 121.373C110.94 118.79 109.67 117.153 108.061 115.08C106.117 112.578 103.917 109.743 103.917 104.84C103.917 94.5606 110.972 89.96 117.964 89.96C121.13 89.96 122.836 91.0302 124.206 91.891C125.431 92.6593 126.397 93.2666 128.5 93.2666C130.602 93.2666 131.569 92.6593 132.794 91.891C134.164 91.0302 135.87 89.96 139.036 89.96C146.028 89.96 153.083 94.5606 153.083 104.84C153.083 109.743 150.882 112.578 148.939 115.08C147.33 117.153 146.059 118.79 146.059 121.373C146.059 129.974 142.454 139.559 137.28 139.559ZM128.5 116.413C134.823 116.413 135.109 123.253 135.339 128.75C135.485 132.267 135.651 136.253 137.28 136.253C138.851 136.253 142.548 129.97 142.548 121.373C142.548 117.711 144.356 115.381 146.105 113.129C147.887 110.832 149.571 108.664 149.571 104.84C149.571 96.2976 143.896 93.2666 139.036 93.2666C136.933 93.2666 135.966 93.8739 134.742 94.6421C133.372 95.503 131.665 96.5732 128.5 96.5732C125.335 96.5732 123.628 95.503 122.258 94.6421C121.034 93.8739 120.067 93.2666 117.964 93.2666C113.104 93.2666 107.429 96.2976 107.429 104.84C107.429 108.664 109.112 110.832 110.895 113.129C112.644 115.381 114.452 117.711 114.452 121.373C114.452 129.97 118.149 136.253 119.72 136.253C121.349 136.253 121.515 132.267 121.661 128.75C121.891 123.253 122.177 116.413 128.5 116.413Z"
      stroke="#0B8397"
      strokeWidth="4.4"
      mask="url(#path-8-outside-4_9055_1680)"
    />
  </svg>
);
