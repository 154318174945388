import { Colors } from '../../themes/colors';

export const CameraIcon = ({ color = Colors.white, size = 22 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Solid/camera">
      <path
        id="Icon"
        d="M2.7002 7.6998C2.7002 6.76092 3.46131 5.9998 4.4002 5.9998H6.14456C6.56891 5.9998 6.97587 5.83123 7.27593 5.53118L8.50938 4.29772C8.8282 3.97891 9.2606 3.7998 9.71146 3.7998H12.2889C12.7398 3.7998 13.1722 3.97891 13.491 4.29772L14.7245 5.53118C15.0245 5.83123 15.4315 5.9998 15.8558 5.9998H17.6002C18.5391 5.9998 19.3002 6.76092 19.3002 7.6998V16.4998C19.3002 17.4387 18.5391 18.1998 17.6002 18.1998H4.4002C3.46131 18.1998 2.7002 17.4387 2.7002 16.4998V7.6998ZM11.0002 15.8998C13.0989 15.8998 14.8002 14.1985 14.8002 12.0998C14.8002 10.0011 13.0989 8.2998 11.0002 8.2998C8.90151 8.2998 7.20019 10.0011 7.20019 12.0998C7.20019 14.1985 8.90151 15.8998 11.0002 15.8998Z"
        fill={color}
        stroke="white"
      />
    </g>
  </svg>
);
