import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmJob, declineCandidate } from '../../../actions';
import { trackEvent } from '../../../api/analytics';
import Button from '../../../commonComponents/Button';
import { isPartialShift } from '../../../commonComponents/CommonFunctions';
import { Colors } from '../../../themes/colors';
import CounterShiftPopup from '../../CounterShiftPopup';
import DeclineCandidatePopup from '../../DeclineCandidatePopup';

export const ConfirmOrDecline = ({ candidateId, notesForTemp, small = false }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);
  const officeCredentials = useSelector((state) => state.userCredentials.credentials);

  const [declineCandidatePopup, setDeclineCandidatePopup] = useState(false);
  const [counterShiftPopup, setCounterShiftPopup] = useState(false);
  const { job, allCandidates } = useSelector((state) => state.job);
  const currentCounter = allCandidates?.find((counter) => counter?.candidate?.id === candidateId);
  const isCounterHours = isPartialShift(currentCounter);

  const declineCandidatePressed = () => {
    setDeclineCandidatePopup(true);
  };

  const handleAcceptClicked = () => {
    setCounterShiftPopup(false);
    confirmOffer();
  };

  const handleCancelClicked = () => {
    setCounterShiftPopup(false);
  };

  const confirmOffer = () => {
    trackEvent('Confirm Candidate - Confirm Submitted', {
      hours: job?.totalHours,
      shiftId: job?.id,
      shiftSpecialty: job?.specialty,
      procedures: job?.procedures,
      breakTime: job?.lunch_break_time,
      autoConfirm: job?.autoFill,
      softwares: officeCredentials?.softwares,
      assistedHygiene: job?.isAssistedHygiene,
      tpp: job?.amount,
      candidate: {
        id: currentCounter?.candidate?.id,
        appliedDateTime: currentCounter?.created_at,
        yearOfExperience: currentCounter?.candidate?.years_of_experience,
        schoolName: currentCounter?.candidate?.school,
        graduationYear: currentCounter?.candidate?.graduationDate,
        skills: currentCounter?.candidate?.skills,
        badges: currentCounter?.candidate?.badges,
        rating: currentCounter?.candidate?.average_rating,
      },
    });

    dispatch(
      confirmJob({
        jobId: job.id,
        data: { notes: notesForTemp },
        counterOfferId: currentCounter?.id,
        candidateId: currentCounter?.candidate?.id,
      }),
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <Button
        text="Decline"
        style={{
          width: !small ? 152 : 120,
          height: !small ? 48 : 34,
          backgroundColor: Colors.secondary_500,
          marginRight: !small ? 20 : 12,
          marginTop: 0,
        }}
        onClick={declineCandidatePressed}
      />
      <Button
        text="Confirm"
        style={{
          width: !small ? 152 : 120,
          height: !small ? 48 : 34,
          marginTop: 0,
          backgroundColor: Colors.primary_500,
        }}
        onClick={() => {
          if (isCounterHours) {
            setCounterShiftPopup(true);
          } else {
            confirmOffer();
          }
        }}
      />

      {declineCandidatePopup && (
        <DeclineCandidatePopup
          declineBtnPressed={(cancellationReason) => {
            trackEvent('Confirm Candidate - Decline Submitted', {
              hours: job?.totalHours,
              shiftId: job?.id,
              shiftSpecialty: job?.specialty,
              procedures: job?.procedures,
              breakTime: job?.lunch_break_time,
              autoConfirm: job?.autoFill,
              softwares: user?.office_info?.softwares,
              assistedHygiene: job?.isAssistedHygiene,
              tpp: job?.amount,
              candidate: {
                id: currentCounter?.candidate?.id,
                appliedDateTime: currentCounter?.created_at,
                yearOfExperience: currentCounter?.candidate?.years_of_experience,
                schoolName: currentCounter?.candidate?.school,
                graduationYear: currentCounter?.candidate?.graduationDate,
                skills: currentCounter?.candidate?.skills,
                badges: currentCounter?.candidate?.badges,
                rating: currentCounter?.candidate?.average_rating,
              },
              declineReason: cancellationReason,
            });

            dispatch(
              declineCandidate({
                jobId: job.id,
                counterOfferId: currentCounter?.id,
                cancellation_reason: cancellationReason,
              }),
            );
            setDeclineCandidatePopup(false);
          }}
          goBackPressed={() => setDeclineCandidatePopup(false)}
        />
      )}

      {counterShiftPopup ? (
        <CounterShiftPopup
          job={job}
          currentCounter={currentCounter}
          acceptBtnPressed={handleAcceptClicked}
          cancelBtnPressed={handleCancelClicked}
        />
      ) : null}
    </div>
  );
};
