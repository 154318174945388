export const EmptyFavoritesListIcon = () => (
  <svg
    width="172"
    height="171"
    viewBox="0 0 172 171"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="85.8278" cy="85.44" rx="85.8278" ry="85.44" fill="#EBF4F5" />
    <path
      d="M110.332 50.9663C110.332 63.1245 100.476 72.9805 88.3183 72.9805C76.1602 72.9805 66.3041 63.1245 66.3041 50.9663C66.3041 38.8082 76.1602 28.9521 88.3183 28.9521C100.476 28.9521 110.332 38.8082 110.332 50.9663Z"
      fill="#A4E0E0"
    />
    <path
      d="M79 83C86.2232 82.2055 93.6388 81.704 101 87.5C101 87.5 99.0914 106.195 96.7827 117.395L103.5 124.733H41.5864C41.5864 124.733 35.6728 140.29 41.5864 117.395C47.5 94.5 65.1796 84.5202 79 83Z"
      fill="#A4E0E0"
    />
    <path
      d="M136.865 84.9177C130.887 78.9275 121.176 78.9275 115.208 84.9082L113.501 86.6211L111.794 84.9082C105.826 78.9275 96.115 78.9275 90.1373 84.9177C85.0082 90.1425 84.1691 98.2763 87.8368 107.243C92.2022 117.931 103.262 129.453 113.501 129.453C123.74 129.453 134.79 117.922 139.156 107.243C142.833 98.2763 141.994 90.1425 136.865 84.9177Z"
      fill="#EBF4F5"
    />
    <g opacity="0.8">
      <path
        d="M136.68 96.7626C131.984 92.0843 124.353 92.0843 119.664 96.7552L118.323 98.0929L116.982 96.7552C112.292 92.0843 104.662 92.0843 99.9651 96.7626C95.9351 100.843 95.2757 107.196 98.1575 114.198C101.588 122.546 110.277 131.544 118.323 131.544C126.368 131.544 135.051 122.538 138.481 114.198C141.37 107.196 140.71 100.843 136.68 96.7626Z"
        fill="#A4E0E0"
      />
    </g>
    <path
      d="M141.229 87.9336C135.965 82.6902 127.413 82.6902 122.157 87.9253L120.654 89.4246L119.151 87.9253C113.896 82.6902 105.343 82.6902 100.079 87.9336C95.5624 92.507 94.8234 99.6267 98.0533 107.475C101.898 116.831 111.637 126.916 120.654 126.916C129.671 126.916 139.402 116.823 143.247 107.475C146.485 99.6267 145.746 92.507 141.229 87.9336Z"
      stroke="#0B8397"
      strokeWidth="9"
    />
    <path
      d="M86.1886 20C87.8664 20.3041 89.5815 20.4725 91.2134 20.9363C103.107 24.3182 110.654 32.0271 113.135 44.1351C116.427 60.207 105.723 75.907 89.6777 78.882C86.5123 79.4683 83.231 79.442 80.0022 79.687C73.8618 80.1508 68.0539 81.8199 62.5653 84.574C54.5699 88.586 48.1538 94.3764 43.3237 101.91C40.1868 106.804 38.0627 112.117 36.8639 117.796C36.3192 120.371 37.9861 122.733 40.5849 123.16C41.1209 123.248 41.6721 123.25 42.219 123.25C53.5527 123.254 69.8841 123.261 81.2178 123.245C82.6287 123.245 83.8953 123.552 84.9038 124.58C87.0038 126.719 86.3585 130.272 83.6437 131.569C83.32 131.724 82.9875 131.86 82.6572 132.004H39.594C39.4321 131.936 39.2768 131.834 39.1083 131.805C34.8164 131.04 31.5919 128.743 29.5838 124.893C28.8575 123.501 28.5163 121.911 28 120.41C28 119.317 28 118.223 28 117.129C28.5928 114.887 29.0631 112.603 29.7982 110.407C35.1576 94.4092 45.4544 82.7846 60.7956 75.6904C62.5019 74.9007 64.2825 74.2707 66.1354 73.5247C65.7023 73.155 65.3391 72.8575 64.9869 72.5469C56.3505 64.9518 52.658 55.4163 54.8914 44.1439C57.2934 32.0205 64.8929 24.316 76.7887 20.9341C78.4184 20.4725 80.1357 20.3041 81.8135 20C83.2726 20 84.7295 20 86.1886 20ZM63.0028 49.7221C62.9744 61.3554 72.3939 70.6897 84.2176 70.74C95.4812 70.7881 104.942 61.2701 104.997 49.8337C105.054 38.29 95.6256 28.7983 84.0557 28.7523C72.5143 28.7064 63.0313 38.1522 63.0028 49.7221Z"
      fill="#0B8397"
    />
  </svg>
);
