import { useWatch } from 'react-hook-form';

export const useOfficeProfile = (control) => {
  const watchedValues = useWatch({
    control,
    name: [
      'officeName',
      'website',
      'dentistTitleName',
      'dentistFirstName',
      'dentistLastName',
      'additionalContactTitleName',
      'additionalContactFirstName',
      'additionalContactLastName',
      'officeType',
      'selectedSpecialty',
    ],
  });

  const formValues = {
    officeName: watchedValues[0],
    website: watchedValues[1],
    dentistTitleName: watchedValues[2],
    dentistFirstName: watchedValues[3],
    dentistLastName: watchedValues[4],
    additionalContactTitleName: watchedValues[5],
    additionalContactFirstName: watchedValues[6],
    additionalContactLastName: watchedValues[7],
    officeType: watchedValues[8],
    selectedSpecialty: watchedValues[9],
  };

  const isValid =
    !!formValues.officeName?.trim() &&
    !!formValues.dentistFirstName?.trim() &&
    !!formValues.dentistLastName?.trim() &&
    (!formValues.additionalContactFirstName || !!formValues.additionalContactFirstName?.trim()) &&
    (!formValues.additionalContactLastName || !!formValues.additionalContactLastName?.trim()) &&
    !!formValues.officeType?.trim() &&
    !!formValues.selectedSpecialty?.trim();

  return {
    formValues,
    isValid,
  };
};
