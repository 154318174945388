export const Center = ({
  children,
  width,
  height,
  gap,
  backgroundColor,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingBottom,
  paddingHorizontal,
  marginTop,
  marginLeft,
  marginRight,
  marginBottom,
  marginHorizontal,
  style,
  ...props
}) => (
  <div
    {...props}
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      width,
      height,
      gap,
      backgroundColor,
      marginTop,
      marginBottom,
      marginRight: marginRight || marginHorizontal,
      marginLeft: marginLeft || marginHorizontal,
      paddingRight: paddingRight || paddingHorizontal,
      paddingLeft: paddingLeft || paddingHorizontal,
      ...style,
    }}
  >
    {children}
  </div>
);
