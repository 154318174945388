import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DELETE_ALL_ALERTS_ACTION, DELETE_ONE_ALERT_ACTION, GET_ALL_ALERTS_ACTION, GET_USER_MESSAGES, getAlerts } from "../../../../actions";
import { trackEvent } from "../../../../api/analytics";
import CustomSpinner from "../../../../commonComponents/CustomSpinner";
import history from "../../../../history";
import useLoading from "../../../../hooks/useLoading";
import Alerts from "../../../Alerts";
import { NotificationLayout } from "../Layout";
import { useTaskDeletion } from "./hooks/useTaskDeletion";
import { useTasksCount } from "./hooks/useTasksCount";

const LIMIT_VALUE = 15;

export const TaskList = () => {
  const dispatch = useDispatch()
  
  const isActiveRequestAlerts = useSelector((state) => state.alerts.isActiveRequest);
  const isFinalPageAlerts = useSelector((state) => state.alerts.isFinalPage);
  const { isLoading } = useLoading([
    GET_ALL_ALERTS_ACTION,
    GET_USER_MESSAGES,
    DELETE_ALL_ALERTS_ACTION,
    DELETE_ONE_ALERT_ACTION,
  ]);

  const dashboardInfo = useSelector((state) => state.job.dashboardInfo);
  const searchParamsAlerts = useSelector((state) => state.alerts.searchParams);
  const totalTasks = useTasksCount();
  const { handleDeleteAll, handleDeleteOne } = useTaskDeletion()

  const selectedAlert = (job) => {
    // TODO: - follow new alert schema
    if (job.job_path === 'DNT_OFFER') {
      trackEvent('Office Dashboard - Inbox Notifications Tile Clicked', {
        actionNeededShiftsCount: dashboardInfo?.actionRequired,
        scheduledShiftsCount: dashboardInfo?.scheduled,
        openShiftsCount: dashboardInfo?.activeRequest,
      });

      history.push(`/dashboard/job/${job.joboffer_id}`, {
        jobId: job.joboffer_id,
      });
    }
  };

  useEffect(() => {
    const handleScrollAlerts = () => {
      const userScrollHeight = document.documentElement.scrollHeight - window.scrollY;
      const clientHeight = window.innerHeight + 20;

      if (
        userScrollHeight <= clientHeight &&
          !isActiveRequestAlerts &&
          !isFinalPageAlerts
      ) {
        dispatch(getAlerts({ page: searchParamsAlerts?.page + 1, limit: LIMIT_VALUE }));
      }
    };

    window.addEventListener('scroll', handleScrollAlerts);

    return () => window.removeEventListener('scroll', handleScrollAlerts);
  }, [dispatch, isActiveRequestAlerts, isFinalPageAlerts, searchParamsAlerts?.page]);

  if (!totalTasks) return null;

  return (
    <NotificationLayout 
      icon="thunder-lightning" 
      title={`Tasks (${totalTasks})`} 
      style={{
        body: {
          paddingLeft: 0,
          paddingRight: 0,
        }
      }}
    >
      <div style={{ padding: "0 32px" }}>
        <Alerts
          isLoading={isLoading}
          selectedJobs={selectedAlert}
          deleteAll={handleDeleteAll}
          deleteOneAlert={handleDeleteOne}
        />
        {!isFinalPageAlerts && isLoading ? (
          <div
            style={{
              width: '100%',
              height: 100,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CustomSpinner isLoading size={50} />
          </div>
        ) : (
          <div />
        )}
      </div>

    </NotificationLayout>
  )
}