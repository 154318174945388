export const EmptyHighlyRatedIcon = () => (
  <svg
    width="172"
    height="171"
    viewBox="0 0 172 171"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="85.8278" cy="85.44" rx="85.8278" ry="85.44" fill="#EBF4F5" />
    <path
      d="M113.333 50.9663C113.333 63.1245 103.476 72.9805 91.3183 72.9805C79.1602 72.9805 69.3041 63.1245 69.3041 50.9663C69.3041 38.8082 79.1602 28.9521 91.3183 28.9521C103.476 28.9521 113.333 38.8082 113.333 50.9663Z"
      fill="#A4E0E0"
    />
    <path
      d="M82 83C89.2233 82.2055 97.6389 82.2039 105 88C105 88 102.092 106.195 99.7828 117.395L98.2702 124.733H44.5864C44.5864 124.733 38.6729 140.29 44.5864 117.395C50.5 94.5 68.1797 84.5202 82 83Z"
      fill="#A4E0E0"
    />
    <path
      d="M108.577 89.0512C109.383 86.5832 112.875 86.5832 113.68 89.0512L118.055 102.452C118.415 103.556 119.445 104.303 120.606 104.303L134.735 104.304C137.338 104.304 138.417 107.637 136.308 109.162L124.899 117.415C123.954 118.098 123.558 119.314 123.92 120.422L128.283 133.789C129.089 136.26 126.264 138.32 124.158 136.797L112.702 128.511C111.763 127.833 110.495 127.833 109.556 128.511L98.0994 136.797C95.9936 138.32 93.1689 136.26 93.9751 133.789L98.3376 120.422C98.6994 119.314 98.3039 118.098 97.3591 117.415L85.9499 109.162C83.841 107.637 84.92 104.304 87.5228 104.304L101.652 104.303C102.813 104.303 103.842 103.556 104.203 102.452L108.577 89.0512Z"
      fill="#EBF4F5"
      stroke="#EBF4F5"
      strokeWidth="8.56606"
    />
    <g opacity="0.8">
      <path
        d="M109.536 93.5803C110.54 90.5045 114.911 90.5045 115.915 93.5803L119.502 104.57C119.951 105.946 121.239 106.877 122.692 106.877H134.3C137.549 106.877 138.899 111.015 136.271 112.916L126.88 119.708C125.705 120.559 125.213 122.066 125.662 123.441L129.249 134.431C130.253 137.507 126.716 140.064 124.088 138.163L114.697 131.371C113.522 130.521 111.93 130.521 110.755 131.371L101.364 138.163C98.7354 140.064 95.199 137.507 96.2029 134.431L99.7899 123.441C100.239 122.066 99.747 120.559 98.5716 119.708L89.1807 112.916C86.5524 111.015 87.9032 106.877 91.152 106.877H102.76C104.213 106.877 105.5 105.946 105.949 104.57L109.536 93.5803Z"
        fill="#A4E0E0"
      />
    </g>
    <path
      d="M112.97 83.8159C113.776 81.3479 117.267 81.3479 118.073 83.8159L122.447 97.2169C122.808 98.3209 123.837 99.0678 124.999 99.0679L139.127 99.0684C141.73 99.0685 142.809 102.402 140.7 103.927L129.291 112.179C128.346 112.863 127.951 114.078 128.313 115.187L132.675 128.554C133.481 131.025 130.657 133.084 128.551 131.561L117.094 123.276C116.156 122.597 114.887 122.597 113.949 123.276L102.492 131.561C100.386 133.084 97.5615 131.025 98.3677 128.554L102.73 115.187C103.092 114.078 102.697 112.863 101.752 112.179L90.3425 103.927C88.2336 102.402 89.3126 99.0685 91.9154 99.0684L106.044 99.0679C107.206 99.0678 108.235 98.3209 108.595 97.2169L112.97 83.8159Z"
      stroke="#0B8397"
      strokeWidth="8"
    />
    <path
      d="M89.1886 20C90.8664 20.3041 92.5815 20.4725 94.2134 20.9363C106.107 24.3182 113.654 32.0271 116.135 44.1351C119.427 60.207 108.723 75.907 92.6777 78.882C89.5123 79.4683 86.231 79.442 83.0022 79.687C76.8618 80.1508 71.0539 81.8199 65.5653 84.574C57.5699 88.586 51.1538 94.3764 46.3237 101.91C43.1868 106.804 41.0627 112.117 39.8639 117.796C39.3192 120.371 40.9861 122.733 43.5849 123.16C44.1209 123.248 44.6721 123.25 45.219 123.25C56.5527 123.254 72.8841 123.261 84.2178 123.245C85.6287 123.245 86.8953 123.552 87.9038 124.58C90.0038 126.719 89.3585 130.272 86.6437 131.569C86.32 131.724 85.9875 131.86 85.6572 132.004H42.594C42.4321 131.936 42.2768 131.834 42.1083 131.805C37.8164 131.04 34.5919 128.743 32.5838 124.893C31.8575 123.501 31.5163 121.911 31 120.41C31 119.317 31 118.223 31 117.129C31.5928 114.887 32.0631 112.603 32.7982 110.407C38.1576 94.4092 48.4544 82.7846 63.7956 75.6904C65.5019 74.9007 67.2825 74.2707 69.1354 73.5247C68.7023 73.155 68.3391 72.8575 67.9869 72.5469C59.3505 64.9518 55.658 55.4163 57.8914 44.1439C60.2934 32.0205 67.8929 24.316 79.7887 20.9341C81.4184 20.4725 83.1357 20.3041 84.8135 20C86.2726 20 87.7295 20 89.1886 20ZM66.0028 49.7221C65.9744 61.3554 75.3939 70.6897 87.2176 70.74C98.4812 70.7881 107.942 61.2701 107.997 49.8337C108.054 38.29 98.6256 28.7983 87.0557 28.7523C75.5143 28.7064 66.0313 38.1522 66.0028 49.7221Z"
      fill="#0B8397"
    />
  </svg>
);
