import { useState, useEffect } from 'react';

export const useFixedWidth = (parentRef) => {
  const [fixedWidth, setFixedWidth] = useState('100%');

  useEffect(() => {
    if (parentRef.current) {
      const updateWidth = () => {
        setFixedWidth(`${parentRef.current.offsetWidth}px`);
      };

      updateWidth();

      window.addEventListener('resize', updateWidth);

      return () => window.removeEventListener('resize', updateWidth);
    }

    return () => {};
  }, [parentRef]);

  return fixedWidth;
};
