import { useSelector } from 'react-redux';
import { FETCH_OPEN_JOBS_ACTION } from '../../../../../actions';
import history from '../../../../../history';
import { FilterStatusEnum } from '../../../../../utils/Enum';
import { headerOpenTabTitles } from '../../utils';
import { CustomTable } from '../CustomTable';
import { renderRow } from './renderRow';
import { trackEvent } from '../../../../../api/analytics';

const actions = [FETCH_OPEN_JOBS_ACTION];

export const OpenTab = () => {
  const openJobs = useSelector((state) => state.job.openJobs);
  const user = useSelector((state) => state.user.user);

  const handleOnClick = (item) => {
    trackEvent('Shifts - Open Shifts Tab - Shift Tile Clicked', {
      shiftId: item?.id,
      shiftSpecialty: item?.specialty,
      procedures: item?.procedures,
      breakTime: item?.lunch_break_time,
      autoConfirm: item?.autoFill,
      softwares: user?.office_info?.softwares,
      assistedHygiene: item?.isAssistedHygiene,
      tpp: item?.amount,
    });

    history.push(`/dashboard/job/${item.id}`);
  };

  return (
    <CustomTable
      type={FilterStatusEnum.OPEN}
      data={openJobs}
      renderRow={(item) => renderRow(item, handleOnClick)}
      headerTitles={headerOpenTabTitles}
      onRowClick={(item) => handleOnClick(item)}
      actions={actions}
    />
  );
};
