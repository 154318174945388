import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../../commonComponents/Button';
import WarningIcon from '../../../../../../images/icons/Warning';
import { Colors } from '../../../../../../themes/colors';
import ConfirmPopup from '../../../../../shared/ConfirmPopup';
import { CalendarSelection } from '../../../../../../commonComponents/CalendarSelection';
import { checkIfAnyHoliday, convertDatesToTimezone } from './utils';

export const DateContent = ({ defaultValues, onConfirm, maxDaysToSelect, onLimitExceeded }) => {
  const user = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);
  const [selectedDates, setSelectedDates] = useState([]);
  const isDisabled = !selectedDates.length;
  const [confirmPostOnHoliday, setConfirmPostOnHoliday] = useState(false);

  const handleConfirmDates = () => {
    onConfirm(selectedDates);
    setConfirmPostOnHoliday(false);
  };

  const handleButtonClick = () => {
    if (checkIfAnyHoliday(convertDatesToTimezone(selectedDates, user.time_zone))) {
      setConfirmPostOnHoliday(true);
    } else {
      handleConfirmDates();
    }
  };

  useEffect(() => {
    setSelectedDates(defaultValues);
  }, [defaultValues]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CalendarSelection
          onSelect={setSelectedDates}
          maxDaysToSelect={maxDaysToSelect}
          dates={selectedDates}
          onLimitExceeded={onLimitExceeded}
        />

        <Button
          text={`Select Date${!maxDaysToSelect || maxDaysToSelect > 1 ? 's' : ''}`}
          onClick={handleButtonClick}
          disabled={isDisabled}
          style={{
            backgroundColor: !isDisabled ? Colors.secondary_500 : Colors.secondary_100,
            width: 180,
            height: 48,
            marginTop: 20,
            marginBottom: 12,
          }}
        />
      </div>

      {confirmPostOnHoliday && (
        <ConfirmPopup
          Icon={() => (
            <div style={{ marginBottom: 20 }}>
              <WarningIcon width={80} height={80} color={Colors.warning_500} />
            </div>
          )}
          title="Major U.S Holiday Conflict"
          description={
            <>
              Looks like you are trying to post a shift on a<br />
              Major U.S. Holiday. Please make sure your office
              <br />
              will be open on this date!
            </>
          }
          rightButtonText="Continue"
          leftButtonText="Go Back"
          rightButtonAction={handleConfirmDates}
          leftButtonAction={() => setConfirmPostOnHoliday(false)}
        />
      )}
    </>
  );
};
