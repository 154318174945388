import { useState } from 'react';
import { EmojiHappy, EmojiOkay, EmojiSad, EmojiSoHappy, EmojiSoSad } from '../../images/emojis';
import { Colors } from '../../themes/colors';
import { Center } from '../Center';
import { Column } from '../Column';
import { Row } from '../Row';
import { Text } from '../Text';

const emojis = [
  { key: 'soSad', title: 'Yikes!', Component: EmojiSoSad },
  { key: 'sad', title: 'Meh', Component: EmojiSad },
  { key: 'okay', title: 'Okay', Component: EmojiOkay },
  { key: 'happy', title: 'Sweet', Component: EmojiHappy },
  { key: 'soHappy', title: 'Fantastic!', Component: EmojiSoHappy },
];

export const EmojiSelection = ({ onSelect }) => {
  const [selectedEmoji, setSelectedEmoji] = useState(null);

  const handleSelect = (key, title) => {
    const isSelected = selectedEmoji === key;
    setSelectedEmoji(isSelected ? null : key);
    onSelect(isSelected ? { key: null, title: null } : { key, title });
  };

  return (
    <Row>
      {emojis.map(({ key, Component, title }) => (
        <EmojiWrapper
          key={key}
          Emoji={Component}
          isSelected={selectedEmoji === key}
          onClick={() => handleSelect(key, title)}
          title={title}
        />
      ))}
    </Row>
  );
};

const EmojiWrapper = ({ Emoji, isSelected, onClick, title }) => (
  <Column
    onClick={onClick}
    width={68}
    height={84}
    style={{
      cursor: 'pointer',
    }}
  >
    <Center>
      <Emoji selected={isSelected} />
      {isSelected && (
        <Text fontSize={18} bold color={Colors.secondary_500}>
          {title}
        </Text>
      )}
    </Center>
  </Column>
);
