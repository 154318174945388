import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_ALL_PAYMENTS_ACTION, getAllPaymnets } from "../../../../../actions";
import { enablePaymentExpirationReminders, getHideNotificationsOnParentOffice } from "../../../../../growthbook";
import useLoading from "../../../../../hooks/useLoading";
import { useCheckPaymentExpiration } from "./useCheckPaymentExpiration";

export const usePaymentExpiration = () => {
  const dispatch = useDispatch();

  const isEnablePaymentExpiration = useRef(enablePaymentExpirationReminders()).current;
  const isHideNotificationsOnParentOffice = useRef(getHideNotificationsOnParentOffice()).current;

  const {isLoading} = useLoading([GET_ALL_PAYMENTS_ACTION]);
  const user = useSelector((state) => state.user.selectedChildOffice || state.user.user);
  const isParentOffice = useSelector((state) => 
    (state.user?.selectedChildOffice?.childOffices || state.user?.childOffices)?.length > 1
  );
  const isParentOfficeAndHideNotificationsOnParentOffice =
    isParentOffice && isHideNotificationsOnParentOffice;

  useEffect(() => {
    if (
      isEnablePaymentExpiration &&
      !user?.is_pre_approved &&
      !isParentOfficeAndHideNotificationsOnParentOffice
    ) {
      dispatch(getAllPaymnets())
    }
  }, [
    dispatch,
    isEnablePaymentExpiration,
    isParentOfficeAndHideNotificationsOnParentOffice,
    user?.is_pre_approved,
  ]);

  const { isExpired, isExpiring } = useCheckPaymentExpiration();

  if (
    !isEnablePaymentExpiration ||
    isParentOfficeAndHideNotificationsOnParentOffice
  ) {
    return {
      isLoading: false,
      isPaymentExpired: false,
      isPaymentExpiring: false,
    }
  }

  return {
    isLoading,
    isPaymentExpired: !isLoading && !user?.is_pre_approved && isExpired,
    isPaymentExpiring: !isLoading && !user?.is_pre_approved && isExpiring,
  }
}