import { UPDATE_RATING_COUNT } from '../actions';
import { apiMethodsConst } from '../services/methods';

const initialState = {
  ratingCount: 5,
  feedbackRequired: [],
  feedbackRequiredDate: null,
  isFeedbackDone: true,
  feedbackJobId: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case apiMethodsConst.FEEDBACK_REQUEST:
    const jobs = action.payload;
    return {
      ...state,
      feedbackRequired: jobs,
      feedbackRequiredDate: jobs.length <= 1 ? new Date() : null,
    };
  case apiMethodsConst.FEEDBACK_SAVE_REQUEST:
    const index = state.feedbackRequired.findIndex(
      (job) => job.id === action.payload.joboffer_id,
    );

    return {
      ...state,
      isFeedbackDone: true,
      feedbackJobId: null,
      feedbackRequired: [
        ...state.feedbackRequired.slice(0, index),
        ...state.feedbackRequired.slice(index + 1),
      ],
    };
  case UPDATE_RATING_COUNT:
    return {
      ...state,
      ratingCount: action.payload,
    };
  case 'FEEDBACK_GET_STATUS_ACTION_SUCCESS':
    return {
      ...state,
      isFeedbackDone: action.payload.iSRatingAndComments,
      feedbackJobId: action.payload.jobId,
    };
  default:
    return state;
  }
};
