import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ExpirationUpdatePopupImage from '../../../../../images/ExpirationUpdatePopup.svg';
import { Colors } from '../../../../../themes/colors';
import ConfirmPopup from '../../../../shared/ConfirmPopup';

export function ExpirationUpdatePopup() {
  const user = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);
  const [showPopup, setShowPopup] = useState(false);
  const isSameOrBefore = moment().isBefore('2024-12-01');

  const getStorage = () => {
    const isExpirationUpdatePopupNew = localStorage.getItem(
      `isExpirationUpdatePopupNew-${user?.id}`,
    );

    if (isExpirationUpdatePopupNew === null) {
      setShowPopup(true);
    }
  };

  const setStorage = () => {
    localStorage.setItem(`isExpirationUpdatePopupNew-${user?.id}`, 'false');
    setShowPopup(false);
  };

  const handleConfirm = () => {
    setStorage();
  };

  useEffect(() => {
    getStorage();
  }, [user]);

  return (
    showPopup &&
    isSameOrBefore && (
      <ConfirmPopup
        Icon={() => (
          <img
            alt="Important Expiration Update"
            src={ExpirationUpdatePopupImage}
            style={{ width: 224, height: 158, marginBottom: 24 }}
          />
        )}
        title="Important Expiration Update!"
        description={
          <div
            style={{
              textAlign: 'left',
            }}
          >
            We have updated our offer expiration timeline to make managing offers easier! What you
            need to know:
            <br />
            <ul
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
              }}
            >
              <li>
                All offers submitted by candidates on Monday - Thursday will expire at{' '}
                <b>7:00 pm the next day</b>.
              </li>
              <li>
                All offers submitted by candidates on Friday - Sunday will expire at{' '}
                <b>7:00 pm on the following Monday</b>.
              </li>
              <li>Offers will not expire on Holidays.</li>
            </ul>
          </div>
        }
        descriptionStyle={{ color: Colors.neutral_500, marginBottom: 32, width: 455 }}
        rightButtonText="Got it!"
        rightButtonAction={handleConfirm}
        closePopup={handleConfirm}
      />
    )
  );
}
