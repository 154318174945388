import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ADD_FAVORITE_PROFESSIONAL,
  BLOCK_RECENT_PROFESSIONAL,
  FETCH_OPEN_JOBS_FOR_RECENT_PROFESSIONAL_ACTION,
  FETCH_RECENT_PROFESSIONALS,
  fetchRecentProfessionals,
} from '../../../../../actions';
import { createSuccessSelector } from '../../../../../api/selectors';
import useLoading from '../../../../../hooks/useLoading';

const actions = [
  FETCH_RECENT_PROFESSIONALS,
  ADD_FAVORITE_PROFESSIONAL,
  BLOCK_RECENT_PROFESSIONAL,
  FETCH_OPEN_JOBS_FOR_RECENT_PROFESSIONAL_ACTION,
];

const successAddToFavoritesSelector = createSuccessSelector([ADD_FAVORITE_PROFESSIONAL]);
const successBlockProfessionalSelector = createSuccessSelector([BLOCK_RECENT_PROFESSIONAL]);

export const useRecentProfessionalsList = () => {
  const dispatch = useDispatch();
  const { isLoading } = useLoading(actions);
  const recentProfessionals = useSelector((state) => state.user.recentProfessionals);
  const user = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);
  const isRecentProfessionalsTabNew = localStorage.getItem(
    `isRecentProfessionalsTabNew-${user.id}`,
  );
  const isAddedToFavoritesSuccess = useSelector((state) => successAddToFavoritesSelector(state));
  const isBlockedProfessionalSuccess = useSelector((state) =>
    successBlockProfessionalSelector(state),
  );
  const filter = useSelector((state) => state.user.professionalHubFilter);

  useEffect(() => {
    dispatch(fetchRecentProfessionals({ filter }));
  }, [dispatch, isAddedToFavoritesSuccess, isBlockedProfessionalSuccess, filter]);

  useEffect(() => {
    if (!isRecentProfessionalsTabNew) {
      localStorage.setItem(`isRecentProfessionalsTabNew-${user.id}`, false);
    }
  }, [isRecentProfessionalsTabNew, user]);

  return {
    recentProfessionals,
    isLoading,
    isRecentProfessionalsTabNew,
    isEmpty: !recentProfessionals?.length,
    isFiltered: filter !== 'all',
  };
};
