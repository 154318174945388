import { useSelector } from 'react-redux';
import { growthbook, showShowWCManagementPage } from '../../../growthbook';
import greenCircleCheck from '../../../images/green-circle-check.svg';
import WarningSolidIcon from '../../../images/icons/WarningSolid';
import logoDarkIcon from '../../../images/logos/logoDarkIcon.svg';
import { Colors } from '../../../themes/colors';

export const Title = ({ isRegistration }) => {
  const user = useSelector((state) => state.user.selectedChildOffice);
  const userClassification = user?.workerClassification?.classificationType;
  const latestWorkerClassificationRequest = user?.latestWorkerClassificationRequest;
  const workerClassificationRequest = user?.workerClassificationRequest;

  const getWorkerType = () => {
    let title = 'Not Specified';
    let color = Colors.error_500;
    let icon = <WarningSolidIcon color={Colors.error_500} />;

    if (
      showShowWCManagementPage() &&
      (latestWorkerClassificationRequest?.status === 'requested' ||
        (workerClassificationRequest?.length === 1 &&
          workerClassificationRequest?.[0]?.status === 'requested'))
    ) {
      title = `Pending Approval / 
      ${
        latestWorkerClassificationRequest?.requestWorkerClassification ??
        workerClassificationRequest?.[0]?.requestWorkerClassification
      }`;
      color = Colors.warning_700;
      icon = <WarningSolidIcon color={Colors.warning_700} />;
    } else if (userClassification) {
      title = userClassification;
      color = Colors.success_500;
      icon = (
        <img
          src={greenCircleCheck}
          style={{
            width: 22,
          }}
          alt="warning_alert"
        />
      );
    }

    return (
      <span
        style={{
          display: 'flex',
          marginLeft: 5,
          fontWeight: 400,
          alignItems: 'center',
          textTransform: 'uppercase',
          color,
          gap: 8,
        }}
      >
        <span>{title}</span>
        {icon}
      </span>
    );
  };

  return (
    <div
      style={{
        maxWidth: 436,
      }}
    >
      {isRegistration && (
        <>
          <img src={logoDarkIcon} style={{ width: 40 }} alt="logo_dark_icon" />
        </>
      )}
      <h1 className="global_font f-dark" style={{ textAlign: 'center', marginBottom: 10 }}>
        Worker Type
        {!isRegistration ? ' Setting' : ''}
      </h1>

      {!isRegistration && (
        <span
          style={{
            fontSize: 18,
            fontWeight: 700,
            fontFamily: 'Nunito',
            color: Colors.neutral_600,
            display: 'flex',
            marginLeft: 72,
            whiteSpace: 'nowrap',
          }}
        >
          Worker Type:
          {getWorkerType()}
        </span>
      )}
    </div>
  );
};
