import React from 'react';
import { Colors } from '../../../themes/colors';

export const Label = ({ text, extraText, marginBottom = 8, required = true }) => (
  <div style={{ marginTop: 0, marginBottom }}>
    <span className="input_names">{text}</span>
    {extraText && (
      <span
        style={{
          color: Colors.neutral_500,
          fontWeight: 400,
          fontSize: 16,
        }}
      >
        {` ${extraText}`}
      </span>
    )}
    {required && (
      <span
        style={{
          color: Colors.neutral_500,
          fontWeight: 400,
          fontSize: 16,
        }}
      >
        {' (Required)'}
        <span
          style={{
            color: Colors.error_500,
          }}
        >
          *
        </span>
      </span>
    )}
  </div>
);
