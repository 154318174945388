import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllJobs } from '../../../../actions';
import { Colors } from '../../../../themes/colors';
import { createDaysOfWeekArray } from '../../utils';
import { DayCard } from './components/DayCard/DayCard';

const getFilteredJobs = (jobs, status) => {
  let statusToFilter = status;

  if (status === 'all') {
    return Object.fromEntries(
      Object.entries(jobs).map(([date, objects]) => [
        date,
        objects.filter((obj) => obj.status !== 'today'),
      ]),
    );
  }

  if (status === 'pending-counter') {
    statusToFilter = 'pending';
  }

  return Object.fromEntries(
    Object.entries(jobs).map(([date, objects]) => [
      date,
      objects.filter((obj) => obj.status === statusToFilter),
    ]),
  );
};

const daysOfWeekArray = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const Calendar = ({ status }) => {
  const dispatch = useDispatch();
  const schedule = useSelector((state) => state.job.schedule);
  const selectedMonth = moment(schedule.dateRange.start).month();
  const jobsPerDay = useSelector((state) => state.job.jobsPerDay);

  const jobsFiltered = useMemo(() => getFilteredJobs(jobsPerDay, status), [jobsPerDay, status]);
  const dateArray = useMemo(
    () => createDaysOfWeekArray(schedule.dateRange.start),
    [schedule.dateRange],
  );

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      dispatch(getAllJobs({ ...schedule.dateRange }));
    }, 300);

    return () => clearTimeout(debounceTimeout);
  }, [schedule.dateRange, dispatch]);

  return (
    <div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(7, 1fr)',
          gap: 8,
          marginBottom: 12,
          justifyItems: 'flex-end',
        }}
      >
        {daysOfWeekArray.map((day) => (
          <span
            key={day}
            style={{
              fontFamily: 'Nunito',
              color: Colors.neutral_500,
              fontSize: 16,
              marginRight: 12,
            }}
          >
            {day}
          </span>
        ))}
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(7, 1fr)',
          gap: 8,
          justifyItems: 'center',
        }}
      >
        {dateArray.map((dateString) => (
          <DayCard
            key={dateString}
            jobs={jobsFiltered[dateString]}
            status={status}
            dateString={dateString}
            disabled={selectedMonth !== moment(dateString).month()}
          />
        ))}
      </div>
    </div>
  );
};
