import { motion } from 'framer-motion';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Tooltip } from '../../../../commonComponents/TooltipWrapper/TooltipWrapper';
import { UserProfession, UserSubProfession } from '../../../../enums/UserProfession';
import BulbIcon from '../../../../images/icons/BulbIcon';
import EditPencilIcon from '../../../../images/icons/EditPencil';
import TrashIcon from '../../../../images/icons/Trash';
import WarningSolidIcon from '../../../../images/icons/WarningSolid';
import { Colors } from '../../../../themes/colors';
import { useTipsCard } from '../hooks/useTipsCard';
import isHoliday from '../../isHoliday';
import BackTipsCard from './BackTipsCard';
import { Tips } from './Tips/Tips';
import { isDailyOvertime } from '../utils';

export default function JobMDBCard({ job, deleteJobPressed, editHasBeenClicked }) {
  const {
    localStart,
    localEnd,
    rate,
    is_break_paid: isBreakPaid,
    break_time: breakTime,
    localDate,
    profession,
    subProfession,
    specialty,
    isAssistedHygiene,
  } = job;

  const user = useSelector((state) => state.user.selectedChildOffice);
  const workerClassificationType = user?.workerClassification?.classificationType;
  const overtimeLimits = user?.overtimeLimits?.[workerClassificationType];
  const formattedDate = moment.utc(localDate).set({ hour: 8, minute: 0, second: 0 });
  const { isFlipped, showTips, handleTipsClick } = useTipsCard(job);

  return (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0, rotateY: isFlipped ? 180 : 0 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.6,
        exit: { duration: 0.2 },
        layout: { duration: 0.3 },
      }}
      layout
      className="card"
      data-cy="mdb-job-card"
      style={{
        backgroundColor: Colors.white,
        width: 438,
        height: 256,
        borderRadius: 8,
        border: `2px solid transparent`,
        ...(showTips && {
          border: `2px solid ${isFlipped ? Colors.secondary_25 : Colors.secondary_500}`,
        }),
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.15), 0px 3px 10px 1px rgba(0, 0, 0, 0.15)',
      }}
    >
      {/* Front View */}
      {!isFlipped && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.8,
          }}
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
          }}
        >
          <div style={{ width: '100%' }}>
            <div
              style={{
                borderBottom: `1px solid ${Colors.neutral_100}`,
                marginBottom: 10,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '18px 16px',
                paddingBottom: 8,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 12,
                }}
              >
                <span className="card-title" style={styles.titleStyle}>
                  {moment.utc(localDate).format('ddd, MMM DD, YYYY')}
                </span>
                {isHoliday(formattedDate) && (
                  <span
                    style={{
                      fontFamily: 'Nunito',
                      fontSize: 12,
                      fontWeight: 700,
                      textTransform: 'uppercase',
                      color: Colors.white,
                      padding: '2px 7px',
                      backgroundColor: Colors.neutral_400,
                      borderRadius: 3,
                    }}
                  >
                    holiday
                  </span>
                )}
              </div>

              <div style={{ marginRight: 10, display: 'flex', gap: 16 }}>
                <button
                  type="button"
                  style={{ all: 'unset', cursor: 'pointer' }}
                  data-cy="edit-shift"
                  onClick={() => editHasBeenClicked(localDate)}
                >
                  <EditPencilIcon color={Colors.secondary_500} />
                </button>
                <button
                  type="button"
                  style={{ all: 'unset', cursor: 'pointer' }}
                  data-cy="remove-shift"
                  onClick={() => deleteJobPressed(localDate)}
                >
                  <TrashIcon color={Colors.secondary_500} />
                </button>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                margin: '0 26px 26px',
                gap: 2,
              }}
            >
              <span style={styles.textStyle}>
                {UserSubProfession.getName(subProfession) ?? UserProfession.getName(profession)}
              </span>
              <span style={styles.textStyle}>{specialty?.split('_')?.[1] || 'General'}</span>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <span
                  style={{
                    ...styles.textStyle,
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: 4,
                  }}
                >
                  {`${localStart} - ${localEnd}`}
                  {isDailyOvertime(job, overtimeLimits?.daily) && (
                    <Tooltip text="Overtime">
                      <span style={{ marginLeft: 4, marginBottom: -3, display: 'flex' }}>
                        <WarningSolidIcon color={Colors.warning_500} />
                      </span>
                    </Tooltip>
                  )}
                </span>
                <span style={styles.textStyle}>
                  {`/ Meal Break: ${breakTime} ${isBreakPaid ? '(Paid)' : '(Unpaid)'}`}
                </span>
              </div>
              {isAssistedHygiene !== undefined && (
                <span style={styles.textStyle}>{`Assisted Hygiene Available: ${
                  isAssistedHygiene ? 'Yes' : 'No'
                }`}</span>
              )}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                {showTips && <BulbIcon color={Colors.secondary_500} />}
                <span style={styles.textStyle}>{`$${rate || ''}/hr`}</span>
              </div>
              {showTips && (
                <div
                  style={{
                    position: 'absolute',
                    right: 26,
                    bottom: 32,
                  }}
                >
                  <Tips onClick={handleTipsClick} />
                </div>
              )}
            </div>
          </div>
        </motion.div>
      )}

      {/* Back View */}
      {isFlipped && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.8,
          }}
          style={{
            transform: 'rotateY(180deg)',
            height: '100%',
          }}
        >
          <BackTipsCard localDate={localDate} onReturnClick={handleTipsClick} />
        </motion.div>
      )}
    </motion.div>
  );
}

const styles = {
  titleStyle: {
    color: Colors.neutral_600,
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    fontSize: 18,
    marginLeft: 10,
    textTransform: 'capitalize',
  },
  textStyle: {
    color: Colors.neutral_600,
    fontFamily: 'Nunito',
    fontSize: 16,
    marginTop: 2,
  },
};
