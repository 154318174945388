import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ConfirmPopup from '../../../components/shared/ConfirmPopup';
import { Colors } from '../../../themes/colors';
import CandidateSortAnnouncement from '../../../images/candidate-sort-announcement.svg';

export function CandidateSortAnnouncementPopup() {
  const user = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);
  const [showPopup, setShowPopup] = useState(false);

  const getStorage = useCallback(() => {
    if (!user) {
      return;
    }

    const popupAppeared = localStorage.getItem(`candidateSortAnnouncement-${user.id}`);

    if (popupAppeared === null) {
      setShowPopup(true);
    }
  }, [user]);

  const setStorage = useCallback(() => {
    if (!user) {
      return;
    }

    localStorage.setItem(`candidateSortAnnouncement-${user.id}`, 'false');
    setShowPopup(false);
  }, [user]);

  useEffect(() => {
    getStorage();
  }, []);

  if (!showPopup) {
    return null;
  }

  return (
    <ConfirmPopup
      modalStyle={{ minWidth: 600, paddingTop: 72 }}
      Icon={() => (
        <img
          alt="CandidateSortAnnouncement"
          src={CandidateSortAnnouncement}
          style={{ marginBottom: 32 }}
        />
      )}
      title="Confirm Candidate Improvements!"
      titleStyle={{ color: Colors.primary_500, fontSize: 22, marginBottom: 16 }}
      description={
        <div>
          You can now see <b>even more</b> candidates for a shift, and it’s even easier to find
          exactly <b>the right pro</b> for the job.
          <br />
          <br />
          Click the <b>arrow</b> on the right of your candidates to see more, and use the new{' '}
          <b>‘Sort By’</b> dropdown to help get closer to what you’re looking for.
        </div>
      }
      descriptionStyle={{ color: Colors.neutral_500, minWidth: 569 }}
      rightButtonText="Got It"
      rightButtonAction={setStorage}
      rightButtonStyle={{ width: 217 }}
    />
  );
}
