import { motion } from 'framer-motion';

import Button from '../../commonComponents/Button';
import Portal from '../../commonComponents/Portal';
import ClosePopupIcon from '../../images/ClosePopupIcon.svg';
import { Colors } from '../../themes/colors';

export default function ConfirmPopup({
  rightButtonAction,
  leftButtonAction,
  rightButtonText,
  leftButtonText,
  title,
  titleStyle,
  description,
  descriptionStyle,
  Icon,
  closePopup,
  showClosePopupIcon,
  modalStyle,
  children,
  leftButtonStyle,
  rightButtonStyle,
  rightButtonDisabled,
  leftButtonDisabled,
}) {
  const renderDescription = () => {
    if (!description) {
      return null;
    }

    if (typeof description === 'function') {
      const DescriptionComponent = description;
      return (
        <div 
          style={{
            marginBottom: 70,
          }}
        >
          <DescriptionComponent />
        </div>
      );
    }

    return (
      <p
        style={{
          width: '80%',
          fontFamily: 'Nunito',
          fontSize: 16,
          color: Colors.neutral_500,
          textAlign: 'center',
          margin: 0,
          marginBottom: 70,
          ...descriptionStyle,
        }}
      >
        {description}
      </p>
    );
  }

  return (
    <Portal>
      <div className="modal" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} onClick={closePopup}>
        <motion.div
          onClick={(event) => event.stopPropagation()}
          className="modal_content"
          style={{ position: 'relative', padding: '45px 10px', ...modalStyle }}
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          {showClosePopupIcon && (
            <img
              alt="close-popup-icon"
              src={ClosePopupIcon}
              style={{
                position: 'absolute',
                marginTop: -24,
                marginRight: 16,
                cursor: 'pointer',
                alignSelf: 'flex-end',
              }}
              onClick={closePopup}
            />
          )}

          {Icon && <Icon />}

          {title && (
            <h1
              style={{
                fontFamily: 'Nunito',
                fontWeight: 'bold',
                fontSize: 18,
                color: Colors.neutral_600,
                margin: 0,
                marginBottom: 20,
                textAlign: 'center',
                ...titleStyle,
              }}
            >
              {title}
            </h1>
          )}

          {renderDescription()}

          {children}

          <div style={{ display: 'flex', columnGap: 16 }}>
            {leftButtonText && (
              <Button
                style={{
                  alignSelf: 'center',
                  width: 152,
                  margin: 0,
                  backgroundColor: Colors.secondary_500,
                  opacity: leftButtonDisabled ? 0.5 : 1,
                  ...leftButtonStyle,
                }}
                onClick={leftButtonAction}
                disabled={leftButtonDisabled}
                text={leftButtonText}
              />
            )}

            {rightButtonText && (
              <Button
                type="button"
                style={{
                  alignSelf: 'center',
                  width: 152,
                  margin: 0,
                  backgroundColor: Colors.primary_500,
                  opacity: rightButtonDisabled ? 0.5 : 1,
                  ...rightButtonStyle,
                }}
                onClick={rightButtonAction}
                disabled={rightButtonDisabled}
                text={rightButtonText}
              />
            )}
          </div>
        </motion.div>
      </div>
    </Portal>
  );
}
