const encodePhone = (phone) => `(•••) •••-${phone.slice(-4)}`;

const encodeEmail = (email) => {
  const emailEncode = email.split('@');
  let hashCover;
  if (emailEncode[0].length === 1) {
    return `${'•' + '@'}${emailEncode[1]}`;
  }
  if (emailEncode[0].length % 2 === 1) {
    hashCover =
      '•'.repeat(Math.ceil(emailEncode[0].length / 2)) +
      emailEncode[0].slice(emailEncode[0].length / 2);
    return `${hashCover}@${emailEncode[1]}`;
  }
  hashCover =
    '•'.repeat(Math.ceil(emailEncode[0].length / 2)) +
    emailEncode[0].slice(emailEncode[0].length / 2);
  return `${hashCover}@${emailEncode[1]}`;
};

const forgotPasswordOptions = {
  email: 'email',
  phone: 'mobile phone',
};

export { encodeEmail, encodePhone, forgotPasswordOptions };
