import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { hideConfirmCandidateAnotherJobPopup } from '../../../actions';
import { trackEvent } from '../../../api/analytics';
import AnimatedCheckCircle from '../../shared/AnimatedCheckCircle';
import ConfirmPopup from '../../shared/ConfirmPopup';

export const ConfirmCandidateAnotherJobPopup = ({ job, candidate }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const pendingJobs = useSelector((state) => state.job.actionRequiredJobs);

  // Sort pending jobs by closest date to today
  const sortedPendingJobs = pendingJobs
    .filter(
      (j) => j.id !== job?.id && j.multipleCounters.some((c) => c.candidate.id === candidate?.id),
    )
    .sort((a, b) =>
      moment(a.local_date, 'ddd, MMM D, YYYY').diff(moment(b.local_date, 'ddd, MMM D, YYYY')),
    );

  const nextJob = sortedPendingJobs[0];

  return (
    <ConfirmPopup
      Icon={() => (
        <div style={{ marginBottom: 20 }}>
          <AnimatedCheckCircle />
        </div>
      )}
      title={`Candidate Confirmed for ${job?.local_date}!`}
      description={
        <span>
          {candidate?.full_name} has also applied to another shift at your office on{' '}
          <b>{nextJob?.local_date}</b>. Would you like to review their application?
        </span>
      }
      rightButtonAction={() => {
        trackEvent('Candidate Confirmed Popup - Review Button Clicked');
        dispatch(hideConfirmCandidateAnotherJobPopup());
        history.push(`/dashboard/job/${nextJob.id}/confirm/${candidate?.id}`);
      }}
      rightButtonText="Review"
      leftButtonText="No Thanks"
      leftButtonAction={() => {
        trackEvent('Candidate Confirmed Popup - No Thanks Button Clicked');
        dispatch(hideConfirmCandidateAnotherJobPopup());
        history.push(`/dashboard/jobs/${pendingJobs?.length > 0 ? 'pending' : 'scheduled'}`);
      }}
    />
  );
};
