import { Colors } from '../../themes/colors';
import { Icon } from '../Icon';
import { Label } from '../Label';
import { Row } from '../Row';

export const FieldValue = ({
  label,
  value,
  setValue,
  placeholder,
  validation = {},
  customInputStyle = {},
  shouldCapitalize = true,
  required = false,
  error = false,
}) => (
  <div>
    <Row gap={4}>
      <Label text={label} required={required} />
      {error && <Icon name="warning-solid" color={Colors.warning_500} />}
    </Row>
    <input
      className="input-field"
      style={{ margin: 0, width: 252, ...customInputStyle }}
      type="text"
      value={value}
      placeholder={placeholder || label}
      {...validation}
      onChange={(e) => {
        const name = e.target.value
          .split(/\s+/)
          .map((s) => {
            if (shouldCapitalize) {
              return s.charAt(0).toUpperCase() + s.substring(1).toLowerCase();
            }
            return s;
          })
          .join(' ');
        setValue(name);
      }}
    />
  </div>
);
