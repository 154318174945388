export const FacebookIcon = ({ fill }) => (
  <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.848 35.9989C16.8891 35.9989 15.9265 35.9221 14.9892 35.7709C10.8497 35.1048 7.05591 32.9769 4.30748 29.7795C1.52904 26.5474 0 22.4176 0 18.1509C0 8.30935 8.00646 0.302856 17.8492 0.302856C27.692 0.302856 35.6984 8.30935 35.6984 18.1509C35.6984 22.4836 34.1262 26.6627 31.2721 29.9164C28.4445 33.1401 24.5595 35.2392 20.3348 35.8285C19.5175 35.9425 18.6822 36.0001 17.8504 36.0001L17.848 35.9989ZM17.848 1.74191C8.79979 1.74191 1.43903 9.10268 1.43903 18.1497C1.43903 26.251 7.23354 33.0633 15.2184 34.3487C16.8279 34.6079 18.5153 34.6259 20.1344 34.4003C28.1853 33.2781 34.257 26.2918 34.257 18.1497C34.257 9.10268 26.8962 1.74191 17.848 1.74191Z"
      fill={fill}
    />
    <path
      d="M20.2328 14.7747V18.5061H24.8487L24.1178 23.5324H20.2328V35.1131C19.4538 35.2211 18.6569 35.2775 17.848 35.2775C16.9142 35.2775 15.9973 35.2031 15.1044 35.0591V23.5324H10.8473V18.5061H15.1044V13.9405C15.1044 11.1081 17.4003 8.81091 20.234 8.81091V8.81332C20.2424 8.81332 20.2496 8.81091 20.258 8.81091H24.8499V13.158H21.8494C20.9577 13.158 20.234 13.8817 20.234 14.7734L20.2328 14.7747Z"
      fill={fill}
    />
  </svg>
);

export const LinkedinIcon = ({ fill }) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.7676 35.4572H17.6896C7.93567 35.4572 0 27.5216 0 17.7676V17.6896C0 7.93565 7.93567 0 17.6896 0H17.7676C27.5215 0 35.4572 7.93565 35.4572 17.6896V17.7676C35.4572 27.5216 27.5215 35.4572 17.7676 35.4572ZM17.6896 1.20019C8.59697 1.20019 1.20019 8.59695 1.20019 17.6896V17.7676C1.20019 26.8603 8.59697 34.257 17.6896 34.257H17.7676C26.8602 34.257 34.257 26.8603 34.257 17.7676V17.6896C34.257 8.59695 26.8602 1.20019 17.7676 1.20019H17.6896Z"
      fill={fill}
    />
    <path
      d="M8.76993 11.9863C8.31866 11.5675 8.09424 11.049 8.09424 10.4321C8.09424 9.81518 8.31986 9.27391 8.76993 8.85384C9.2212 8.43498 9.8021 8.22495 10.5138 8.22495C11.2255 8.22495 11.7836 8.43498 12.2337 8.85384C12.685 9.27271 12.9094 9.79958 12.9094 10.4321C12.9094 11.0646 12.6838 11.5675 12.2337 11.9863C11.7824 12.4052 11.2099 12.6152 10.5138 12.6152C9.8177 12.6152 9.2212 12.4052 8.76993 11.9863ZM12.5301 14.3891V27.2311H8.47229V14.3891H12.5301Z"
      fill={fill}
    />
    <path
      d="M26.0382 15.6577C26.9228 16.6178 27.3645 17.9357 27.3645 19.6135V27.0043H23.5107V20.1344C23.5107 19.2883 23.291 18.6306 22.853 18.1625C22.4149 17.6944 21.8244 17.4592 21.0851 17.4592C20.3458 17.4592 19.7553 17.6932 19.3172 18.1625C18.8791 18.6306 18.6595 19.2883 18.6595 20.1344V27.0043H14.7829V14.3531H18.6595V16.031C19.0519 15.4717 19.5813 15.03 20.2462 14.7048C20.9111 14.3795 21.6588 14.2175 22.4905 14.2175C23.9715 14.2175 25.1549 14.6975 26.0382 15.6577Z"
      fill={fill}
    />
  </svg>
);

export const InstagramIcon = ({ fill }) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.7676 35.8786H17.6896C7.93566 35.8786 0 27.9429 0 18.189V18.111C0 8.35703 7.93566 0.421387 17.6896 0.421387H17.7676C27.5215 0.421387 35.4572 8.35703 35.4572 18.111V18.189C35.4572 27.9429 27.5215 35.8786 17.7676 35.8786ZM17.6896 1.62158C8.59696 1.62158 1.20019 9.01834 1.20019 18.111V18.189C1.20019 27.2816 8.59696 34.6784 17.6896 34.6784H17.7676C26.8602 34.6784 34.257 27.2816 34.257 18.189V18.111C34.257 9.01834 26.8602 1.62158 17.7676 1.62158H17.6896Z"
      fill={fill}
    />
    <path
      d="M23.0293 7.93287H12.4292C9.46875 7.93287 7.0604 10.3412 7.0604 13.3017V22.9993C7.0604 25.9598 9.46875 28.3681 12.4292 28.3681H23.0293C25.9898 28.3681 28.3982 25.9598 28.3982 22.9993V13.3017C28.3982 10.3412 25.9898 7.93287 23.0293 7.93287ZM9.04991 13.3017C9.04991 11.4387 10.5662 9.92239 12.4292 9.92239H23.0293C24.8924 9.92239 26.4087 11.4387 26.4087 13.3017V22.9993C26.4087 24.8623 24.8924 26.3786 23.0293 26.3786H12.4292C10.5662 26.3786 9.04991 24.8623 9.04991 22.9993V13.3017Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.116014"
    />
    <path
      d="M17.7293 23.0893C20.4525 23.0893 22.6693 20.8738 22.6693 18.1494C22.6693 15.4249 20.4537 13.2094 17.7293 13.2094C15.0049 13.2094 12.7893 15.4249 12.7893 18.1494C12.7893 20.8738 15.0049 23.0893 17.7293 23.0893ZM17.7293 15.0841C19.4204 15.0841 20.7958 16.4595 20.7958 18.1506C20.7958 19.8416 19.4204 21.217 17.7293 21.217C16.0382 21.217 14.6628 19.8416 14.6628 18.1506C14.6628 16.4595 16.0382 15.0841 17.7293 15.0841Z"
      fill={fill}
    />
    <path
      d="M23.1265 14.0074C23.8599 14.0074 24.4576 13.4109 24.4576 12.6764C24.4576 11.9419 23.8611 11.3454 23.1265 11.3454C22.392 11.3454 21.7955 11.9419 21.7955 12.6764C21.7955 13.4109 22.392 14.0074 23.1265 14.0074Z"
      fill={fill}
    />
  </svg>
);
