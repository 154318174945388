import { motion } from 'framer-motion';
import { EmptyScreen } from '../../../../commonComponents/EmptyScreen/EmptyScreen';
import { EmptyFavoritesListIcon } from '../../../../images/emptyScreenIcons';

export const EmptyFavorites = () => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5 }}
    style={{
      maxWidth: '827px',
      height: 600,
      display: 'flex',
      flexDirection: 'column',
      gap: 32,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: -20,
    }}
  >
    <EmptyScreen
      Icon={EmptyFavoritesListIcon}
      title="No favorite professionals yet!"
      width={540}
      description={(
        <>
          Your office have no added any professionals to your favorites list. There are three ways
          you can add a professional to your favorites:
          <ul
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 24,
              marginTop: 20,
              marginLeft: -20,
            }}
          >
            <li>
              <b>End-of-day review:</b>
              {' '}
              Click “Add to Favorites” when prompted to leave a review for
              a professional.
            </li>
            <li>
              <b>Completed Shifts screen:</b>
              {' '}
              Go to Schedule
              {' '}
              {'>'}
              {' '}
              Completed Shifts, and view the Shift Details screen.
            </li>
            <li>
              <b>Professionals Hub:</b>
              {' '}
              You can add the professionals you have recently worked with
              or you rated highly to your favorites.
            </li>
          </ul>
        </>
      )}
      descriptionStyle={{
        textAlign: 'left',
      }}
    />
  </motion.div>
);
