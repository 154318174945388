import { all, call, fork, put, select, take } from 'redux-saga/effects';
import * as actions from '../actions';
import { apiMethods } from '../services/api';
import { apiMethodsConst } from '../services/methods';
import { trackEvent } from '../api/analytics';

export const prepareBodyPS = (user, selectedUser) => {
  const address = user?.address[0] || {};
  const selectedUserAddress = selectedUser?.address[0] || {};

  if (user?.id !== selectedUser?._id) {
    return {
      first_name: selectedUser?.first_name || '',
      last_name: selectedUser?.last_name || '',
      full_name: selectedUser?.full_name || '',
      email: selectedUser?.email_id || '',
      formatted_address: selectedUserAddress?.formatted_address || '',
      city: selectedUserAddress?.city || '',
      state: selectedUserAddress?.state || '',
      country: selectedUserAddress?.country || '',
      postal_code: selectedUserAddress?.postal_code || '',
      phone_number: selectedUser?.contact_number || '',
    };
  }
  return {
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    full_name: user?.full_name || '',
    email: user?.email_id || '',
    formatted_address: address?.formatted_address || '',
    city: address?.city || '',
    state: address?.state || '',
    country: address?.country || '',
    postal_code: address?.postal_code || '',
    phone_number: user?.contact_number || '',
  };
};

function* callAndDispatch(method, payload) {
  const response = yield call(apiMethods[method], payload);
  yield put({ type: method, payload: response });
}

function* callAndDispatchPayload(method, payload) {
  yield call(apiMethods[method], payload);
  yield put({ type: method, payload });
}

function* watchGetAllPaymnets() {
  const events = actions.createRequestTypes(actions.GET_ALL_PAYMENTS_ACTION);
  while (true) {
    yield take(actions.GET_ALL_PAYMENTS_ACTION);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.GET_ALL_PAYMENTS_REQUEST);
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error?.response?.data });
    }
  }
}

function* watchGetPlaidToken() {
  const events = actions.createRequestTypes(actions.GET_PLAID_TOKEN);
  while (true) {
    yield take(actions.GET_PLAID_TOKEN);

    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.GET_PLAID_TOKEN);
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error?.response?.data });
    }
  }
}

function* watchSubmitPlaidToken() {
  const events = actions.createRequestTypes(actions.SUBMIT_PLAID_TOKEN);
  while (true) {
    const { publicToken, metadata } = yield take(actions.SUBMIT_PLAID_TOKEN);

    yield put({ type: events.REQUEST });

    try {
      const response = yield* callAndDispatch(apiMethodsConst.SUBMIT_PLAID_TOKEN, {
        publicToken,
        metadata,
      });
      yield* callAndDispatch(apiMethodsConst.GET_ALL_PAYMENTS_REQUEST);
      yield put({ type: events.SUCCESS, payload: response?.data });
    } catch (error) {
      yield put({
        type: events.FAILURE,
        payload: error?.response?.data?.error
          ? { message: error?.response?.data?.error }
          : error?.response?.data,
      });
    }
  }
}

function* watchAddCardPayment() {
  const events = actions.createRequestTypes(actions.ADD_PAYMENT_ACTION);
  while (true) {
    const { card } = yield take(actions.ADD_PAYMENT_ACTION);
    yield put({ type: events.REQUEST });
    try {
      const { user } = yield select((store) => store.user);
      const { selectedChildOffice } = yield select((store) => store.user);

      const body = { token: card.token?.id, user: prepareBodyPS(user, selectedChildOffice) };
      yield* callAndDispatch(apiMethodsConst.ADD_PAYMENT_REQUEST, body);

      yield* callAndDispatch(apiMethodsConst.GET_ALL_PAYMENTS_REQUEST);

      yield put({ type: events.SUCCESS });
      trackEvent('Payment Method Submitted', { type: 'card' });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error?.response?.data });
    }
  }
}

function* watchDeletePayment() {
  const events = actions.createRequestTypes(actions.DELETE_PAYMENT_ACTION);
  while (true) {
    const { paymentId } = yield take(actions.DELETE_PAYMENT_ACTION);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatchPayload(apiMethodsConst.DELETE_PAYMENT_REQUEST, paymentId);
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error?.response?.data });
    }
  }
}

function* watchSelectPayment() {
  const events = actions.createRequestTypes(actions.SELECT_PAYMENT_ACTION);
  while (true) {
    const { paymentId } = yield take(actions.SELECT_PAYMENT_ACTION);

    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatchPayload(apiMethodsConst.SELECT_PAYMENT_REQUEST, paymentId);

      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error?.response?.data });
    }
  }
}

function* watchDeleteDefaultPayment() {
  const events = actions.createRequestTypes(actions.DELETE_DEFAULT_PAYMENT_ACTION);
  while (true) {
    const { oldPaymentId, newPaymentId } = yield take(actions.DELETE_DEFAULT_PAYMENT_ACTION);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatchPayload(apiMethodsConst.SELECT_PAYMENT_REQUEST, newPaymentId);
      yield* callAndDispatchPayload(apiMethodsConst.DELETE_PAYMENT_REQUEST, oldPaymentId);
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error?.response?.data });
    }
  }
}

function* watchVerifyPayment() {
  const events = actions.createRequestTypes(actions.VERIFY_BANK_ACTION);
  while (true) {
    const { paymentId, data } = yield take(actions.VERIFY_BANK_ACTION);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatchPayload(apiMethodsConst.VERIFY_BANK_REQUEST, { paymentId, data });

      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error?.response?.data });
    }
  }
}

// use them in parallel
export default function* sagaPayments() {
  yield all([
    // paymnet
    fork(watchGetAllPaymnets),
    fork(watchDeletePayment),
    fork(watchAddCardPayment),
    fork(watchSelectPayment),
    fork(watchDeleteDefaultPayment),
    fork(watchVerifyPayment),
    fork(watchGetPlaidToken),
    fork(watchSubmitPlaidToken),
  ]);
}
