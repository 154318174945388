import React from 'react';
import Portal from '../commonComponents/Portal';
import Button from '../commonComponents/Button';

export default ({ missingSkills, onButtonPress }) => (
  <Portal>
    <div className="modal">
      <div className="modal_content" style={{ minHeight: 0, padding: 20, margin: 25 }}>
        <p className="global_font f-dark" style={{ margin: '10px 10px', textAlign: 'center' }}>
          This professional is not experienced in{' '}
          <span style={{ fontWeight: 'bold' }}>{missingSkills}</span>.
        </p>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBlock: 20,
          }}
        >
          <Button text="Ok" size="small" className="mr-2" onClick={onButtonPress} />
        </div>
      </div>
    </div>
  </Portal>
);
