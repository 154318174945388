import * as actions from '../actions';
import { sortDates } from '../components/Request/multiday/utils';

const initialState = {
  jobDates: null,
  jobInfo: [],
  currentJobs: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
  case actions.SELECT_DAYS_MDB:
    return {
      ...state,
      jobDates: sortDates(Object.keys(action.jobData)),
    };

  case actions.SELECT_DEFAULT_INFO_MDB:
    return {
      ...state,
      jobInfo: state.jobDates
        .map((localDate) => ({ ...action.jobData, localDate }))
        .sort((a, b) => new Date(a.localDate) - new Date(b.localDate)),
    };

  case actions.DELETE_JOB_FROM_MDB:
    const { deletedJob } = action;
    const deletedJpobIndex = state.jobInfo.findIndex((job) => job.localDate === deletedJob);
    if (deletedJpobIndex === -1) {
      return state;
    }

    return {
      ...state,
      jobInfo: [
        ...state.jobInfo.slice(0, deletedJpobIndex),
        ...state.jobInfo.slice(deletedJpobIndex + 1),
      ],
    };

  case actions.EDIT_JOB_INFO_MDB:
    const { jobData } = action;
    const newJobDate = action.jobDate;
    const editJobIndex = state.jobInfo.findIndex((job) => job.localDate === newJobDate);
    if (editJobIndex === -1) {
      return state;
    }

    return {
      ...state,
      jobInfo: [
        ...state.jobInfo.slice(0, editJobIndex),
        { localDate: newJobDate, ...jobData },
        ...state.jobInfo.slice(editJobIndex + 1),
      ],
    };

  case actions.CLEAR_MULTIDAY_FORM:
  case 'MULTIDAY_BOOKING_INVITE_POST_SUCCESS':
  case 'MULTIDAY_BOOKING_POST_SUCCESS':
    return {
      ...state,
      jobInfo: [],
      jobDates: null,
    };

  default:
    return state;
  }
};
