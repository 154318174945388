import React from 'react';
import { Colors } from '../../themes/colors';

const ArrowRightIcon = ({ color = Colors.secondary_500 }) => (
  <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.98223 7.63636L0.366117 1.8623C-0.122039 1.43627 -0.122039 0.745546 0.366117 0.31952C0.854272 -0.106507 1.64573 -0.106507 2.13388 0.31952L9.63388 6.86497C10.122 7.291 10.122 7.98173 9.63388 8.40775L2.13388 14.9532C1.64573 15.3792 0.854272 15.3792 0.366117 14.9532C-0.122039 14.5272 -0.122039 13.8365 0.366117 13.4104L6.98223 7.63636Z"
      fill={color}
    />
  </svg>
);

export default ArrowRightIcon;
