import { useSelector } from 'react-redux';
import { Center, Column, Link, Text } from '../../../../../commonComponents';
import { renderPoints } from '../../../../../commonComponents/RenderListFunc';
import { Links } from '../../../../../constants';
import { Colors } from '../../../../../themes/colors';
import ConfirmPopup from '../../../../shared/ConfirmPopup';
import { isEmployeeOption } from '../../../../WorkerClassification/utils';

export const GuidelinesPopup = ({ onClose }) => {
  const user = useSelector((state) => state.user.user);
  const isEmployee = isEmployeeOption(user);

  return (
    <ConfirmPopup
      rightButtonAction={onClose}
      rightButtonText="Close"
      modalStyle={{
        width: 700,
      }}
      description={() => (
        <Center paddingHorizontal={40} gap={0}>
          <Text color={Colors.primary_500} bold fontSize={22}>
            GoTu&apos;s Shift Guidelines
          </Text>

          <Column marginTop={32}>
            <Text color={Colors.neutral_500} marginLeft={30}>
              In order to be a good member of the community and receive the best service, please
              remember to follow these guidelines:
            </Text>

            <Column gap={16} marginTop={20}>
              {renderPoints(
                'I acknowledge that there is no charge to post shifts. The booking fee will be billed for each individual shift after the dental professional is confirmed by your office. The professional pay will be billed no more than 28 days prior to shift date.',
              )}
              {renderPoints(
                'I agree to make any edits, changes, or cancellations to this shift through GoTu directly.',
              )}
              {renderPoints(
                'I agree to schedule all temp shifts with this dental professional through GoTu.',
              )}
              {renderPoints('I agree to follow all local and national health guidelines.')}
              {isEmployee &&
                renderPoints(
                  'I agree to let the professional take earned rest breaks in compliance with my state’s employment laws, if applicable.',
                )}
              {renderPoints(
                <>
                  I agree with the{' '}
                  <Link
                    href={Links.termsOfUse}
                    rel="noopener noreferrer"
                    text="Offices Cancellation Policy"
                  />
                  . Filled shifts are subject to cancellation fees up to 7 days before the start of
                  the shift. For details, please refer to the Terms and Conditions.
                </>,
              )}
            </Column>
          </Column>

          <Center marginTop={32} marginBottom={-32}>
            <Text color={Colors.neutral_500} textAlign="center">
              If you have any questions, please call or text our Customer Support at
              <br />
              786-460-2170.
            </Text>
          </Center>
        </Center>
      )}
    />
  );
};
