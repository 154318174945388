export const removePrefixes = (obj) => {
  const newObj = {};

  Object.entries(obj).forEach(([key, value]) => {
    const newKey = key.replace(/^specialty_|procedure_/g, '');
    const newValue = value.map((val) => val.replace(/^procedure_/g, ''));

    if (newValue.length > 0) {
      newObj[newKey] = newValue;
    }
  });

  return newObj;
};

export const getCompanyMatchLabel = ({
  averageRating,
  hasBadges,
  hasWorkedWithDSO,
  hasWorkedWithOffice,
  isDSO,
  officeName,
}) => {
  if (averageRating >= 4.8 && hasBadges) {
    if (isDSO && hasWorkedWithDSO) {
      return { label: 'Company Experience', text: `Has worked with ${officeName} before.` };
    }

    if (!isDSO && hasWorkedWithOffice) {
      return { label: 'Returning Professional', text: `Has worked with ${officeName} before.` };
    }
  }

  return null;
};
