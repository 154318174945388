export const WCConfirmedWorkerTypeIcon = () => (
  <svg
    width="172"
    height="171"
    viewBox="0 0 172 171"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="85.8278" cy="85.44" rx="85.8278" ry="85.44" fill="#EBF4F5" />
    <path
      d="M70.05 34.8994C66.4878 34.8994 63.6 37.7872 63.6 41.3494C63.6 44.9116 66.4878 47.7994 70.05 47.7994H82.9501C86.5123 47.7994 89.4001 44.9116 89.4001 41.3494C89.4001 37.7872 86.5123 34.8994 82.9501 34.8994H70.05Z"
      fill="#A4E0E0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.8 54.2494C37.8 47.1249 43.5756 41.3494 50.7 41.3494C50.7 52.0361 59.3633 60.6994 70.05 60.6994H82.9501C93.6368 60.6994 102.3 52.0361 102.3 41.3494C109.425 41.3494 115.2 47.1249 115.2 54.2494V125.199C115.2 132.324 109.425 138.099 102.3 138.099H50.7001C43.5756 138.099 37.8 132.324 37.8 125.199V54.2494ZM58.3 80.0494C54.7377 80.0494 52.8709 82.6176 52.8709 85.414C52.8709 88.2103 55.2509 90.7785 58.3 90.7785C60.8477 90.7785 63.3916 88.5875 63.6646 85.414C63.3916 82.6176 61.8622 80.0494 58.3 80.0494ZM76.5 81.1348C72.9378 81.1348 70.05 82.6176 70.05 85.414C70.05 88.2103 72.9378 89.6931 76.5 89.6931H95.85C99.4123 89.6931 101.924 88.0448 102.3 85.414C101.924 82.6176 99.4123 81.1348 95.85 81.1348H76.5ZM58.2355 105.849C55.7936 105.954 52.8709 107.652 52.8709 111.214C52.8709 113.926 55.4544 116.579 58.2355 116.579H58.3C60.6781 116.579 63.6646 114.095 63.6646 111.214C63.6646 107.82 60.6781 105.954 58.3 105.849H58.2355ZM76.5 106.935C72.9378 106.935 70.2254 108.402 70.05 111.214C69.9042 113.552 72.9378 115.493 76.5 115.493H95.85C99.4123 115.493 102.3 113.76 102.3 111.214C102.3 108.668 99.4123 106.935 95.85 106.935H76.5Z"
      fill="#A4E0E0"
    />
    <path
      d="M69.9998 38.6667H59.3332C53.4421 38.6667 48.6665 43.4423 48.6665 49.3333V113.333C48.6665 119.224 53.4421 124 59.3332 124H112.667C118.558 124 123.333 119.224 123.333 113.333V49.3333C123.333 43.4423 118.558 38.6667 112.667 38.6667H102M69.9998 38.6667C69.9998 44.5577 74.7755 49.3333 80.6665 49.3333H91.3332C97.2242 49.3333 102 44.5577 102 38.6667M69.9998 38.6667C69.9998 32.7756 74.7755 28 80.6665 28H91.3332C97.2242 28 102 32.7756 102 38.6667M85.9998 76H102M85.9998 97.3333H102M69.9998 76H70.0532M69.9998 97.3333H70.0532"
      stroke="#0B8397"
      strokeWidth="8.7"
      strokeLinecap="round"
    />
    <path
      d="M142.169 119.418C142.169 131.799 131.545 142.17 118.017 142.17C104.489 142.17 93.8644 131.799 93.8644 119.418C93.8644 107.037 104.489 96.6662 118.017 96.6662C131.545 96.6662 142.169 107.037 142.169 119.418Z"
      stroke="#EBF4F5"
      strokeWidth="7.72881"
    />
    <path
      d="M137.339 119.024C137.137 129.736 128.893 138.281 118.033 138.305C107.228 138.329 98.7132 129.842 98.695 119.02C98.6768 108.149 107.212 99.6774 117.96 99.6612C128.85 99.6449 137.185 108.232 137.339 119.024Z"
      fill="#A4E0E0"
    />
    <circle cx="118.017" cy="118.017" r="24.1525" stroke="#EBF4F5" strokeWidth="7.72881" />
    <path
      d="M129.161 118.217C130.146 117.232 130.146 115.635 129.161 114.65C128.176 113.665 126.579 113.665 125.594 114.65L116.861 123.384L113.386 119.909C112.401 118.924 110.804 118.924 109.819 119.909L110.673 120.763L109.819 119.909C108.834 120.894 108.834 122.491 109.819 123.476L115.078 128.734C116.063 129.719 117.66 129.719 118.645 128.734L129.161 118.217Z"
      fill="#EBF4F5"
      stroke="#EBF4F5"
      strokeWidth="2.41525"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M130.127 114.352C131.112 113.367 131.112 111.77 130.127 110.785C129.142 109.8 127.545 109.8 126.56 110.785L117.827 119.518L114.352 116.043C113.367 115.058 111.77 115.058 110.785 116.043L111.639 116.897L110.785 116.043C109.8 117.028 109.8 118.625 110.785 119.61L116.044 124.869C117.029 125.854 118.626 125.854 119.611 124.869L130.127 114.352Z"
      fill="#0B8397"
      stroke="#0B8397"
      strokeWidth="2.41525"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="120.432" cy="116.568" r="22.7034" stroke="#0B8397" strokeWidth="7.72881" />
  </svg>
);
