export const CalendarTodayFilterIcon = () => (
  <svg
    width="172"
    height="171"
    viewBox="0 0 172 171"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="85.8278" cy="85.44" rx="85.8278" ry="85.44" fill="#EBF4F5" />
    <path
      d="M110.332 50.9663C110.332 63.1245 100.476 72.9805 88.3183 72.9805C76.1602 72.9805 66.3041 63.1245 66.3041 50.9663C66.3041 38.8082 76.1602 28.9521 88.3183 28.9521C100.476 28.9521 110.332 38.8082 110.332 50.9663Z"
      fill="#A4E0E0"
    />
    <path
      d="M79 83C86.2232 82.2055 93.6388 81.704 101 87.5C101 87.5 99.0914 106.195 96.7827 117.395L95.2701 124.733H41.5864C41.5864 124.733 35.6728 140.29 41.5864 117.395C47.5 94.5 65.1796 84.5202 79 83Z"
      fill="#A4E0E0"
    />
    <path
      d="M95.0231 132.002C93.0521 131.385 91.0877 130.782 89.4842 129.36C87.1282 127.271 85.7041 124.672 85.6626 121.55C85.5598 113.644 85.562 105.736 85.6451 97.8306C85.6954 93.2411 88.852 89.8592 93.3758 89.2204C93.6646 89.1789 93.9534 89.1417 94.3668 89.0848C94.3668 88.2273 94.3537 87.3632 94.369 86.4991C94.4127 83.8281 96.2722 81.8987 98.7703 81.9162C101.227 81.9337 103.06 83.8435 103.113 86.451C103.13 87.2866 103.115 88.1223 103.115 89.0432H118.867C118.867 88.2076 118.854 87.3479 118.869 86.4882C118.915 83.8238 120.786 81.8922 123.282 81.9162C125.738 81.9403 127.563 83.85 127.611 86.4619C127.626 87.3304 127.613 88.1988 127.613 89.1111C130.538 89.297 132.999 90.3317 134.591 92.7336C135.394 93.9433 135.788 95.4243 136.365 96.7828V122.596C136.298 122.755 136.199 122.911 136.164 123.079C135.554 125.892 134.211 128.305 131.78 129.863C130.317 130.799 128.576 131.304 126.959 132.004H95.0209L95.0231 132.002ZM94.3668 103.389V104.334C94.3668 109.689 94.3646 115.044 94.3668 120.399C94.3668 122.48 95.1324 123.252 97.1997 123.252C106.381 123.256 115.56 123.256 124.741 123.252C126.867 123.252 127.615 122.489 127.617 120.334C127.62 115.016 127.617 109.696 127.617 104.378C127.617 104.061 127.585 103.746 127.565 103.391H94.369L94.3668 103.389Z"
      fill="#EBF4F5"
    />
    <g opacity="0.8">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M104.297 89.3428C102.705 89.3428 101.414 90.6276 101.414 92.2126V95.0825H98.5315C95.3472 95.0825 92.7657 97.6522 92.7657 100.822V129.521C92.7657 132.69 95.3472 135.26 98.5315 135.26H133.126C136.31 135.26 138.892 132.69 138.892 129.521V100.822C138.892 97.6522 136.31 95.0825 133.126 95.0825H130.243V92.2126C130.243 90.6276 128.952 89.3428 127.36 89.3428C125.768 89.3428 124.477 90.6276 124.477 92.2126V95.0825H107.18V92.2126C107.18 90.6276 105.889 89.3428 104.297 89.3428ZM104.297 105.51C102.705 105.51 101.414 106.795 101.414 108.38C101.414 109.965 102.705 111.25 104.297 111.25H127.36C128.952 111.25 130.243 109.965 130.243 108.38C130.243 106.795 128.952 105.51 127.36 105.51H104.297Z"
        fill="#A4E0E0"
      />
    </g>
    <path
      d="M108.622 92.5661V83M127.841 92.5661V83M106.847 104.952H129.34M99.6154 127.847H136.848C139.501 127.847 141.653 125.706 141.653 123.064V92.5661C141.653 89.9245 139.501 87.7831 136.848 87.7831H99.6154C96.9618 87.7831 94.8107 89.9245 94.8107 92.5661V123.064C94.8107 125.706 96.9618 127.847 99.6154 127.847Z"
      stroke="#0B8397"
      strokeWidth="8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M86.1886 20C87.8664 20.3041 89.5815 20.4725 91.2134 20.9363C103.107 24.3182 110.654 32.0271 113.135 44.1351C116.427 60.207 105.723 75.907 89.6777 78.882C86.5123 79.4683 83.231 79.442 80.0022 79.687C73.8618 80.1508 68.0539 81.8199 62.5653 84.574C54.5699 88.586 48.1538 94.3764 43.3237 101.91C40.1868 106.804 38.0627 112.117 36.8639 117.796C36.3192 120.371 37.9861 122.733 40.5849 123.16C41.1209 123.248 41.6721 123.25 42.219 123.25C53.5527 123.254 69.8841 123.261 81.2178 123.245C82.6287 123.245 83.8953 123.552 84.9038 124.58C87.0038 126.719 86.3585 130.272 83.6437 131.569C83.32 131.724 82.9875 131.86 82.6572 132.004H39.594C39.4321 131.936 39.2768 131.834 39.1083 131.805C34.8164 131.04 31.5919 128.743 29.5838 124.893C28.8575 123.501 28.5163 121.911 28 120.41C28 119.317 28 118.223 28 117.129C28.5928 114.887 29.0631 112.603 29.7982 110.407C35.1576 94.4092 45.4544 82.7846 60.7956 75.6904C62.5019 74.9007 64.2825 74.2707 66.1354 73.5247C65.7023 73.155 65.3391 72.8575 64.9869 72.5469C56.3505 64.9518 52.658 55.4163 54.8914 44.1439C57.2934 32.0205 64.8929 24.316 76.7887 20.9341C78.4184 20.4725 80.1357 20.3041 81.8135 20C83.2726 20 84.7295 20 86.1886 20ZM63.0028 49.7221C62.9744 61.3554 72.3939 70.6897 84.2176 70.74C95.4812 70.7881 104.942 61.2701 104.997 49.8337C105.054 38.29 95.6256 28.7983 84.0557 28.7523C72.5143 28.7064 63.0313 38.1522 63.0028 49.7221Z"
      fill="#0B8397"
    />
  </svg>
);
