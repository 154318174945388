import React from 'react';
import { Colors } from '../../themes/colors';

const HeartIcon = ({ color = Colors.primary_500, width = 24, height = 24, outline }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.3694 4.91232C18.2282 2.7981 14.7495 2.7981 12.6116 4.90895L12.0003 5.51349L11.389 4.90895C9.25118 2.7981 5.77251 2.7981 3.63127 4.91232C1.79399 6.75636 1.4934 9.62711 2.80719 12.7917C4.37091 16.5642 8.33254 20.6305 12.0003 20.6305C15.6681 20.6305 19.6264 16.5608 21.1901 12.7917C22.5073 9.62711 22.2067 6.75636 20.3694 4.91232Z"
      fill={!outline ? color : 'transparent'}
      stroke={!outline ? Colors.white : color}
      strokeWidth={!outline ? 0 : 2}
    />
  </svg>
);

export default HeartIcon;
