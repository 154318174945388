import { useEffect, useState } from 'react';
import ReactSwitch from 'react-switch';
import { getAutoFillInfo } from '../../../../../constants';
import AutoConfirmIcon from '../../../../../images/autofill-icon.svg';
import { Colors } from '../../../../../themes/colors';
import { RemoteConfigUtils } from '../../../../../utils/RemoteConfigUtils';
import Tooltip from '../../../../Tooltip';
import { CollapsibleRow } from '../CollapsibleRow/CollapsibleRow';
import { Title } from '../CollapsibleRow/Title';

export const AutoConfirmRow = ({ onConfirm, professionType, skills, defaultValue }) => {
  const [isEnabled, setIsEnabled] = useState({
    eligibleProfessionals: defaultValue?.eligibleProfessionals,
    favorites: defaultValue?.favorites,
    highlyRated: defaultValue?.highlyRated,
  });
  const autoFillInfo = getAutoFillInfo(professionType, skills);

  const tooltipDescription = (
    <>
      Professional’s eligibility criteria:
      <br />
      <br />
      {'\u2022  '}
      Has at least a {RemoteConfigUtils.getAutofillMinAttendanceScore()}% attendance score for
      previous shifts worked.
      <br />
      {'\u2022  '}
      Has a minimum of 1 year working experience.
      <br />
      {'\u2022  '}
      Has received exceptional performance ratings on over 95% of completed shifts.
      <br />
      {'\u2022  '}
      Has completed at least 3 shifts.
      <br />
      <br />
      Note: You may change your auto-confirm preference at any time by visiting the Shift Details
      page.
    </>
  );

  useEffect(() => {
    onConfirm({ isEnabled });
  }, [isEnabled]);

  return (
    <CollapsibleRow
      isOpen
      disabled
      customCardStyles={{
        borderColor: Colors.neutral_100,
      }}
      customImageStyles={{
        marginRight: 8,
        marginLeft: 4,
      }}
      titlePosition="flex-start"
      TitleComponent={() => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Title text="Auto-Confirm:" />
          <div
            style={{
              marginLeft: 8,
              position: 'relative',
              top: '2px',
            }}
          >
            <Tooltip content={tooltipDescription} />
          </div>
        </div>
      )}
      ValueComponent={() =>
        autoFillInfo?.show && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 12,
            }}
          >
            <AutoConfirmOptions
              options={[
                {
                  key: 'favorites',
                  value: isEnabled.favorites,
                  label: 'Favorites',
                },
                {
                  key: 'highlyRated',
                  value: isEnabled.highlyRated,
                  label: 'Your Highly-Rated',
                },
                {
                  key: 'eligibleProfessionals',
                  value: isEnabled.eligibleProfessionals,
                  label: 'Eligible Professionals',
                },
              ]}
              onChange={setIsEnabled}
            />
          </div>
        )
      }
      icon={AutoConfirmIcon}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: -24,
          padding: '0 60px',
        }}
      >
        <span
          style={{
            color: Colors.neutral_500,
            fontStyle: 'italic',
          }}
        >
          {autoFillInfo?.message}
        </span>
      </div>
    </CollapsibleRow>
  );
};

export const AutoConfirmOptions = ({ options, onChange }) =>
  options.map((option) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        fontSize: 16,
        color: Colors.neutral_600,
        gap: 12,
      }}
    >
      <ReactSwitch
        checked={option.value}
        onChange={() =>
          onChange((prev) => ({
            ...prev,
            [option.key]: !prev[option.key],
          }))
        }
        onColor={Colors.secondary_500}
        onHandleColor={Colors.white}
        handleDiameter={20}
        uncheckedIcon={false}
        checkedIcon={false}
        offColor={Colors.neutral_100}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.35)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={25}
        width={50}
        className="react-switch"
        id="material-switch"
      />
      {option.label}
    </div>
  ));
