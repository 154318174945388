export const EmojiHappy = ({ selected = false }) => (
  <svg
    width="58"
    height="58"
    viewBox="0 0 58 58"
    fill="none"
    data-testid={`emoji-happy-${selected ? 'selected' : 'unselected'}`}
  >
    <g>
      <path
        id="Icon"
        d="M50.75 29C50.75 41.0122 41.0122 50.75 29 50.75C16.9878 50.75 7.25 41.0122 7.25 29C7.25 16.9878 16.9878 7.25 29 7.25C41.0122 7.25 50.75 16.9878 50.75 29Z"
        fill={selected ? '#4FC0CF' : '#FFFFFF'}
        stroke="#4FC0CF"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="mouth"
        d="M35.8348 35.8353C32.0597 39.6104 25.9391 39.6104 22.1641 35.8353"
        stroke={selected ? '#FFFFFF' : '#4FC0CF'}
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="eyes"
        d="M21.75 24.1666H21.7742M36.25 24.1666H36.2742"
        stroke={selected ? '#FFFFFF' : '#4FC0CF'}
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
