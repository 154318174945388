export const EmptyFilteredFavorites = () => (
  <svg
    width="181"
    height="181"
    viewBox="0 0 181 181"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="85.2632" cy="94.9866" rx="85.2632" ry="86.0134" fill="#EBF4F5" />
    <path
      d="M90.2153 123.988L90.2158 123.999C90.4117 128.954 90.6216 134.262 91.9626 138.332C92.6342 140.37 93.5925 142.108 94.9838 143.337C96.3777 144.568 98.1971 145.279 100.573 145.279C103.045 145.279 105.32 144.071 107.336 142.007C109.352 139.945 111.117 137.021 112.575 133.571C115.49 126.671 117.186 117.639 117.186 109.096C117.186 104.019 119.548 100.802 122.573 96.6883C126.226 91.7242 130.382 86.0746 130.382 76.3105C130.382 66.0915 127.058 58.6823 122.076 53.8279C117.096 48.9749 110.467 46.6847 103.872 46.6847C97.89 46.6847 94.6605 48.8229 92.0804 50.5338C90.9287 51.2965 89.9094 51.9714 88.6872 52.4577C87.4674 52.943 86.0409 53.2419 84.079 53.2419C82.1171 53.2419 80.6906 52.943 79.4708 52.4577C78.2486 51.9714 77.2292 51.2965 76.0775 50.5338C73.4975 48.8229 70.268 46.6847 64.2858 46.6847C57.6909 46.6847 51.0622 48.9749 46.0816 53.8279C41.0996 58.6823 37.7764 66.0915 37.7764 76.3105C37.7764 86.0746 41.9321 91.7242 45.5847 96.6883C48.6098 100.802 50.9718 104.019 50.9718 109.096C50.9718 117.639 52.6676 126.671 55.5831 133.571C57.0408 137.021 58.8059 139.945 60.8218 142.007C62.8381 144.071 65.1128 145.279 67.5846 145.279C69.9609 145.279 71.7803 144.568 73.1741 143.337C74.5654 142.108 75.5238 140.37 76.1954 138.332C77.5363 134.262 77.7463 128.954 77.9422 123.999L77.9427 123.988C78.1605 118.499 78.4368 113.972 79.2981 110.817C79.7286 109.241 80.302 108.02 81.0768 107.194C81.8475 106.373 82.8233 105.936 84.079 105.936C85.3347 105.936 86.3105 106.373 87.0812 107.194C87.856 108.02 88.4294 109.241 88.8599 110.817C89.7212 113.972 89.9975 118.499 90.2153 123.988Z"
      fill="#A4E0E0"
      stroke="#A4E0E0"
      strokeWidth="0.236842"
    />
    <path
      d="M106.346 159.198C93.9914 159.198 93.4062 145.191 92.8903 132.833C92.3181 119.173 91.438 110.218 84.671 110.218C77.904 110.218 77.024 119.173 76.4518 132.833C75.9359 145.191 75.3506 159.198 62.9957 159.198C50.2218 159.198 41.3204 135.535 41.3204 114.3C41.3204 107.924 38.1833 103.882 34.211 98.765C29.4135 92.5881 23.9802 85.588 23.9802 73.4832C23.9802 48.1062 41.3985 36.7483 58.6607 36.7483C66.4753 36.7483 70.6876 39.3905 74.0704 41.5157L74.0749 41.5185C77.0957 43.4138 79.4831 44.9116 84.671 44.9116C89.8589 44.9116 92.2463 43.4138 95.2671 41.5185L95.2717 41.5157C98.6545 39.3905 102.867 36.7483 110.681 36.7483C127.944 36.7483 145.362 48.1062 145.362 73.4832C145.362 85.588 139.929 92.5881 135.131 98.765C131.159 103.882 128.022 107.924 128.022 114.3C128.022 135.535 119.12 159.198 106.346 159.198ZM84.671 102.055C100.28 102.055 100.987 118.942 101.555 132.512V132.512C101.916 141.194 102.326 151.035 106.346 151.035C110.226 151.035 119.351 135.523 119.351 114.3C119.351 105.259 123.815 99.5078 128.134 93.9472L128.138 93.9421C132.537 88.2746 136.692 82.9212 136.692 73.4832C136.692 52.3946 122.681 44.9116 110.681 44.9116C105.493 44.9116 103.106 46.4094 100.085 48.3047L100.081 48.3075C96.6979 50.4327 92.4857 53.0749 84.671 53.0749C76.8564 53.0749 72.6441 50.4327 69.2613 48.3075L69.2568 48.3047C66.236 46.4094 63.8486 44.9116 58.6607 44.9116C46.6612 44.9116 32.6503 52.3946 32.6503 73.4832C32.6503 82.9212 36.8051 88.2746 41.2038 93.9421L41.2077 93.9472C45.5269 99.5078 49.9906 105.259 49.9906 114.3C49.9906 135.523 59.1159 151.035 62.9957 151.035C67.0158 151.035 67.4262 141.194 67.7874 132.512L67.7874 132.512C68.3553 118.942 69.062 102.055 84.671 102.055Z"
      fill="#0B8397"
    />
    <path
      d="M106.346 159.198C93.9914 159.198 93.4062 145.191 92.8903 132.833C92.3181 119.173 91.438 110.218 84.671 110.218C77.904 110.218 77.024 119.173 76.4518 132.833C75.9359 145.191 75.3506 159.198 62.9957 159.198C50.2218 159.198 41.3204 135.535 41.3204 114.3C41.3204 107.924 38.1833 103.882 34.211 98.765C29.4135 92.5881 23.9802 85.588 23.9802 73.4832C23.9802 48.1062 41.3985 36.7483 58.6607 36.7483C66.4753 36.7483 70.6876 39.3905 74.0704 41.5157L74.0749 41.5185C77.0957 43.4138 79.4831 44.9116 84.671 44.9116C89.8589 44.9116 92.2463 43.4138 95.2671 41.5185L95.2717 41.5157C98.6545 39.3905 102.867 36.7483 110.681 36.7483C127.944 36.7483 145.362 48.1062 145.362 73.4832C145.362 85.588 139.929 92.5881 135.131 98.765C131.159 103.882 128.022 107.924 128.022 114.3C128.022 135.535 119.12 159.198 106.346 159.198ZM84.671 102.055C100.28 102.055 100.987 118.942 101.555 132.512V132.512C101.916 141.194 102.326 151.035 106.346 151.035C110.226 151.035 119.351 135.523 119.351 114.3C119.351 105.259 123.815 99.5078 128.134 93.9472L128.138 93.9421C132.537 88.2746 136.692 82.9212 136.692 73.4832C136.692 52.3946 122.681 44.9116 110.681 44.9116C105.493 44.9116 103.106 46.4094 100.085 48.3047L100.081 48.3075C96.6979 50.4327 92.4857 53.0749 84.671 53.0749C76.8564 53.0749 72.6441 50.4327 69.2613 48.3075L69.2568 48.3047C66.236 46.4094 63.8486 44.9116 58.6607 44.9116C46.6612 44.9116 32.6503 52.3946 32.6503 73.4832C32.6503 82.9212 36.8051 88.2746 41.2038 93.9421L41.2077 93.9472C45.5269 99.5078 49.9906 105.259 49.9906 114.3C49.9906 135.523 59.1159 151.035 62.9957 151.035C67.0158 151.035 67.4262 141.194 67.7874 132.512L67.7874 132.512C68.3553 118.942 69.062 102.055 84.671 102.055Z"
      stroke="#0B8397"
    />
    <ellipse cx="156" cy="25.5" rx="25" ry="25.5" fill="#DFEBEE" />
    <g opacity="0.8">
      <path
        d="M165.386 20.6405C162.565 17.8309 157.983 17.8309 155.167 20.636L154.361 21.4394L153.556 20.636C150.74 17.8309 146.157 17.8309 143.336 20.6405C140.916 23.0911 140.52 26.9061 142.251 31.1116C144.311 36.125 149.529 41.5289 154.361 41.5289C159.193 41.5289 164.407 36.1205 166.467 31.1116C168.202 26.9061 167.806 23.0911 165.386 20.6405Z"
        fill="#A4E0E0"
      />
    </g>
    <path
      d="M168.719 15.9386C165.557 12.7896 160.421 12.7896 157.265 15.9336L156.362 16.834L155.459 15.9336C152.303 12.7896 147.167 12.7896 144.005 15.9386C141.293 18.6852 140.849 22.9611 142.789 27.6745C145.097 33.2935 150.947 39.3501 156.362 39.3501C161.777 39.3501 167.622 33.2885 169.93 27.6745C171.875 22.9611 171.431 18.6852 168.719 15.9386Z"
      stroke="#0B8397"
      strokeWidth="6"
    />
  </svg>
);
