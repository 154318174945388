import React from 'react';
import './Pressable.scss';

export const Pressable = ({ onPress, children }) => {
  const handleClick = (event) => {
    if (onPress) onPress(event);
  };

  return (
    <button
      type="button"
      className="pressable"
      onClick={handleClick}
    >
      {children}
    </button>
  );
};