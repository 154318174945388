import { Colors } from '../themes/colors';

export const Text = ({
  children,
  style,
  marginRight,
  marginLeft,
  marginBottom,
  marginTop,
  width,
  color = Colors.neutral_600,

  // TODO(lucky): default font size should be inherited from body to avoid inline styling on html
  fontSize = 16,

  bold = false,
  textAlign = 'left',
  ...props
}) => (
  <span
    {...props}
    style={{
      // TODO(lucky): remove in the future, this can be inherited from body
      fontFamily: 'Nunito',

      // FIXME(lucky): default font weight should be normal/400
      fontWeight: bold ? 700 : 500,
      fontSize,
      width,
      color,
      textAlign,
      marginRight,
      marginLeft,
      marginBottom,
      marginTop,
      ...style,
    }}
  >
    {children}
  </span>
);
