import { fetchActionRequiredJobs, fetchOpenJobs, fetchScheduledJobs } from '../../../actions';
import { CalendarActionNeededFilterIcon, CalendarOpenFilterIcon, CalendarTodayFilterIcon } from '../../../images/emptyScreenIcons';
import { FilterStatusEnum } from '../../../utils/Enum';

export const headerOpenTabTitles = ['Office', 'Shift Details', 'Profession', 'Rate', 'Send to'];

export const headerPendingTabTitles = [
  'Office',
  'Shift Details',
  'Profession',
  'Candidates',
  'Send to',
  'More',
];

export const headerScheduledTabTitles = [
  'Office',
  'Professional',
  'Shift Details',
  'Profession',
  'Rate',
];

export const getInitialTab = (type) => {
  if (type === FilterStatusEnum.OPEN) return 0;
  if (type === FilterStatusEnum.PENDING) return 1;
  return 2;
};

export const getTypeByTab = (activeTab) => {
  if (activeTab === 0) return FilterStatusEnum.OPEN;
  if (activeTab === 1) return FilterStatusEnum.PENDING;
  return FilterStatusEnum.SCHEDULED;
};

export const fetchJobList = (type, data) => {
  switch (type) {
  case FilterStatusEnum.OPEN:
    return fetchOpenJobs(data);
  case FilterStatusEnum.PENDING:
    return fetchActionRequiredJobs(data);
  case FilterStatusEnum.SCHEDULED:
    return fetchScheduledJobs(data);
  default:
    return () => {};
  }
};

export const getEmptyFilteredScreen = (filter) => {
  switch (filter) {
  case FilterStatusEnum.SCHEDULED:
    return {
      title: 'Ready to schedule your next candidate!',
      description: (
        <>
          You have no candidates scheduled.
          <br />
          Click on the “Action Needed” tab to see if any
          <br />
          candidates have applied to your posted shifts!
        </>
      ),
      Icon: CalendarTodayFilterIcon,
    };
  case FilterStatusEnum.PENDING:
    return {
      title: 'Waiting for candidates to apply!',
      description: (
        <>
          No professionals have applied to your shifts yet.
          <br />
          Check back soon, someone might apply at any moment!
        </>
      ),
      Icon: CalendarActionNeededFilterIcon,
    };
  default:
    return {
      title: 'Post shifts, let us help you!',
      description: (
        <>
          You have not posted any shifts.
          <br />
          Request a temp, to find the right professionals
          <br />
          and secure the coverage you need!
        </>
      ),
      Icon: CalendarOpenFilterIcon,
    };
  }
};
