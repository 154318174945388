import React from 'react';
import defaultProfileImage from '../../../images/defaultProfileImage.png';

import { Colors } from '../../../themes/colors';

export default ({ selectedCandidate }) => {
  if (!selectedCandidate) {
    return <div />;
  }
  return (
    <div style={{ marginTop: 10 }}>
      <div
        style={{
          color: Colors.primary_500,
          marginTop: 0,
          fontSize: 24,
          fontFamily: 'Nunito',
          fontWeight: 'bold',
          marginBottom: 12,
          textAlign: 'center',
        }}
      >
        Virtual Resume
      </div>
      <img
        alt="profile"
        src={defaultProfileImage}
        style={{ resizeMode: 'contain', height: 75, width: 75 }}
      />

      <div
        style={{
          color: Colors.neutral_600,
          fontSize: 25,
          fontWeight: 'bold',
          fontFamily: 'Nunito',
          marginBottom: 5,
          marginTop: 10,
        }}
      >
        {selectedCandidate.professional.first_name} {selectedCandidate.professional.last_name}
      </div>
      <div style={styles.titleBlue}>Registered Dental Hygienist</div>
      <div style={styles.descriptionGray}>{selectedCandidate.professional.contact_number}</div>
      <div style={styles.descriptionGray}>{selectedCandidate.professional.email_id}</div>
    </div>
  );
};

const styles = {
  titleBlue: {
    color: Colors.primary_500,
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: 'Nunito',
    marginBottom: 5,
  },
  descriptionGray: {
    color: Colors.neutral_600,
    fontSize: 16,
    fontFamily: 'Nunito',
    marginBottom: 5,
  },
  roundButton: {
    width: '45%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};
