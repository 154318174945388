import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FETCH_JOB_ACTION, fetchJob } from '../../../actions';
import { createLoadingSelector } from '../../../api/selectors';
import BackButton from '../../../commonComponents/BackButton';
import CardSpinner from '../../../commonComponents/CardSpinner';
import { Links } from '../../../constants';
import { showCandidateOvertime } from '../../../growthbook';
import roundedPattern from '../../../images/rounded_pattern.png';
import { Colors } from '../../../themes/colors';
import Tooltip from '../../Tooltip';
import { isEmployeeOption } from '../../WorkerClassification/utils';
import { getJobStatus, returnTransactionComponent } from './utils';
import { UserSubProfession } from '../../../enums/UserProfession';

const adjustmentMadeTooltip = (
  <>
    If “Yes” is indicated in this field, there has been an adjustment to the hours of the shift that
    resulted on an additional charge or a refund to the original total paid. <br />
    <br /> If “No” is indicated in this field, there has not been an adjustment made that impacts
    the initial payment amount.
  </>
);

const convenienceFeeTooltip = (
  <>
    GoTu charges a 3.5% convenience fee on Credit Card transactions. If you do not wish to be
    charged the convenience fee, please update your billing to E-check/ACH.
  </>
);

const overtimeTooltip =
  'Overtime pay (1.5x rate) is required for hours that exceed the state’s daily and/or weekly regular working hour limits.';

export default function TransactionsInfo({ match }) {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);
  const job = useSelector((state) => state.job.job);

  const loadingSelector = createLoadingSelector([FETCH_JOB_ACTION]);
  const isLoading = useSelector((state) => loadingSelector(state));

  const renderRow = ({ title, description, timezone, crossedDescription }) => (
    <div className="row_style" style={{ margin: 0, paddingBottom: 0, borderBottom: 'none' }}>
      <div style={{ display: 'flex', alignItems: 'center', columnGap: 10 }}>
        <p
          className="global_font bold_font"
          style={{ margin: 0, fontSize: 16, color: Colors.neutral_600 }}
        >
          {title}
        </p>

        {timezone && (
          <div
            style={{
              backgroundColor: Colors.neutral_300,
              padding: '1px 4px',
              borderRadius: 100,
            }}
          >
            <p
              style={{
                margin: 0,
                fontSize: 12,
                fontWeight: 'bold',
                color: 'white',
              }}
            >
              EDT
            </p>
          </div>
        )}
      </div>

      <p
        className="global_font"
        style={{
          margin: 0,
          color: Colors.neutral_600,
          fontSize: 16,
          textAlign: 'right',
          textDecoration: crossedDescription ? 'line-through' : 'none',
        }}
      >
        {description}
      </p>
    </div>
  );

  const renderCancelationInfo = () => (
    <div
      style={{
        padding: '15px 10px',
        backgroundColor: Colors.neutral_50,
        marginBottom: 20,
      }}
    >
      {renderRow({
        title: 'Cancellation Date:',
        description: moment(job?.job_cancelled_datetime).format('ddd, MMM DD, YYYY'),
      })}
      {renderRow({
        title: 'Cancellation Time:',
        description: moment(job?.job_cancelled_datetime).format('hh:mm a'),
      })}
      {job?.cancelPerformedBy &&
        renderRow({
          title: 'Cancelled by:',
          description: `${job?.cancelPerformedBy?.first_name || ''} ${
            job?.cancelPerformedBy?.last_name || ''
          }`,
        })}
    </div>
  );

  useEffect(() => {
    if (!job || job?.id !== match?.params?.jobId) {
      dispatch(fetchJob({ jobId: match?.params?.jobId }));
    }
  }, []);

  if (!job || isLoading) {
    return <CardSpinner />;
  }

  return (
    <div className="component_container">
      <div
        className="floating_container"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          paddingBottom: 120,
        }}
      >
        <div
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            width: '95%',
          }}
        >
          <div style={{ position: 'absolute', left: 0 }}>
            <BackButton />
          </div>

          <h1 className="global_font f-dark" style={{ textAlign: 'center', marginBottom: 10 }}>
            Transaction History
          </h1>
        </div>

        <p
          className="global_font"
          style={{
            margin: 0,
            marginBottom: 28,
            fontSize: 18,
            fontWeight: 'bold',
            color: Colors.neutral_600,
          }}
        >
          Shift Status:{' '}
          <span
            style={{
              fontWeight: 'normal',
              color: getJobStatus(job?.status)?.color,
            }}
          >
            {getJobStatus(job?.status)?.label || job?.status}
          </span>
        </p>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '70%',
            padding: '35px 5%',
            marginBottom: 16,
            boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
          }}
        >
          <p
            className="global_font"
            style={{
              margin: 0,
              marginBottom: 22,
              fontSize: 20,
              fontWeight: 'bold',
              color: Colors.neutral_600,
              textAlign: 'center',
            }}
          >
            {job?.is_cancelled ? 'Original' : 'Final'} Shift Details
          </p>

          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ width: '45%' }}>
              {renderRow({ title: 'Date:', description: job?.local_date })}
              {job.offer_final_reciever &&
                renderRow({
                  title: 'Professional:',
                  description: `${job.offer_final_reciever?.first_name} ${job.offer_final_reciever?.last_name}`,
                })}
              {renderRow({
                title: 'Profession:',
                description: UserSubProfession.getName(job?.subProfession) ?? job?.professionName,
              })}
              {renderRow({
                title: 'Preferred Specialty:',
                description: job?.specialty?.split('_')?.[1] || 'General',
              })}
              {renderRow({
                title: 'Scheduled By:',
                description: job?.offer_owner?.full_name,
              })}
            </div>

            <div style={{ width: '45%' }}>
              {renderRow({
                title: 'Hours:',
                description: `${job?.local_start_time} - ${job?.local_end_time}`,
                timezone: true,
              })}
              {renderRow({
                title: 'Hourly Rate:',
                description: `$${job?.rate?.toFixed(2)}`,
              })}
              {renderRow({
                title: `Meal Break:`,
                description: `${job?.lunch_break_time || 0} min ${
                  job?.is_lunch_break_paid ? '(Paid)' : '(Unpaid)'
                }`,
              })}
              {renderRow({
                title: (
                  <>
                    Adjustment Made:
                    <span
                      style={{
                        marginLeft: '5px',
                        position: 'relative',
                        top: '5px',
                      }}
                    >
                      <Tooltip content={adjustmentMadeTooltip} />
                    </span>
                  </>
                ),
                description: job?.isAdjusted ? 'Yes' : 'No',
              })}
            </div>
          </div>

          <div
            style={{
              width: '100%',
              borderBottom: '1px dashed #CBD4D8',
              marginTop: 35,
              marginBottom: 18,
            }}
          />

          <div
            key={job?.id}
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ width: '45%' }}>
              {!showCandidateOvertime() ? (
                renderRow({
                  title: `Professional Pay (${job?.workingHourls?.toFixed(1)} hrs):`,
                  description: `$${job?.amount?.toFixed(2)}`,
                  crossedDescription: job?.status === 'cancelled' || job?.status === 'expired',
                })
              ) : (
                <>
                  {job?.regularAndOvertimeHours?.regular > 0 &&
                    job?.totalRegular > 0 &&
                    renderRow({
                      title: `Regular Rate Pay (${job?.regularAndOvertimeHours?.regular?.toFixed(
                        2,
                      )} hrs):`,
                      description: `$ ${job?.totalRegular?.toFixed(2) || ''}`,
                      crossedDescription: job?.status === 'cancelled' || job?.status === 'expired',
                    })}
                  {job?.regularAndOvertimeHours?.overtime > 0 &&
                    job?.totalOvertime > 0 &&
                    renderRow({
                      title: (
                        <div style={{ position: 'relative' }}>
                          {`Overtime Pay (${job?.regularAndOvertimeHours?.overtime?.toFixed(
                            2,
                          )} hrs):`}
                          <span
                            style={{
                              marginLeft: '5px',
                              position: 'absolute',
                            }}
                          >
                            <Tooltip content={overtimeTooltip} />
                          </span>
                        </div>
                      ),
                      description: `$${job?.totalOvertime?.toFixed(2) || ''}`,
                      crossedDescription: job?.status === 'cancelled' || job?.status === 'expired',
                    })}
                </>
              )}

              {renderRow({
                title: `Service Fee (${job?.tm_fee_percent}%):`,
                description: `$${job?.tm_fee?.toFixed(2)}`,
                crossedDescription: job?.status === 'cancelled' || job?.status === 'expired',
              })}
              {isEmployeeOption(user) &&
                renderRow({
                  title: 'Employee Fee:',
                  description: `$${job?.employeeOptionFee?.toFixed(2)}`,
                  crossedDescription: job?.status === 'cancelled' || job?.status === 'expired',
                })}
              {job?.convenience_fee ? (
                renderRow({
                  title: (
                    <div style={{ position: 'relative' }}>
                      {`Convenience Fee (${job?.convenience_fee_percent?.toFixed(1)}%):`}
                      <span
                        style={{
                          marginLeft: '5px',
                          position: 'absolute',
                        }}
                      >
                        <Tooltip content={convenienceFeeTooltip} />
                      </span>
                    </div>
                  ),
                  description: `$${job?.convenience_fee?.toFixed(2)}`,
                  crossedDescription: job?.status === 'cancelled' || job?.status === 'expired',
                })
              ) : (
                <></>
              )}

              {job?.counterCover
                ? renderRow({
                  title: 'GoTu Counter Cover:',
                  description: `-$${job.counterCover.amount.toFixed(2)}`,
                })
                : null}
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                width: '45%',
              }}
            >
              {job?.is_cancelled && renderCancelationInfo()}

              <p
                className="global_font"
                style={{
                  margin: 0,
                  fontSize: 24,
                  fontWeight: 'bold',
                  color: Colors.neutral_600,
                  width: '100%',
                  textAlign: 'right',
                }}
              >
                {job?.status === 'cancelled' ? 'Cancellation Fee: ' : 'Total Pay: '}
                <span style={{ color: Colors.success_500 }}>
                  {`$${job?.totalTransactionAmount?.toFixed(2)}`}
                </span>
              </p>
            </div>
          </div>
        </div>

        {job?.is_cancelled && (
          <p
            className="global_font"
            style={{
              color: Colors.neutral_600,
              fontSize: 15,
              margin: 0,
              marginBottom: 12,
            }}
          >
            For full details on the Offices Cancellation Policy, please see{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={Links.termsOfUse}
              style={{ color: Colors.primary_500, fontWeight: 'bold', textDecoration: 'none' }}
            >
              Terms and Conditions.
            </a>
          </p>
        )}

        {job?.counterCover && (
          <span
            style={{
              fontFamily: 'Nunito',
              fontSize: 15,
              color: Colors.neutral_600,
              textAlign: 'center',
              marginTop: 8,
              marginBottom: 8,
            }}
          >
            The Total Pay shown above reflects that GoTu’s Counter Cover was applied successfully.
          </span>
        )}

        <div style={{ position: 'relative', width: '80%', marginTop: 16 }}>
          <div
            style={{
              backgroundColor: Colors.neutral_70,
              padding: '50px 30px',
              boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
            }}
          >
            <p
              className="global_font"
              style={{
                margin: 0,
                marginBottom: 30,
                fontSize: 20,
                fontWeight: 'bold',
                color: Colors.neutral_600,
                textAlign: 'center',
              }}
            >
              Transaction Table
            </p>

            <div style={{ display: 'flex', marginBottom: 15 }}>
              <p style={Styles.columnTitle}>Charge Date</p>
              {!showCandidateOvertime() ? (
                <p style={Styles.columnTitle}>Professional Pay</p>
              ) : (
                <p style={Styles.columnTitle}>Total Professional Pay</p>
              )}
              <p style={Styles.columnTitle}>Service Fee</p>
              {isEmployeeOption(user) && <p style={Styles.columnTitle}>Employee Fee</p>}
              <p style={Styles.columnTitle}>Convenience Fee</p>
              <p style={Styles.columnTitle}>Total</p>
              <p style={Styles.columnTitle}>Status</p>
            </div>

            {job?.transactions?.map((transaction) => {
              const { hygienistPay, tmFee, convinienceFee, employeeOptionFee } =
                returnTransactionComponent(transaction);

              return (
                <div style={{ display: 'flex' }} key={transaction?.id}>
                  <p style={Styles.columnItem}>{moment(transaction?.date).format('MM/DD/YYYY')}</p>

                  <p style={Styles.columnItem}>
                    {hygienistPay
                      ? `${transaction?.type === 'debit' ? '' : '-'}$${hygienistPay?.toFixed(2)}`
                      : '-'}
                  </p>

                  <p style={Styles.columnItem}>
                    {tmFee
                      ? `${transaction?.type === 'debit' ? '' : '-'}$${tmFee?.toFixed(2)}`
                      : '-'}
                  </p>

                  {isEmployeeOption(user) && job.employeeOptionFee && (
                    <p style={Styles.columnItem}>
                      {employeeOptionFee
                        ? `${transaction?.type === 'debit' ? '' : '-'}$${employeeOptionFee?.toFixed(
                          2,
                        )}`
                        : '-'}
                    </p>
                  )}

                  <p style={Styles.columnItem}>
                    {convinienceFee
                      ? `${transaction?.type === 'debit' ? '' : '-'}$${convinienceFee?.toFixed(2)}`
                      : '-'}
                  </p>

                  <p style={Styles.columnTitle}>
                    {`${transaction?.type === 'debit' ? '' : '-'}$${transaction?.amount?.toFixed(
                      2,
                    )}`}
                  </p>

                  <p
                    className="global_font"
                    style={{
                      margin: 0,
                      textAlign: 'center',
                      fontSize: 16,
                      fontWeight: 'bold',
                      width: 'calc(100% / 6)',
                      color:
                        transaction?.type === 'debit' ? Colors.success_500 : Colors.primary_500,
                    }}
                  >
                    {transaction?.type === 'debit' ? 'CHARGED' : 'REFUNDED'}
                  </p>
                </div>
              );
            })}

            <div
              style={{
                width: '100%',
                borderBottom: '1px solid #819096',
                marginTop: 20,
                marginBottom: 18,
              }}
            />

            <div style={{ display: 'flex' }}>
              <div style={{ width: 'calc(100% / 2)' }} />

              <p style={Styles.columnTitle}>Total Amount</p>
              <p style={Styles.columnTitle}>
                {job?.totalTransactionAmount < 0
                  ? `-$${(job?.totalTransactionAmount * -1)?.toFixed(2)}`
                  : `$${job?.totalTransactionAmount?.toFixed(2)}`}
              </p>
            </div>
          </div>

          <img
            src={roundedPattern}
            style={{
              position: 'absolute',
              backgroundColor: 'white',
              top: '89%',
              objectFit: 'cover',
              width: '100%',
            }}
            alt="rounded_pattern"
          />
        </div>
      </div>
    </div>
  );
}

const Styles = {
  columnTitle: {
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    width: 'calc(100% / 6)',
    margin: 0,
    textAlign: 'center',
    color: Colors.neutral_600,
  },
  columnItem: {
    fontFamily: 'Nunito',
    width: 'calc(100% / 6)',
    margin: 0,
    textAlign: 'center',
    color: Colors.neutral_600,
  },
};
