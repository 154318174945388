import { useFormContext as useFormContextRHF } from "react-hook-form";

export const useFormContext = () => {
  const { setValue: setValueRHF, ...rest } = useFormContextRHF();

  const setValue = (name, value, options = {}) => {
    setValueRHF(name, value, {...options, shouldDirty: true, shouldValidate: true});
  }

  return {
    setValue,
    ...rest
  }
}