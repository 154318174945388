export const Row = ({
  children,
  width,
  marginLeft,
  marginTop,
  marginBottom,
  height,
  justifyContent,
  gap,
  style,
  alignItems,
  ...props
}) => (
  <div
    {...props}
    style={{
      display: 'flex',
      flexDirection: 'row',
      width,
      height,
      gap,
      marginTop,
      marginLeft,
      marginBottom,
      alignItems,
      justifyContent,
      ...style,
    }}
  >
    {children}
  </div>
);
