import moment from 'moment';
import { useEffect, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { useSelector } from 'react-redux';
import { NUMBER_OF_DAYS_TO_ADD } from './CalendarPopup';
import { HeadRow } from '../components/Request/multiday/components/Rows/DateRow/HeadRow';
import { MonthSelection } from '../components/Request/multiday/components/Rows/DateRow/MonthSelection';
import { convertDatesToTimezone } from '../components/Request/multiday/components/Rows/DateRow/utils';

export const CalendarSelection = ({ onSelect, onLimitExceeded, maxDaysToSelect, dates = [] }) => {
  const user = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);
  const [selectedDates, setSelectedDates] = useState([]);
  const fromMonth = new Date();

  useEffect(() => {
    setSelectedDates(
      dates?.length ? convertDatesToTimezone(dates).map((date) => new Date(date)) : [],
    );
  }, [dates, user.time_zone]);

  return (
    <>
      <DayPicker
        mode="multiple"
        onSelect={(dateArr) => {
          const formattedDates = dateArr.map((date) => moment(date).format('YYYY-MM-DD'));
          if (maxDaysToSelect && formattedDates.length > maxDaysToSelect) {
            if (maxDaysToSelect === 1) {
              const lastDate = formattedDates[formattedDates.length - 1];
              setSelectedDates([lastDate]);
              onSelect([lastDate]);
              return;
            }
            onLimitExceeded?.();
            return;
          }
          onSelect(formattedDates);
        }}
        selected={selectedDates}
        fromMonth={fromMonth}
        components={{
          Caption: MonthSelection,
          Head: HeadRow,
        }}
        disabled={[
          {
            before: new Date(),
            after: moment().add(NUMBER_OF_DAYS_TO_ADD, 'days').toDate(),
          },
        ]}
      />
    </>
  );
};
