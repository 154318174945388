import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDaysMDB } from '../../../../../../actions';
import CalendarIcon from '../../../../../../images/calendar-icon.svg';
import WarningIcon from '../../../../../../images/icons/Warning';
import { Colors } from '../../../../../../themes/colors';
import ConfirmPopup from '../../../../../shared/ConfirmPopup';
import { CollapsibleRow } from '../../CollapsibleRow/CollapsibleRow';
import { Title } from '../../CollapsibleRow/Title';
import { DateContent } from './DateContent';
import { Value } from './Value';
import { handleConfirmRemoveDate } from './utils';

export const DateRow = ({ onToggle, isOpen, showInvite = false }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);
  const jobDates = useSelector((state) => state.multiday.jobDates);
  const values = !jobDates ? [] : jobDates.map((date) => moment(date).format('ddd, MMM DD, YYYY'));
  const [showDeleted, setShowDeleted] = useState({ open: false, date: null });
  const [selectedDates, setSelectedDates] = useState([]);
  const [showInviteNumberWarningPopup, setShowInviteNumberWarningPopup] = useState(false);

  const handleRemoveDate = (index) => {
    setShowDeleted({ open: true, date: jobDates[index] });
  };

  const confirmRemoveDate = () => {
    const jobData = handleConfirmRemoveDate(jobDates, showDeleted.date);
    dispatch(selectDaysMDB({ jobData }));
    setShowDeleted({ open: false, date: null });
  };

  const handleConfirmDates = (jobData) => {
    dispatch(
      selectDaysMDB({
        jobData: jobData.reduce((acc, date) => {
          acc[date] = {};
          return acc;
        }, {}),
      }),
    );
    onToggle();
  };

  useEffect(() => {
    setSelectedDates(isOpen && jobDates?.length ? jobDates : []);
  }, [jobDates, user.time_zone, isOpen]);

  return (
    <>
      <CollapsibleRow
        isOpen={isOpen}
        onToggle={onToggle}
        disabled={jobDates?.length && !isOpen}
        showArrow={!jobDates?.length}
        TitleComponent={() => <Title text="Dates:" />}
        titlePosition="flex-start"
        ValueComponent={() => (
          <Value
            handleRemoveDate={handleRemoveDate}
            onToggle={onToggle}
            showSelectedDates={!isOpen && jobDates?.length}
            values={values}
          />
        )}
        icon={CalendarIcon}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span
            style={{
              fontFamily: 'Nunito',
              color: Colors.neutral_600,
              fontStyle: 'italic',
              fontSize: 16,
              marginBottom: 20,
            }}
          >
            Use the calendar below to request one or more days.
          </span>
          <DateContent
            maxDaysToSelect={showInvite && 10}
            onConfirm={handleConfirmDates}
            defaultValues={selectedDates}
            onLimitExceeded={() => showInvite && setShowInviteNumberWarningPopup(true)}
          />
        </div>
      </CollapsibleRow>

      {showDeleted.open && (
        <ConfirmPopup
          title="Remove Date"
          description={
            <>
              Are you sure you would like to remove{' '}
              <strong>{moment(showDeleted.date).format('ddd, MMM DD, YYYY')}</strong>
              <br />
              from your request?
            </>
          }
          descriptionStyle={{ width: '95%', marginBottom: 50 }}
          leftButtonText="Go Back"
          leftButtonStyle={{
            backgroundColor: Colors.secondary_500,
          }}
          closePopup={() => setShowDeleted({ open: false, date: null })}
          leftButtonAction={() => setShowDeleted({ open: false, date: null })}
          rightButtonText="Remove"
          rightButtonAction={confirmRemoveDate}
        />
      )}

      {showInviteNumberWarningPopup && (
        <ConfirmPopup
          Icon={() => (
            <div style={{ marginBottom: 20 }}>
              <WarningIcon width={80} height={80} color={Colors.warning_500} />
            </div>
          )}
          title="Sorry!"
          description="The maximum number of invites have been reached for this request form. You can send up to 10 shift invites at a time."
          rightButtonText="Close"
          rightButtonAction={() => setShowInviteNumberWarningPopup(false)}
        />
      )}
    </>
  );
};
