import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_JOB_ACTION, fetchJob } from '../../../../../actions';
import useLoading from '../../../../../hooks/useLoading';

export const useJob = (jobId) => {
  const dispatch = useDispatch();
  const job = useSelector((state) => state.job.job);
  const { isLoading } = useLoading([FETCH_JOB_ACTION]);

  useEffect(() => {
    if (jobId !== job?.id) {
      dispatch(fetchJob({ jobId }));
    }
  }, [jobId, job?.id, dispatch]);

  return { job, isLoading };
};
