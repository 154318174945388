export const apiMethodsConst = {
  SIGN_IN_REQUEST: 'signInRequest',
  AUTH_ME_REQUEST: 'authMeRequest',
  USER_CREDENTIALS_REQUEST: 'userCredentialsRequest',
  AUTH_SIGN_UP_REQUEST: 'authSignUpRequest',
  AUTH_LOGOUT_REQUEST: 'authLogoutRequest',
  EDIT_USER_REQUEST: 'editUserRequest',
  ADD_ADDRESS_REQUEST: 'addAddressRequest',
  ADD_OFFICE_PRODUCTIVITY_REQUEST: 'addOfficeProductivityRequest',
  BLOCK_PROFESSIONAL_REQUEST: 'blockProfessionalRequest',
  PHONE_VERIFICATION_REQUEST: 'phoneVerificationRequest',
  SEND_SMS_CODE_REQUEST: 'sendSMSCodeRequest',
  DELETE_ADDRESSES_REQUEST: 'deleteAddressesRequest',
  AUTH_REFRESH_REQUEST: 'authRefreshRequest',
  FEEDBACK_REQUEST: 'feedbackRequest',
  FEEDBACK_SAVE_REQUEST: 'feedbackSaveRequest',
  SEND_PROCEDURE_ENDORSEMENT_REQUEST: 'sendProcedureEndorsementRequest',
  FETCH_USER_PROCEDURE_ENDORSEMENT_REQUEST: 'fetchUserProcedureEndorsementRequest',
  SEND_NPS_REQUEST: 'saveNpsRequest',
  SEND_TEMPMEE_FEEDBACK_REQUEST: 'saveTempMeeFeedbackRequest',
  GET_ALL_PROFESSIONS_REQUEST: 'getAllProfessionsRequest',
  GET_ALL_SPECIALIZATIONS_REQUEST: 'getAllSpecializationsRequest',
  GET_USER_EDUCATION_REQUEST: 'getUserEducationRequest',
  GET_OFFICE_EDUCATION_REQUEST: 'getOfficeEducationRequest',
  UPDATE_USER_EDUCATION_REQUEST: 'updateUserEducationRequest',
  GET_ALL_SOFTWARES_REQUEST: 'getAllSoftwaresRequest',
  ACCEPT_TERMS_REQUEST: 'acceptTermsRequest',
  ACCEPT_PSA_TERMS_REQUEST: 'acceptPSATermsRequest',
  UPLOAD_AVATAR_REQUEST: 'uploadAvatarRequest',

  FORGOT_PASSWORD_REQUEST: {
    SEND_CODE: 'forgotPasswordSendCodeRequest',
    RESET: 'forgotPasswordResetRequest',
  },

  CLOSE_MODAL_ACTION: 'CLOSE_MODAL_ACTION_SUCCESS',
  // dso
  GET_SELECTED_OFFICE_PROFILE_REQUEST: 'getSelectedOfficeProfile',

  // referrals
  SAVE_GIFT_EMAIL_REQUEST: 'saveGiftEmailRequest',
  GET_REFERRALS_REQUEST: 'getReferralRequest',
  CRETE_REFERRALS_REQUEST: 'createReferralRequest',
  PUT_REFERRAL_LINK_REQUEST: 'putReferralLinkRequest',
  CREATE_FIREBASE_LINK: 'createFirebaseLinkRequest',
  // payment
  GET_ALL_PAYMENTS_REQUEST: 'getAllPaymentsRequest',
  ADD_PAYMENT_REQUEST: 'addPaymentRequest',
  GET_PLAID_TOKEN: 'createPlaidToken',
  SUBMIT_PLAID_TOKEN: 'submitPlaidToken',

  DELETE_PAYMENT_REQUEST: 'deletePaymentRequest',
  SELECT_PAYMENT_REQUEST: 'selectPaymentRequest',
  VERIFY_BANK_REQUEST: 'verifyBankRequest',
  // job
  CREATE_JOB_REQUEST: 'createJobRequest',
  FETCH_JOB_REQUEST: 'fetchJobRequest',
  FETCH_JOB_CANDIDATES_REQUEST: 'fetchJobCandidatesRequest',
  FETCH_CANDIDATE_OVERTIME_REQUEST: 'fetchCandidateOvertimeRequest',
  GET_USER_OVERTIME_LIMITS_REQUEST: 'getUserOvertimeLimitsRequest',
  FETCH_AVERAGE_RATING: 'fetchAverageRatingRequest',
  EDIT_JOB_REQUEST: 'editJobRequest',
  FETCH_OPEN_JOB_REQUEST: 'fetchOpenJobRequest',
  FETCH_SCHEDULED_JOBS_REQUEST: 'fetchScheduledJobsRequest',
  FETCH_ACTION_REQUIRED_JOBS_REQUEST: 'fetchActionRequiredJobsRequest',
  FETCH_TODAY_JOBS_REQUEST: 'fetchTodayJobsRequest',
  GET_ALL_JOBS_REQUEST: 'getAllJobsRequest',
  GET_LAST_POSTED_JOBS_REQUEST: 'getLastPostedJobsRequest',
  GET_JOBS_BY_STATUS_DATE_REQUEST: 'getJobsRequestByStatusDate',
  FETCH_JOBS_WITH_TRANSACTIONS: 'fetchJobsWithTransactions',
  CANCEL_JOB_REQUEST: 'cancelJobRequest',
  CONFIRM_JOB_REQUEST: 'confirmJobRequest',
  REPORT_INCIDENT_REQUEST: 'reportIncidentRequest',
  MULTIDAY_JOB_POSTING_REQUEST: 'multidayJobPostingRequest',
  PP_POSTING_REQUEST: 'ppbPostingRequest',
  DECLINE_COUNTER_OFFER_REQUEST: 'declineCounterOffer',
  FETCH_EXPIRED_COUNTER_OFFERS: 'fetchExpiredCounterOffersRequest',
  FETCH_DASHBOARD_INFO: 'fetchDashboardInfoRequest',
  ADJUST_HOURS_REQUEST: 'adjustHoursRequest',
  FETCH_CANCELLATION_REASONS_REQUEST: 'fetchCancellationReasonsRequest',
  SET_CANCELLATION_REASONS_REQUEST: 'setCancellationReasonsRequest',

  // alerts
  GET_ALERTS_REQUEST: 'getAlertsRequest',
  DELETE_ALERTS_REQUEST: 'deleteAlertsRequest',
  DELETE_ONE_ALERT_REQUEST: 'deleteOneAlertRequest',
  // messages
  GET_MESSAGES_REQUEST: 'getMessagesRequest',
  GET_MESSAGES_BY_JOB_ID_REQUEST: 'getMessagesByJobIdRequest',
  POST_MESSAGE_REQUEST: 'postMessageRequest',
  INVITE_ADMIN_REQUEST: 'inviteAdminRequest',
  // receipts
  FETCH_RECEIPTS_REQUEST: 'fetchReceiptsRequest',
  FETCH_RECEIPTS_REPORT_REQUEST: 'fetchReceiptsReportRequest',
  // invoices
  FETCH_INVOICES_REQUEST: 'fetchInvoicesRequest',

  // pp
  FETCH_ALL_PP_JOBS: 'fetchAllPPJobsRequest',
  FETCH_PP_APPLICATIONS_REQUEST: 'fetchPPApplicationsRequest',
  POST_PP_ACTIONS_REQUEST: 'postPPActionnRequest',
  FETCH_PP_APPLICATION_BY_ID_REQUEST: 'fetchPPApplicationByIdRequest',

  // adjustments
  FETCH_JOBS_WITH_ADJUSTMENTS_REQUEST: 'fetchAdjustmentsRequest',
  CHANGE_STATUS_ADJUSTMENTS_REQUEST: 'changeStatusAdjustmentsRequest',

  // locum tenens dentist
  CREATE_LOCUM_TENENS_DENTIST_REQUEST: 'createLocumTenensDentistRequest',

  // notifications
  FETCH_NOTIFICATION_SETTINGS_REQUEST: 'fetchNotificationSettingsRequest',
  UPDATE_NOTIFICATION_SETTINGS_REQUEST: 'updateNotificationSettingsRequest',

  // favorites
  FETCH_FAVORITE_PROFESSIONALS_REQUEST: 'fetchFavoritesProfessionalRequest',
  DELETE_FAVORITE_PROFESSIONAL_REQUEST: 'deleteFavoriteProfessionalRequest',
  ADD_FAVORITE_PROFESSIONAL_REQUEST: 'addFavoriteProfessionalRequest',
  INVITE_FAVORITE_TO_JOB_REQUEST: 'inviteFavoriteToJobRequest',
  INVITE_PROFESSIONALS_TO_JOBS_REQUEST: 'inviteProfessionalsToJobsRequest',

  // recent professionals
  FETCH_RECENT_PROFESSIONALS_REQUEST: 'fetchRecentProfessionalsRequest',
  FETCH_OPEN_JOBS_FOR_RECENT_PROFESSIONAL_REQUEST: 'fetchOpenJobsForRecentProfessionalRequest',

  // highly-rated professionals
  FETCH_HIGHLY_RATED_PROFESSIONALS_REQUEST: 'fetchHighlyRatedProfessionalsRequest',

  // invites
  FETCH_OFFICE_JOB_INVITES_REQUEST: 'fetchOfficeJobInvitesRequest',

  // partnership code
  FETCH_PARTNERSHIP_ORGANIZATIONS_REQUEST: 'fetchPartnershipOrganizationsRequest',
  VERIFY_PARTNERSHIP_CODE_REQUEST: 'verifyPartnershipCodeRequest',

  // metadata
  FETCH_METADATA_REQUEST: 'fetchMetadataRequest',

  // worker type
  SAVE_WORKER_CLASSIFICATION_REQUEST: 'saveWorkerClassificationRequest',
  GET_STATE_WORKER_CLASSIFICATION_REQUEST: 'getStateWorkerClassificationRequest',
  GET_WORKER_CLASSIFICATION_MANAGEMENT: 'getWorkerClassificationManagement',
  SAVE_CHILD_WORKER_CLASSIFICATION: 'saveChildOfficeWorkerClassification',
  SAVE_STATUS_CHILD_WORKER_CLASSIFICATION: 'saveStatusChildOfficeWorkerClassification',

  // Office Rating
  GET_OFFICE_REVIEW_INFO_REQUEST: 'getOfficeReviewInfoRequest',
};
