import '../../themes/global.scss';
import '../../themes/confirm_hyg.scss';

import React from 'react';

import { Colors } from '../../themes/colors';
import defaultImage from '../../images/HY_tooth.png';
import BackWhiteButton from '../../commonComponents/BackWhiteButton';
import Button from '../../commonComponents/Button';

export default ({
  userInfo,
  jobDate,
  inviteAdminPressed,
  openGuidelinePressed,
  isDisabledButton,
}) => (
  <div
    style={{
      backgroundColor: Colors.primary_500,
      borderBottom: '1px solid #ffffff',
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 44,
      paddingTop: 20,
      paddingBottom: 40,
      boxShadow: '0px 3px 4px 0px rgba(0,0,0,0.2) inset',
    }}
  >
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <BackWhiteButton
        whiteArrow
        containerStyle={{ width: 'auto', marginRight: 60, marginBottom: 20 }}
      />

      {userInfo?.profile_image ? (
        <img
          src={userInfo?.profile_image}
          style={{
            width: 100,
            height: 100,
            borderRadius: 100,
            objectFit: 'cover',
          }}
          alt="profile_image"
        />
      ) : (
        <div className="default_circle_container">
          <img src={defaultImage} alt="profile_image" width="50px" />
        </div>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: 20,
        }}
      >
        <h4
          style={{
            fontSize: 24,
            color: Colors.white,
            fontFamily: 'Nunito',
            fontWeight: 'bold',
            marginBottom: 10,
            marginTop: 10,
          }}
        >
          {`${userInfo?.first_name || ''} ${userInfo?.last_name || ''}`}
        </h4>

        <div className="global_font " style={{ color: Colors.white }}>
          Shift Date: {jobDate}
        </div>
      </div>
      <div style={{ flex: 1 }} />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'center',
          borderBottomColor: '#E5E5E5',
          borderBottomWidth: 1.5,
          width: '40%',
          marginRight: 20,
          justifyContent: 'center',
          alignItems: 'flex-end',
          padding: 10,
        }}
      >
        <Button
          text="Chat Guidelines"
          onClick={() => openGuidelinePressed()}
          size="small"
          style={{
            marginBottom: 15,
            backgroundColor: Colors.secondary_500,
          }}
        />

        <Button
          disabled={isDisabledButton}
          text="Request Support"
          onClick={() => inviteAdminPressed()}
          size="small"
          style={{
            backgroundColor: isDisabledButton ? Colors.neutral_300 : Colors.success_500,
          }}
        />
      </div>
    </div>
  </div>
);
