import { motion } from 'framer-motion';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  FEEDBACK_SAVE_ACTION,
  FETCH_JOB_ACTION,
  addFavoriteProfessional,
  blockProfessional,
  feedbackSave,
  fetchJob,
  hideMessage,
  updateRatingCount,
} from '../actions';
import { createErrorObjectSelector, createLoadingSelector } from '../api/selectors';
import Button from '../commonComponents/Button';
import CardSpinner from '../commonComponents/CardSpinner';
import { getAverageRating, isGoodRating } from '../commonComponents/CommonFunctions';
import MobileAppModal from '../commonComponents/MobileAppModal';
import RatingInput from '../commonComponents/RatingInput';
import { growthbook } from '../growthbook';
import blockCross from '../images/block-cross.svg';
import blockUserIcon from '../images/block-user.svg';
import heartOutline from '../images/heart-outline.svg';
import PencilIcon from '../images/icons/Pencil';
import { Colors } from '../themes/colors';
import '../themes/global.scss';
import '../themes/job_summary.scss';
import ProfileSummary from './JobSummary/ProfileSummary';
import Tooltip from './Tooltip';
import ConfirmPopup from './shared/ConfirmPopup';
import { trackEvent } from '../api/analytics';

class JobSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedbackForTempmee: '',
      modalIsOpen: false,
      isConfirmationPopupShow: false,
      showUnableToAddBlockedModal: false,
      showBlockConfirmPopup: false,
      isSubmitError: false,
      isNewRatingMandatory: false,
      professionalSkillsRating: null,
      interpersonalSkillsRating: null,
      timeManagementRating: null,
    };
    this.ratingContainerRef = React.createRef();
  }

  async componentDidMount() {
    const { fetchJob, match, job } = this.props;
    const jobId = match?.params?.jobId;

    if (job?.id !== jobId) {
      fetchJob({ jobId });
    }

    const ratingFeatureValue = await growthbook.getFeatureValue('eod-rating-enhancement');
    const isNewRatingMandatory = ratingFeatureValue === 'new-rating-mandatory';
    this.setState({ isNewRatingMandatory });
  }

  componentDidUpdate(prevProps) {
    const { job, history } = this.props;
    if (job?.isFeedbackSubmited) {
      history.replace(`/dashboard/job/${job.id}/details`);
    }
  }

  addToFavorite = () => {
    const { addFavoriteProfessional, job, user } = this.props;
    const isBlocked = user?.blocked_hygienist?.includes(job?.offer_final_reciever?.id);

    if (isBlocked) {
      this.setState({ showUnableToAddBlockedModal: true });
    } else {
      addFavoriteProfessional(job.offer_final_reciever?.id);
    }
  };

  blockProfessional = () => {
    const { job, blockProfessional } = this.props;

    blockProfessional({ user: job.offer_final_reciever });
  };

  isUserFavorite = () => {
    const { user, job } = this.props;
    const favoriteProfessionals = user?.favoriteProfessionals || [];

    return favoriteProfessionals?.includes(job?.offer_final_reciever?.id);
  };

  getCardDetails = () => {
    const { job } = this.props;
    if (job.card.last4 === '') {
      return '';
    }

    return job?.card?.type === 'card'
      ? `${job.card.brand} ${job.card.last4}`
      : `${job.card.bank_name} ${job.card.last4}`;
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
    if (this.props.mobile) {
      this.props.closeMobile();
    }
  };

  validateAdjustmentsSend = () => {
    const { adjustmentsSend, job } = this.props;

    const isAdjustmensSent = adjustmentsSend.filter((adj) => adj === job.id);
    if (isAdjustmensSent.length === 0) {
      this.setState({ isConfirmationPopupShow: true });
    } else {
      this.sendFeedback();
    }
  };

  sendFeedback = () => {
    const { job, feedbackSave } = this.props;
    const {
      feedbackForTempmee,
      isNewRatingMandatory,
      professionalSkillsRating,
      interpersonalSkillsRating,
      timeManagementRating,
    } = this.state;
    this.setState({ isSubmitError: false });

    if (isNewRatingMandatory) {
      if (!professionalSkillsRating || !interpersonalSkillsRating || !timeManagementRating) {
        this.setState({ isSubmitError: true });
        this.ratingContainerRef.current.focus();
        return;
      }
    }

    let professionalRating = null;
    if (timeManagementRating || interpersonalSkillsRating || professionalSkillsRating) {
      professionalRating = Object.fromEntries(
        Object.entries({
          timeManagement: timeManagementRating,
          interpersonalSkills: interpersonalSkillsRating,
          professionalSkills: professionalSkillsRating,
        }).filter(([, value]) => value !== undefined && value !== null),
      );
    }

    const data = {
      comment: feedbackForTempmee,
      hireAgain: 'sure',
      rating: null,
      professionalRating,
    };

    feedbackSave({ data, jobId: job.id });

    trackEvent('Office EOD Rating Submitted', {
      job_id: job.id,
      is_mandatory: isNewRatingMandatory,
      time_management: timeManagementRating,
      professional_skills: professionalSkillsRating,
      interpersonal_skills: interpersonalSkillsRating,
      average_rating: getAverageRating([
        professionalSkillsRating,
        interpersonalSkillsRating,
        timeManagementRating,
      ]),
      feedback: feedbackForTempmee,
    });
  };

  renderProfessionalActions = () => {
    const { user, job } = this.props;
    const { professionalSkillsRating, interpersonalSkillsRating, timeManagementRating } =
      this.state;

    const averageRating = getAverageRating([
      professionalSkillsRating,
      interpersonalSkillsRating,
      timeManagementRating,
    ]);

    const shouldDisplayFavorite = averageRating === null || isGoodRating(averageRating);

    if (shouldDisplayFavorite) {
      return (
        <>
          {!this.isUserFavorite() ? (
            <motion.div
              key="favorite_container"
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            >
              <div>
                <h3
                  className="f-16 global_font f-dark"
                  style={{
                    margin: '40px 0 30px 0',
                    width: '330px',
                    textAlign: 'center',
                  }}
                >
                  Would you like to add this professional to your Favorites List?
                  <span
                    style={{
                      marginLeft: '5px',
                      position: 'relative',
                      top: '5px',
                    }}
                  >
                    <Tooltip content="By adding this professional to your Favorites List, you may directly request them for open shifts in the future." />
                  </span>
                </h3>
              </div>

              <button
                type="button"
                className="green-button"
                style={{
                  alignSelf: 'center',
                  width: 220,
                  margin: 0,
                  backgroundColor: Colors.primary_500,
                }}
                onClick={this.addToFavorite}
              >
                <img src={heartOutline} style={{ marginRight: 8 }} alt="heart_outline" />

                <p className="white-text-18" style={{ margin: 0 }}>
                  Add to Favorites
                </p>
              </button>
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              style={{ marginTop: '60px' }}
            >
              <p
                className="global_font f-18"
                style={{
                  margin: 0,
                  color: Colors.primary_500,
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                Congrats!
              </p>
              <p className="global_font" style={{ color: Colors.neutral_600 }}>
                This professional is now on your Favorites List.
              </p>
            </motion.div>
          )}
        </>
      );
    }

    return (
      <>
        {!user?.blocked_hygienist?.includes(job?.offer_final_reciever?.id) ? (
          <motion.div
            key="block_container"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            <div>
              <h3
                className="f-16 global_font f-dark"
                style={{
                  margin: '40px 0 30px 0',
                  width: '330px',
                  textAlign: 'center',
                }}
              >
                Would you like to block this professional from working with you again?
                <span
                  style={{
                    marginLeft: '5px',
                    position: 'relative',
                    top: '5px',
                  }}
                >
                  <Tooltip content="Blocking a professional means that they will not be able to see shifts posted by this office, preventing you from working with them in the future." />
                </span>
              </h3>
            </div>

            <button
              type="button"
              className="green-button"
              style={{
                alignSelf: 'center',
                width: 240,
                margin: 0,
                backgroundColor: Colors.neutral_500,
              }}
              onClick={() => this.setState({ showBlockConfirmPopup: true })}
            >
              <img src={blockUserIcon} style={{ marginRight: 8 }} alt="block_user_icon" />

              <p className="white-text-18" style={{ margin: 0 }}>
                Block Professional
              </p>
            </button>
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            style={{ marginTop: '60px' }}
          >
            <p
              className="global_font f-18"
              style={{
                margin: 0,
                color: Colors.neutral_600,
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              Successfully Blocked
            </p>
            <p className="global_font" style={{ color: Colors.neutral_600 }}>
              This professional is now blocked.
            </p>
          </motion.div>
        )}
      </>
    );
  };

  renderRating = () => {
    const {
      isNewRatingMandatory,
      professionalSkillsRating,
      interpersonalSkillsRating,
      timeManagementRating,
      isSubmitError,
    } = this.state;

    return (
      <>
        <span className="global_font f-dark f-20 bold" style={{ marginTop: 0 }}>
          Please rate this professional{' '}
          {isNewRatingMandatory ? (
            <span className="f-16" style={{ fontWeight: 400, color: Colors.neutral_500 }}>
              (Required)
              <span className="bold" style={{ color: Colors.error_500 }}>
                *
              </span>
            </span>
          ) : (
            <span className="f-16" style={{ fontWeight: 400, color: Colors.neutral_500 }}>
              (Optional)
            </span>
          )}
        </span>
        <span
          style={{
            color: Colors.neutral_500,
            marginTop: 10,
            marginBottom: 40,
            marginInline: 30,
            font: 'Nunito',
            fontSize: 16,
            fontStyle: 'italic',
            textAlign: 'center',
            alignSelf: 'center',
          }}
        >
          Your rating will help us improve our capability to connect you with high-quality
          candidates. This won’t be shared with the professional.
        </span>

        <RatingInput
          title="Professional Skills"
          value={professionalSkillsRating}
          onChange={(value) => this.setState({ professionalSkillsRating: value })}
          direction="row"
          isError={isSubmitError && !professionalSkillsRating}
        />

        <RatingInput
          title="Interpersonal Skills"
          value={interpersonalSkillsRating}
          onChange={(value) => this.setState({ interpersonalSkillsRating: value })}
          direction="row"
          isError={isSubmitError && !interpersonalSkillsRating}
        />

        <RatingInput
          title="Time Management"
          value={timeManagementRating}
          onChange={(value) => this.setState({ timeManagementRating: value })}
          direction="row"
          isError={isSubmitError && !timeManagementRating}
        />
      </>
    );
  };

  render() {
    const { job, isLoading, history, adjustmentsSend } = this.props;

    if (!job || isLoading) {
      return <CardSpinner />;
    }

    const displayAdjustButton =
      new Date().toISOString() > job?.job_datetime && job.status !== 'complete';
    const isAdjustmensSent = adjustmentsSend.filter((jobId) => jobId === job.id);
    const professionalName = `${job.offer_final_reciever?.first_name} ${job.offer_final_reciever?.last_name}`;
    const isFavorite = job.offer_final_reciever?.isFavorite;

    return (
      <div className="component_container" ref={(element) => (this.divRef = element)}>
        <div style={{ width: '100%' }}>
          <ProfileSummary title="Job Summary" job={job} />

          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: '-1px 20px 10px 20px',
              }}
            >
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gridTemplateRows: 'auto',
                  gap: 6,
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    gridArea: '1 / 1 / 2 / 2',
                    backgroundColor: Colors.white,
                    height: 585,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow:
                      '0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
                  }}
                >
                  <p
                    className="global_font"
                    style={{
                      margin: 0,
                      color: Colors.primary_500,
                      fontSize: 18,
                      fontWeight: 'bold',
                    }}
                  >
                    Shift Details
                  </p>
                  <div style={{ width: '300px' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignContent: 'center',
                      }}
                    >
                      <p style={{ color: Colors.neutral_600, fontWeight: 700 }}>Date:</p>
                      <p style={{ color: Colors.neutral_600 }}>{job.local_date}</p>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignContent: 'center',
                      }}
                    >
                      <p style={{ color: Colors.neutral_600, fontWeight: 700 }}>Hours:</p>
                      <p style={{ color: Colors.neutral_600 }}>
                        {`${job.local_start_time} - ${job.local_end_time}`}
                      </p>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignContent: 'center',
                      }}
                    >
                      <p style={{ color: Colors.neutral_600, fontWeight: 700 }}>
                        {`Meal Break: (${job.is_lunch_break_paid ? 'Paid' : 'Unpaid'})`}
                      </p>
                      <p style={{ color: Colors.neutral_600 }}>{`${job.lunch_break_time} min`}</p>
                    </div>
                  </div>
                  {isAdjustmensSent?.length !== 0 ? (
                    <p className="f-dark global-font" style={{ marginTop: '20px' }}>
                      Your adjustment request is under review.
                    </p>
                  ) : (
                    <div />
                  )}
                  {isAdjustmensSent?.length === 0 && displayAdjustButton ? (
                    <div
                      onClick={() => history.push(`/dashboard/jobs/${job.id}/adjust`)}
                      style={{
                        borderRadius: 25,
                        display: 'flex',
                        height: 48,
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'row',
                        border: `2px solid ${Colors.primary_500}`,
                        backgroundColor: Colors.white,
                        marginTop: 25,
                        cursor: 'pointer',
                        width: 195,
                      }}
                    >
                      <PencilIcon />
                      <p
                        className="global_font f-dark bold f-18"
                        style={{
                          textAlign: 'center',
                          color: Colors.primary_500,
                          marginLeft: 8,
                        }}
                      >
                        Adjust Hours
                      </p>
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
                <div
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                  tabIndex={0}
                  ref={this.ratingContainerRef}
                  style={{
                    gridArea: '1 / 2 / 2 / 3',
                    backgroundColor: Colors.white,
                    height: 585,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow:
                      '0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
                  }}
                >
                  {this.renderRating()}
                  {this.renderProfessionalActions()}
                </div>
              </div>
              <div
                style={{
                  margin: '5px 20px 10px 20px',
                  background: Colors.white,
                  boxShadow:
                    ' 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <h3 className="f-18 global_font f-dark" style={{ marginTop: '30px' }}>
                  Confidential Feedback for GoTu{' '}
                  <span className="f-16" style={{ fontWeight: 400, color: Colors.neutral_500 }}>
                    (Optional)
                  </span>
                </h3>
                <textarea
                  className="textarea-style"
                  style={{ width: 468 }}
                  placeholder="Enter information"
                  onChange={(e) => this.setState({ feedbackForTempmee: e.target.value })}
                />
                <div style={{ margin: '35px 0px' }}>
                  <Button
                    text="Submit"
                    style={{ width: 152 }}
                    textClassName="text-capitalize"
                    onClick={this.validateAdjustmentsSend}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <MobileAppModal
          show={this.state.modalIsOpen}
          closeModal={this.closeModal}
          height={1143}
          content="mobile"
        />
        {this.state.isConfirmationPopupShow ? (
          <ConfirmPopup
            title="Final Notice"
            description="Are you sure you don't need to submit an adjustment of hours worked? This is your last opportunity to disclose this information and have your shift adjusted accordingly."
            rightButtonText="Confirm"
            rightButtonAction={() => {
              this.setState({ isConfirmationPopupShow: false });
              this.sendFeedback();
            }}
            leftButtonAction={() => this.setState({ isConfirmationPopupShow: false })}
            leftButtonText="Go Back"
          />
        ) : (
          <div />
        )}

        {this.state.showUnableToAddBlockedModal && (
          <ConfirmPopup
            title="Unable to Add to Favorites"
            description="To add this professional to your Favorites, please contact Customer Support to remove this professional from your blocked list."
            rightButtonText="Close"
            rightButtonAction={() => this.setState({ showUnableToAddBlockedModal: false })}
          />
        )}

        {this.state.showBlockConfirmPopup && (
          <ConfirmPopup
            title="Block Professional"
            description={
              isFavorite
                ? `To block this professional, they must be removed from your Favorites list. Would you like to continue with removing ${professionalName} from your Favorites and blocking them?`
                : 'Are you sure you want to block this professional from working with you again?'
            }
            leftButtonText="Go Back"
            leftButtonAction={() => this.setState({ showBlockConfirmPopup: false })}
            rightButtonText="Continue"
            rightButtonAction={this.blockProfessional}
            Icon={() => <img src={blockCross} style={{ marginBottom: 20 }} alt="block_cross" />}
            closePopup={() => this.setState({ showBlockConfirmPopup: false })}
          />
        )}
      </div>
    );
  }
}

const actions = [FETCH_JOB_ACTION, FEEDBACK_SAVE_ACTION];
const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorObjectSelector(actions);

const mapStateToProps = (state) => ({
  error: errorSelector(state),
  job: state.job.job,
  isLoading: loadingSelector(state),
  adjustmentsSend: state.job.adjustmentsSend,
  ratingCount: state.feedback.ratingCount,
  user: state.user.user,
});

export default connect(mapStateToProps, {
  fetchJob,
  hideMessage,
  feedbackSave,
  addFavoriteProfessional,
  blockProfessional,
  updateRatingCount,
})(JobSummary);
