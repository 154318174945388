import { call, put, take, all, fork } from 'redux-saga/effects';
import * as actions from '../actions';
import { logCustomEvent } from '../api/analytics';
import { apiMethods } from '../services/api';
import { apiMethodsConst } from '../services/methods';

function* callAndDispatch(method, payload) {
  const response = yield call(apiMethods[method], payload);
  yield put({ type: method, payload: response });
}

function* watchFetchPPJobDetails() {
  const events = actions.createRequestTypes(actions.FETCH_APPLICATIONS_PP);
  while (true) {
    try {
      const { jobId } = yield take(actions.FETCH_APPLICATIONS_PP);
      yield logCustomEvent('pp_fetch_job', jobId);

      yield put({ type: events.REQUEST });
      yield* callAndDispatch(apiMethodsConst.FETCH_PP_APPLICATIONS_REQUEST, { jobId });
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchPostApplicationAction() {
  const events = actions.createRequestTypes(actions.POST_ACTION_PP);
  while (true) {
    try {
      const { jobId, applicationId, actionType, notes } = yield take(actions.POST_ACTION_PP);

      yield put({ type: events.REQUEST });
      yield logCustomEvent('pp_create_action', { jobId, actionType });

      yield* callAndDispatch(apiMethodsConst.POST_PP_ACTIONS_REQUEST, {
        jobId,
        applicationId,
        actionType,
        notes,
      });
      if (actionType.includes('interview')) {
        yield put({
          type: actions.SHOW_MESSAGE,
          payload: {
            title: 'The hygienist has been sent your interview preference',
            message: 'You will be notified when the hygienist reserves time on your calendar.',
          },
        });
      }
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchFetchApplicationById() {
  const events = actions.createRequestTypes(actions.FETCH_APPLICATION_BY_ID_PP);
  while (true) {
    try {
      const { jobId, applicationId } = yield take(actions.FETCH_APPLICATION_BY_ID_PP);
      yield logCustomEvent('pp_fetch_application ', { applicationId, jobId });

      yield put({ type: events.REQUEST });
      yield* callAndDispatch(apiMethodsConst.FETCH_PP_APPLICATION_BY_ID_REQUEST, {
        jobId,
        applicationId,
      });
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchFetchAllPPJobs() {
  const events = actions.createRequestTypes(actions.FETCH_ALL_PP_JOBS);
  while (true) {
    try {
      yield take(actions.FETCH_ALL_PP_JOBS);

      yield put({ type: events.REQUEST });
      yield* callAndDispatch(apiMethodsConst.FETCH_ALL_PP_JOBS);
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

export default function* sagaJob() {
  yield all([
    fork(watchFetchPPJobDetails),
    fork(watchFetchApplicationById),
    fork(watchPostApplicationAction),
    fork(watchFetchAllPPJobs),
  ]);
}
