import { FieldValue, OptionField, Box, Column, Divider, Row, RadioOption, Label } from '../../../../commonComponents';
import { CollapsibleCard } from '../../../../commonComponents/CollapsibleCard';
import { ChangeAccountPhoto } from '../../../profile/components/ChangeAccountPhoto';
import { useFormContext } from '../../hooks/useFormContext';
import { CardTitle } from '../CardTitle';
import { PrimaryPracticeField } from './components/PrimaryPracticeField';
import { useOfficeProfile } from './hooks/useOfficeProfile';

export const OfficeProfileCard = ({ isOpen, onToggle }) => {
  const {
    register,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();
  const { formValues, isValid } = useOfficeProfile(control);

  return (
    <CollapsibleCard
      TitleComponent={() => (
        <CardTitle name="Office Profile" showWarningIcon={!isOpen && !isValid} />
      )}
      isOpen={isOpen}
      onToggle={onToggle}
    >
      <Column gap={20} paddingHorizontal={80} marginBottom={40}>
        <Row gap={20}>
          <Box marginTop={24}>
            <ChangeAccountPhoto />
          </Box>

          <Column flex={1} gap={16}>
            <Column flex={1}>
              <FieldValue
                label="Office Name"
                required
                shouldCapitalize={false}
                value={formValues.officeName}
                setValue={(val) => setValue('officeName', val)}
                validation={{
                  ...register('officeName', { required: true }),
                }}
                error={errors.officeName}
                customInputStyle={{ width: '100%', boxSizing: 'border-box' }}
              />
            </Column>
            <Column>
              <FieldValue
                label="Website"
                shouldCapitalize={false}
                placeholder="Company Website"
                value={formValues.website}
                setValue={(val) => setValue('website', val)}
                validation={{
                  ...register('website'),
                }}
                customInputStyle={{ width: '100%', boxSizing: 'border-box' }}
              />
            </Column>
          </Column>
        </Row>

        <Column gap={8} marginTop={12}>
          <Label text="Dentist Name:" required={false} />
          <Box
            gap={12}
            marginTop={0}
            display="grid"
            gridTemplateColumns="1.2fr 2fr 2fr"
            style={{
              maxWidth: '100%',
              gridColumnGap: 12,
              boxSizing: 'border-box',
            }}
          >
            <OptionField
              value={formValues.dentistTitleName}
              setValue={(val) => setValue('dentistTitleName', val)}
              validation={{
                ...register('dentistTitleName', { required: true }),
              }}
              customStyle={{ width: '100%' }}
            />
            <FieldValue
              label="First Name"
              required
              shouldCapitalize={false}
              value={formValues.dentistFirstName}
              setValue={(val) => setValue('dentistFirstName', val)}
              validation={{
                ...register('dentistFirstName', { required: true }),
              }}
              error={errors.dentistFirstName}
              customInputStyle={{ width: '100%', boxSizing: 'border-box' }}
            />

            <FieldValue
              label="Last Name"
              required
              shouldCapitalize={false}
              value={formValues.dentistLastName}
              setValue={(val) => setValue('dentistLastName', val)}
              validation={{
                ...register('dentistLastName', { required: true }),
              }}
              error={errors.dentistLastName}
              customInputStyle={{ width: '100%', boxSizing: 'border-box' }}
            />
          </Box>
        </Column>

        <Column gap={8} marginTop={12}>
          <Label text="Additional Contact Name:" required={false} />
          <Box
            gap={12}
            marginTop={0}
            display="grid"
            gridTemplateColumns="1.2fr 2fr 2fr"
            style={{
              maxWidth: '100%',
              gridColumnGap: 12,
              boxSizing: 'border-box',
            }}
          >
            <OptionField
              value={formValues.additionalContactTitleName}
              setValue={(val) => setValue('additionalContactTitleName', val)}
              validation={{
                ...register('additionalContactTitleName'),
              }}
              customStyle={{ width: '100%' }}
            />
            <FieldValue
              label="First Name"
              required={false}
              value={formValues.additionalContactFirstName}
              setValue={(value) => setValue('additionalContactFirstName', value)}
              validation={{
                ...register('additionalContactFirstName'),
              }}
              customInputStyle={{ width: '100%', boxSizing: 'border-box' }}
            />
            <FieldValue
              label="Last Name"
              required={false}
              shouldCapitalize={false}
              value={formValues.additionalContactLastName}
              setValue={(value) => setValue('additionalContactLastName', value)}
              validation={{
                ...register('additionalContactLastName'),
              }}
              customInputStyle={{ width: '100%', boxSizing: 'border-box' }}
            />
          </Box>
        </Column>

        <Divider marginVertical={20} />

        <Box>
          <Label
            text="Are you a private practice or DSO?"
            marginBottom={20}
            error={errors.officeType}
          />
          <Column gap={20} marginLeft={40}>
            <RadioOption
              text="Private Practice (Independent Office)"
              value="private_practice_independent"
              checked={formValues.officeType === 'private_practice_independent'}
              onClick={() => setValue('officeType', 'private_practice_independent')}
            />
            <RadioOption
              text="Private Practice (Part of a Multi-Office Group)"
              value="private_practice_multi"
              checked={formValues.officeType === 'private_practice_multi'}
              onClick={() => setValue('officeType', 'private_practice_multi')}
            />
            <RadioOption
              text="DSO"
              value="dso"
              checked={formValues.officeType === 'dso'}
              onClick={() => setValue('officeType', 'dso')}
            />
          </Column>
        </Box>

        <Divider marginVertical={20} />

        <PrimaryPracticeField />
      </Column>
    </CollapsibleCard>
  );
};
