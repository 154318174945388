import React, { Component } from 'react';
import BackButton from '../commonComponents/BackButton';
import { Colors } from '../themes/colors';

export default class About extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { isVisible } = this.state;

    return (
      <div className="component_container" ref={(element) => (this.divRef = element)}>
        <div className="floating_container" style={{ minHeight: 900 }}>
          <div style={{ margin: '15px 50px' }}>
            <BackButton />

            <h1 className="global_font f-dark">About</h1>
            <p className="global_font f-dark">
              GoTu is a new and easier way to connect dental professionals with dental offices in
              need of temporary help through an on-demand smartphone app.
            </p>

            <h4 className="global_font f-dark" style={{ color: Colors.primary_500 }}>
              DENTISTS
            </h4>
            <p className="global_font f-dark">
              Connect quickly with licensed dental professionals near you through your smartphone or
              desktop. GoTu works in the background to find you temporary dental professionals that
              are ready to get to work. Whether your office has an unexpected absence, a
              pre-scheduled vacation, or you simply need a little extra help; we have you covered.
              Today, Tomorrow, or 3 months from now, GoTu provides licensed dental professionals
              on-demand.
            </p>

            <h4 className="global_font f-dark">How does it work?</h4>
            <p className="global_font f-dark">
              GoTu’s hygienists are licensed, verified, and are available when YOU need them. You
              GoTu’s dental professionals are licensed, verified, and available when YOU need them.
              You set their schedule and the hourly rate they earn and we’ll handle the rest. After
              you approve the successful completion of the day, the funds will automatically be
              transferred to the dental professionals account. It’s just that easy. After each
              shift, you will get the opportunity to rate your dental professional and select if you
              would like to hire them again. The dental professional will do the same.
            </p>

            <p className="global_font f-dark">
              GoTu is designed to simplify and streamline the slow and frustrating process of
              finding a temporary dental professional. It is quick, easy and cost effective. No need
              to use the time consuming sub lists, unreliable message boards, or overpriced temp
              services. With top rated licensed dental professionals on-demand your office will
              never miss a beat!
            </p>

            <h4 className="global_font f-dark" style={{ color: Colors.primary_500 }}>
              DENTAL PROFESSIONALS
            </h4>
            <p className="global_font f-dark">
              Pick up shifts near you and set your own schedule using our smartphone app or desktop
              site. GoTu is a beautifully designed app that allows you to see which dental offices
              in your area are looking to hire temporary licensed dental professionals. It’s free to
              sign up and fast to get started. There are no fees or time commitments, so you take
              home 100% of what you earn every time you complete a shift.
            </p>

            <h4 className="global_font f-dark">How does it work?</h4>
            <p className="global_font f-dark">
              Review all shifts in your area. Accept the offers that work for you. Decline or
              Counter the ones that don’t. With GoTu on your phone, your schedule is always in your
              hands. Your shift as a GoTu Dental Professional will proceed like any other shift.
              Arrive on time, provide exceptional service, get paid, and head home. Your past,
              present and future shifts are available on your phone and your payment is received
              through the app.
            </p>

            <p className="global_font f-dark">
              After each shift, you will get the opportunity to rate the dental office and select if
              you would like to sub there again. The Dental Office will do the same. Whether you
              choose to work a little or a lot, you are in control of how you share your skills,
              earn on demand, and further your career.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
