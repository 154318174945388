import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UserProfession } from '../../../../enums/UserProfession';
import { getShouldFilterRenouncedProcedures } from '../../../../growthbook';
import { Colors } from '../../../../themes/colors';
import { EducationUtils } from '../../../../utils/EducationUtils';
import { filterObjectKeys } from '../../../../utils/Object';

export const RdhFdProcedures = ({ candidate, job }) => {
  const procedureEndorsement = useSelector((store) => store.job.currentCandidateEndorsement);
  const candidateEducation = candidate?.professional_info?.education;
  const candidateProfession = candidate?.license?.profession;
  const candidateSpecialtiesAndProcedures =
    candidateEducation?.[job?.profession]?.specialtiesAndProcedures;
  const specialties = useMemo(
    () => Object.keys(filterObjectKeys(candidateSpecialtiesAndProcedures, 'specialty')),
    [candidateSpecialtiesAndProcedures],
  );

  const procedures = useMemo(() => {
    const procedures = EducationUtils.getProceduresOfProfession({
      specialtiesAndProcedures: candidateSpecialtiesAndProcedures,
      profession: candidateProfession,
      specialties,
    });

    if (getShouldFilterRenouncedProcedures() && procedureEndorsement) {
      const endorsements = procedureEndorsement.education?.specialtiesAndProcedures ?? [];

      return EducationUtils.getEndorsedProcedures({
        endorsements,
        procedures,
        profession: candidateProfession,
      });
    }

    return procedures;
  }, [candidateEducation, job?.profession]);

  return (
    [UserProfession.RDH, UserProfession.FD].includes(job?.profession) && (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 8,
          marginTop: 12,
          marginLeft: 8,
        }}
      >
        {procedures.map((name) => (
          <span key={name} style={{ display: 'flex', color: Colors.neutral_600, fontSize: 16 }}>
            {`\u2022  ${EducationUtils.removeProcedurePrefix(name)}`}
          </span>
        ))}
      </div>
    )
  );
};
